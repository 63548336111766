/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { fetchProperty, fetchPropertyComments, fetchComment, newComment, updateComment } from '../../misc/apiRequests';
// import { propertiesData } from '../../misc/jsonData/propertyListingData';
// import { commentsJSON, selectedComments1JSON, selectedComments2JSON } from '../../misc/jsonData/forumData';
import PageIndex from '../../presentational/pageIndex';
import PropertyForumDisplayThumb from '../../presentational/properties/propertyForumDisplayThumb';
import '../../../assets/css/propertyForms.css';
import Exit from '../../../assets/images/exit.svg';

const PropertyForms = ({ user, setRedirect, handleLoader, handleModal, handleMessagesModal, handleImageModal }) => {
  const { t } = useTranslation();

  // eslint-disable-next-line camelcase
  const [selectedProperty, setSelectedProperty] = useState();
  const [pageType, setPageType] = useState("repair");
  const [newCommentMode, setNewCommentMode] = useState(false);
  const [commentActive, setCommentActive] = useState(true);
  const [commentType, setCommentType] = useState('repair');
  const [commentTitle, setCommentTitle] = useState('');
  const [commentBody, setCommentBody] = useState('');
  const [commentImages, setCommentImages] = useState([]);
  const [selectedComment, setSelectedComment] = useState(null);
  const [comments, setComments] = useState([]);
  const [pageNumb, setPageNumb] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [numbRecords, setNumbRecords] = useState(0);

  const uploadsInputRef = useRef(null);

  let { id } = useParams();

  const handleSelectComment = comment => {
    if(comment.comment_id !== null) { return console.log('Cant select subcomment'); }
    setPageNumb(1);
    setSelectedComment(comment);
  };

  // const handleIndexChange = (e, nextPage = false) => {
  //   e.preventDefault();
  //   if(!nextPage && ((pageNumb - 1) !== 0)) setPageNumb(pageNumb - 1);
  //   if(nextPage && ((pageNumb + 1) <= maxPages)) setPageNumb(pageNumb + 1);
  // };

  const handleNewCommentMode = (mode = true) => {
    setNewCommentMode(!newCommentMode);
  };

  // Populate all subforums and related posts paginated by 5 posts per page
  const populateComments = () => comments.map(commentData => (
    <PropertyForumDisplayThumb
      key={commentData.id}
      comment={commentData}
      handleSelectComment={handleSelectComment}
    />
  ));

    // Ensures that the comment images uploaded doesn't go too far over 1 megabyte
    const handleCheckFileSize = e => {
      const elem = e.target;
      let overlimit = false;
      const errorList = [];
      Array.from(elem.files).forEach((x, index) => {
        if (elem.files[index].size > 1048576) {
          console.log(elem.files[index]);
          errorList.push(`${x.name} - File size too big (max size allowed: 1 megabyte)`);
          overlimit = true;
        } else { 
          const imagesList = commentImages;
          imagesList.push(elem.files[index]);
          setCommentImages(imagesList);
        }
      })
  
      if (overlimit) {
        elem.value = '';
        setCommentImages([]);
        handleMessagesModal('Image error', errorList, 'error');
      }
    };

    const populateImages = () => commentImages.map((img, index) => (
      // <ImageGallery key={img.id} imgInfo={img} handleImageRemoval={handleImageRemoval} />
      <button type="button" name={img.name} className='img-upload-btn-2 mt-1 mb-05' key={index + 'img'}>
        <img alt="complaint" src={URL.createObjectURL(img)} />
      </button>
    ));
    
    const handleSubmitNew = (e) => {
      e.preventDefault();
      commentImages.forEach((x) => console.log(x));
  
      const commentData = new FormData();
      commentData.append('comment[comment_type]', commentType);
      commentData.append('comment[title]', commentTitle);
      commentData.append('comment[body]', commentBody);
      commentImages.forEach((x) => commentData.append("comment[images][]", x))
      // commentData.append('comment[images]', commentImages);

      handleLoader(true);
      const propertyID = parseInt(id, 10);
      newComment(commentData, propertyID)
        .then(response => {
          if (response.success) {
            setComments(response.records);
            setMaxPages(response.records_params.max_page || 1);
            setNumbRecords(response.records_params.no_of_records)
            handleMessagesModal('Submission Successful', response.message, 'success');
            // setRedirect(`/properties/forms/${propertyID}`)
            handleNewCommentMode()
          }
          if (!response.success) {
            handleMessagesModal('Submission Issue', Array.isArray(response.errors) ? response.errors.join("\n") : response.errors, 'error');
          }
          handleLoader(false);
        });
  
      return null;
    };

  const handleClearImages = () => {
    const uploadsInputElem = uploadsInputRef.current;

    uploadsInputElem.value = '';
    setCommentImages([]);
  }

  // Fetch Property by ID
  useEffect(() => {
    if (id) {
      const propertyID = parseInt(id, 10);
      handleLoader(true);
      fetchProperty(propertyID)
        .then(response => {
          if (response.success) {
            setSelectedProperty(response.record);
          }
          if (!response.success) {
            handleMessagesModal('Submission Issue', Array.isArray(response.errors) ? response.errors.join("\n") : response.errors, 'error');
          }
          handleLoader(false);
        });
    }
  }, [id, handleLoader, handleModal, handleMessagesModal]);

  // Fetch Comments by Property ID and Comment Type
  useEffect(() => {
    if (id) {
      const propertyID = parseInt(id, 10);
      handleLoader(true);
      fetchPropertyComments(perPage, pageNumb, pageType, commentActive, propertyID)
        .then(response => {
          if (response.success) {
            setComments(response.records);
            setMaxPages(response.records_params.max_page || 1);
            setNumbRecords(response.records_params.no_of_records)
          }
          if (!response.success) {
            handleMessagesModal('Submission Issue', Array.isArray(response.errors) ? response.errors.join("\n") : response.errors, 'error');
          }
          handleLoader(false);
        });
    }
  }, [id, pageNumb, handleLoader, handleModal, perPage, pageType, commentActive, handleMessagesModal]);

  // Redirect if unauthorized
  useEffect(() => {
    if(selectedProperty) {
      if(user?.id !== selectedProperty.resident_id && user?.id !== selectedProperty.owner_id) { setRedirect("/"); }
    }
  }, [user, user?.id, selectedProperty, setRedirect])

  // Redirect Page once comment is selected
  useEffect(() => {
    if(selectedComment !== null) {
      const propertyID = parseInt(id, 10);
      const commentID = parseInt(selectedComment?.id, 10);
      setRedirect(`/properties/forms/${propertyID}/comment/${commentID}`);
    }
  }, [id, selectedComment, setRedirect]);

  // Fetch FAKE DATA Property by ID
    // useEffect(() => {
    //   if (id) {
    //     const propertyID = parseInt(id, 10);
    //     const property = propertiesData.filter(x => x.id === propertyID)
    //     if (property[0]) {
    //       setSelectedProperty(property[0]);
    //     }
    //     else {
    //       handleModal(['Property with given ID not found']);
    //     }
    //   }
    // }, [id, handleModal]);

    // Fetch FAKE DATA Comments by Property ID
    // useEffect(() => {
    //   setComments(commentsJSON.records.slice(pageNumb - 1, (maxPages / perPage) - (perPage - pageNumb)));
    //   // console.log(`${maxPages} / ${perPage} - (${perPage} - ${pageNumb}) = ${(maxPages / perPage) - (perPage - pageNumb)}`)
    //   setMaxPages(Math.ceil(commentsJSON.record_params.no_of_records / perPage));
    //   setNumbRecords(commentsJSON.record_params.no_of_records);
    // }, [perPage, pageNumb, maxPages]);

    // Fetch FAKE DATA Comments for selected Comment
    // useEffect(() => {
    //   if(selectedComment !== null) {
    //     const propertyID = parseInt(id, 10);
    //     const commentID = parseInt(selectedComment?.id, 10);
    //     setRedirect(`/properties/forms/${propertyID}/comment/${commentID}`);
    //   }
    // }, [id, selectedComment, setRedirect]);

  // useEffect(() => {
  //   if(!user.logged_in) { setRedirect("/"); }
  // }, [user, setRedirect]);
  
  const newCommentForum = () =>
    (
      <form className='confirm-modal-form account-form' onSubmit={handleSubmitNew} encType="multipart/form-data">
        <h3 className='mb-05'>{t('topic_summary')}</h3>
        <input
          type="text"
          className='form-control'
          value={commentTitle}
          onChange={e => setCommentTitle(e.target.value)}
          minLength="3"
          required
        />
        <h3 className='mb-1'>{t('issue_details')}</h3>
        <textarea
          className='size-2 form-control'
          rows="8"
          cols="42"
          value={commentBody}
          onChange={e => setCommentBody(e.target.value)}
        />
        <div className='flex-col mt-2'>
          <h3 className='mb-1'>{t('f_photo')}</h3>
          <input
            type="file"
            multiple
            ref={uploadsInputRef}
            className='form-control'
            alt='Property photo uploads'
            id='photo_uploads'
            name="photo_uploads"
            accept="image/png, image/jpeg, image/jpg"
            // value={photoUpload}
            onChange={e => handleCheckFileSize(e)}
            style={{
              width: 'auto',
              height: 'auto',
              opacity: '100',
              overflow: 'hidden',
              position: 'relative',
              zIndex: 'auto'
            }}
          />
          <div className='flex-row'>{commentImages && populateImages()}</div>
          <button className='mt-1 btn btn-secondary' style={{padding: '16px 8px'}} type='button' onClick={() => handleClearImages()}>{t('f_clearImage')}</button>
        </div>
      </form>
    );

  return (
    <div id='PropertyForms'>
      {newCommentMode && (<div className="modal">
        <div className="modal-content" style={{minHeight: 'auto', maxHeight: 'none'}}>
          <button type="button" className="modal-exit" onClick={() => handleNewCommentMode()}><img src={Exit} alt='exit icon' /></button>
          <div className='container-md'>
            <h3 className='mb-1'>{t('reportIssue')}</h3>
            {newCommentForum()}
          </div>
          <div className='text-center mt-1 mb-1'>
            <button type="button" className="btn btn-danger confirm-modal-btnx mr-1" onClick={() => handleNewCommentMode()}>{t('f_cancel')}</button>
            <button type="button" className="btn btn-primary confirm-modal-btnx ml-1" onClick={(e) => handleSubmitNew(e)}>{t('f_submit')}</button>
          </div>
        </div>
      </div>
      )}
      <div className='container'>
        <h3 className='text-center mt-2 mb-2'>{t('property_forum')}</h3>
        <div className='property-navbar mt-2'>
          <span className="text-grey text-camel">{selectedProperty?.property_group ? 'Apartment Complex' : selectedProperty?.property_type}</span>
          {selectedProperty?.property_group && <h3 className=''>{selectedProperty?.property_group?.group_name}</h3>}
          <div className="pb-1">
            <h3 className="text-camel">{selectedProperty?.settlement || 'Settlement'}, {selectedProperty?.island}</h3>
            <div className="text-camel">{selectedProperty?.street_address}, <span className="text-caps">{selectedProperty?.po_box}</span></div>
          </div>
          {selectedProperty?.property_group && <span className="text-grey text-camel">{selectedProperty?.property_unit || 'Unit#'}</span>}
        </div>
        <div className='property-navbar mt-2'>
          <h3 className='mb-05'>{t('maintenance_req')}</h3>
          <button type='button' onClick={() => handleNewCommentMode()} className='forum-btn'>{t('new_repair_req')}</button>
          <div className='flex-row'>
            <button type='button' className='forum-btn w-50' onClick={() => setCommentActive(true)}>{t('open')}</button>
            <button type='button' className='forum-btn w-50' onClick={() => setCommentActive(false)}>{t('closed')}</button>
            {/* {user?.id === selectedProperty?.resident_id && <button type='button' onClick={() => handleNewCommentMode()} className='forum-btn'>New Repair request</button>} */}
          </div>
        </div>
        <div className=''>
          {/* {newCommentMode && newCommentForum()} */}
          <div className='flex-col'>
            {comments.length > 0 ? populateComments() : <span>No {commentActive ? 'active' : 'closed'} forum posts available...</span>}
          </div>
          <div>
          {maxPages > 1 && <PageIndex setPage={setPageNumb} setPerPage={setPerPage} page={pageNumb} perPage={perPage} maxPages={maxPages} viewMore={false} />}
          {/* <div>
            <span>showing {perPage} record(s) per page</span><br/>
            <span>total records: {numbRecords}</span>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

PropertyForms.propTypes = {
  user: propTypes.instanceOf(Object),
  setRedirect: propTypes.func.isRequired,
  handleLoader: propTypes.func.isRequired,
  handleModal: propTypes.func.isRequired,
  handleMessagesModal: propTypes.func.isRequired,
  handleImageModal: propTypes.func.isRequired,
};

export default PropertyForms;
