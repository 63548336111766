/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import translate from "translate";
import { fetchProperty, inquireProperty,
         fetchPropertyGroup, rateProperty,
         favoredProperty, favoriteProperty } from '../../misc/apiRequests';
import { numberWithCommas, officeHours } from '../../misc/misc';
import '../../../assets/css/property.css';
import Verified from '../../../assets/images/logo-simple.png';
import Star from '../../../assets/images/star2_full.svg';
import StarEmpty from '../../../assets/images/star2.svg';
import StarHalf from '../../../assets/images/star2_half.svg';
import Phone from '../../../assets/images/phone.svg';
import MobilePhone from '../../../assets/images/mobile_phone.svg';
import Globe from '../../../assets/images/globe.svg';
import Clock from '../../../assets/images/clock.svg';
import Favorite from '../../../assets/images/favorite.svg';
import FavoriteFull from '../../../assets/images/favorite_full.svg';
import MoveInSpecial from '../../../assets/images/discount_tag.svg';
import MailModal from '../../functional/properties/sendMailModal';
import AppointmentTable from '../../functional/properties/appointmentTable';
// import Mail from '../../../assets/images/mail.svg';
import MapsComponent from "../../functional/properties/googleMap";
import UnitDisplay from './unitDisplay';
import ImageCarousel from '../../functional/properties/imageCarousel';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import Swal from 'sweetalert2';
// import { propertiesData } from '../../misc/jsonData/propertyListingData';
import DiscussionsIcon from '../../../assets/images/discussions.svg';
import EditIcon from '../../../assets/images/edit.svg';
import RemoveIcon from '../../../assets/images/remove.svg';

translate.engine = "google";

const PropertyDisplay = ({ user, setRedirect, handlePropertyRequests, handlePropertyRemove,
                           handleLoader, handleModal, handleMessagesModal, handleImageModal,
                           handleConfirmModal, currentLang }) => {
  const { t } = useTranslation();
  const [translatedDesc, setTranslatedDesc] = useState('');
  const [translatedSpecial, setTranslatedSpecial] = useState('');
  const [translatedDisclaimer, setTranslatedDisclaimer] = useState('');

  // eslint-disable-next-line camelcase
  const [selectedProperty, setSelectedProperty] = useState();
  const [isOwner, setIsOwner] = useState(false);
  const [isResident, setIsResident] = useState(false);
  const [isFavorited, setFavorited] = useState(false);
  const [inquiryComment, setInquiryComment] = useState('Interested in your rental property.')
  const [selectedUnitType, setSelectedUnitType] = useState('all')
  const [propertyGroup, setPropertyGroup] = useState()
  const [showMailModal, setShowMailModal] = useState(false);
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [customText, setCustomText] = useState('');

  const unitAll = useRef(null);
  const unitStudio = useRef(null);
  const unitBedroom1 = useRef(null);
  const unitBedroom2 = useRef(null);
  const unitBedroom3 = useRef(null);
  const contactBox = useRef(null);
  const contactDiv = useRef(null);
  const reviews = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  let { id } = useParams();


  const dynamicTranslate = (txt) => {
    let setLang = '';
    switch (currentLang) {
      case 'English':
        setLang = 'en';
        break;
      case 'Español':
        setLang = 'es';
        break;
      case 'Créole':
        setLang = 'ht';
        break;
      default:
        setLang = 'en';
    }

    return translate(txt, setLang);
  };

  useEffect(() => {
    const dynamicTranslate = async (txt) => {
      let setLang = '';
      switch (currentLang) {
        case 'English':
          setLang = 'en';
          break;
        case 'Español':
          setLang = 'es';
          break;
        case 'Créole':
          setLang = 'ht';
          break;
        default:
          setLang = 'en';
      }
  
      return await translate(txt, setLang);
    };
    dynamicTranslate(selectedProperty?.desc || propertyGroup?.desc).then(res => {
      setTranslatedDesc(res || t('g_propertyDesc'))
    }).catch(err => console.log(err))
    dynamicTranslate(selectedProperty?.move_in_special || propertyGroup?.move_in_special).then(res => {
      setTranslatedSpecial(res || t('g_noneAvailable'))
    }).catch(err => console.log(err))
    dynamicTranslate(selectedProperty?.disclaimer || propertyGroup?.disclaimer).then(res => {
      setTranslatedDisclaimer(res)
    }).catch(err => console.log(err))

  }, [selectedProperty, propertyGroup, t, currentLang])

  const handleSelectedUnitType = (e, unit) => {
    e.preventDefault();
    setSelectedUnitType(unit);
  };

  const handleEditMode = e => {
    e.preventDefault();
    setRedirect(`/properties/edit/${id}`)
  };

  const handleReview = e => {
    e.preventDefault();
    const header = t('g_leaveReview');
    const text = t('g_leaveReviewDesc');

    handleConfirmModal({ visible: true,
                         header: header, message: text,
                         textbox: true, rating: true,
                         handleFunction: handleReviewSubmit });
  };

  const handleFavorite = (e) => {
    e.preventDefault();

    handleLoader(true);
    favoriteProperty(id)
      .then(response => {
        if (response.success) {
          setFavorited(!isFavorited);
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });

    return null;
  };

  
  const handleMailModal = (e, text) => {
    e.preventDefault();

    setCustomText(text);
    setShowMailModal(!showMailModal);
  };

  useEffect(() => {
    const contactBtn = contactBox?.current;
    const contactBtnPos = contactDiv?.current?.offsetTop + window.innerHeight/1.9;
    // console.log(contactBtnPos);

    window.onscroll = (function() {
      let currentScroll = window.scrollY;
      // console.log(currentScroll);

      if (currentScroll >= contactBtnPos) {
        contactBtn.classList.add("fixed-scroller")

      } else {
        contactBtn.classList.remove("fixed-scroller")
      }
    });
  });

  const formatPhone = (numb) => {
    const part1 = numb?.slice(0, 2);
    const part2 = `(${numb?.slice(3, 6)})`
    const part3 = `${numb?.slice(6, 9)}`
    const part4 = `-${numb?.slice(9, 12)}`

    return `${part1} ${part2} ${part3} ${part4}`;
  };
  // const dateEnd = selectedProperty.created_at.indexOf('T');
  // const shortDate = selectedProperty.created_at.substring(0, dateEnd);

  const generateStars = (stars, max = 5) => {
    const jsxData = [];
    const roundedStars = Math.trunc(stars);
    const leftOver = stars - roundedStars;
    let halfStar = false;
    if(Math.round(leftOver) === 1) halfStar = true;

    for (let i = 0; i < roundedStars; i++) {
      jsxData.push(<img key={`Star${i}`} className='rating-star filter-star' style={{position: 'relative', top: '-1px'}} src={Star} alt='star rating'/>);
    }

    if(halfStar) { jsxData.push(<img key={'HalfStar1'} className='rating-star filter-star' style={{position: 'relative', top: '-1px'}} src={StarHalf} alt='half star rating'/>); }

    const maxStars = max - (roundedStars + halfStar)
    for (let i = 0; i < maxStars; i++) {
      jsxData.push(<img key={`EmptyStar${i}`} className='rating-star filter-star' style={{position: 'relative', top: '-1px'}} src={StarEmpty} alt='empty star rating'/>);
    }

    return jsxData;
  }

  const reviewsText = () => {
    return (
        <div className="flex-row">{generateStars(selectedProperty?.rating?.score || 0)}
        ({selectedProperty?.rating?.score}/5) {selectedProperty?.rating?.reviews} {t('reviews')}</div>)
  }

  // const populateReviews = () => {
  //   if(!selectedProperty?.property_history) return null;
  //   const arr = [];

  //   (async function func() {
  //     for (let h of selectedProperty?.property_history) {
  //       let result = await dynamicTranslate(h.comment).then(x => x);

  //       arr.push({ id: h.id, score: h.score, comment: result, created_at: h.created_at });
  //     }
  //   })()
  //   // console.log(Promise.resolve(arr).then(arr => arr));
  //   console.log(arr)

  //   return arr.map(x => (
  //     <div key={`PH${x.id}`} className="flex-col p-1 review-box">
  //       <div className="flex-row mb-05">{generateStars(x.score || 0)}</div>
  //       <span className='size-1 mb-1'>{new Date(x.created_at).toDateString()}</span>
  //       <h3>{t('g_verifiedRenter')}</h3>
  //       <p>{x.comment}</p>
  //     </div>
  //   ));
  // };

  // const populateReviews = async () => {
  //   if(!selectedProperty?.property_history) return null;
  //   const arr = [];

  //   for (let h of selectedProperty?.property_history) {
  //     let result = await dynamicTranslate(h.comment);
  //     console.log(result);

  //     arr.push({ id: h.id, score: h.score, comment: result, created_at: h.created_at });
  //   }
  //   // console.log(Promise.resolve(arr).then(arr => arr));
  //   console.log('FIX REVIEW TRANSLATIONS', arr)

  //   return arr.map(x => (
  //     <div key={`PH${x.id}`} className="flex-col p-1 review-box">
  //       <div className="flex-row mb-05">{generateStars(x.score || 0)}</div>
  //       <span className='size-1 mb-1'>{new Date(x.created_at).toDateString()}</span>
  //       <h3>{t('g_verifiedRenter')}</h3>
  //       <p>{x.comment}</p>
  //     </div>
  //   ));
  // };

  const populateReviews = () => selectedProperty?.property_history.map((h, index) => (
    <div key={`PH${h.id}`} className="flex-col p-1 review-box">
      <div className="flex-row mb-1">{generateStars(h.score || 0)}</div>
      <span className='size-1 mb-2'>{new Date(h.created_at).toDateString()}</span>
      <h3>{t('g_verifiedRenter')}</h3>
      <p>{h.comment}</p>
    </div>
  ));

  // const reviewsPromise = () => {
  //   populateReviews().then(res => res)
  // }

  const displayFeatures = () => {
    const features = [];
    if(selectedProperty.furnished) {features.push(t('f_furnished'));}
    if(selectedProperty.kitchen) {features.push(t('f_kitchen'));}
    if(selectedProperty.living_room) {features.push(t('f_livingRoom'));}
    if(selectedProperty.air_conditioned) {features.push(t('f_airConditioned'));}
    if(selectedProperty.fenced_in) {features.push(t('f_fencedIn'));}
    if(selectedProperty.back_yard) {features.push(t('f_backyard'));}
    if(selectedProperty.gated_community) {features.push(t('f_gatedCommunity'));}
    if(selectedProperty.pets_allowed) {features.push(t('f_petsAllowed'));}
    if(selectedProperty.in_unit_washer_and_dryer) {features.push(t('f_inUnitWasher'));}
    if(selectedProperty.washer_and_dryer_hookups) {features.push(t('f_washerHookups'));}
    if(selectedProperty.dishwasher) {features.push(t('f_dishwasher'));}
    if(selectedProperty.wheelchair_accessible) {features.push(t('f_wheelchairAccess'));}
    if(selectedProperty.laundry_facilities) {features.push(t('f_laundryFacilities'));}
    if(selectedProperty.fitness_center) {features.push(t('f_fitnessCenter'));}
    if(selectedProperty.pool) {features.push(t('f_pool'));}
    if(selectedProperty.elevator) {features.push(t('f_elevator'));}
    if(selectedProperty.parking) {features.push(t('f_parking'));}
    if(selectedProperty.balcony) {features.push(t('f_balcony'));}
    if(selectedProperty.upstairs) {features.push(t('f_upstairs'));}
    if(selectedProperty.downstairs) {features.push(t('f_downstairs'));}
    // if(selectedProperty.water) {features.push('Water included');}
    // if(selectedProperty.internet) {features.push('Internet included');}
    // if(selectedProperty.electricity) {features.push('Electricity included');}

    return features;
  };

  const populateFeatures = (features) => features.map((f, index) => (
    <li key={`Feats${index}`}>{f}</li>
  ));


  // Inquire about Property
  const handleInquiry = () => {
    if (id) {
      const propertyID = parseInt(id, 10);
      handleLoader(true);
      inquireProperty(propertyID, { comment: inquiryComment })
        .then(response => {
          if (response.success) { handleMessagesModal('Successful', response.message, 'success'); }
          if (!response.success) {
            if (response?.errors[0].toLowerCase() === 'account not authorized') {
              const msg = [t('needToRegister'), t('needToSignIn')];
              // handleModal(msg);
              Swal.fire({
                position: 'middle',
                icon: 'error',
                title: msg.join("\n"),
                showConfirmButton: false,
                timer: 2000
              })
            } else {
              // handleModal(response.errors);
              Swal.fire({
                position: 'middle',
                icon: 'error',
                title: response.errors,
                showConfirmButton: false,
                timer: 2000
              })
            }
          }
          handleLoader(false);
        });
    }
  } 

  // Handle property Review
  const handleReviewSubmit = (comment, score) => {
    if (id) {
      const propertyID = parseInt(id, 10);
      handleLoader(true);
      const ratingParams = { property: {score, comment} };

      rateProperty(propertyID, ratingParams)
        .then(response => {
          if (response.success) { handleMessagesModal('Successful', response.message, 'success'); }
          if (!response.success) {
            if (response?.errors[0].toLowerCase() === 'account not authorized') {
              const msg = [t('needToRegister'),
                           t('needToBeResident'),
                           t('needToSignIn')];
              // handleModal(msg);
              Swal.fire({
                position: 'middle',
                icon: 'error',
                title: msg.join("\n"),
                showConfirmButton: false,
                timer: 2000
              })
            } else { 
              // handleModal(response.errors);
              Swal.fire({
                position: 'middle',
                icon: 'error',
                title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
                showConfirmButton: false,
                timer: 2000
              })
            }
          }
          handleLoader(false);
        });
    }
  };

  // Fetch Property by ID
  useEffect(() => {
    if (id) {
      const propertyID = parseInt(id, 10);
      handleLoader(true);
      fetchProperty(propertyID)
        .then(response => {
          if (response.success) {
            setSelectedProperty(response.record);
          }
          if (!response.success) {
            // handleModal(response.errors);
            Swal.fire({
              position: 'top-end',
              toast: true,
              timerProgressBar: true,
              icon: 'error',
              title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
              showConfirmButton: false,
              timer: 2000
            })
          }
          handleLoader(false);
        });
    }
  }, [id, handleLoader, handleModal]);

    // Fetch FAKE DATA Property by ID
    // useEffect(() => {
    //   if (id) {
    //     const propertyID = parseInt(id, 10);
    //     const property = propertiesData.filter(x => x.id === propertyID)
    //     if (property[0]) {
    //       setSelectedProperty(property[0]);
    //     }
    //     else {
    //       handleModal(['Property with given ID not found']);
    //     }
    //   }
    // }, [id, handleModal]);

  useEffect(() => {
    if(!selectedProperty || !user) return;
    if(selectedProperty.owner_id === user.id) setIsOwner(true);
    if(selectedProperty.resident_id === user.id && selectedProperty.resident_id !== null) setIsResident(true);
  }, [selectedProperty, user])

  // Fetch related Property Group
  useEffect(() => {
    if (selectedProperty?.property_group_id) {
      handleLoader(true);
      fetchPropertyGroup(selectedProperty.property_group_id)
        .then(response => {
          if (response.success) {
            setPropertyGroup(response.record);
          }
          if (!response.success) {
            // handleModal(response.errors);
            Swal.fire({
              position: 'top-end',
              toast: true,
              timerProgressBar: true,
              icon: 'error',
              title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
              showConfirmButton: false,
              timer: 2000
            })
          }
          handleLoader(false);
        });
    }
  }, [handleLoader, handleModal, selectedProperty])

  // Check if Property Group is favorited
  useEffect(() => {
    if (id) {
      handleLoader(true);
      favoredProperty(id)
        .then(response => {
          if (response.success) {
            setFavorited(response.status);
          }
          // if (!response.success) handleModal(response.errors);
          handleLoader(false);
        });
    }
  }, [handleLoader, handleModal, id])
  
  useEffect(() => {
    const unitAllElem = unitAll.current;
    const unitStudioElem = unitStudio.current;
    const unitBedroom1Elem = unitBedroom1.current;
    const unitBedroom2Elem = unitBedroom2.current;
    const unitBedroom3Elem = unitBedroom3.current;

    if(selectedUnitType === 'all') {
      unitStudioElem?.classList.remove("selected");
      unitBedroom1Elem?.classList.remove("selected");
      unitBedroom2Elem?.classList.remove("selected");
      unitBedroom3Elem?.classList.remove("selected");
      unitAllElem?.classList.add("selected");
    }

    if(selectedUnitType === 'studio') {
      unitStudioElem?.classList.add("selected");
      unitBedroom1Elem?.classList.remove("selected");
      unitBedroom2Elem?.classList.remove("selected");
      unitBedroom3Elem?.classList.remove("selected");
      unitAllElem?.classList.remove("selected");
    }

    if(selectedUnitType === '1 bedroom') {
      unitStudioElem?.classList.remove("selected");
      unitBedroom1Elem?.classList.add("selected");
      unitBedroom2Elem?.classList.remove("selected");
      unitBedroom3Elem?.classList.remove("selected");
      unitAllElem?.classList.remove("selected");
    }

    if(selectedUnitType === '2 bedroom') {
      unitStudioElem?.classList.remove("selected");
      unitBedroom1Elem?.classList.remove("selected");
      unitBedroom2Elem?.classList.add("selected");
      unitBedroom3Elem?.classList.remove("selected");
      unitAllElem?.classList.remove("selected");
    }

    if(selectedUnitType === '3 bedroom') {
      unitStudioElem?.classList.remove("selected");
      unitBedroom1Elem?.classList.remove("selected");
      unitBedroom2Elem?.classList.remove("selected");
      unitBedroom3Elem?.classList.add("selected");
      unitAllElem?.classList.remove("selected");
    }
  }, [selectedUnitType])
  
  // Set scroll focus to review section when review search param present and true
  useEffect(() => {
    const reviewsElem = reviews.current;

    if (searchParams.get('review') === 'true') {
      reviews.current.scrollIntoView({ behavior: "instant", block: "end" });
    }
  }, [searchParams, selectedProperty, propertyGroup])

  const renderRelatedUnitsSection = () => {
    if(propertyGroup) return (
      <div className='mt-2 mb-2'>
        <h3 className='mb-3'>{t('g_otherUnits')}</h3>
        {renderRelatedUnitsNavBar()}
        <div>
          {renderRelatedUnits()}
        </div>
      </div>
    );
  };

  const renderRelatedUnitsNavBar = () => (
      <div className='related-units mb-3'>
        <button type='button' ref={unitAll} onClick={e => handleSelectedUnitType(e, 'all')}>{t('g_all')}</button>
        <button type='button' ref={unitStudio} onClick={e => handleSelectedUnitType(e, 'studio')}>{t('g_studio')}</button>
        <button type='button' ref={unitBedroom1} onClick={e => handleSelectedUnitType(e, '1 bedroom')}></button>
        <button type='button' ref={unitBedroom2} onClick={e => handleSelectedUnitType(e, '2 bedroom')}></button>
        <button type='button' ref={unitBedroom3} onClick={e => handleSelectedUnitType(e, '3 bedroom')}></button>
      </div>
    );
  
  const renderRelatedUnits = () => {
    // if(!selectedPropertyGroup){ return <div className='related-listings'><p>No related units available.</p></div> }

    if(selectedUnitType === 'all') {
      if(propertyGroup?.properties.length === 0) {
        return <div className='related-listings'><p>{t('g_noRelatedUnits')}</p></div>
      } else {
        const populateStudioUnits = propertyGroup?.summary?.studio?.sorted.map((property) => (
          <UnitDisplay
            key={property.sq_ft}
            property={property}
            renderInquiry={renderInquiry}
            handleImageModal={handleImageModal}
            propertyTag={t('g_studio')}
            unitGroup={true}
          />
        ));

        const populate1BedUnits = propertyGroup?.summary?.bedroom1?.sorted.map((property) => (
          <UnitDisplay
            key={property.sq_ft}
            property={property}
            renderInquiry={renderInquiry}
            handleImageModal={handleImageModal}
            propertyTag={`1 ${t('g_bedroom')}`}
            unitGroup={true}
          />
        ));

        const populate2BedUnits = propertyGroup?.summary?.bedroom2?.sorted.map((property) => (
          <UnitDisplay
            key={property.sq_ft}
            property={property}
            renderInquiry={renderInquiry}
            handleImageModal={handleImageModal}
            propertyTag={`1 ${t('g_bedroom')}`}
            unitGroup={true}
          />
        ));

        const populate3BedUnits = propertyGroup?.summary?.bedroom3?.sorted.map((property) => (
          <UnitDisplay
            key={property.sq_ft}
            property={property}
            renderInquiry={renderInquiry}
            handleImageModal={handleImageModal}
            propertyTag={`1 ${t('g_bedroom')}`}
            unitGroup={true}
          />
        ));

        return populateStudioUnits?.concat(populate1BedUnits, populate2BedUnits, populate3BedUnits);
      }
    }

    if(selectedUnitType === 'studio') {
      if(propertyGroup?.summary?.studio?.sorted.length === 0) {
        return <div className='related-listings'><p>{t('g_noRelatedUnits')}</p></div>
      } else {
        const populateUnits = propertyGroup?.summary?.studio?.sorted.map((property) => (
          <UnitDisplay
            key={property.sq_ft}
            property={property}
            renderInquiry={renderInquiry}
            handleImageModal={handleImageModal}
            propertyTag={t('g_studio')}
            unitGroup={true}
          />
        ));

        return populateUnits;
      }
    }

    if(selectedUnitType === '1 bedroom') {
      if(propertyGroup?.summary?.bedroom1?.sorted.length === 0) {
        return <div className='related-listings'><p>{t('g_noRelatedUnits')}</p></div>
      } else {
        const populateUnits = propertyGroup?.summary?.bedroom1?.sorted.map((property) => (
          <UnitDisplay
            key={property.sq_ft}
            property={property}
            renderInquiry={renderInquiry}
            handleImageModal={handleImageModal}
            propertyTag={`1 ${t('g_bedroom')}`}
            unitGroup={true}
          />
        ));

        return populateUnits;
      }
    }

    if(selectedUnitType === '2 bedroom') {
      if(propertyGroup?.summary?.bedroom2?.sorted.length === 0) {
        return <div className='related-listings'><p>{t('g_noRelatedUnits')}</p></div>
      } else {
        const populateUnits = propertyGroup?.summary?.bedroom2?.sorted.map((property) => (
          <UnitDisplay
            key={property.sq_ft}
            property={property}
            renderInquiry={renderInquiry}
            handleImageModal={handleImageModal}
            propertyTag={`2 ${t('g_bedroom')}`}
            unitGroup={true}
          />
        ));

        return populateUnits;
      }
    }

    if(selectedUnitType === '3 bedroom') {
      if(propertyGroup?.summary?.bedroom3?.sorted.length === 0) {
        return <div className='related-listings'><p>{t('g_noRelatedUnits')}</p></div>
      } else {
        const populateUnits = propertyGroup?.summary?.bedroom3?.sorted.map((property) => (
          <UnitDisplay
            key={property.sq_ft}
            property={property}
            renderInquiry={renderInquiry}
            handleImageModal={handleImageModal}
            propertyTag={`3 ${t('g_bedroom')}`}
            unitGroup={true}
          />
        ));

        return populateUnits;
      }
    }
  };

  const renderInquiry = (center = false, formatted = 'contact-btn') => {
    const marginClass = center ? 'mx-auto' : '';
    const classSet = formatted;
    const isAvailable = selectedProperty?.resident_id === null ? t('g_apply') : t('propertyUnavailable');
    const airBnb = selectedProperty?.property_type === 'air bnb';
    if (!user?.logged_in) {
      return (<button type="button" disabled style={{opacity: '30%'}}  className={`${classSet} ${marginClass}`} onClick={() => handleInquiry()}>{isAvailable}</button>);
    }
    else if (isResident || isOwner) {
      return (<button type="button" disabled style={{opacity: '30%'}}  className={`${classSet} ${marginClass}`} onClick={() => handleInquiry()}>{isAvailable}</button>);
    }
    else if(!airBnb) {
      return (<button type="button" title={t('g_ownerNotify')} className={`${classSet} ${marginClass}`} onClick={() => handleInquiry()}>{isAvailable}</button>);
    }
    else {
      return (<button type="button" title={t('g_ownerNotify')} className={`${classSet} ${marginClass}`} onClick={() => setShowCalendarModal(!showCalendarModal)}>{isAvailable}</button>);
    }
  };

  return (selectedProperty?.disabled || selectedProperty?.archive) ? setRedirect('/') : (
    <div id='PropertyPage'>
      {showMailModal && <MailModal
                          property={selectedProperty}
                          customText={customText}
                          handleLoader={handleLoader}
                          handleMessagesModal={handleMessagesModal}
                          handleModal={handleModal}
                          handleMailModal={handleMailModal}
                          isPropertyGroup={false}
                        />}
      {showCalendarModal && <AppointmentTable
                              property={selectedProperty}
                              handleLoader={handleLoader}
                              setShowCalendarModal={setShowCalendarModal}
                            />}
      <div className="pos-relative mb-2">
        {/* Generate Image Carousel */}
        <ImageCarousel images={selectedProperty?.images} handleImageModal={handleImageModal} />
      </div>
      <div className="container">
        { isOwner && <h4 className='py-1 mb-1'>{t('g_ownerOptions')}</h4> }
        { isResident && <h4 className='py-1 mb-1'>{t('g_residentOptions')}</h4> }
        <div className="flex-rowx mb-3" style={{maxWidth: '1000px'}}>
        {(isResident || isOwner) && <button type='button' className='btn property-header-btn mb-2 mr-2' onClick={e => handlePropertyRequests(id)}>
          {t('g_tenantForums')}
          <img src={DiscussionsIcon} style={{width: '22px', height: 'auto', marginLeft: '4px'}} alt='tenant forums'></img>
        </button>}
        { isOwner && <button type="button" className="btn bg-info-subtle bg-info-subtle-shadow property-header-btn mb-1 mr-2" onClick={e => handleEditMode(e)}>
          {t('g_editProperty')}
          <img src={EditIcon} style={{width: '22px', height: 'auto', marginLeft: '4px'}} alt='tenant forums'></img>
        </button>}
        { isOwner && <button type='button' className='btn bg-danger-subtle bg-danger-subtle-shadow property-header-btn mb-1' onClick={e => handlePropertyRemove(id)}>
          {t('g_removeProperty')}
          <img src={RemoveIcon} style={{width: '22px', height: 'auto', marginLeft: '4px'}} alt='tenant forums'></img>
        </button>}
        </div>
        <div className='main-property-div'>
          <div className="">
            <div className="flex-col mb-1">
              <span className="text-grey text-camel">{!selectedProperty?.property_group_id ? selectedProperty?.property_type : t('apartmentComplex')}</span>
              <div className='flex-col'>
                <h3>
                  {selectedProperty?.property_group_id ? (<a href={`/property_groups/${propertyGroup?.id}`} className='text-camel'>
                    {propertyGroup?.group_name}
                  </a>) : t('g_rentalProperty')}
                </h3>
                <h3>{selectedProperty?.property_group_id && (`${t('unit')} # ${selectedProperty?.property_unit}` || `${t('unit')} #`)}</h3>
              </div>
              {/* <h3>Apartment Name</h3> */}
            </div>
            <div className="pb-1">
              {/* <span className="text-grey mb-1">Address</span> */}
              {!selectedProperty?.property_group_id && (
                <div className="text-camel">{selectedProperty?.island}, {selectedProperty?.street_address},
                {' '}
                <span className="text-caps">{selectedProperty?.po_box}</span></div>)}
              {selectedProperty?.property_group_id && (
                <div className="text-camel">{propertyGroup?.island}, {propertyGroup?.street_address},
                {' '}
                <span className="text-caps">{propertyGroup?.po_box}</span></div>)}
              {/* <a href={`/properties/owned_by/${selectedProperty?.owner_id}`} className='text-camel'>
                {selectedProperty?.owner.profile.first_name} {selectedProperty?.owner.profile.last_name}</a> */}
              <a href={`/search/?island=${propertyGroup?.island || selectedProperty?.island}&settlement=${propertyGroup?.settlement || selectedProperty?.settlement}`} className='text-camel'>
                {propertyGroup?.settlement ||selectedProperty?.settlement}</a>
            </div>
          </div>
          <div className="flex-row mb-2" style={{"alignItems": "flex-end"}}>
              {reviewsText()}
            </div>
            <div className='flex-row'>
              <button type='button' title='Favorite' className='bare-btn mr-3' onClick={e => handleFavorite(e)}>
                {isFavorited && <img className='heart filter-red' alt='Favorite button' src={FavoriteFull} />}
                {!isFavorited && <img className='heart' alt='Favorite button' src={Favorite} />}
              </button>
              <div className="">
                <FacebookShareButton
                  url={window.location.href}
                  quote={'RentalFaqz.com'}
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton
                  url={window.location.href}
                  quote={'RentalFaqz.com'}
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <WhatsappShareButton
                  url={window.location.href}
                  quote={'RentalFaqz.com'}
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </div>
              {(!selectedProperty?.property_group_id && selectedProperty?.verified) && (
                <div className='flex-row ml-2 p-05 verified-btn' title='Verified Listing'>
                  <img alt='verified listing' src={Verified} className='filter-verified'/>
                  <span className='size-1 text-green'>Verified</span>
                </div>
              )}
              {selectedProperty?.property_group_id && (
                propertyGroup?.verified && (
                  <div className='flex-row ml-2 p-05 verified-btn' title='Verified Listing'>
                    <img alt='verified listing' src={Verified} className='filter-verified'/>
                    <span className='size-1 text-green'>Verified</span>
                  </div>
                )
              )}
            </div>

          <div className="mt-3 mb-3">
            <div className="property-table">
              <table className='mx-auto w-100 text-center'>
                <thead>
                  <tr className='property-tableheader'>
                    {/* <th className='monthly-fee text-normal'>{selectedProperty?.payment_frequency} {t('f_fee')}</th> */}
                    <th className='monthly-fee text-normal'>{t('f_fee')}</th>
                    <th className='bedrooms text-normal'></th>
                    <th className='bathrooms text-normal'></th>
                    <th className='footage hide-sm text-normal'>{t('g_squareFeet')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='text-bold'>
                    <td>{`$${numberWithCommas(selectedProperty?.fee)}`}</td>
                    <td className='text-camel'>{selectedProperty?.studio ? t('g_studio') : selectedProperty?.bedrooms}</td>
                    <td>{selectedProperty?.bathrooms} ba</td>
                    <td className='hide-sm'>{`${numberWithCommas(selectedProperty?.sq_ft)} sq ft`}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className='move-in-box mb-4'>
            <div className='flex-row'>
              <h4>{t('f_moveInSpecial')}</h4>
              <img className='ml-1 contact-icon mr-1' src={MoveInSpecial} alt='move in special icon'/>
            </div>
            <p>{translatedSpecial}</p>
          </div>

          {/* About Description of property */}
          <h3 className='mt-3'>{t('g_about')} {propertyGroup ? `${t('unit')} # ${selectedProperty?.property_unit}` || propertyGroup?.group_name : `${t('g_this')} ${selectedProperty?.property_type}`}</h3>
          <p>{translatedDesc}</p>

          <div className="mb-2">
            {!selectedProperty?.property_group_id && <h3>{t('propertyFeatures')}</h3>}
            {selectedProperty?.property_group_id && <h3>{t('unitFeatures')}</h3>}
            <ol className="features-list">
              {selectedProperty && populateFeatures(displayFeatures())}
            </ol>
          </div>

          <div className='mb-2'>
            {(selectedProperty?.water || selectedProperty?.internet || selectedProperty?.electricity) && <h3>{t('g_utilities')}</h3>}
            <ol className="features-list">
              {selectedProperty?.water && <li>{t('g_waterIncluded')}</li>}
              {selectedProperty?.internet && <li>{t('g_internetIncluded')}</li>}
              {selectedProperty?.electricity && <li>{t('g_electricityIncluded')}</li>}
            </ol>
          </div>

          {/* Community Amenities */}
          {/* <h3 className='mt-2'>Community Amenities</h3>
          <p>{selectedPropertyGroup?.features || 'Enter property amenities available to all tenants/units.'}</p> */}

          {/* Google Maps Component */}
          <h3 className='mt-3 py-1'>{t('g_location')}</h3>
          <div className="text-camel pb-1"><b>{t('g_propertyAddress')}:</b> {propertyGroup?.settlement || selectedProperty?.settlement}, {propertyGroup?.island || selectedProperty?.island}, {propertyGroup?.street_address || selectedProperty?.street_address}</div>
          <div className='mb-2'>
            <a target='_blank' rel='noreferrer' href={`https://www.google.com/maps/dir//${propertyGroup?.latitude || selectedProperty?.latitude},+${propertyGroup?.longitude || selectedProperty?.longitude}/@${propertyGroup?.latitude || selectedProperty?.latitude},${propertyGroup?.longitude || selectedProperty?.longitude},17z?entry=ttu`}>
              <b>Get Directions</b>
            </a>
          </div>
          {selectedProperty && <MapsComponent property={propertyGroup || selectedProperty}/>}

          {/* Disclaimer */}
          {selectedProperty?.disclaimer && (
            <div>
              <h3 className='mt-3'>{t('g_rentalDisclaimer')}</h3>
              <p>{translatedDisclaimer}</p>
            </div>
          )}

          {/* Contact Owner Box */}
          <div className="contact-owner-still mt-4 mb-3">
            <h3 className="mb-3 fs-3">{t('g_contactOwner')}</h3>
            <div className="contact-inside-still">
              <div className='flex-col'>
                {renderInquiry(false, 'contact-btn2-still mb-2 fs-5x')}

                <button type='button' className="contact-btn2-still mb-2 fs-5x" onClick={e => handleMailModal(e, 'I want to schedule a tour.')}>
                {t('g_tourReq')}
                </button>
                
                <button type='button' className="contact-btn3-still fs-5x" onClick={e => handleMailModal(e)}>
                {t('sendMessage')}
                </button>
              </div>

              <div className='flex-col'>
                {selectedProperty?.owner?.profile?.phone_numb && (
                <div className="flex-row justify-flex-center mb-1">
                  <img className='contact-icon mr-1' src={Phone} alt='phone icon'/>
                  <a href={`tel:+${selectedProperty?.owner?.profile?.phone_numb}`}>{formatPhone(selectedProperty?.owner?.profile?.phone_numb)}</a>
                </div>
                )}
                
                {selectedProperty?.owner?.profile?.mobile_numb && (
                <div className="flex-row justify-flex-center">
                  <img className='contact-icon mr-1' src={MobilePhone} alt='mobile phone icon'/>
                  <a href={`tel:+${selectedProperty?.owner?.profile?.mobile_numb}`}>{formatPhone(selectedProperty?.owner?.profile?.mobile_numb)}</a>
                </div>
                )}
              </div>

              <div className='flex-col'>
                <div className='flex-row size-2'>
                  <img className='contact-icon mr-1' src={Globe} alt='globe language icon'/>
                  <div>{t('g_language')}: <span className='text-camel'>{selectedProperty?.owner?.profile?.preferred_language || t('f_english')}</span></div>
                </div>
                <div className='flex-row size-2 mb-2 mt-1'>
                  <img className='contact-icon mr-1' src={Clock} alt='globe language icon'/>
                  <div>{`${t('g_open')}: ${officeHours(selectedProperty?.owner?.profile?.office_hours)}`}</div>
                </div>
                <div className='flex-row justify-flex-center size-2'>
                  <button type='button' className='bare-btn text-link' onClick={() => handleMessagesModal('Office Hours', officeHours(selectedProperty?.owner?.profile?.office_hours, false))}>{t('g_allHours')}</button>
                </div>
              </div>
            </div>
          </div>

          {/* Property Reviews */}
          <div className='mt-3'>
            {<h3>{t('g_residentReviews')}</h3>}
            {reviewsText()}
            {selectedProperty?.rating?.score <= 0 && <div className='mt-3'>{t('g_noRatings')}...</div>}
            <div className="flex-row mt-3">
              {/* {reviewsPromise()} */}
              {populateReviews()}
            </div>
          </div>

          <div className='mt-3 mb-3 p-1'></div>
          {/* Related Units Section */}
          {renderRelatedUnitsSection()}
        </div>

        <div ref={contactDiv} className='main-property-div-r hide-sm hide-md'>
          {/* Contact Owner Box */}
          <div ref={contactBox} className="contact-owner">
            <h3 className="size-5 mb-3">{t('g_contactOwner')}</h3>
            <div className="flex-col align-c-center justify-flex-center">
              {renderInquiry(true, 'contact-btn2 mb-3 fs-5x')}
              
              <a className='contact-btn3 mb-3 mx-auto' title={t('sendMessage_title')} href={`mailto:${selectedProperty?.owner?.email}`}>{t('sendMessage')}</a>
              
              <div className='mb-3 pb-2 border-bottom'>
                {selectedProperty?.owner?.profile?.phone_numb && (
                <div className="flex-row justify-flex-center">
                  <img className='contact-icon mr-1' src={Phone} alt='phone icon'/>
                  <a href={`tel:+${selectedProperty?.owner?.profile?.phone_numb}`}>{formatPhone(selectedProperty?.owner?.profile?.phone_numb)}</a>
                </div>
                )}
                
                {selectedProperty?.owner?.profile?.mobile_numb && (
                <div className="flex-row justify-flex-center">
                  <img className='contact-icon mr-1' src={MobilePhone} alt='mobile phone icon'/>
                  <a href={`tel:+${selectedProperty?.owner?.profile?.mobile_numb}`}>{formatPhone(selectedProperty?.owner?.profile?.mobile_numb)}</a>
                </div>
                )}
              </div>

              <div className='flex-row justify-flex-center size-2'>
                <img className='contact-icon mr-1' src={Globe} alt='globe language icon'/>
                <div>{t('g_language')}: <span className='text-camel'>{selectedProperty?.owner?.profile?.preferred_language || t('f_english')}</span></div>
              </div>
              <div className='flex-row justify-flex-center size-2'>
                <img className='contact-icon mr-1' src={Clock} alt='globe language icon'/>
                <div>{`${t('g_open')}: ${officeHours(selectedProperty?.owner?.profile?.office_hours)}`}</div>
              </div>
              <div className='flex-row justify-flex-center size-2'>
                <button type='button' className='bare-btn text-link' onClick={() => handleMessagesModal('Office Hours', officeHours(selectedProperty?.owner?.profile?.office_hours, false))}>{t('g_allHours')}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PropertyDisplay.propTypes = {
  user: propTypes.instanceOf(Object),
  setRedirect: propTypes.func.isRequired,
  handlePropertyRequests: propTypes.func.isRequired,
  handlePropertyRemove: propTypes.func.isRequired,
  handleLoader: propTypes.func.isRequired,
  handleModal: propTypes.func.isRequired,
  handleConfirmModal: propTypes.func.isRequired,
  handleMessagesModal: propTypes.func.isRequired,
  handleImageModal: propTypes.func.isRequired
};

export default PropertyDisplay;
