/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import translate from "translate";
import { fetchProperty, inquireProperty,
         fetchPropertyGroup, rateProperty,
         favoredPropertyGroup, favoritePropertyGroup } from '../../misc/apiRequests';
import { numberWithCommas, timeFormat,
         officeHours } from '../../misc/misc';
import Swal from 'sweetalert2'
// import { GoogleMapsApiKey } from '../../misc/apiRequests';
import '../../../assets/css/property.css';
import Verified from '../../../assets/images/logo-simple.png';
import Star from '../../../assets/images/star2_full.svg';
import StarEmpty from '../../../assets/images/star2.svg';
import StarHalf from '../../../assets/images/star2_half.svg';
import Phone from '../../../assets/images/phone.svg';
import MobilePhone from '../../../assets/images/mobile_phone.svg';
import Globe from '../../../assets/images/globe.svg';
import Clock from '../../../assets/images/clock.svg';
import Favorite from '../../../assets/images/favorite.svg';
import FavoriteFull from '../../../assets/images/favorite_full.svg';
import MoveInSpecial from '../../../assets/images/discount_tag.svg';
import MailModal from '../../functional/properties/sendMailModal';
import MapsComponent from "../../functional/properties/googleMap";
import UnitDisplay from './unitDisplay';
import ImageCarousel from '../../functional/properties/imageCarousel';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
// import { propertiesData } from '../../misc/jsonData/propertyListingData';

translate.engine = "google";

const PropertyGroupDisplay = ({ user, setRedirect, handlePropertyRequests, handlePropertyRemove,
                           handleLoader, handleModal, handleMessagesModal, handleImageModal,
                           handleConfirmModal, currentLang }) => {
  const { t } = useTranslation();
  const [translatedDesc, setTranslatedDesc] = useState('');
  const [translatedSpecial, setTranslatedSpecial] = useState('');
  const [translatedDisclaimer, setTranslatedDisclaimer] = useState('');

  // eslint-disable-next-line camelcase
  const [selectedPropertyGroup, setSelectedPropertyGroup] = useState();
  const [isOwner, setIsOwner] = useState(false);
  const [isFavorited, setFavorited] = useState(false);
  const [inquiryComment, setInquiryComment] = useState('Interested in your rental property.');
  const [selectedUnitType, setSelectedUnitType] = useState('all');
  const [showMailModal, setShowMailModal] = useState(false);
  const [customText, setCustomText] = useState('');

  const unitAll = useRef(null);
  const unitStudio = useRef(null);
  const unitBedroom1 = useRef(null);
  const unitBedroom2 = useRef(null);
  const unitBedroom3 = useRef(null);
  const contactBox = useRef(null);
  const contactDiv = useRef(null);

  let { id } = useParams();

  useEffect(() => {
    const dynamicTranslate = async (txt) => {
      let setLang = '';
      switch (currentLang) {
        case 'English':
          setLang = 'en';
          break;
        case 'Español':
          setLang = 'es';
          break;
        case 'Créole':
          setLang = 'ht';
          break;
        default:
          setLang = 'en';
      }
  
      return await translate(txt, setLang);
    };
    dynamicTranslate(selectedPropertyGroup?.desc).then(res => {
      // console.log(res);
      setTranslatedDesc(res || t('g_propertyDesc'))
    })
    dynamicTranslate(selectedPropertyGroup?.move_in_special).then(res => {
      // console.log(res);
      setTranslatedSpecial(res || t('g_noneAvailable'))
    })
    dynamicTranslate(selectedPropertyGroup?.disclaimer).then(res => {
      // console.log(res);
      setTranslatedDisclaimer(res)
    })

  }, [selectedPropertyGroup, t, currentLang])

  const handleSelectedUnitType = (e, unit) => {
    e.preventDefault();
    setSelectedUnitType(unit);
  };

  const handleEditMode = e => {
    e.preventDefault();
    setRedirect(`/properties/edit/${id}`)
  };

  const handleFavorite = (e) => {
    e.preventDefault();

    handleLoader(true);
    favoritePropertyGroup(id)
      .then(response => {
        if (response.success) {
          setFavorited(!isFavorited);
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });

    return null;
  };

  const handleMailModal = (e, text) => {
    e.preventDefault();

    setCustomText(text);
    setShowMailModal(!showMailModal);
  };

  // const dateEnd = selectedProperty.created_at.indexOf('T');
  // const shortDate = selectedProperty.created_at.substring(0, dateEnd);

  useEffect(() => {
    const contactBtn = contactBox.current;
    const contactBtnPos = contactDiv.current.offsetTop + window.innerHeight/1.9;
    // console.log(contactBtnPos);

    window.onscroll = (function() {
      let currentScroll = window.scrollY;
      // console.log(currentScroll);

      if (currentScroll >= contactBtnPos) {
        contactBtn.classList.add("fixed-scroller")

      } else {
        contactBtn.classList.remove("fixed-scroller")
      }
    });
  });

  // const populateImages = () => selectedPropertyGroup?.images.map(img => (
  //     <button type="button" key={img.id} onClick={() => handleImageModal(img.url)}><img alt="Property" src={img.url} /></button>
  // ));

  const formatPhone = (numb) => {
    const part1 = numb?.slice(0, 2);
    const part2 = `(${numb?.slice(3, 6)})`
    const part3 = `${numb?.slice(6, 9)}`
    const part4 = `-${numb?.slice(9, 12)}`

    return `${part1} ${part2} ${part3} ${part4}`;
  };

  const generateStars = (stars, max = 5) => {
    const jsxData = [];
    const roundedStars = Math.trunc(stars);
    const leftOver = stars - roundedStars;
    let halfStar = false;
    if(Math.round(leftOver) === 1) halfStar = true;

    for (let i = 0; i < roundedStars; i++) {
      jsxData.push(<img key={`Star${i}`} className='rating-star filter-star' style={{position: 'relative', top: '-1px'}} src={Star} alt='star rating'/>);
    }

    if(halfStar) { jsxData.push(<img key={'HalfStar1'} className='rating-star filter-star' style={{position: 'relative', top: '-1px'}} src={StarHalf} alt='half star rating'/>); }

    const maxStars = max - (roundedStars + halfStar)
    for (let i = 0; i < maxStars; i++) {
      jsxData.push(<img key={`EmptyStar${i}`} className='rating-star filter-star' style={{position: 'relative', top: '-1px'}} src={StarEmpty} alt='empty star rating'/>);
    }

    return jsxData;
  }

  const reviewsText = () => {
    return (
        <div className="flex-row">{generateStars(selectedPropertyGroup?.rating || 0)}
        ({selectedPropertyGroup?.rating || 0}/5) {selectedPropertyGroup?.property_history?.length} reviews</div>)
  }

  const populateReviews = () => selectedPropertyGroup?.property_history.map((h, index) => (
    <div key={`PH${h.id}`} className="flex-col p-1 review-box">
      <div className="flex-row mb-1">{generateStars(h.score || 0)}</div>
      <span className='size-1 mb-2'>{new Date(h.created_at).toDateString()}</span>
      <h3>{t('g_verifiedRenter')}</h3>
      <p>{h.comment}</p>
    </div>
  ));

  // Inquire about Property
  const handleInquiry = () => {
    if (id) {
      const propertyID = parseInt(id, 10);
      handleLoader(true);
      inquireProperty(propertyID, { comment: inquiryComment })
        .then(response => {
          if (response.success) { handleMessagesModal('Successful', response.message, 'success'); }
          if (!response.success) {
            if (response?.errors[0].toLowerCase() === 'account not authorized') {
              const msg = [t('needToRegister'), t('needToSignIn')];
              // handleModal(msg);
              Swal.fire({
                position: 'middle',
                icon: 'error',
                title: msg.join("\n"),
                showConfirmButton: false,
                timer: 2000
              })
            } else { 
              // handleModal(response.errors);
              Swal.fire({
                position: 'middle',
                icon: 'error',
                title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
                showConfirmButton: false,
                timer: 2000
              })
            }
          }
          handleLoader(false);
        });
    }
  };


  useEffect(() => {
    if(!selectedPropertyGroup || !user) return;
    if(selectedPropertyGroup.user_id === user.id) setIsOwner(true);
  }, [selectedPropertyGroup, user])

  // Fetch related Property Group
  useEffect(() => {
    if (id) {
      handleLoader(true);
      fetchPropertyGroup(id)
        .then(response => {
          if (response.success) {
            setSelectedPropertyGroup(response.record);
          }
          if (!response.success) {
            // handleModal(response.errors);
            Swal.fire({
              position: 'top-end',
              toast: true,
              timerProgressBar: true,
              icon: 'error',
              title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
              showConfirmButton: false,
              timer: 2000
            })
          }
          handleLoader(false);
        });
    }
  }, [handleLoader, handleModal, id])

  // Check if Property Group is favorited
  useEffect(() => {
    if (id) {
      handleLoader(true);
      favoredPropertyGroup(id)
        .then(response => {
          if (response.success) {
            setFavorited(response.status);
          }
          // if (!response.success) handleModal(response.errors);
          handleLoader(false);
        });
    }
  }, [handleLoader, handleModal, id])

  useEffect(() => {
    const unitAllElem = unitAll.current;
    const unitStudioElem = unitStudio.current;
    const unitBedroom1Elem = unitBedroom1.current;
    const unitBedroom2Elem = unitBedroom2.current;
    const unitBedroom3Elem = unitBedroom3.current;

    if(selectedUnitType === 'all') {
      unitStudioElem?.classList.remove("selected");
      unitBedroom1Elem?.classList.remove("selected");
      unitBedroom2Elem?.classList.remove("selected");
      unitBedroom3Elem?.classList.remove("selected");
      unitAllElem?.classList.add("selected");
    }

    if(selectedUnitType === 'studio') {
      unitStudioElem?.classList.add("selected");
      unitBedroom1Elem?.classList.remove("selected");
      unitBedroom2Elem?.classList.remove("selected");
      unitBedroom3Elem?.classList.remove("selected");
      unitAllElem?.classList.remove("selected");
    }

    if(selectedUnitType === '1 bedroom') {
      unitStudioElem?.classList.remove("selected");
      unitBedroom1Elem?.classList.add("selected");
      unitBedroom2Elem?.classList.remove("selected");
      unitBedroom3Elem?.classList.remove("selected");
      unitAllElem?.classList.remove("selected");
    }

    if(selectedUnitType === '2 bedroom') {
      unitStudioElem?.classList.remove("selected");
      unitBedroom1Elem?.classList.remove("selected");
      unitBedroom2Elem?.classList.add("selected");
      unitBedroom3Elem?.classList.remove("selected");
      unitAllElem?.classList.remove("selected");
    }

    if(selectedUnitType === '3 bedroom') {
      unitStudioElem?.classList.remove("selected");
      unitBedroom1Elem?.classList.remove("selected");
      unitBedroom2Elem?.classList.remove("selected");
      unitBedroom3Elem?.classList.add("selected");
      unitAllElem?.classList.remove("selected");
    }
  }, [selectedUnitType])

  const renderRelatedUnitsSection = () => (
      <div className='mt-2 mb-4'>
        <h3 className='mb-3'>{t('gPricingUnit')}</h3>
        {renderRelatedUnitsNavBar()}
        <div>
          {renderRelatedUnits()}
        </div>
      </div>
  );

  const renderRelatedUnitsNavBar = () => (
      <div className='related-units mb-3'>
        <button type='button' ref={unitAll} onClick={e => handleSelectedUnitType(e, 'all')}>{t('g_all')}</button>
        <button type='button' ref={unitStudio} onClick={e => handleSelectedUnitType(e, 'studio')}>{t('g_studio')}</button>
        <button type='button' ref={unitBedroom1} onClick={e => handleSelectedUnitType(e, '1 bedroom')}></button>
        <button type='button' ref={unitBedroom2} onClick={e => handleSelectedUnitType(e, '2 bedroom')}></button>
        <button type='button' ref={unitBedroom3} onClick={e => handleSelectedUnitType(e, '3 bedroom')}></button>
      </div>
    );
  
  const renderRelatedUnits = () => {
    // if(!selectedPropertyGroup){ return <div className='related-listings'><p>No related units available.</p></div> }

    if(selectedUnitType === 'all') {
      if(selectedPropertyGroup?.properties.length === 0) {
        return <div className='related-listings'><p>{t('g_noRelatedUnits')}</p></div>
      } else {
        const populateStudioUnits = selectedPropertyGroup?.summary?.studio?.sorted.map((property) => (
          <UnitDisplay
            key={property.sq_ft}
            property={property}
            renderInquiry={renderInquiry}
            handleImageModal={handleImageModal}
            propertyTag={t('g_studio')}
            unitGroup={true}
          />
        ));

        const populate1BedUnits = selectedPropertyGroup?.summary?.bedroom1?.sorted.map((property) => (
          <UnitDisplay
            key={property.sq_ft}
            property={property}
            renderInquiry={renderInquiry}
            handleImageModal={handleImageModal}
            propertyTag={`1 ${t('g_bedroom')}`}
            unitGroup={true}
          />
        ));

        const populate2BedUnits = selectedPropertyGroup?.summary?.bedroom2?.sorted.map((property) => (
          <UnitDisplay
            key={property.sq_ft}
            property={property}
            renderInquiry={renderInquiry}
            handleImageModal={handleImageModal}
            propertyTag={`2 ${t('g_bedroom')}`}
            unitGroup={true}
          />
        ));

        const populate3BedUnits = selectedPropertyGroup?.summary?.bedroom3?.sorted.map((property) => (
          <UnitDisplay
            key={property.sq_ft}
            property={property}
            renderInquiry={renderInquiry}
            handleImageModal={handleImageModal}
            propertyTag={`3 ${t('g_bedroom')}`}
            unitGroup={true}
          />
        ));

        return populateStudioUnits?.concat(populate1BedUnits, populate2BedUnits, populate3BedUnits);
      }
    }

    if(selectedUnitType === 'studio') {
      if(selectedPropertyGroup?.summary?.studio?.sorted.length === 0) {
        return <div className='related-listings'><p>{t('g_noRelatedUnits')}</p></div>
      } else {
        const populateUnits = selectedPropertyGroup?.summary?.studio?.sorted.map((property) => (
          <UnitDisplay
            key={property.sq_ft}
            property={property}
            renderInquiry={renderInquiry}
            handleImageModal={handleImageModal}
            propertyTag={t('g_studio')}
            unitGroup={true}
          />
        ));

        return populateUnits;
      }
    }

    if(selectedUnitType === '1 bedroom') {
      if(selectedPropertyGroup?.summary?.bedroom1?.sorted.length === 0) {
        return <div className='related-listings'><p>{t('g_noRelatedUnits')}</p></div>
      } else {
        const populateUnits = selectedPropertyGroup?.summary?.bedroom1?.sorted.map((property) => (
          <UnitDisplay
            key={property.sq_ft}
            property={property}
            renderInquiry={renderInquiry}
            handleImageModal={handleImageModal}
            propertyTag={`1 ${t('g_bedroom')}`}
            unitGroup={true}
          />
        ));

        return populateUnits;
      }
    }

    if(selectedUnitType === '2 bedroom') {
      if(selectedPropertyGroup?.summary?.bedroom2?.sorted.length === 0) {
        return <div className='related-listings'><p>{t('g_noRelatedUnits')}</p></div>
      } else {
        const populateUnits = selectedPropertyGroup?.summary?.bedroom2?.sorted.map((property) => (
          <UnitDisplay
            key={property.sq_ft}
            property={property}
            renderInquiry={renderInquiry}
            handleImageModal={handleImageModal}
            propertyTag={`2 ${t('g_bedroom')}`}
            unitGroup={true}
          />
        ));

        return populateUnits;
      }
    }

    if(selectedUnitType === '3 bedroom') {
      if(selectedPropertyGroup?.summary?.bedroom3?.sorted.length === 0) {
        return <div className='related-listings'><p>{t('g_noRelatedUnits')}</p></div>
      } else {
        const populateUnits = selectedPropertyGroup?.summary?.bedroom3?.sorted.map((property) => (
          <UnitDisplay
            key={property.sq_ft}
            property={property}
            renderInquiry={renderInquiry}
            handleImageModal={handleImageModal}
            propertyTag={`3 ${t('g_bedroom')}`}
            unitGroup={true}
          />
        ));

        return populateUnits;
      }
    }
  };

  const renderInquiry = (center = false) => {
    const marginClass = center ? 'mx-auto' : ''
    const isAvailable = selectedPropertyGroup?.resident_id === null ? t('g_inquire') : t('propertyUnavailable')
    if (!user.logged_in) {
      return (<button type="button" disabled style={{opacity: '30%'}}  className={`contact-btn ${marginClass}`} onClick={() => handleInquiry()}>{isAvailable}</button>);
    }
    else if(isOwner) {
      return (<button type="button" disabled style={{opacity: '30%'}}  className={`contact-btn ${marginClass}`} onClick={() => handleInquiry()}>{isAvailable}</button>);
    }
    else {
      (<button type="button" title={t('g_ownerNotify')} className={`contact-btn ${marginClass}`} onClick={() => handleInquiry()}>{isAvailable}</button>);
    }
  };

  const groupSummary = selectedPropertyGroup?.summary?.all;

  useEffect(() => {
    const allPropertiesDisabled = () => {
      let inactive = 0;
      let maxLength = selectedPropertyGroup?.properties.length;
      selectedPropertyGroup?.properties.forEach((x) => {
        if (x.archive || x.disabled) inactive ++;
      })
  
      if (inactive === 0 ) return null;
      if (inactive === maxLength) setRedirect('/');
    }

    allPropertiesDisabled();
  }, [selectedPropertyGroup, setRedirect])

  return (
    <div id='PropertyPage'>
      {showMailModal && <MailModal
                          property={selectedPropertyGroup}
                          customText={customText}
                          handleLoader={handleLoader}
                          handleMessagesModal={handleMessagesModal}
                          handleModal={handleModal}
                          handleMailModal={handleMailModal}
                          isPropertyGroup={true}
                        />}
      <div className="pos-relative mb-2">
        {/* Generate Image Carousel */}
        <ImageCarousel images={selectedPropertyGroup?.images} handleImageModal={handleImageModal} />
      </div>
      <div className="container">
        {/* { isOwner && <h4 className='py-1'>Owner Property Options</h4> }
        <div className="flex-row border-b mb-3" style={{maxWidth: '1000px'}}>
        { isOwner && <button type="button" className="property-header-btn mb-1 mr-2" onClick={e => handleEditMode(e)}>Edit Property</button>}
        { isOwner && <button type='button' className='property-header-btn mb-1' onClick={e => handlePropertyRemove(id)}>Remove Property</button>}
        </div> */}
        <div>
        <div className='main-property-div'>
          <div className="">
            <div className="flex-col mb-1">
              <span className="text-grey text-camel">{t('apartmentComplex')}</span>
              <div className='flex-row'>
              <h1 className='mr-2'>{selectedPropertyGroup?.group_name}</h1>
              </div>
              {/* <h3>Apartment Name</h3> */}
            </div>
            <div className="pb-1">
              {/* <span className="text-grey mb-2">Address</span> */}
              <div className="text-camel">{selectedPropertyGroup?.island}, {selectedPropertyGroup?.street_address},{' '}
                <span className="text-caps">{selectedPropertyGroup?.po_box}</span>
              </div>
              {/* <a href={`/properties/owned_by/${selectedPropertyGroup?.owner_id}`} className='text-camel'>
                {selectedPropertyGroup?.owner?.profile?.first_name} {selectedPropertyGroup?.owner?.profile?.last_name}</a> */}
              <a href={`/search/?island=${selectedPropertyGroup?.island}&settlement=${selectedPropertyGroup?.settlement}`} className='text-camel'>
                {selectedPropertyGroup?.settlement}</a>
            </div>
          </div>
          <div className="flex-row mb-2" style={{"alignItems": "flex-end"}}>
            {reviewsText()}
          </div>
          <div className='flex-row'>
            <button type='button' title='Favorite' className='bare-btn mr-3' onClick={e => handleFavorite(e)}>
              {isFavorited && <img className='heart filter-red' alt='Favorite button' src={FavoriteFull} />}
              {!isFavorited && <img className='heart' alt='Favorite button' src={Favorite} />}
            </button>
            <div className="">
              <FacebookShareButton
                url={window.location.href}
                quote={'RentalFaqz.com'}
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton
                url={window.location.href}
                quote={'RentalFaqz.com'}
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <WhatsappShareButton
                url={window.location.href}
                quote={'RentalFaqz.com'}
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
            </div>
            {(selectedPropertyGroup?.verified) && (
              <div className='flex-row ml-2 p-05 verified-btn' title='Verified Listing'>
                <img alt='verified listing' src={Verified} className='filter-verified'/>
                <span className='size-1 text-green'>Verified</span>
              </div>
            )}
          </div>

          <div className="mt-3 mb-3">
            <div className="property-table">
              <table className='mx-auto w-100 text-center'>
                <thead>
                  <tr className='property-tableheader'>
                    <th className='monthly-fee text-normal'>{t('f_monthlyFee')}</th>
                    <th className='bedrooms text-normal'></th>
                    <th className='bathrooms text-normal'></th>
                    <th className='footage hide-sm text-normal'>{t('g_squareFeet')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='text-bold'>
                    <td>{`$${numberWithCommas(groupSummary?.price_range?.min)} - $${numberWithCommas(groupSummary?.price_range?.max)}`}</td>
                    <td className='text-camel'>{selectedPropertyGroup?.summary?.desc}</td>
                    <td>{`${groupSummary?.bathrooms_range?.min} - ${groupSummary?.bathrooms_range?.max} ba`}</td>
                    <td className='hide-sm'>{`${numberWithCommas(groupSummary?.sq_ft_range?.min)} - ${numberWithCommas(groupSummary?.sq_ft_range?.max)} sq ft`}</td>
                  </tr>
                </tbody>
              </table>
              {/* <div className="flex-row mb-1 text-center">
                <div className="flex-col mr-05">
                  <span className="property-tableheader">Monthly Fee</span>
                  <span className="property-data">{`$${numberWithCommas(groupSummary?.price_range?.min)} - $${numberWithCommas(groupSummary?.price_range?.max)}`}</span>
                </div>
                <div className="flex-col mr-05">
                  <span className="property-tableheader bedrooms"></span>
                  <span className="property-data text-camel">{selectedPropertyGroup?.summary?.desc}</span>
                </div>
                <div className="flex-col mr-05">
                  <span className="property-tableheader bathrooms"></span>
                  <span className="property-data text-camel">{`${groupSummary?.bathrooms_range?.min} - ${groupSummary?.bathrooms_range?.max} bath(s)`}</span>
                </div>
                <div className="flex-col hide-sm">
                  <span className="property-tableheader footage">Square Feet</span>
                  <span className="property-data">{`${numberWithCommas(groupSummary?.sq_ft_range?.min)} - ${numberWithCommas(groupSummary?.sq_ft_range?.max)} sq ft`}</span>
                </div>
              </div> */}
            </div>
          </div>
          
          <div className='move-in-box mb-4'>
            <div className='flex-row'>
              <h4>{t('f_moveInSpecial')}</h4>
              <img className='ml-1 contact-icon mr-1' src={MoveInSpecial} alt='move in special icon'/>
            </div>
            <p>{translatedSpecial}</p>
          </div>

          {/* Related Units Section */}
          {renderRelatedUnitsSection()}

          {/* About Description of property */}
          <h3 className='mt-3'>{t('g_about')} {selectedPropertyGroup?.group_name}</h3>
          <p>{translatedDesc}</p>

          {/* Community Amenities */}
          {/* <h3 className='mt-2'>Community Amenities</h3>
          <p>{selectedPropertyGroup?.features || 'Enter property amenities available to all tenants/units.'}</p> */}

          {/* Google Maps Component */}
          <h3 className='mt-3 py-1'>{t('g_location')}</h3>
          <div className="text-camel pb-1"><b>{t('g_propertyAddress')}:</b> {selectedPropertyGroup?.settlement}, {selectedPropertyGroup?.island}, {selectedPropertyGroup?.street_address}</div>
          <div className='mb-2'>
            <a target='_blank' rel='noreferrer' href={`https://www.google.com/maps/dir//${selectedPropertyGroup?.latitude},+${selectedPropertyGroup?.longitude}/@${selectedPropertyGroup?.latitude},${selectedPropertyGroup?.longitude},17z?entry=ttu`}>
              <b>Get Directions</b>
            </a>
          </div>
          {selectedPropertyGroup && <MapsComponent property={selectedPropertyGroup}/>}

          {/* Disclaimer */}
          {selectedPropertyGroup?.disclaimer && (
            <div>
              <h3 className='mt-3'>{t('g_rentalDisclaimer')}</h3>
              <p>{translatedDisclaimer}</p>
            </div>
          )}

          {/* Contact Owner Box */}
          <div className="contact-owner-still mt-4 mb-3">
            <h3 className="mb-3 fs-3">{t('g_contactOwner')}</h3>
            {/* {renderInquiry(true)} */}
            <div className="contact-inside-still">
              <div className='flex-col'>
                <button type='button' className="contact-btn2-still mb-2 fs-5x" onClick={e => handleMailModal(e, 'I want to schedule a tour.')}>
                {t('g_tourReq')}
                </button>
                
                <button type='button' className="contact-btn3-still fs-5x" onClick={e => handleMailModal(e)}>
                {t('sendMessage')}
                </button>
              </div>

              <div className='flex-col'>
                {selectedPropertyGroup?.owner?.profile?.phone_numb && (
                <div className="flex-row justify-flex-center mb-1">
                  <img className='contact-icon mr-1' src={Phone} alt='phone icon'/>
                  <a href={`tel:+${selectedPropertyGroup?.owner?.profile?.phone_numb}`}>{formatPhone(selectedPropertyGroup?.owner?.profile?.phone_numb)}</a>
                </div>
                )}
                
                {selectedPropertyGroup?.owner?.profile?.mobile_numb && (
                <div className="flex-row justify-flex-center">
                  <img className='contact-icon mr-1' src={MobilePhone} alt='mobile phone icon'/>
                  <a href={`tel:+${selectedPropertyGroup?.owner?.profile?.mobile_numb}`}>{formatPhone(selectedPropertyGroup?.owner?.profile?.mobile_numb)}</a>
                </div>
                )}
              </div>

              <div className='flex-col'>
                <div className='flex-row size-2'>
                  <img className='contact-icon mr-1' src={Globe} alt='globe language icon'/>
                  <div>{t('g_language')}: <span className='text-camel'>{selectedPropertyGroup?.owner?.profile?.preferred_language || 'English'}</span></div>
                </div>
                <div className='flex-row size-2 mb-2 mt-1'>
                  <img className='contact-icon mr-1' src={Clock} alt='globe language icon'/>
                  <div>{`${t('g_open')}: ${officeHours(selectedPropertyGroup?.owner?.profile?.office_hours)}`}</div>
                </div>
                <div className='flex-row justify-flex-center size-2'>
                  <button type='button' className='bare-btn text-link' onClick={() => handleMessagesModal('Office Hours', officeHours(selectedPropertyGroup?.owner?.profile?.office_hours, false))}>{t('g_allHours')}</button>
                </div>
              </div>
            </div>
          </div>

          {/* Property Reviews */}
          <div className='mt-3'>
            {<h3>{t('g_residentReviews')}</h3>}
            {reviewsText()}
            {selectedPropertyGroup?.rating <= 0 && <div className='mt-3'>{t('g_noRatings')}...</div>}
            <div className="flex-row mt-3">
              {populateReviews()}
            </div>
          </div>
        </div>
        <div ref={contactDiv} className='main-property-div-r hide-sm hide-md'>
          {/* Contact Owner Box */}
          <div ref={contactBox} className="contact-owner">
            <h3 className="size-5x mb-3 fs-3">{t('g_contactOwner')}</h3>
            {/* {renderInquiry(true)} */}
            <div className="flex-col align-c-center justify-flex-center align-items-center">
              <button type='button' className="contact-btn2 mb-3 fs-5x" onClick={e => handleMailModal(e, 'I would like to schedule a tour.')}>
              {t('g_tourReq')}
              </button>
              
              <button type='button' className="contact-btn3 mb-3 fs-5x" onClick={e => handleMailModal(e)}>
              {t('sendMessage')}
              </button>
              
              <div className='mb-3 pb-2 border-bottom'>
                {selectedPropertyGroup?.owner?.profile?.phone_numb && (
                <div className="flex-row justify-flex-center">
                  <img className='contact-icon mr-1' src={Phone} alt='phone icon'/>
                  <a href={`tel:+${selectedPropertyGroup?.owner?.profile?.phone_numb}`}>{formatPhone(selectedPropertyGroup?.owner?.profile?.phone_numb)}</a>
                </div>
                )}
                
                {selectedPropertyGroup?.owner?.profile?.mobile_numb && (
                <div className="flex-row justify-flex-center">
                  <img className='contact-icon mr-1' src={MobilePhone} alt='mobile phone icon'/>
                  <a href={`tel:+${selectedPropertyGroup?.owner?.profile?.mobile_numb}`}>{formatPhone(selectedPropertyGroup?.owner?.profile?.mobile_numb)}</a>
                </div>
                )}
              </div>

              <div className='flex-row justify-flex-center size-2'>
                <img className='contact-icon mr-1' src={Globe} alt='globe language icon'/>
                <div>{t('g_language')}: <span className='text-camel'>{selectedPropertyGroup?.owner?.profile?.preferred_language || 'English'}</span></div>
              </div>
              <div className='flex-row justify-flex-center size-2'>
                <img className='contact-icon mr-1' src={Clock} alt='clock icon'/>
                <div>{`${t('g_open')}: ${officeHours(selectedPropertyGroup?.owner?.profile?.office_hours)}`}</div>
              </div>
              <div className='flex-row justify-flex-center size-2'>
                <button type='button' className='bare-btn text-link' onClick={() => handleMessagesModal('Office Hours', officeHours(selectedPropertyGroup?.owner?.profile?.office_hours, false))}>{t('g_allHours')}</button>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

PropertyGroupDisplay.propTypes = {
  user: propTypes.instanceOf(Object),
  setRedirect: propTypes.func.isRequired,
  handlePropertyRequests: propTypes.func.isRequired,
  handlePropertyRemove: propTypes.func.isRequired,
  handleLoader: propTypes.func.isRequired,
  handleModal: propTypes.func.isRequired,
  handleConfirmModal: propTypes.func.isRequired,
  handleMessagesModal: propTypes.func.isRequired,
  handleImageModal: propTypes.func.isRequired
};

export default PropertyGroupDisplay;
