/* eslint-disable no-unused-vars */

import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const PropertyForumDisplayThumb = ({ comment, handleSelectComment }) => {
  const { t } = useTranslation();

  // eslint-disable-next-line camelcase
  const { id, comment_id, comment_type, title,
          body, created_at, updated_at,
          images, user } = comment;

  // Created At 
  const created_at_seconds_index = created_at.indexOf('.');
  const created_at_no_seconds = created_at.substring(0, created_at_seconds_index);
  const dateEnd = created_at.indexOf('T');
  const shortDate = created_at.substring(0, dateEnd);

  // Updated At
  const updated_at_seconds_index = updated_at.indexOf('.');
  const updated_at_no_seconds = updated_at.substring(0, updated_at_seconds_index);
  const dateEndEdited = updated_at.indexOf('T');
  const shortDateEdited = updated_at.substring(0, dateEndEdited);

  // Comment Data
  const bodyLength = body.length;
  const titleLength = title.length;

  return (
    <button key={id} onClick={() => handleSelectComment(comment)} className='forum-thread mb-1'>
      <div className='flex-row align-items-start'>
        <img alt='User Avatar' className='mr-05' src={user?.photo} />
        <div className='forum-thread-body'>
          <div className='flex-row mb-1'>
            <div className='flex-col'>
              <h3>
                {title.slice(0, 26)}
                {titleLength > 26 && '...'}
              </h3>
              <span className='size-01'>{shortDate} {created_at_no_seconds !== updated_at_no_seconds && `(${t('f_edited')})`}</span>
            </div>
          </div>
          <div className='flex-col mb-2'>
            <span className='text-camel text-bold size-4'>{user.profile?.first_name} {user.profile?.last_name}</span>
            {images.length > 0 && (
                <div>
                  <span className='fst-italic'>Post contains images (tap to see)</span>
                  <img src={images[0].url} alt='report' style={{width: '12px', height: '12px'}}></img>
                </div>
                )
              }
          </div>
          <p className='size-3'>
            {body.slice(0, 120)}
            {bodyLength > 120 && '...'}
          </p>
        </div>
      </div>
    </button>
  );
};

PropertyForumDisplayThumb.propTypes = {
  comment: propTypes.instanceOf(Object).isRequired,
  handleSelectComment: propTypes.func.isRequired,
};

export default PropertyForumDisplayThumb;
