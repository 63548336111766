import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    // debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // language resources
    resources: {
      en: {
        translation: {
          // Error
          fileBig: "File size too big (max size allowed: 3 megabyte)",

          // Navbar
          login: "Login",
          signup: "Sign Up",
          featured: "Featured",
          searchAdvanced: "Advanced Search",
          about: "About Us",
          contact: "Contact Us",
          notifications: "Notifications",
          myAccount: "My Account",

          // About Page
          terms: "Terms and Conditions",
          privacy: "Privacy Statement",

          // Homepage
          header: "Discover new beginnings",
          header2: "New reasons, new adventures, new memories.",
          header3: "Find your perfect fit in ",
          search: "Search",

          // Login Page
          f_login: "Login to your account",
          f_createAcct: "Create an account?",
          f_resendEmail: "Resend Confirmation Email",
          f_forgotPass: "Forgot Password?",

          //  Register New Account Page
          joinCommunity: "Join our Community",
          fullAccess: "Create an account for full access",
          siteUsage: "How do you intend to use this site?",
          landLord: "LandLord",
          landLordDesc: "Rent out properties, by posting listings on this site.",
          or: "Or",
          tenant: "Tenant",
          tenantDesc: "Search for a property to live in, by applying to listings available on this site.",
          personInfo: "Personal Information",
          requiredFields: "Required fields are highlighted with a distinct border color",
          // Form Fields
          f_passwordReset: "Request a Password Reset",
          f_sendEmail: "Send Email",
          f_passwordMatch: "Password doesn't Match Confirmation!",
          f_fName: "First Name",
          f_mName: "Middle Name",
          f_lName: "Last Name",
          f_sex: "Sex",
          f_opt_male: "Male",
          f_opt_female: "Female",
          f_dob: "Date of Birth",
          f_dobDesc: "Must be 18 years of age or older",
          f_cob: "Country of Birth",
          f_cor: "Country of Residence",
          f_nib: "NIB number",
          f_govID: "Government ID Upload",
          f_photo: "Photo Upload",
          f_contact: "Contact Information",
          f_phone: "Phone Contact",
          f_bank: "Banking Information",
          f_bankName: "Name of Bank",
          f_bankBranch: "Branch",
          f_bankTransit: "Transit",
          f_bankAccount: "Account",
          f_family: "Family Relations",
          f_relations: "Relationship Status",
          f_opt_single: "Single",
          f_opt_married: "Married",
          f_opt_divorced: "Divorced",
          f_opt_relationship: "Relationship",
          f_spouse: "Do you live with your spouse?",
          f_haveChildren: "Do you have any children?",
          f_haveBaby: "Are any of your children considered an infant/baby?",
          f_income: "Income Information",
          f_occupation: "Occupation",
          f_opt_agriculture: "Agriculture, Food & Natural Resources",
          f_opt_art: "Arts, Audio/Video Technology & Communications",
          f_opt_education: "Education & training",
          f_opt_government: "Government & Public Administration",
          f_opt_hospitality: "Hospitality & Tourism",
          f_opt_IT: "Information technology",
          f_opt_manufacture: "Manufacturing",
          f_opt_math: "Science, technology, Engineering & Mathematics",
          f_opt_construction: "Architecture & Construction",
          f_opt_business: "Business Management & Administration",
          f_opt_finance: "Finance",
          f_opt_health: "Health Science",
          f_opt_human: "Human Services",
          f_opt_law: "Law, Public Safety, Corrections & Security",
          f_opt_marketing: "Marketing, Sales & Service",
          f_opt_transportation: "Transportation, Distribution & Logistics",
          f_opt_other: "Other",
          f_employer: "Employed At",
          f_employmentLength: "Length of Employment in months",
          f_jobLetter: "Job Letter Upload",
          f_workContact: "Work Contact",
          f_annualIncome: "Annual Income in",
          f_lessThan: "Less than",
          f_higherThan: "and higher",
          f_account: "Account Information",
          f_email: "Email Address",
          f_backupEmail: "Recovery Email",
          f_password: "Password",
          f_passwordConfirm: "Password Confirmation",
          f_declaration: "The information provided is correct to my knowledge",
          f_uploadImage: "Upload Images",
          f_clearImage: "Clear images to Upload",
          f_cancel: "Cancel",
          f_remove: "Remove",
          f_submit: "Submit",
          f_island: "Island",
          f_settlement: "Settlement",
          f_streetAddress: "Street Address",
          f_PoBox: "PO Box",
          f_longitude: "Google Maps Longitude",
          f_latitude: "Google Maps Latitude",
          f_desc: "Description",
          f_moveInSpecial: "Move-In Special",
          f_disclaimer: "Disclaimer",
          f_highlights: "Highlights",
          f_propertyPhoto: "Property Photo Uploads",
          f_propertyPhotoDesc: "First photo uploaded is used as property image thumbnail",
          f_propertyPhotoDesc2: "Red highlighted Images are selected for removal",
          f_selectPhoto: "Select Cover Photo",
          f_selectPhotoDesc: "Green highlighted Image is the selected cover photo",
          f_clear: "Clear",
          f_back: "Back",
          propertyCategorize: "Categorize Property into a Group",
          f_unit: "Unit",
          f_propertyType: "Property Type",
          f_opt_apartment: "Apartment",
          f_opt_bnb: "Air BnB",
          f_opt_home: "Home",
          f_opt_condo: "Condo",
          f_opt_townhouse: "Townhouse",
          f_sqFt: "Square Footage of Property",
          f_bedrooms: "Number of Bedrooms",
          f_bathrooms: "Number of Bathrooms",
          f_payment: "Payment Information",
          f_fee: "Fee",
          f_deposit: "Deposit Expected",
          f_depositDesc: "Total payment expected of tenant for first month and moving in fee.",
          f_frequency: "Payment Frequency",
          f_opt_once: "One time",
          f_opt_daily: "Daily",
          f_opt_weekly: "Weekly",
          f_opt_monthly: "Monthly",
          f_amenities: "Property Amenities",

          // Confirm Signup Page
          welcome: "Welcome",
          signupThanks: ", and thanks for signing up!",
          checkEmail: "Please check your email",
          accountLink: "for the account activation link",
          checkSpam: "PS: If you don't see the activation email, please check your Junk or Spam folders!",

          // Property Groups
          propertyGroup: "Property Groups Information",
          propertyGroupDesc: "Use property groups to link rental units if they are within the same apartment complex",
          propertyGroupSelect: "Select Property Group to edit",
          propertyGroupEdit: "Edit Property Group",
          propertyGroupCreate: "Create new Property Group",
          propertyGroupName: "Group Name",
          propertyGroupRemove: "Remove Group",
          propertyGroupUpdate: "Update Property Group",
          propertyGroupNew: "New Property Group",
          propertyGroupShow: "Show Group Information",

          // Add Property
          addProperty: "Add a new Property",
          propertyCreate: "Create a new property",
          propertyInfo: "Property Information",
          propertySingle: "Single Unit",
          propertyMultiple: "Multiple Units",
          propertyUnitDesc: "Choose either a single unit listing or an apartment complex listing",
          f_addProperty: "Add Property",
          f_addUnit: "Add Unit",

          // Edit Property
          tenantInfo: "Tenant Information",
          appInquiry: "Applicant inquiries",
          appInquiryDesc: "Users who've inquired about the property",
          propertyTenant: "Property Tenants",
          propertyTenantDesc: "Users who are currently tenants of the property",
          f_disabled: "Is this property available for public viewing?",
          updateProperty: "Update Property",
          apartmentComplex: "Apartment Complex",
          f_applicants: "Applicants",
          f_groups: "Groups",
          f_info: "Info",

          // Property Payments
          choose_plan: "Choose your plan",
          choose: "Choose",
          basic: "Basic",
          week: "week",
          month: "month",
          allowed_to_list: "Allowed to list properties, up to ",
          allowed_to_list_unlimited: "Allowed to list an unlimited amount of properties",
          premium: "Premium",
          business: "Business",
          can_accept_tenants: "Can accept tenants online",
          can_accept_payments: "Can accept tenants payments online",

          // Property Search
          select_filter: "Select Filters",
          clear_filter: "Clear Filters",
          f_sqFt_greater: "Square Ft greater than",
          f_price_range: "Price Range",
          f_min: "Minimum",
          f_max: "Max",
          f_sort_by: "Sort By",
          f_oldest_newest: "Oldest to newest",
          f_most_recent: "Most recent",

          //Property Forums
          topic_summary: "Topic Summary",
          issue_details: "Details of issue",
          f_submit_req: "Submit Request",
          property_forum: "Property Forums",
          maintenance_req: "Maintenance Requests",
          new_repair_req: "New Repair request",
          open: "Open",
          closed: "Closed",
          // Selected Property Forum
          repair_req: "Repair Requests",
          tenant_inq: "Tenant Inquiries",
          // Forum New Comment
          title: "Title",
          enterText: "Enter text below",
          f_reply: "Reply",
          // Property Forum Display
          f_edit: "Edit",
          f_attachedImages: "Attached images",
          f_posted: "posted",
          f_edited: "edited",
          f_replies: "Replies",
          f_close_topic: "Close Topic",

          // Favorited Properties
          favoritedProperty: "Favorited Properties",

          // Property Display Thumb
          t_beds: "Bed(s)",
          t_baths: "Bath(s)",
          t_propertyOwned: "Property Owned",
          t_yourHome: "Your Home",
          t_rented: "Rented",
          t_repairsReq: "Request Repairs / Queries",
          studio: "studio",
          one_bed: "1 bed",
          two_beds: "2 beds",
          three_beds: "3 beds",
          studio_one_bed: "studio - 1 bed",
          studio_two_beds: "studio - 2 beds",
          studio_three_beds: "studio - 3 beds",
          studio_studio: "studio - studio",
          one_bed_one_bed: "1 bed - 1 bed",
          one_bed_two_beds: "1 bed - 2 beds",
          one_bed_three_beds: "1 bed - 3 beds",
          two_beds_two_beds: "2 beds - 2 beds",
          two_beds_three_beds: "2 beds - 3 beds",
          three_beds_three_beds: "3 beds - 3 beds",

          // Property  Display
          g_leaveReview: "Leave a review",
          g_leaveReviewDesc: "Leave a review detailing your stay at this residence.",
          needToBeResident: "You can only leave a review after being a resident of the property in question.",
          g_otherUnits: "Other Apartment Complex Units",
          g_noRelatedUnits: "No related units available.",
          g_bedroom: "bedroom",
          g_apply: "Apply now",
          g_this: "this",
          unitFeatures: "Unit Features",
          g_ownerOptions: "Owner Property Options",
          g_residentOptions: "Resident Property Options",
          g_tenantForums: "Tenant Forums",
          g_editProperty: "Edit Property",
          g_removeProperty: "Remove Property",
          g_rentalProperty: "Rental Property",
          g_waterIncluded: "Water included",
          g_internetIncluded: "Internet included",
          g_electricityIncluded: "Electricity included",
          g_utilities: "Utilities",


          // Property Group Display
          g_verifiedRenter: "Verified Renter",
          needToRegister: "You must be a registered user to use this feature!",
          needToSignIn: "Please Sign in or create an account.",
          gPricingUnit: "Pricing & Units",
          g_all: "All",
          g_studio: "Studio",
          g_inquire: "Inquire now",
          g_ownerNotify: "Property Owner will be notified with your contact info",
          g_squareFeet: "Square Feet",
          g_noneAvailable: "None available at this time.",
          g_about: "About",
          g_propertyDesc: "Enter property description here.",
          g_location: "Location",
          g_propertyAddress: "Property Address",
          g_rentalDisclaimer: "Rental Disclaimer",
          g_contactOwner: "Contact Property Owner",
          g_tourReq: "Request Tour",
          g_language: "Language",
          g_open: "Open",
          g_allHours: "View All Hours",
          g_residentReviews: "Resident reviews",
          g_noRatings: "No ratings available",

          // Unit Display
          f_furnished: "Furnished",
          f_kitchen: "Kitchen",
          f_livingRoom: "Living Room",
          f_airConditioned: "Air Conditioned",
          f_fencedIn: "Fenced In",
          f_backyard: "Backyard",
          f_gatedCommunity: "Gated Community",
          f_petsAllowed: "Pets Allowed",
          f_inUnitWasher: "In unit Washer and Dryer",
          f_washerHookups: "Washer and Dryer Hookups",
          f_dishwasher: "Dishwasher",
          f_wheelchairAccess: "Wheelchair Accessible",
          f_laundryFacilities: "Laundry Facilities",
          f_fitnessCenter: "Fitness Center",
          f_pool: "Pool",
          f_elevator: "Elevator",
          f_parking: "Parking",
          f_balcony: "Balcony",
          f_upstairs: "Located Upstairs",
          f_downstairs: "Located Downstairs",
          f_utilities: "Utilities included",
          f_water: "Water",
          f_internet: "Internet",
          f_electricity: "Electricity",
          units: "units",
          availableUnit: "available unit",
          availableUnits: "available units",
          propertyAvailable: "Property Available",
          propertyUnavailable: "Property Unavailable",
          hideUnits: "Hide Units",
          showUnits: "Show Units",
          rentalFee: "Rental Fee",
          baths: "Baths",
          propertyFeatures: "Property Features",
          sendMessage_title: "Send email message to property owner",
          sendMessage: "Send Message",


          // Account Page
          profileSettings: "Profile Settings",
          profile: "Profile",
          myHome: "My Home",
          ownedProperties: "Owned Properties",
          propertyTransactions: "Property Transactions",
          manageSubs: "Manage Subscriptions",
          logOut: "Log Out",
          f_preferredLang: "Preferred Language",
          f_english: "English",
          f_spanish: "Spanish",
          f_creole: "Creole",
          f_contactHours: "Contact Hours",
          f_sunday: "Sunday",
          f_monday: "Monday",
          f_tuesday: "Tuesday",
          f_wednesday: "Wednesday",
          f_thursday: "Thursday",
          f_friday: "Friday",
          f_saturday: "Saturday",
          f_startTime: "Start time",
          f_endTime: "End time",
          f_contactUpdate: "Update Contact info",
          f_updateBank: "Update Bank info",
          f_updateEmployment: "Update Employment info",
          f_changePassword: "Change Password",
          f_newPassword: "New Password",
          f_newPasswordConfirm: "New Password Confirmation",
          f_deactivateAccount: "Deactivate your account",
          f_closeAccount: "Close Account",
          f_propertyListing: "Property Listings",
          f_newListing: "Add new Listing",
          f_singleListing: "Single Listings",
          f_complexListing: "Apt Complex Listings",

          // Payment Modal
          f_cardHolder: "Card Holder's Name",
          f_paymentAmount: "Payment Amount",
          f_cardNumber: "Card Number",
          f_cardExpiry: "Card Expiry",
          f_cardCVC: "Card CVC",
          f_pay: "Pay",

          // PaymentSettingDisplay
          f_propertySubs: "Property Owner Subscription",
          f_recurring: "Recurring",
          f_invoiceNumb: "Invoice No.",
          f_activateDate: "Activated Date",
          f_expiryDate: "Expiry Date",
          f_renew: "Renew",
          f_initialDeposit: "Initial Deposit",
          f_monthlyFee: "Monthly Fee",
          f_totalFee: "Total Fee amount",
          f_paymentStatus: "Payment Status",
          f_datePaid: "Date Paid",
          f_nextPayDate: "Next Pay Date",
          f_tapToPay: "Tap to Pay!",
          recent_sub: "Recent Subscription",
          purchase_sub: "Purchase a subscription plan",
          my_home_rental: "My Home Rental Fees",

          // Tenant Modal
          f_termsTenantLeft: "Terms in which tenant left previous apartment",
          f_landlordScore: "Landlord Score",
          f_landlordComment: "Comment of previous LandLord",
          f_tenantName: "Tenant Name",
          no_reviews: "No Reviews yet.",
          reviews: "reviews",
          f_generalInfo: "General Info",
          f_race: "Race",
          f_passport: "Passport No.",
          f_citizenRecord: "Citizen Record Info",
          f_criminalRecord: "Criminal Record",
          f_reasonJailed: "Reason Jailed",
          f_tenantHistory: "Tenant History",
          no_history: "No Tenant History.",
          f_overallRating: "Overall Rating",
          f_rateTenant: "Rate Tenant",
          f_giveRating_req: "Give a rating for the tenant you a removing from the property listing",
          f_tenantClosure: "Closure (Why is the tenant leaving)",
          f_movedOut: "Moved Out",
          f_evicted: "Evicted",
          f_tenantRating: "Tenant rating,",
          f_comment: "Comment",
          f_reviewTenant: "Review Tenant",
          f_denyTenant: "Deny Tenant Inquiry",
          f_acceptTenant: "Accept as Tenant",
          f_removeTenant: "Remove Tenant",

          //Send Mail Modal
          f_moveInDate: "Move-in Date",
          f_customMessage: "Custom Message",

          // Confirm Modal
          ratingRange: "Rating range, 1 - 5 stars",

          // Report Modal
          reportIssue: "Report an issue",
          descIssue: "Describe issue",

          // Terms of Use
          registerNow: "Register Now",
          contactText: `In order to properly respond to your privacy questions or requests about our services, 
            please email us at 
            <a href="mailto:rentalfaqz@gmail.com"> rentalfaqz@gmail.com</a>, or call us at: 
            <a href="tel:+12428202234"> (242) 820-2234</a>`,
          aboutText: `Here at <b>Rentalfaqz</b>, our aim is to become the Caribbean’s most comprehensive online rental marketplace.
            <br/><br/>
            Therefore we will be adding venue, and transport rentals to our services in order to accommodate you better, and make your life that much easier.
            So plan ahead and book in advance from our many vendors, with little effort and with time to spare.
            <br/><br/>
            As we expand our reach into the surrounding Caribbean countries, we will be creating an extensive clientele network of real estate, 
            venue, transport rentals, bringing them right to your finger tips.
            <br/><br/>
            So whether you're looking to increase your business online presence, secure the right venue for that big occasion, 
            or fill out your rental vacancies; you’ve come to the right place!`,
          termsText: `<h3>Terms of Service Agreement</h3>
            <p class='size-1'>
              These Terms of Service apply to the services offered by Rentalfaqz.com. Any individual who visits, 
              views, accesses or uses the Site, including through a bot or other automated means, (“You” or 
              “User”) is bound by these Terms of Service. By using the Site, You represent and warrant that You 
              are at least eighteen (18) years of age and You hereby agree to be bound by all of the following 
              provisions of these Terms of Service, which form a legally binding contract between You and 
              Rentalfaqz.com. If You do not agree to these Terms of Service, You are prohibited from accessing 
              the Site and must immediately discontinue such access.
            </p>

            <h3>What should you expect?</h3>
            <p class='size-1'>
              Rentalfaqz.com is an online multifaceted platform that provides advertising, and searching service 
              for rental service providers apartment hunters, landlords, and property managers. This site does 
              not (a) provide any of the services directly and is not a party to any transaction between service 
              provider and customer, (b) guarantee or ensure any service or any transaction between a customer
              and service provider, (c) conduct background screening on rental candidates, or collect payment on 
              behalf of customer or service provider, or (d) act as a mediator, debt collector, or credit reporting 
              agency, and does not guarantee any results from using the service. The Site facilitates the use of 
              crowd sourcing within it’s network of services that provides an account of tenants as a history
              report from the current and previous landlord, or property manager, that is submitted and shared 
              throughout the system for screening purposes of future landlords and property managers.
            </p>

            <h3>Warning!</h3>
            <p class='size-1'>
              You are strongly encouraged to personally inspect any apartment advertised for rent prior to: 
              signing any documentation; providing personal information; or wiring or otherwise sending 
              money for any deposit, or rent payment. By using the Site, You acknowledge that published rents 
              and availabilities are subject to change at the sole discretion of the property owner or manager at 
              any time and without further notice.
            </p>

            <h3>Site Materials</h3>
            <p class='size-1'>
              All information and content available on this Site (collectively, “Materials”) are protected by 
              copyright and other intellectual property laws. The Materials are intended for personal and 
              noncommercial use only. You may not copy, reproduce, sell, license, publish, distribute, modify, 
              display, perform, combine, create a derivative work from, re-post or otherwise use any portion of 
              the Materials in any other way or for any other purpose without the prior written consent of 
              RentalFaqz.com. Requests regarding use of the Materials for any purpose other than personal, 
              noncommercial use should be directed to rentalfaqz@gmail.com, or N-10841 Wellington Street , 
              Nassau Bahamas.
            </p>

            <h3>Third-Party Sites</h3>
            <p class='size-1'>
              During the course of your visit to the Site, You may be taken to third-party websites to fulfill certain 
              site features and functionality (e.g., if You choose to view community information or check 
              availability of a certain apartment unit). You agree that when visiting third-party websites (for 
              example, among others, websites not containing “RenalFaqz.com” in the URL) You are subject to the 
              privacy policy and terms of service, if any, of that third-party website, which may differ from those 
              of RentalFaqz.com. By selecting links to such third-party websites, You agree that You will be 
              leaving the Site and that RentalFaqz.com and its affiliates have no responsibility or liability 
              whatsoever in connection with your use or your exchange of any information with such third-party 
              websites. RentalFaqz.com and its affiliates do not endorse, sponsor or guarantee these linked 
              websites and are not responsible in any way for any advice, content, information, practices, 
              products or services related to or made available through such linked websites.
            </p>

            <h3>Trademarks</h3>
            <p class='size-1'>
              You acknowledge that the Materials includes certain trademarks, service marks, and other indicia 
              of source (together “Marks”) that are owned by RetalFaqz.com, its affiliates, Licensors and others. 
              You agree not to copy, use, infringe or dilute the Marks. You further agree that You will not alter or 
              remove any Mark, copyright notice or other notice from the Materials or any copy thereof. The 
              “RentalFaqz.com” name and logo are trademarks of RentalFaqz.com. Other product and company 
              names herein may be trademarks of their respective owners.
            </p>

            <h3>User Content</h3>
            <p class='size-1'>
              By submitting ads, photos, descriptions, data, or other information (“Content”) to the Site in 
              connection with a property, You hereby grant to RentalFaqz.com, its affiliates, and its parent and 
              related companies a royalty-free, perpetual, irrevocable, nonexclusive, fully transferable, fully 
              sublicenseable right and license to copy, modify, display, distribute, perform, create derivative 
              works from, store, and otherwise use and exploit all such Content in any form, media, software or 
              technology of any kind now existing or developed in the future. You further grant to 
              RentalFaqz.com, its affiliates, and its parent and related companies, a royalty-free right and license 
              to use your name, image and likeness in connection with the reproduction or distribution of the 
              Content. By submitting any Content through the Site, including, but not limited to, through any lead 
              form, you agree that RentalFaqz.com, a landlord, a property manager or other similar individual or 
              entity may contact you via email, text message, phone or autodialer for any purpose. The Site may 
              permit You to voluntarily submit Content relating to You, including your total monthly income or 
              any other (“Consumer-Provided Information”). RentalFaqz.com collects this information for 
              informational purposes only and it is not used by RentalFaqz.com or its customers that are 
              landlords, listing agents or owners (“Properties” and/or “Property”) to make a decision to rent a 
              property to you. By providing Consumer-Provided Information, You acknowledge that 
              RentalFaqz.com may share such Consumer-Provided Information with Properties offering units for 
              rent through the Site subject to these Terms of Service. Any recipient of such Consumer-Provided 
              Information agrees that any Consumer-Provided Information may not be accurate, should not be 
              relied on for any reason and may not be used to make a decision about whether to rent an 
              apartment to a particular person. The Site may offer opportunities for Users 18 years of age and 
              older to submit Content that express their opinions and share their ideas, for example in a 
              consumer blogging format (“Opinion Content”). RentalFaqz.com and its affiliates do not endorse the 
              accuracy or reliability of any Opinion Content. If You decide to submit Opinion Content, please use 
              your best judgment and be respectful of other individuals. By posting or submitting Opinion 
              Content, You agree to abide by these Terms of Service. You may not use the Site to engage in any 
              Prohibited Activities (as defined below). Uploading copyrighted or other proprietary material of 
              any kind on the Site without the express permission of the owner of that material is prohibited and 
              may result in civil and/or criminal liability.
              <br/><br/>
              If You are a customer that is a Property processing rental payment transactions through the Service 
              (as defined below), You must provide Your legal name and/or legal business entity name, personal 
              or business contact information, and NIB number(s) (“Reference Information”), if requested. 
              RentalFaqz.com may collect and maintain the Reference Information, as well as your account 
              information and the amount of payments and number of payment transactions You or Your 
              business processes through the Service, so that Your payments can be reported to the Internal 
              Revenue Service when required by law.
            </p>

            <h3>RentalFaqz.com’s Rights</h3>
            <p class='size-1'>
              Given the nature of the Site and the volume of messages and postings, except as otherwise provided 
              in these Terms of Service or other applicable terms, RentalFaqz.com cannot and does not monitor 
              all of the Content posted or transmitted by You and other third-party information providers. 
              RentalFaqz.com reserves the right, in its sole discretion, to monitor, refuse to publish, remove, 
              delete, move or edit any Content without notice, at any time for any reason. By using the Site, You 
              expressly agree that RentalFaqz.com (a) will not be liable for any claims, actions or judgments 
              arising out of or related to any Content and (b) may monitor, refuse to publish, remove, delete, 
              move or edit any Content without notice at any time for any reason, without liability and without 
              providing a refund. You further expressly agree that You are solely responsible for any and all 
              Content You submit to the Site.
            </p>

            <h3>Unsolicited Content</h3>
            <p class='size-1'>
              RentalFaqz.com does not accept unsolicited content or ideas for use or publication in its 
              programming or in other digital, electronic or print media except in connection with the Forums, 
              chat rooms and bulletin boards on the Site.
            </p>

            <h3>Representations / Indemnity</h3>
            <p class='size-1'>
              You hereby represent, warrant and agree that (a) You have all necessary authority, rights and 
              permissions to submit the Content to the Site, (b) the Content does not and will not infringe or 
              misappropriate any copyright, trademark, trade secret, patent or any other intellectual property or 
              other right of any third party, including any rights of privacy or publicity, (c) the Content, Reference 
              Information and Your use of the Site does not and will not violate any applicable federal, state, or 
              local law or regulation including, but not limited to, any fair housing laws or regulations or 
              applicable real estate licensure or brokerage regulations, or cause injury to any person, (d) the 
              Content and Reference Information is complete, truthful, accurate and not misleading, € the 
              Content and Reference Information has not been submitted in order to mislead, deceive or defraud 
              or seek to mislead, deceive or defraud any other User of the Site, and (f) You will not mislead, 
              deceive, defraud, seek to mislead, deceive or defraud, make any misrepresentations to, or seek to 
              make misrepresentations to, any other User of the Site. You acknowledge that these 
              representations concern facts that are material to RentalFaqz.com. You agree to release, defend, 
              indemnify and hold RentalFaqz.com, its affiliates, Licensors, and their respective officers, directors, 
              employees, agents and contractors harmless from and against any and all claims, costs, demands or 
              expenses, including attorneys’ fees, arising from (i) any distribution, publication, refusal to publish, 
              removal, deletion, movement, editing or other use of the Content or Reference Information You 
              provide, (ii) your use of the Site or any reliance on the Materials, (iii) your breach of these Terms of 
              Service or (iv) any actual, prospective or terminated sale, lease or other transaction between You 
              and a third party.
            </p>

            <h3>Prohibited Activities</h3>
            <p class='size-1'>
              You shall not:
              <ul class='size-1'>
                <li class='mb-1'>
                  Submit Content that is patently offensive to the online community, such as content that 
                  reflects or promotes racism, bigotry, hatred or physical harm of any kind against any group 
                  or individual;
                </li>
                <li>
                  Engage in activity or submit Content that could be harmful to minors;
                </li>
                <li class='mb-1'>
                  Engage in activity or submit Content that harasses or advocates harassment of another person;
                </li>
                <li class='mb-1'>
                  Engage in activity that involves the transmission of “junk mail” or unsolicited mass mailing 
                  or “spam” to others;
                </li>
                <li class='mb-1'>
                  Engage in activity, submit Content or Reference Information, or promote information, that is 
                  fraudulent, false, misleading, inaccurate and/or constitutes a misrepresentation, or 
                  promotes illegal activities or conduct that is abusive, threatening, obscene, vulgar, 
                  defamatory, libelous or otherwise objectionable;
                </li>
                <li class='mb-1'>
                  Submit Content that contains restricted or password-only access pages, or hidden pages or 
                  images;
                </li>
                <li class='mb-1'>
                  Submit Content that displays pornographic or sexually explicit material of any kind;
                </li>
                <li class='mb-1'>
                  Submit Content that provides instructional information about illegal activities such as 
                  making or buying illegal weapons, violating someone’s privacy, or providing or creating 
                  computer viruses;
                </li>                
                <li class='mb-1'>
                  Engage in activities or submit Content that solicits passwords or personally identifiable 
                  information from others for unlawful purposes;
                </li>
                <li class='mb-1'>
                  Engage in commercial activities and/or sales without our prior written consent, such as 
                  contests, sweepstakes, barter, advertising and pyramid schemes;
                </li>
                <li class='mb-1'>
                  Use the Site’s lead forms and/or toll-free numbers to advertise or promote products and 
                  services to RentalFaqz.com advertisers or to solicit RentalFaqz.com advertisers in any 
                  manner;
                </li>
                <li class='mb-1'>
                  Use any robot, spider or other automatic device, or a manual process, to access, monitor or 
                  copy web pages or the Materials contained in the Site or for any other unauthorized 
                  purpose;
                </li>
                <li class='mb-1'>
                  Use any device, software or routine to interfere or attempt to interfere with the proper 
                  working of the Site;
                </li>
                <li class='mb-1'>
                  Decompile, reverse engineer, disassemble or otherwise attempt to obtain the source code 
                  for the Software; or
                </li>
                <li class='mb-1'>
                  Take any action that imposes an unreasonable or disproportionately large load on 
                  RentalFaqz.com’s hardware and software infrastructure.
                </li>
              </ul>
            </p>

            <h3>RentalFaqz.com’s Remedies</h3>
            <p class='size-1'>
              You acknowledge that any breach of these binding Terms of Service, including without 
              limitation the restrictions on competitor access and the sections “Site Material,” 
              “Representations/Indemnity,” and “Prohibited Activities,” or any unauthorized use of the
              Site, is a material breach of this agreement between You and RentalFaqz.com and will cause 
              irreparable harm and injury to RentalFaqz.com, for which there is no adequate remedy at 
              law. Thus, in addition to all other remedies available at law or in equity, You agree that in 
              the event of such breach RentalFaqz.com shall be entitled to injunctive or other equitable 
              relief, whether or not liquidated damages are available without the requirement that 
              RentalFaqz.com post a bond.
              <br/><br/>
              Material breach of these binding Terms of Service harms the integrity, functionality, and 
              reputation of RentalFaqz.com; detracts from Users’ trust in and use of the Site; and damages 
              the business of RentalFaqz.com. Accordingly, You acknowledge and agree that 
              RentalFaqz.com incurs actual damages, which are extremely difficult or impossible to 
              quantify, as a result of material breach of this these binding Terms of Service. For the 
              avoidance of doubt, such damages would not constitute an adequate remedy at law or in 
              any way affect your agreement that RentalFaqz.com would be entitled to injunctive or other 
              equitable relief in the event of such breach.
            </p>

            <h3>Refund Policy</h3>
            <p class='size-1'>
              Terminating services within the first week of a subscription purchase will result in a full refund.
              After the second week, if terminating services you will receive half of the purchase order.
              All sales are final after the third week, as you have recognized and agreed that you shall not be entitled to a refund.
            </p>

            <h3>Warranty Disclaimer</h3>
            <p class='size-1'>
              YOU EXPRESSLY AGREE THAT USE OF THE SITE AND RELIANCE ON ITS CONTENT AND 
              CONSUMER PROVIDED INFORMATION IS AT YOUR OWN RISK. RENTALFAQZ.COM, ITS 
              AFFILIATES AND THEIR RESPECTIVE THIRD-PARTY LICENSORS DO NOT MAKE ANY 
              REPRESENTATIONS OR WARRANTIES OF ANY KIND REGARDING THE SITE, THE 
              SOFTWARE, THE CONTENT, CONSUMER PROVIDED INFORMATION, THE MATERIALS, THE 
              PROPERTY SUBMISSION SERVICE, THE APARTMENT PROCUREMENT SERVICE 
              (COLLECTIVELY, THE “SERVICE”) OR THE RESULTS THAT MAY BE OBTAINED FROM USE 
              OF ANY OF THE FOREGOING. THE SERVICE IS PROVIDED ON AN “AS IS, AS AVAILABLE” 
              BASIS, AND RENTALFAQZ.COM, ITS AFFILIATES, AND THEIR RESPECTIVE THIRD-PARTY 
              LICENSORS SPECIFICALLY DISCLAIM ANY AND ALL EXPRESS OR IMPLIED WARRANTIES 
              INCLUDING, WITHOUT LIMITATION, THE WARRANTIES OF FITNESS FOR A PARTICULAR 
              PURPOSE, WARRANTIES OF MERCHANTABILITY AND WARRANTIES OF 
              NONINFRINGEMENT. RENTALFAQZ.COM AND ITS AFFILIATES MAKE NO 
              REPRESENTATIONS OR WARRANTIES, EXPRESSED OR IMPLIED, TO ANY ACTUAL OR 
              PROSPECTIVE RENTER OF ANY APARTMENT AS TO THE EXISTENCE, OWNERSHIP OR 
              CONDITION OF THE APARTMENT; AS TO THE ADVERTISED AVAILBILITIES, RENT, LEASE 
              TERMS, SECURITY DEPOSIT, OR APPLICATION FEES, IF ANY; OR AS TO THE ACCURACY OR 
              COMPLETENESS OF ANY INFORMATION ABOUT AN APARTMENT APPEARING ON THE 
              SITE. RENTALFAQZ.COM DOES NOT WARRANT THE ACCURACY OR COMPLETENESS OF 
              SUCH INFORMATION. RENTALFAQZ.COM RESERVES THE RIGHT, IN ITS SOLE DISCRETION, 
              TO CORRECT ANY ERROR OR OMISSION ON THE SITE OR IN THE CONTENT. ANY AND ALL 
              CONCERNS, DIFFERENCES OR DISCREPANCIES REGARDING AN APARTMENT MUST BE 
              ADDRESSED WITH THE LANDLORD AND/OR PROPERTY MANAGEMENT COMPANY PRIOR 
              TO RENTING OF THE APARTMENT. RENTALFAQZ.COM DOES NOT MAKE AND EXPRESSLY 
              DISCLAIMS ANY REPRESENTATIONS, WARRANTIES OR GUARANTEES TO YOU REGARDING 
              THE PROPERTY SUBMISSION SERVICE AND THE APARTMENT PROCUREMENT SERVICE, 
              INCLUDING, WITHOUT LIMITATION, ANY REPRESENTATIONS, WARRANTIES OR 
              GUARANTEES THAT YOU WILL LEASE YOUR APARTMENT, OBTAIN AN ACCEPTABLE RENT 
              FOR YOUR APARTMENT, ONLY RECEIVE LEGITIMATE INQUIRIES OR SOLICITATIONS 
              FROM QUALIFIED RENTERS, OR RECEIVE ANY INQUIRIES REGARDING YOUR APARTMENT 
              FOR RENT. FOR PURPOSES OF THIS WARRANTY DISCLAIMER, “THIRD-PARTY LICENSOR” 
              DOES NOT INCLUDE YOU.
            </p>

            <h3>Limitation of Liability</h3>
            <p class='size-1'>
              RENTALFAQZ.COM, ITS AFFILIATES, AND THEIR RESPECTIVE OWNERS, EMPLOYEES, 
              AGENTS, CONTRACTORS AND THIRD-PARTY LICENSORS SHALL IN NO EVENT BE LIABLE 
              FOR ANY DAMAGES OR LOSSES INCLUDING, WITHOUT LIMITATION, DIRECT, INDIRECT, 
              CONSEQUENTIAL, SPECIAL, INCIDENTAL OR PUNITIVE DAMAGES, RESULTING FROM OR 
              CAUSED BY THE SERVICE OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, DAMAGES 
              RESULTING FROM NEGLIGENCE. IN NO EVENT WILL THE AGGREGATE MAXIMUM 
              LIABILITY OF RENTALFAQZ.COM, ITS AFFILIATES, AND THEIR RESPECTIVE OWNERS, 
              EMPLOYEES, AGENTS, CONTRACTORS AND THIRD-PARTY LICENSORS FOR ANY CLAIM 
              ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SERVICE EXCEED THE 
              TOTAL AMOUNT OF FEES ACTUALLY PAID BY YOU TO RENTALFAQZ.COM. THE NEGATION 
              OF DAMAGES SET FORTH ABOVE IS A FUNDAMENTAL ELEMENT OF THE BASIS OF THE 
              BARGAIN BETWEEN RENTALFAQZ.COM AND YOU. THE SERVICE WOULD NOT BE 
              PROVIDED TO YOU WITHOUT SUCH LIMITATIONS. NO ADVICE OR INFORMATION, 
              WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM RENTALFAQZ.COM CREATES 
              ANY WARRANTY, REPRESENTATION AND/OR GUARANTEE NOT EXPRESSLY STATED IN 
              THIS AGREEMENT. FOR PURPOSES OF THIS LIMITATION OF LIABILITY, “THIRD-PARTY 
              LICENSOR” DOES NOT INCLUDE YOU.
            </p>

            <h3>Termination of Service</h3>
            <p class='size-1'>
              You understand and agree that in Rentalfaqz.com’s sole discretion, and without prior notice, 
              your access to this Site may be terminated or suspended, and RentalFaqz.com may exercise 
              any other remedy available and remove any Content, if it believes that your use of the Site 
              and/or any Content or Reference Information You provide (a) violates (i) these Terms of 
              Service, (ii) the rights of RentalFaqz.com, its affiliates, a Licensor, or another User, or (iii) 
              any law or regulation, or are otherwise objectionable or inappropriate or (b) constitute 
              fraudulent or unlawful activity of any nature. You agree that monetary damages may not 
              provide a sufficient remedy to RentalFaqz.com for violations of these Terms of Service, and 
              You consent to injunctive or other equitable relief for such violations without the 
              requirement that RentalFaqz.com post a bond. RentalFaqz.com is not required to provide 
              any refund to You if your use is terminated as a result of RentalFaqz.com’s determination, in 
              its sole discretion, that You have violated these Terms of Service.
            </p>

            <h3>Waiver</h3>
            <p class='size-1'>
              You understand and agree that any individual or entity that violates any term of these 
              Terms of Service is precluded from enforcing, agrees not to enforce, and covenants not to 
              sue to enforce, any similar or related provision in any contract, including terms and 
              conditions, terms of use and terms of service, asserted by such individual, entity or any 
              affiliate thereof, as binding upon RentalFaqz.com or any of its affiliates.
            </p>

            <h3>Miscellaneous</h3>
            <p class='size-1'>
              These Terms of Service may be changed at any time and You will be notified of any such 
              changes by an updated posting of the new Terms of Service on this page of the Site. Your 
              continued use of the Site after the posting of any amended Terms of Service shall constitute 
              your agreement to be bound by any such changes. RentalFaqz.com may modify, suspend, 
              discontinue or restrict the use of any portion of the Site without notice or liability. You 
              hereby agree that any cause of action You may have with respect to the Site must be 
              exclusively filed in the supreme courts located in New Providence Bahamas, within one (1) 
              year after the cause of action arises or the cause is barred. You hereby consent to personal 
              jurisdiction under the supreme courts of New Providence Bahamas, and waive any 
              objection based on forum non convenience. As a condition of using this Site, You agree that 
              all causes of action arising out of or connected with this Site shall be resolved individually, 
              without resort to any form of class action. If for any reason a court of competent jurisdiction 
              finds any provision of these Terms of Service, or portion thereof, to be unenforceable, that 
              provision of these Terms of Service will be enforced to the maximum extent permissible so 
              as to affect the intent of the parties, and the remainder of these Terms of Service will 
              continue in full force and effect. Failure by RentalFaqz.com to enforce any provision of this 
              Terms of Service will not be deemed a waiver of future enforcement of that or any other 
              provision of these Terms of Service. These Terms of Service and any other terms and 
              conditions or policies applying to Your use of the Site, constitute the entire agreement 
              between the parties regarding the subject matter hereof. Nothing contained herein shall be 
              construed as creating a partnership, joint venture or agency relationship or granting a 
              franchise between the parties. Notwithstanding anything to the contrary herein, if You and 
              RentalFaqz.com have entered into a separate written advertising contract, the terms and 
              conditions of such contract shall control with respect to any term that is inconsistent with 
              these Terms of Service. IF YOU DO NOT AGREE TO THE TERMS STATED ABOVE OR TO ANY 
              CHANGES TO THESE TERMS OF SERVICE, PLEASE EXIT THE SERVICE IMMEDIATELY.
              <br/><br/>
              © 2023 RentalFaqz.com, Inc., N-10841 New Providence Bahamas
              <br/><br/>
              Last updated: May 12, 2023
            </p>`,
          privacyText: `<h3>Privacy Statement</h3>
            <p class='size-1'>
              This Privacy Notice describes the types of Personal Information we collect and our practices for 
              using, maintaining, sharing, and protecting it. It also describes the rights and choices you may have 
              with respect to your Personal Information and how you may contact us about our privacy practices. 
              Our privacy practices may vary among the countries in which we operate to reflect local practices 
              and legal requirements, and specific privacy practices may apply to some of our products and 
              services.
              <br/><br/>
              When using our websites, you may choose to interact with features from third parties that operate 
              independently from RentalFaq.com, such as social media widgets and links to third-party websites. 
              RentalFaqz.com has no control over and is not responsible for the privacy practices of such third 
              parties. This Privacy Notice does not apply to the extent RentalFaqz.com does not own or control 
              any linked websites or features you visit or use. We recommend that you familiarize yourself with 
              the privacy practices of these third parties.
            </p>

            <h3>Table of Contents</h3>
            <p class='size-1'>
              <ol class='size-1'>
                <li class='mb-1'>Types of Personal Information</li>
                <li class='mb-1'>Collection of Personal Information</li>
                <li class='mb-1'>Use of Personal Information</li>
                <li class='mb-1'>Disclosure of Personal Information</li>
                <li class='mb-1'>International Transfer of Personal Information</li>
                <li class='mb-1'>Security of Personal Information</li>
                <li class='mb-1'>Retention of Personal Information</li>
                <li class='mb-1'>Manage Your Personal Information</li>
                <li class='mb-1'>Global Policy Notice Changes</li>
                <li class='mb-1'>Contact Us</li>
              </ol>
            </p>
             
            <h3>1. Types of Personal Information</h3>
            <p class='size-1'>
              “Personal Information” means any information directly or indirectly relating to an identified or 
              identifiable individual. Examples of Personal Information RentalFaqz.com may collect include:
              <ul class='size-1'>
                <li class='mb-1'>
                  Payment information (credit card and other payment card information)
                </li>
                <li class='mb-1'>
                  Personal contact information (name, nickname, gender, mailing address, email address, 
                  phone number)
                </li>
                <li class='mb-1'>
                  Business information (office location, business contact information, department, job 
                  title/description, professional bio)
                </li>
                <li class='mb-1'>
                  Log-in credentials (username, password)
                </li>
                <li class='mb-1'>
                  Usage details (log-on activity, search terms, views, clicks, downloads, lead submission 
                  information)
                </li>
                <li class='mb-1'>
                  Technical details (IP address, geolocation information, cookie/session IDs, authentication 
                  information, browser type/version, operating system/platform)
                </li>
                <li class='mb-1'>
                  Any other information you or other customers may upload or enter into the Services
                </li>
                <li class='mb-1'>
                  Phone call and video conference recordings
                </li>
              </ul>
            </p>

            <h3>2. Collection of Personal Information</h3>
            <p class='size-1'>
              RentalFaqz may collect Personal Information using the following methods:
              <ul class='size-1'>
                <li class='mb-1'>
                  Directly from you when you provide it to us (information you enter into web forms, 
                  chatbots, participation in phone calls or video conferences, subscription agreements, and 
                  contracts or other content you submit to the Services)
                </li>
                <li class='mb-1'>
                  Through your participation in surveys, promotions, and contests
                </li>
                <li class='mb-1'>
                  RentalFaqz internal customer relationship management system and databases
                </li>
                <li class='mb-1'>
                  From third parties, such as analytics and email marketing service providers
                </li>
                <li class='mb-1'>
                  Researching public websites where permitted by the Terms of Use (company websites, 
                  search engines, social media)
                </li>
                <li class='mb-1'>
                  Automatically through tracking technologies (cookies, web beacons, log files), including over 
                  time and across third-party websites or other online services
                </li>
              </ul>
            </p>
            
            <h3>3. Use of Personal Information</h3>
            <p class='size-1'>
              We may use your Personal Information for the following purposes:
              <ul class='size-1'>
                <li class='mb-1'>
                  Communicating with you
                </li>
                <li class='mb-1'>
                  Providing you with the Services
                </li>
                <li class='mb-1'>
                  Authenticating use, detecting potential fraudulent use, and otherwise maintaining the 
                  security of the Services
                </li>
                <li class='mb-1'>
                  Developing, testing, improving, and demonstrating the Services
                </li>
                <li class='mb-1'>
                  Creating and maintaining a customer relationship management system, member directories, 
                  and invitation lists for events
                </li>
                <li class='mb-1'>
                  Carrying out our legal and contractual obligations and enforcing our rights, including billing 
                  and payment processing
                </li>
                <li class='mb-1'>
                  Anonymizing and aggregating information for analytics and reporting
                </li>
                <li class='mb-1'>
                  Advertising, marketing, and selling the Services, including linking together or merging 
                  Personal Information with other Personal Information so that we may better understand 
                  your needs and inform you about our Services and those of our partners
                </li>
                <li class='mb-1'>
                  Short-term transient use, as defined in the California Consumer Privacy Act
                </li>
                <li class='mb-1'>
                  Training and quality assurance
                </li>
                <li class='mb-1'>
                  For any other purpose with your consent
                </li>
              </ul>
            </p>
            
            <h3>4. Disclosure of Personal Information</h3>
            <p class='size-1'>
              We may disclose Personal Information:
              <ul class='size-1'>
                <li class='mb-1'>
                  To affiliates and other entities within group of companies, distribution partners, other 
                  customers, and service providers
                </li>
                <li class='mb-1'>
                  To third parties when you use a third party feature in our Services
                </li>
                <li class='mb-1'>
                  To enforce or apply our Terms of Use and other agreements or comply with any court order, 
                  law, or legal process, including government or regulatory requests
                </li>
                <li class='mb-1'>
                  To a buyer or other successor in the event of a merger, divestiture, restructuring, 
                  reorganization, dissolution, or other sale or transfer of some or all of RentalFaqz.com assets
                </li>
                <li class='mb-1'>
                  To advisors, consultants, auditors, or similar professionals to assess our business, financial, 
                  and legal obligations or operations
                </li>
                <li class='mb-1'>
                  If we believe it is necessary to protect the rights, property, or safety of RentalFaqz.com or 
                  others
                </li>
                <li class='mb-1'>
                  For any other purpose disclosed by us when you provide Personal Information
                </li>
                <li class='mb-1'>
                  With your consent, such as when you choose to provide your information to third parties
                  We may also disclose de-identified information without restriction. Note that if you make any 
                  Personal Information publicly available on the Services, anyone may see and use such information.
                </li>
              </ul>
            </p>


            <h4><u>Listing Services</u></h4>
            <p class='size-1'>
              RentalFaqz may share member information in the following areas on the Services: Marketing 
              Center, Email Alerts and Professional Directory. When a customer enters a sale or lease listing in the 
              Marketing Center, listing contact information is also entered. During this process, the listing 
              submitter is able to view other customers’ contact information when he or she adds another contact 
              to the listing. In addition, customers can view contact information (name, email, and phone 
              number) when viewing a property listing profile.
              The Services may also allow users to share contact information with one another via email alerts. 
              With email alerts, users can save their property search criteria to have information about properties 
              that match the specified criteria emailed to them. When selecting this service, users are given the 
              option of releasing their contact information to other users who have property listings that match 
              their criteria. This contact information is then shared with the users who have property listings that 
              match the buying criteria.
            </p>

            <h4><u>RentalFaqz.com</u></h4>
            <p class='size-1'>
              If you elect to use the website’s Free Listing Service to advertise a property, it is important to note 
              that any information you choose to include in your listing may be made publicly available via the 
              website and other media in which the website listings are published, including affiliate sites. For 
              example, you may include a phone number and/or an email address to allow users to contact you 
              regarding your listing. RentalFaqz.com is not responsible for unsolicited phone calls and emails that 
              you may receive from third parties, even after your listing Is no longer on the website. Except for 
              required information, you should not include any contact or other information in your listing that 
              you do not want other parties to see or use.
            </p>
            
            <h3>5. International Transfer of Personal Information</h3>
            <p class='size-1'>
              We may transfer your Personal Information to the United States, and to other countries which may 
              not have the same privacy laws as the country in which you initially provided the information, but 
              we will protect your Personal Information in accordance with this Global Privacy Notice, or as 
              otherwise disclosed to you.
            </p>

            <h4><u>European Economic Area (EEA) Residents and United Kingdom Residents</u></h4>
            <p class='size-1'>
              If you live in the EEA or United Kingdom, we may transfer Personal Information to countries for 
              which adequacy decisions have been issued, use contractual protections for the transfer of Personal 
              Information to third parties, such as an intra-company agreement or the European Commission’s 
              Standard Contractual Clauses or their equivalent under applicable law.
            </p>

            <h4><u>Canadian Residents</u></h4>
            <p class='size-1'>
              We may use service providers located outside of Canada, including, without limitation, in the United 
              States of America, to process Personal Information, and may transfer your Personal Information to 
              such service providers for this purpose. When your Personal Information is processed outside of 
              Canada, it will be subject to the laws of the countries where the information is processed and may 
              be accessible to law enforcement and national security authorities of those countries in accordance 
              with their laws.
            </p>

            <h3>6. Security of Personal Information</h3>
            <p class='size-1'>
              RentalFaqz implements security safeguards to protect your Personal Information.
              We take steps to secure Personal Information through administrative, technical and physical 
              safeguards designed to protect against the risk of accidental, unlawful or unauthorized destruction, 
              loss, alteration, access, disclosure or use. For example, we encrypt payment card information in 
              accordance with the Payment Card Industry Data Security Standard (PCI DSS) and store sensitive 
              information such as geolocation data on our servers located in the United States with access limited 
              to authorized RentalFaqz employees.
              Unfortunately, we cannot guarantee the security of information transmitted through the Internet, 
              and where we have given you (or where you have chosen) a password, you are responsible for 
              keeping this password confidential.
            </p>

            <h3>7. Retention of Personal Information</h3>
            <p class='size-1'>
              RentalFaqz.com will retain your information only for as long as is reasonably necessary for the 
              purposes set out on this Privacy Notice, for as long as your account is active, or as needed to provide 
              the Services to you. If you no longer want us to use your information to provide the Services to you, 
              you may close your account. RentalFaqz.com will retain and use your information to the extent 
              necessary to comply with our legal obligations, resolve disputes, enforce our agreements, and as 
              otherwise described in this Privacy Notice. Please note that closing an account may not make your 
              email address, username, or property name (if any) available for reuse on a new account. We also 
              retain log files for internal analysis purposes. These log files are retained for a brief period of time, 
              except in cases where they are used to protect the safety and security of the Services, to maintain 
              and improve functionality of the Services, or to comply with legal obligations.
            </p>

            <h3>8. Manage Your Personal Information</h3>
            <p class='size-1'>
              You may manage your Personal Information by making certain choices. If you believe that any 
              Personal Information you have provided to us is out-of-date or inaccurate, you may review and 
              correct such information if it is within your online account by logging in at any time or Contact Us. 
              You may also have additional rights detailed below depending on where you live. As a security 
              precaution, we may need to request information from you to help us confirm your identity when 
              exercising a right related to your Personal Information.
            </p>

            <h4><u>Direct Marketing Opt-Out</u></h4>
            <p class='size-1'>
              If you no longer wish to receive direct marketing emails from us, you may click the “Unsubscribe” 
              link in an email at any time to stop receiving emails for that particular subject. Depending on the 
              Services, you may also be able to manage notification preferences in your account. Note that we may 
              still contact you about the Terms of Use, Global Privacy Notice, or other legal notices, or in 
              connection with providing you Services you have requested from us.
            </p>

            <h4><u>Tracking/Online Advertising Opt-Out</u></h4>
            <p class='size-1'>
              You may disable or delete browser cookies through your browser settings. Cookies are generally 
              easy to disable or delete, but the method varies between browsers. To learn how you can manage 
              Flash cookies, visit Adobe’s Flash player settings page. If you disable or delete cookies, or if you are 
              running third-party software that intercepts or deletes cookies, please note that some parts of our 
              websites may not work properly.
            </p>

            <h3>9. Global Privacy Notice Changes</h3>
            <p class='size-1'>
              This Privacy Notice may be updated periodically to reflect changes in our privacy practices.
              We will post changes on this page and identify the date the privacy notice was last revised at the top 
              of the page. If we make material changes to our privacy practices, we will notify you through a 
              notice on the home page of the websites covered by this Privacy Notice. If a material change will 
              apply to Personal Information we have already collected, including any new purposes for which the 
              Personal Information will be used or disclosed to which you have not previously consented, we will 
              additionally seek your affirmative consent.
            </p>

            <h3>10. Contact Us</h3>
            <p class='size-1'>
              In order to properly respond to your privacy questions or requests about our services, please email 
              us at <a href="mailto:rentalfaqz@gmail.com">rentalfaqz@gmail.com</a>, or call us at: <a href="tel:+12428202234">(242) 820-2234</a>
            </p>`,
          f_terms: "By registering, I accept the",
          f_termsUse: "Terms of Use.",
          f_termsProperty: `By clicking Add Property/Add Unit above, I agree that I will provide accurate and non discriminatory information
            and I will comply with the`,
          f_termsConditions: "Terms and Conditions",
          f_termsService: "Add a Property Terms of Service.",
          f_termsConjuction: "and the",

          // Misc
          prev: "Prev",
          next: "Next",
          vmore: "View More",
          f_ok: "Ok",
          actionFailed: "Action failed",
        }
      },
      es: {
        translation: {
          // Error
          fileBig: "Tamaño de archivo demasiado grande (size máximo permitido: 3 megabytes)",

          // Navbar
          login: "Acceso",
          signup: "Inscribirse",
          featured: "Presentado",
          searchAdvanced: "Búsqueda Avanzada",
          about: "Sobre nosotros",
          contact: "Contacta con nosotros",
          notifications: "Notificaciones",
          myAccount: "Mi cuenta",

          // About Page
          terms: "Términos y condiciones",
          privacy: "Declaracion de privacidad",

          // Homepage
          header: "Descubre nuevos comienzos",
          header2: "Nuevos motivos, nuevas aventuras, nuevos recuerdos.",
          header3: "Trouvez votre logement idéal a ",
          search: "Buscar",

          // Login Page
          f_login: "Ingrese a su cuenta",
          f_createAcct: "¿Crea una cuenta?",
          f_resendEmail: "Reenviar correo electrónico de confirmación",
          f_forgotPass: "¿Has olvidado tu contraseña?",

          //  Register New Account Page
          joinCommunity: "Únete a nuestra comunidad",
          fullAccess: "Crear una cuenta para acceso completo",
          siteUsage: "¿Cómo pretende utilizar este sitio?",
          landLord: "Propietario",
          landLordDesc: "Alquile propiedades, publicando listados en este sitio.",
          or: "O",
          tenant: "Arrendatario",
          tenantDesc: "Busque una propiedad para vivir, aplicando a los listados disponibles en este sitio.",
          personInfo: "Informacion personal",
          requiredFields: "Los campos obligatorios se resaltan con un color de borde distinto",
          // Form Fields
          f_passwordReset: "Enviar correo electrónico de restablecimiento de contraseña",
          f_sendEmail: "Enviar correo electrónico",
          f_passwordMatch: "¡La contraseña no coincide con la confirmación!",
          f_fName: "Nombre de pila",
          f_mName: "Segundo nombre",
          f_lName: "Apellido",
          f_sex: "Sexo",
          f_opt_male: "Masculino",
          f_opt_female: "Femenina",
          f_dob: "Fecha de nacimiento",
          f_dobDesc: "Debe tener 18 años de edad o más",
          f_cob: "País de nacimiento",
          f_cor: "País de residencia",
          f_nib: "Número de NIB",
          f_govID: "Carga de identificación del gobierno",
          f_photo: "Carga de fotos",
          f_contact: "Información del contacto",
          f_phone: "Contacto telefonico",
          f_bank: "Información bancaria",
          f_bankName: "Nombre del banco",
          f_bankBranch: "Rama",
          f_bankTransit: "Tránsito",
          f_bankAccount: "Cuenta",
          f_family: "Relaciones familiares",
          f_relations: "Estado civil",
          f_opt_single: "Soltero",
          f_opt_married: "Casado",
          f_opt_divorced: "Divorciado",
          f_opt_relationship: "Relación",
          f_spouse: "¿Vives con tu esposo?",
          f_haveChildren: "¿Tienes hijos?",
          f_haveBaby: "¿Alguno de sus hijos es considerado un infante/bebé?",
          f_income: "Información de ingresos",
          f_occupation: "Ocupación",
          f_opt_agriculture: "Agricultura, alimentos y recursos naturales",
          f_opt_art: "Artes, tecnología de audio/video y comunicaciones",
          f_opt_education: "Educación y formación",
          f_opt_government: "Gobierno y Administración Pública",
          f_opt_hospitality: "Hospitalidad y turismo",
          f_opt_IT: "Tecnologías de la información",
          f_opt_manufacture: "Fabricación",
          f_opt_math: "Ciencia, tecnología, Ingeniería y Matemáticas",
          f_opt_construction: "Arquitectura y Construcción",
          f_opt_business: "Gestión y administración de empresas",
          f_opt_finance: "Finanzas",
          f_opt_health: "Ciencia de la salud",
          f_opt_human: "Servicios Humanos",
          f_opt_law: "Derecho, Seguridad Pública, Correccionales y Seguridad",
          f_opt_marketing: "Marketing, Ventas y Servicio",
          f_opt_transportation: "Transporte, Distribución y Logística",
          f_opt_other: "Otro",
          f_employer: "Empleado en",
          f_employmentLength: "Duración del empleo en meses",
          f_jobLetter: "Carga de carta de trabajo",
          f_workContact: "Contacto de trabajo",
          f_annualIncome: "Renta Anual en",
          f_lessThan: "Menos que",
          f_higherThan: "y más alto",
          f_account: "Información de la cuenta",
          f_email: "Dirección de correo electrónico",
          f_backupEmail: "Correo electrónico de recuperación",
          f_password: "Contraseña",
          f_passwordConfirm: "Confirmación de contraseña",
          f_declaration: "La información proporcionada es correcta a mi conocimiento",
          f_uploadImage: "Subir imágenes",
          f_clearImage: "Imágenes claras para cargar",
          f_cancel: "Cancelar",
          f_remove: "Eliminar",
          f_submit: "Entregar",
          f_island: "Isla",
          f_settlement: "Asentamiento",
          f_streetAddress: "Dirección",
          f_PoBox: "Apartado de correos",
          f_longitude: "Longitud de mapas de Google",
          f_latitude: "Latitud de Google Maps",
          f_desc: "Descripción",
          f_moveInSpecial: "Oferta especial de mudanza",
          f_disclaimer: "Descargo de responsabilidad",
          f_highlights: "Reflejos",
          f_propertyPhoto: "Carga de fotos de propiedades",
          f_propertyPhotoDesc: "La primera foto cargada se usa como miniatura de la imagen de la propiedad",
          f_propertyPhotoDesc2: "Las imágenes resaltadas en rojo se seleccionan para su eliminación",
          f_selectPhoto: "Seleccionar foto de portada",
          f_selectPhotoDesc: "La imagen resaltada en verde es la foto de portada seleccionada",
          f_clear: "Claro",
          f_back: "Atrás",
          propertyCategorize: "Categorizar la propiedad en un grupo",
          f_unit: "Unidad",
          f_propertyType: "tipo de propiedad",
          f_opt_apartment: "Departamento",
          f_opt_bnb: "AirBnB",
          f_opt_home: "Hogar",
          f_opt_condo: "Condominio",
          f_opt_townhouse: "Casa adosada",
          f_sqFt: "Pies cuadrados de propiedad",
          f_bedrooms: "Numero de habitaciones",
          f_bathrooms: "Cantidad de baños",
          f_payment: "Información del pago",
          f_fee: "Tarifa",
          f_deposit: "Depósito esperado",
          f_depositDesc: "Pago total esperado del inquilino por el primer mes y tarifa de mudanza.",
          f_frequency: "Frecuencia de pago",
          f_opt_once: "Una vez",
          f_opt_daily: "A diario",
          f_opt_weekly: "Semanalmente",
          f_opt_monthly: "Mensual",
          f_amenities: "Servicios de la propiedad",

          // Confirm Signup Page
          welcome: "Bienvenido",
          signupThanks: ", y gracias por registrarte!",
          checkEmail: "Por favor revise su correo electrónico",
          accountLink: "para el enlace de activación de la cuenta",
          checkSpam: "PD: Si no ve el correo electrónico de activación, ¡verifique sus carpetas de correo basura o correo no deseado!",

          // Property Groups
          propertyGroup: "Información de grupos de propiedades",
          propertyGroupDesc: "Use grupos de propiedades para vincular unidades de alquiler si están dentro del mismo complejo de apartamentos",
          propertyGroupSelect: "Seleccionar grupo de propiedades para editar",
          propertyGroupEdit: "Editar grupo de propiedades",
          propertyGroupCreate: "Crear nuevo grupo de propiedades",
          propertyGroupName: "Nombre del grupo",
          propertyGroupRemove: "Eliminar grupo",
          propertyGroupUpdate: "Actualizar grupo de propiedades",
          propertyGroupNew: "Nuevo grupo de propiedades",
          propertyGroupShow: "Mostrar información del grupo",

          // Add Property
          addProperty: "Agregar una nueva propiedad",
          propertyCreate: "Crear una nueva propiedad",
          propertyInfo: "Información de la propiedad",
          propertySingle: "Unidad singular",
          propertyMultiple: "Unidades Múltiples",
          propertyUnitDesc: "Elija una lista de una sola unidad o una lista de complejo de apartamentos",
          f_addProperty: "Agregar propiedad",
          f_addUnit: "Agregar unidad",

          // Edit Property
          tenantInfo: "Información del inquilino",
          appInquiry: "Consultas del solicitante",
          appInquiryDesc: "Usuarios que han consultado sobre la propiedad",
          propertyTenant: "Inquilinos de propiedad",
          propertyTenantDesc: "Usuarios que actualmente son inquilinos del inmueble",
          f_disabled: "¿Esta propiedad está disponible para la vista del público?",
          updateProperty: "Actualizar propiedad",
          apartmentComplex: "Complejo de apartamentos",
          f_applicants: "Solicitantes",
          f_groups: "Grupos",
          f_info: "Información",

          // Property Payments
          choose_plan: "Elige tu plan",
          choose: "Elegir",
          basic: "Básico",
          week: "semana",
          month: "mes",
          allowed_to_list: "Permitido enumerar propiedades, hasta ",
          allowed_to_list_unlimited: "Permitido listar una cantidad ilimitada de propiedades.",
          premium: "De primera calidad",
          can_accept_tenants: "Puede aceptar inquilinos en línea",
          can_accept_payments: "Puede aceptar pagos de inquilinos en línea",

          // Property Search
          select_filter: "Seleccionar filtros",
          clear_filter: "Borrar filtros",
          f_sqFt_greater: "Pies cuadrados mayor que",
          f_price_range: "Rango de precios",
          f_min: "Mínimo",
          f_max: "Máx.",
          f_sort_by: "Ordenar por",
          f_oldest_newest: "De más antiguo a más nuevo",
          f_most_recent: "Más reciente",

          //Property Forums
          topic_summary: "Resumen del tema",
          issue_details: "Detalles del problema",
          f_submit_req: "Enviar peticion",
          property_forum: "Foros de propiedades",
          maintenance_req: "Solicitudes de mantenimiento",
          new_repair_req: "Nueva solicitud de reparación",
          open: "Abierto",
          closed: "Cerrado",
          // Selected Property Forum
          repair_req: "Solicitudes de reparación",
          tenant_inq: "Consultas de inquilinos",
          // Forum New Comment
          title: "Título",
          enterText: "Ingrese el texto a continuación",
          f_reply: "Responder",
          // Property Forum Display
          f_edit: "Editar",
          f_attachedImages: "Imágenes adjuntas",
          f_posted: "al corriente",
          f_edited: "editado",
          f_replies: "respuestas",

          // Favorited Properties
          favoritedProperty: "Propiedades favoritas",

          // Property Display Thumb
          t_beds: "Cama(s)",
          t_baths: "Baño(s)",
          t_propertyOwned: "Propiedad de propiedad",
          t_yourHome: "Tu hogar",
          t_rented: "Alquilado",
          t_repairsReq: "Solicitar Reparaciones / Consultas",
          studio: "estudio",
          one_bed: "1 cama",
          two_beds: "2 camas",
          three_beds: "3 camas",
          studio_one_bed: "estudio - 1 cama",
          studio_two_beds: "estudio - 2 camas",
          studio_three_beds: "estudio - 3 camas",
          studio_studio: "estudio - estudio",
          one_bed_one_bed: "1 cama - 1 cama",
          one_bed_two_beds: "1 cama - 2 camas",
          one_bed_three_beds: "1 cama - 3 camas",
          two_beds_two_beds: "2 camas - 2 camas",
          two_beds_three_beds: "2 camas - 3 camas",
          three_beds_three_beds: "3 camas - 3 camas",

          // Property  Display
          g_leaveReview: "Dejar un comentario",
          g_leaveReviewDesc: "Deja un comentario detallando tu estancia en esta residencia.",
          needToBeResident: "Solo puede dejar una reseña después de ser residente de la propiedad en cuestión.",
          g_otherUnits: "Otras unidades del complejo de apartamentos",
          g_noRelatedUnits: "No hay unidades relacionadas disponibles.",
          g_bedroom: "dormitorio",
          g_apply: "Aplica ya",
          g_this: "esto",
          unitFeatures: "Características de la unidad",
          g_ownerOptions: "Opciones de propiedad del propietario",
          g_residentOptions: "Opciones de propiedad para residentes",
          g_tenantForums: "Foros de inquilinos",
          g_editProperty: "Editar propiedad",
          g_removeProperty: "Eliminar propiedad",
          g_rentalProperty: "Alquiler",
          g_waterIncluded: "Agua incluida",
          g_internetIncluded: "Internet incluido",
          g_electricityIncluded: "Electricidad incluida",
          g_utilities: "Utilidades",


          // Property Group Display
          g_verifiedRenter: "Inquilino verificado",
          needToRegister: "¡Debes ser un usuario registrado para usar esta función!",
          needToSignIn: "Inicie sesión o cree una cuenta.",
          gPricingUnit: "Precios y Unidades",
          g_all: "Todo",
          g_studio: "Estudio",
          g_inquire: "Pregunte ahora",
          g_ownerNotify: "El dueño de la propiedad será notificado con su información de contacto",
          g_squareFeet: "Pies cuadrados",
          g_noneAvailable: "Ninguno está disponible en este momento.",
          g_about: "Acerca de",
          g_propertyDesc: "Ingrese la descripción de la propiedad aquí.",
          g_location: "Ubicación",
          g_propertyAddress: "Dirección de Propiedad",
          g_rentalDisclaimer: "Descargo de responsabilidad de alquiler",
          g_contactOwner: "Contactar al dueño de la propiedad",
          g_tourReq: "Solicitar Tour",
          g_language: "Idioma",
          g_open: "Abierto",
          g_allHours: "Ver todas las horas",
          g_residentReviews: "Reseñas de residentes",
          g_noRatings: "No hay calificaciones disponibles",

          // Unit Display
          f_furnished: "Amueblado",
          f_kitchen: "Cocina",
          f_livingRoom: "Sala de estar",
          f_airConditioned: "Aire Acondicionado",
          f_fencedIn: "Cercado",
          f_backyard: "Patio interior",
          f_gatedCommunity: "Comunidad cerrada",
          f_petsAllowed: "Mascotas permitidas",
          f_inUnitWasher: "Lavadora y secadora en el apartamento",
          f_washerHookups: "Conexiones para lavadora y secadora",
          f_dishwasher: "Lavavajillas",
          f_wheelchairAccess: "Silla de ruedas accesible",
          f_laundryFacilities: "Instalaciones de lavandería",
          f_fitnessCenter: "Gimnasio",
          f_pool: "Piscina",
          f_elevator: "Ascensor",
          f_parking: "Estacionamiento",
          f_balcony: "Balcón",
          f_upstairs: "Ubicado arriba",
          f_downstairs: "Ubicado en la planta baja",
          f_utilities: "Utilidades incluidas",
          f_water: "Agua",
          f_internet: "Internet",
          f_electricity: "Electricidad",
          units: "unidades",
          availableUnit: "unidad disponible",
          availableUnits: "unidades disponibles",
          propertyAvailable: "Propiedad Disponible",
          propertyUnavailable: "Propiedad no disponible",
          hideUnits: "Ocultar Unidades",
          showUnits: "Mostrar unidades",
          rentalFee: "Precio de renta",
          baths: "Baños",
          propertyFeatures: "Caracteristicas de la propiedad",
          sendMessage_title: "Enviar un mensaje de correo electrónico al dueño de la propiedad",
          sendMessage: "Enviar mensaje",


          // Account Page
          profileSettings: "Configuración de perfil",
          profile: "Perfil",
          myHome: "Mi hogar",
          ownedProperties: "Propiedades propias",
          propertyTransactions: "Transacciones de propiedad",
          manageSubs: "Administrar Suscripciones",
          logOut: "Cerrar sesión",
          f_preferredLang: "Idioma preferido",
          f_english: "Inglés",
          f_spanish: "Español",
          f_creole: "Francés",
          f_contactHours: "Horas de contacto",
          f_sunday: "Domingos",
          f_monday: "Lunes",
          f_tuesday: "Martes",
          f_wednesday: "Miércoles",
          f_thursday: "Jueves",
          f_friday: "Viernes",
          f_saturday: "Sábados",
          f_startTime: "Hora de inicio",
          f_endTime: "Hora de finalización",
          f_contactUpdate: "Actualizar información de contacto",
          f_updateBank: "Actualizar información bancaria",
          f_updateEmployment: "Actualizar información de empleo",
          f_changePassword: "Cambiar la contraseña",
          f_newPassword: "Nueva contraseña",
          f_newPasswordConfirm: "Nueva confirmación de contraseña",
          f_deactivateAccount: "Desactiva tu cuenta",
          f_closeAccount: "Cerrar cuenta",
          f_propertyListing: "Listados de propiedades",
          f_newListing: "Agregar nuevo listado",
          f_singleListing: "Listados individuales",
          f_complexListing: "Listados de complejos de apartamentos",

          // Payment Modal
          f_cardHolder: "Nombre del tarjetahabiente",
          f_paymentAmount: "Monto del pago",
          f_cardNumber: "Número de tarjeta",
          f_cardExpiry: "Caducidad de la tarjeta",
          f_cardCVC: "Tarjeta CVC",
          f_pay: "Pagar",

          // PaymentSettingDisplay
          f_propertySubs: "Suscripción del propietario de la propiedad",
          f_recurring: "Periódico",
          f_invoiceNumb: "Número de factura",
          f_activateDate: "Fecha de activación",
          f_expiryDate: "Fecha de caducidad",
          f_renew: "Renovar",
          f_initialDeposit: "Depósito inicial",
          f_monthlyFee: "Cuota mensual",
          f_totalFee: "Coste total",
          f_paymentStatus: "Estado de pago",
          f_datePaid: "Fecha de pago",
          f_nextPayDate: "Proxima fecha de pago",
          f_tapToPay: "¡Toca para pagar!",
          recent_sub: "Suscripción reciente",
          purchase_sub: "Compra un plan de suscripción",
          my_home_rental: "Tarifas de alquiler de mi casa",

          // Tenant Modal
          f_termsTenantLeft: "Plazos en los que el inquilino abandonó el piso anterior",
          f_landlordScore: "Puntuación del propietario",
          f_landlordComment: "Comentario del propietario anterior",
          f_tenantName: "Nombre del inquilino",
          no_reviews: "Aún no hay comentarios.",
          reviews: "reseñas",
          f_generalInfo: "Información general",
          f_race: "Course",
          f_passport: "Número de pasaporte",
          f_citizenRecord: "Información de registro ciudadano",
          f_criminalRecord: "Antecedentes penales",
          f_reasonJailed: "Razón encarcelada",
          f_tenantHistory: "Historial del inquilino",
          no_history: "Sin historial de inquilinos.",
          f_overallRating: "Calificación general",
          f_rateTenant: "Tasa de inquilino",
          f_giveRating_req: "Dar una calificación para el inquilino que está eliminando de la lista de propiedades",
          f_tenantClosure: "Cierre (Por qué se va el inquilino)",
          f_movedOut: "Mudado",
          f_evicted: "Desalojado",
          f_tenantRating: "Calificación del inquilino,",
          f_comment: "Comentario",
          f_reviewTenant: "Revisar inquilino",
          f_denyTenant: "Denegar consulta de inquilino",
          f_acceptTenant: "Aceptar como inquilino",
          f_removeTenant: "Eliminar inquilino",

          //Send Mail Modal
          f_moveInDate: "Fecha de mudanza",
          f_customMessage: "Mensaje personalizado",

          // Confirm Modal
          ratingRange: "Rango de calificación, 1 - 5 estrellas",

          // Report Modal
          reportIssue: "Reportar un problema",
          descIssue: "Describir problema",

          // Terms of Use
          registerNow: "Regístrate ahora",
          contactText: `Con el fin de responder adecuadamente a sus preguntas de privacidad o solicitudes sobre nuestros servicios,
             por favor envíenos un correo electrónico a
             <a href="mailto:rentalfaqz@gmail.com"> rentalfaqz@gmail.com</a>, o llámanos al:
             <a href="tel:+12428202234"> (242) 820-2234</a>`,
          aboutText: `En <b>Rentalfaqz</b>, nuestro objetivo es convertirnos en el mercado de alquiler en línea más completo del Caribe.
            <br/><br/>
            Por lo tanto, agregaremos alquileres de espacios y transporte a nuestros servicios para brindarle un mejor servicio y facilitarle la vida.
            Por lo tanto, planifique con anticipación y reserve con anticipación a nuestros numerosos proveedores, con poco esfuerzo y con tiempo de sobra.
            <br/><br/>
            A medida que ampliamos nuestro alcance a los países caribeños circundantes, crearemos una extensa red de clientes de alquiler de bienes raíces, 
            espacios y transporte, que se los pondremos al alcance de la mano.
            <br/><br/>
            Entonces, ya sea que esté buscando aumentar la presencia en línea de su negocio, asegurar el espacio adecuado para esa gran ocasión o cubrir sus vacantes de alquiler, ¡ha llegado al lugar correcto!`,
          termsText: `<h3>Términos del acuerdo de servicio</h3>
             <p class='size-1'>
               Estos Términos de servicio se aplican a los servicios ofrecidos por Rentalfaqz.com. Cualquier individuo que visite,
               ve, accede o usa el Sitio, incluso a través de un bot u otros medios automatizados, ("Usted" o
               “Usuario”) está sujeto a estos Términos de servicio. Al usar el Sitio, Usted declara y garantiza que Usted
               tiene al menos dieciocho (18) años de edad y por la presente acepta estar sujeto a todos los siguientes
               disposiciones de estos Términos de Servicio, que forman un contrato legalmente vinculante entre Usted y
               Rentalfaqz.com. Si no está de acuerdo con estos Términos de servicio, tiene prohibido acceder
               el Sitio y debe descontinuar inmediatamente dicho acceso.
             </p>

             <h3>¿Qué debe esperar?</h3>
             <p class='size-1'>
               Rentalfaqz.com es una plataforma multifacética en línea que brinda servicios de búsqueda y publicidad
               para proveedores de servicios de alquiler, buscadores de apartamentos, propietarios y administradores de propiedades. Este sitio hace
               no (a) proporciona ninguno de los servicios directamente y no es parte de ninguna transacción entre el servicio
               proveedor y cliente, (b) garantizar o asegurar cualquier servicio o cualquier transacción entre un cliente
               y proveedor de servicios, (c) realizar una investigación de antecedentes de los candidatos de alquiler, o cobrar el pago de
               nombre del cliente o proveedor de servicios, o (d) actuar como mediador, cobrador de deudas o informes de crédito
               agencia, y no garantiza ningún resultado del uso del servicio. El Sitio facilita el uso de
               crowdsourcing dentro de su red de servicios que proporciona una cuenta de los inquilinos como un historial
               informe del propietario actual y anterior, o del administrador de la propiedad, que se presenta y se comparte
               en todo el sistema con fines de selección de futuros propietarios y administradores de propiedades.
             </p>

             <h3>¡Advertencia!</h3>
             <p class='size-1'>
               Le recomendamos encarecidamente que inspeccione personalmente cualquier apartamento anunciado para alquiler antes de:
               firmar cualquier documentación; proporcionar información personal; o cablear o enviar de otro modo
               dinero para cualquier depósito, o pago de alquiler. Al utilizar el Sitio, reconoce que los alquileres publicados
               y las disponibilidades están sujetas a cambios a discreción exclusiva del propietario o administrador de la propiedad en
               en cualquier momento y sin previo aviso.
             </p>

             <h3>Materiales del sitio</h3>
             <p class='size-1'>
               Toda la información y el contenido disponible en este Sitio (colectivamente, "Materiales") están protegidos por
               derechos de autor y otras leyes de propiedad intelectual. Los Materiales están destinados a uso personal y
               Sólo uso no comercial. No puede copiar, reproducir, vender, licenciar, publicar, distribuir, modificar,
               exhibir, realizar, combinar, crear un trabajo derivado, volver a publicar o usar cualquier parte de
               los Materiales de cualquier otra manera o para cualquier otro propósito sin el consentimiento previo por escrito de
               RentalFaqz.com. Solicitudes relacionadas con el uso de los Materiales para cualquier propósito que no sea personal,
               el uso no comercial debe dirigirse a rentalfaqz@gmail.com, o N-10841 Wellington Street,
               Nasáu Bahamas.
             </p>

             <h3>Sitios de terceros</h3>
             <p class='size-1'>
               Durante el curso de su visita al Sitio, es posible que lo lleven a sitios web de terceros para cumplir con ciertas
               funciones y características del sitio (por ejemplo, si elige ver información de la comunidad o verificar
               disponibilidad de una determinada unidad de apartamento). Usted acepta que cuando visite sitios web de terceros (por
               ejemplo, entre otros, sitios web que no contienen "RenalFaqz.com" en la URL) Usted está sujeto a la
               política de privacidad y términos de servicio, si corresponde, de ese sitio web de terceros, que pueden diferir de los
               de RentalFaqz.com. Al seleccionar enlaces a dichos sitios web de terceros, usted acepta que será
               abandonar el Sitio y que RentalFaqz.com y sus afiliados no tienen ninguna responsabilidad u obligación
               en relación con su uso o su intercambio de cualquier información con dicho tercero
               sitios web RentalFaqz.com y sus afiliados no respaldan, patrocinan ni garantizan estos enlaces
               sitios web y no son responsables de ninguna manera por cualquier consejo, contenido, información, prácticas,
               productos o servicios relacionados o disponibles a través de dichos sitios web vinculados.
             </p>
             <h3>Marcas</h3>
             <p class='size-1'>
               Usted reconoce que los Materiales incluyen ciertas marcas comerciales, marcas de servicio y otros indicios
               de fuente (en conjunto, "Marcas") que son propiedad de RetalFaqz.com, sus afiliados, Licenciantes y otros.
               Usted acepta no copiar, usar, infringir o diluir las Marcas. Además, acepta que no alterará ni
               eliminar cualquier Marca, aviso de derechos de autor u otro aviso de los Materiales o cualquier copia de los mismos. El
               El nombre y el logotipo de “RentalFaqz.com” son marcas comerciales de RentalFaqz.com. Otro producto y empresa
               Los nombres aquí mencionados pueden ser marcas registradas de sus respectivos dueños.
             </p>

             <h3>Contenido de usuario</h3>
             <p class='size-1'>
               Al enviar anuncios, fotos, descripciones, datos u otra información ("Contenido") al Sitio en
               conexión con una propiedad, por la presente otorga a RentalFaqz.com, sus afiliados y su matriz y
               empresas relacionadas una libre de regalías, perpetua, irrevocable, no exclusiva, totalmente transferible, totalmente
               derecho sublicenciable y licencia para copiar, modificar, exhibir, distribuir, realizar, crear derivados
               trabaja desde, almacena y usa y explota de otro modo todo ese Contenido en cualquier forma, medio, software o
               tecnología de cualquier tipo actualmente existente o desarrollada en el futuro. Usted concede además a
               RentalFaqz.com, sus afiliados y sus empresas matrices y relacionadas, un derecho y una licencia libres de regalías
               utilizar su nombre, imagen y semejanza en relación con la reproducción o distribución del
               Contenido. Al enviar cualquier Contenido a través del Sitio, incluidos, entre otros, a través de cualquier cliente potencial
               formulario, usted acepta que RentalFaqz.com, un arrendador, un administrador de propiedades u otro individuo similar o
               entidad puede comunicarse con usted por correo electrónico, mensaje de texto, teléfono o marcador automático para cualquier propósito. El Sitio puede
               permitirle enviar voluntariamente Contenido relacionado con usted, incluido su ingreso mensual total o
               cualquier otra ("Información proporcionada por el consumidor"). RentalFaqz.com recopila esta información para
               solo con fines informativos y no es utilizado por RentalFaqz.com o sus clientes que son
               arrendadores, agentes de listado o dueños ("Propiedades" y/o "Propiedad") para tomar una decisión de alquilar un
               propiedad para usted. Al proporcionar información proporcionada por el consumidor, usted reconoce que
               RentalFaqz.com puede compartir dicha información proporcionada por el consumidor con propiedades que ofrecen unidades para
               alquiler a través del Sitio sujeto a estos Términos de Servicio. Cualquier destinatario de dicha información proporcionada por el consumidor
               Information acepta que cualquier Información proporcionada por el consumidor puede no ser precisa, no debe ser
               depender por ningún motivo y no se puede utilizar para tomar una decisión sobre si alquilar un
               apartamento a una persona en particular. El Sitio puede ofrecer oportunidades para Usuarios mayores de 18 años y
               mayores para enviar Contenido que exprese sus opiniones y comparta sus ideas, por ejemplo, en un
               formato de blogs de consumo ("Contenido de opinión"). RentalFaqz.com y sus afiliados no respaldan la
               exactitud o confiabilidad de cualquier Contenido de Opinión. Si decide enviar Contenido de opinión, utilice
               su mejor juicio y sea respetuoso con otras personas. Al publicar o enviar una Opinión
               Contenido, Usted acepta cumplir con estos Términos de servicio. Usted no puede usar el Sitio para participar en ningún
               Actividades Prohibidas (como se define a continuación). Subir material protegido por derechos de autor u otro material de propiedad de
               cualquier tipo en el Sitio sin el permiso expreso del dueño de ese material está prohibido y
               puede dar lugar a responsabilidad civil y/o penal.
               <br/><br/>
               Si usted es un cliente que es una Propiedad que procesa transacciones de pago de alquiler a través del Servicio
               (como se define a continuación), debe proporcionar su nombre legal y/o nombre de entidad comercial legal,
               o información de contacto comercial y número(s) de NIB ("Información de referencia"), si se solicita.
               RentalFaqz.com puede recopilar y mantener la información de referencia, así como su cuenta
               información y el monto de los pagos y el número de transacciones de pago que usted o su
               procesos comerciales a través del Servicio, para que Sus pagos puedan ser informados al Interno
               Servicio de Rentas cuando así lo exija la ley.
             </p>
             <h3>Derechos de RentalFaqz.com</h3>
             <p class='size-1'>
               Dada la naturaleza del Sitio y el volumen de mensajes y publicaciones, salvo disposición en contrario
               en estos Términos de servicio u otros términos aplicables, RentalFaqz.com no puede y no supervisa
               todo el Contenido publicado o transmitido por Usted y otros proveedores de información de terceros.
               RentalFaqz.com se reserva el derecho, a su sola discreción, de controlar, negarse a publicar, eliminar,
               eliminar, mover o editar cualquier Contenido sin previo aviso, en cualquier momento y por cualquier motivo. Al usar el Sitio, Usted
               acepta expresamente que RentalFaqz.com (a) no será responsable de ningún reclamo, acción o juicio
               que surja de o esté relacionado con cualquier Contenido y (b) puede monitorear, negarse a publicar, eliminar, eliminar,
               mover o editar cualquier Contenido sin previo aviso en cualquier momento por cualquier motivo, sin responsabilidad y sin
               proporcionando un reembolso. Además, acepta expresamente que es el único responsable de todos y cada uno de los
               Contenido que envía al Sitio.
             </p>

             <h3>Contenido no solicitado</h3>
             <p class='size-1'>
               RentalFaqz.com no acepta contenido o ideas no solicitadas para su uso o publicación en su
               programación o en otros medios digitales, electrónicos o impresos, excepto en relación con los Foros,
               salas de chat y tableros de anuncios en el Sitio.
             </p>

             <h3>Declaraciones / Indemnización</h3>
             <p class='size-1'>
               Por la presente declara, garantiza y acepta que (a) tiene toda la autoridad, los derechos y
               permisos para enviar el Contenido al Sitio, (b) el Contenido no infringe ni infringirá o
               malversar cualquier derecho de autor, marca comercial, secreto comercial, patente o cualquier otra propiedad intelectual o
               otro derecho de cualquier tercero, incluidos los derechos de privacidad o publicidad, (c) el Contenido, la Referencia
               Información y Su uso del Sitio no viola y no violará ningún derecho federal, estatal o
               leyes o reglamentos locales que incluyen, entre otros, cualquier ley o reglamento de vivienda justa o
               licencias de bienes raíces o reglamentos de corretaje aplicables, o causar lesiones a cualquier persona, (d) el
               El contenido y la información de referencia son completos, veraces, exactos y no engañosos, € la
               El contenido y la información de referencia no se han enviado para inducir a error, engañar o defraudar
               o intentar engañar, engañar o defraudar a cualquier otro Usuario del Sitio, y (f) no engañará,
               engañar, defraudar, tratar de engañar, engañar o defraudar, falsear o tratar de engañar
               hacer declaraciones falsas a cualquier otro Usuario del Sitio. Usted reconoce que estos
               Las representaciones se refieren a hechos que son importantes para RentalFaqz.com. Usted acepta liberar, defender,
               indemnizar y retener a RentalFaqz.com, sus afiliados, Licenciantes y sus respectivos funcionarios, directores,
               empleados, agentes y contratistas de y contra todos y cada uno de los reclamos, costos, demandas o
               gastos, incluidos los honorarios de los abogados, derivados de (i) cualquier distribución, publicación, negativa a publicar,
               remoción, eliminación, movimiento, edición u otro uso del Contenido o la Información de Referencia que Ud.
               proporcionar, (ii) su uso del Sitio o cualquier confianza en los Materiales, (iii) su incumplimiento de estos Términos de
               Servicio o (iv) cualquier venta, arrendamiento u otra transacción real, prospectiva o rescindida entre Usted
               y un tercero.
             </p>
             <h3>Actividades Prohibidas</h3>
             <p class='size-1'>
               Tu no debes:
               <ul class='size-1'>
                 <li class='mb-1'>
                   Enviar Contenido que sea claramente ofensivo para la comunidad en línea, como contenido que
                   refleje o promueva el racismo, la intolerancia, el odio o el daño físico de cualquier tipo contra cualquier grupo
                   o individuo;
                 </li>
                 <li>
                   Participar en actividades o enviar Contenido que pueda ser perjudicial para menores;
                 </li>
                 <li class='mb-1'>
                   Participar en actividades o enviar Contenido que acose o promueva el acoso de otra persona;
                 </li>
                 <li class='mb-1'>
                   Participar en actividades que involucren la transmisión de "correo basura" o envío masivo de correo no solicitado
                   o enviar “spam” a otros;
                 </li>
                 <li class='mb-1'>
                   Participar en actividades, enviar contenido o información de referencia, o promover información, que es
                   fraudulento, falso, engañoso, inexacto y/o constituye una tergiversación, o
                   promueva actividades ilegales o conductas abusivas, amenazantes, obscenas, vulgares,
                   difamatorio, calumnioso o de otra manera objetable;
                 </li>
                 <li class='mb-1'>
                   Enviar Contenido que contenga páginas de acceso restringido o con contraseña, o páginas ocultas o
                   imágenes;
                 </li>
                 <li class='mb-1'>
                   Enviar Contenido que muestre material pornográfico o sexualmente explícito de cualquier tipo;
                 </li>
                 <li class='mb-1'>
                   Enviar contenido que proporcione información instructiva sobre actividades ilegales, como
                   fabricar o comprar armas ilegales, violar la privacidad de alguien o proporcionar o crear
                   virus informáticos;
                 </li>
                 <li class='mb-1'>
                   Participar en actividades o enviar Contenido que solicite contraseñas o identificación personal
                   información de otros para fines ilegales;
                 </li>
                 <li class='mb-1'>
                   Participar en actividades comerciales y/o ventas sin nuestro consentimiento previo por escrito, tales como
                   concursos, sorteos, trueques, publicidad y esquemas piramidales;
                 </li>
                 <li class='mb-1'>
                   Usar los formularios de clientes potenciales y/o los números gratuitos del Sitio para publicitar o promocionar productos y
                   servicios a los anunciantes de RentalFaqz.com o para solicitar a los anunciantes de RentalFaqz.com en cualquier
                   manera;
                 </li>
                 <li class='mb-1'>
                   Usar cualquier robot, araña u otro dispositivo automático, o un proceso manual, para acceder, monitorear o
                   copiar páginas web o los Materiales contenidos en el Sitio o para cualquier otro uso no autorizado
                   objetivo;
                 </li>
                 <li class='mb-1'>
                   Usar cualquier dispositivo, software o rutina para interferir o intentar interferir con el correcto
                   funcionamiento del Sitio;
                 </li>
                 <li class='mb-1'>
                   Descompilar, realizar ingeniería inversa, desensamblar o intentar obtener el código fuente
                   para el Software; o
                 </li>
                 <li class='mb-1'>
                   Tomar cualquier acción que imponga una carga irrazonable o desproporcionadamente grande sobre
                   La infraestructura de hardware y software de RentalFaqz.com.
                 </li>
               </ul>
             </p>
             <h3>Remedios de RentalFaqz.com</h3>
             <p class='size-1'>
               Usted reconoce que cualquier incumplimiento de estos Términos de servicio vinculantes, incluso sin
               limitación las restricciones al acceso de competidores y las secciones “Material del sitio”,
               “Declaraciones/Indemnización” y “Actividades prohibidas” o cualquier uso no autorizado del
               Sitio, es un incumplimiento material de este acuerdo entre Usted y RentalFaqz.com y causará
               daños y perjuicios irreparables a RentalFaqz.com, para los cuales no existe un remedio adecuado en
               ley. Por lo tanto, además de todos los demás recursos disponibles por ley o en equidad, usted acepta que en
               caso de tal incumplimiento, RentalFaqz.com tendrá derecho a medidas cautelares u otras medidas equitativas
               reparación, esté o no disponible la indemnización por daños y perjuicios sin el requisito de que
               RentalFaqz.com publica una fianza.
               <br/><br/>
               El incumplimiento material de estos Términos de servicio vinculantes daña la integridad, funcionalidad y
               reputación de RentalFaqz.com; resta valor a la confianza y el uso del Sitio por parte de los Usuarios; y daños
               el negocio de RentalFaqz.com. En consecuencia, usted reconoce y acepta que
               RentalFaqz.com incurre en daños reales, que son extremadamente difíciles o imposibles de
               cuantificar, como resultado de un incumplimiento sustancial de estos Términos de servicio vinculantes. Para el
               para evitar dudas, tales daños no constituirían un remedio adecuado en la ley o en
               afectará de ninguna manera su acuerdo de que RentalFaqz.com tendría derecho a medidas cautelares u otras
               reparación equitativa en caso de tal incumplimiento.
             </p>

             <h3>Politica de reembolso</h3>
             <p class='size-1'>
              La cancelación de los servicios dentro de la primera semana de la compra de una suscripción dará como resultado un reembolso completo.
              Después de la segunda semana, si cancela los servicios, recibirá la mitad de la orden de compra.
              Todas las ventas son definitivas después de la tercera semana, ya que usted ha reconocido y aceptado que no tendrá derecho a un reembolso.
             </p>
 
             <h3>Renuncia de garantía</h3>
             <p class='size-1'>
               USTED ACEPTA EXPRESAMENTE QUE EL USO DEL SITIO Y LA CONFIANZA EN SU CONTENIDO Y
               LA INFORMACIÓN PROPORCIONADA POR EL CONSUMIDOR ES BAJO SU PROPIO RIESGO. RENTALFAQZ.COM, SUS
               LOS AFILIADOS Y SUS RESPECTIVOS LICENCIANTES TERCEROS NO REALIZAN NINGÚN
               DECLARACIONES O GARANTÍAS DE CUALQUIER TIPO EN RELACIÓN CON EL SITIO, EL
               SOFTWARE, EL CONTENIDO, LA INFORMACIÓN PROPORCIONADA POR EL CONSUMIDOR, LOS MATERIALES, LA
               SERVICIO DE PRESENTACIÓN DE INMUEBLES, EL SERVICIO DE ADQUISICIÓN DE APARTAMENTOS
               (COLECTIVAMENTE, EL “SERVICIO”) O LOS RESULTADOS QUE PUEDAN OBTENERSE DEL USO
               DE CUALQUIERA DE LOS ANTERIORES. EL SERVICIO SE PROPORCIONA “TAL CUAL, SEGÚN DISPONIBILIDAD”
               BASIS Y RENTALFAQZ.COM, SUS AFILIADOS Y SUS RESPECTIVOS TERCEROS
               LOS LICENCIANTES RENUNCIA ESPECÍFICAMENTE CUALQUIERA Y TODAS LAS GARANTÍAS EXPLÍCITAS O IMPLÍCITAS
               INCLUYENDO, SIN LIMITACIÓN, LAS GARANTÍAS DE IDONEIDAD PARA UN PARTICULAR
               PROPÓSITO, GARANTÍAS DE COMERCIABILIDAD Y GARANTÍAS DE
               NO INFRACCIÓN. RENTALFAQZ.COM Y SUS AFILIADOS NO HACEN
               DECLARACIONES O GARANTÍAS, EXPRESAS O IMPLÍCITAS, A CUALQUIER ACTUAL O
               ALQUILER POSIBLE DE CUALQUIER APARTAMENTO EN CUANTO A LA EXISTENCIA, PROPIEDAD O
               ESTADO DEL APARTAMENTO; EN CUANTO A LAS DISPONIBILIDADES ANUNCIADAS, ALQUILER, ARRENDAMIENTO
               TÉRMINOS, DEPÓSITO DE GARANTÍA O TARIFAS DE SOLICITUD, SI LAS HAY; O EN CUANTO A LA EXACTITUD O
               COMPLETITUD DE CUALQUIER INFORMACIÓN SOBRE UN APARTAMENTO QUE APARECE EN EL
               SITIO. RENTALFAQZ.COM NO GARANTIZA LA EXACTITUD O INTEGRIDAD DE
               DICHA INFORMACIÓN. RENTALFAQZ.COM SE RESERVA EL DERECHO, A SU ÚNICA DISCRECIÓN,
               PARA CORREGIR CUALQUIER ERROR U OMISIÓN EN EL SITIO O EN EL CONTENIDO. CUALQUIERA Y TODO
               PREOCUPACIONES, DIFERENCIAS O DISCREPANCIAS CON RESPECTO A UN APARTAMENTO DEBEN SER
               ABORDADO CON EL ARRENDADOR Y/O LA COMPAÑÍA DE ADMINISTRACIÓN DE LA PROPIEDAD ANTERIOR
               AL ALQUILER DEL APARTAMENTO. RENTALFAQZ.COM NO HACE Y EXPRESAMENTE
               RENUNCIA A CUALQUIER REPRESENTACIÓN, GARANTÍA O GARANTÍA HACIA USTED CON RESPECTO A
               EL SERVICIO DE ENTREGA DE INMUEBLES Y EL SERVICIO DE ADQUISICIÓN DE APARTAMENTOS,
               INCLUYENDO, SIN LIMITACIÓN, CUALQUIER REPRESENTACIÓN, GARANTÍA O
               GARANTIZA QUE ALQUILARÁ SU APARTAMENTO, OBTENDRÁ UNA RENTA ACEPTABLE
               PARA TU APARTAMENTO, SOLO RECIBE CONSULTAS O SOLICITUDES LEGÍTIMAS
               DE INQUILINOS CALIFICADOS, O RECIBA CUALQUIER CONSULTA ACERCA DE SU APARTAMENTO
               EN RENTA. A LOS EFECTOS DE ESTA RENUNCIA DE GARANTÍA, “LICENCIATARIO DE TERCEROS”
               NO TE INCLUYE.
             </p>

             <h3>Limitación de responsabilidad</h3>
             <p class='size-1'>
               RENTALFAQZ.COM, SUS AFILIADOS Y SUS RESPECTIVOS PROPIETARIOS, EMPLEADOS,
               AGENTES, CONTRATISTAS Y TERCEROS LICENCIANTES EN NINGÚN CASO SERÁN RESPONSABLES
               POR CUALQUIER DAÑO O PÉRDIDA, INCLUIDOS, SIN LIMITACIÓN, DIRECTOS, INDIRECTOS,
               DAÑOS CONSECUENCIALES, ESPECIALES, INCIDENTALES O PUNITIVOS, RESULTANTES DE O
               CAUSADOS POR EL SERVICIO O DE OTRO MODO, INCLUYENDO, SIN LIMITACIÓN, DAÑOS
               RESULTANTE DE LA NEGLIGENCIA. EN NINGÚN CASO EL MÁXIMO AGREGADO
               RESPONSABILIDAD DE APARTAMENTOS.COM, SUS AFILIADOS Y SUS RESPECTIVOS PROPIETARIOS,
               EMPLEADOS, AGENTES, CONTRATISTAS Y TERCEROS LICENCIANTES PARA CUALQUIER RECLAMACIÓN
               QUE SURJA DE O EN CONEXIÓN CON SU USO DEL SERVICIO EXCEDA EL
               IMPORTE TOTAL DE LAS TARIFAS REALMENTE PAGADAS POR USTED A RENTALFAQZ.COM. LA NEGACIÓN
               DE DAÑOS ESTABLECIDOS ANTERIORMENTE ES UN ELEMENTO FUNDAMENTAL DE LA BASE DE LA
               OPORTUNIDAD ENTRE RENTALFAQZ.COM Y USTED. EL SERVICIO NO SERÍA
               PROPORCIONADO A USTED SIN DICHAS LIMITACIONES. SIN CONSEJO NI INFORMACIÓN,
               YA SEA ORAL O ESCRITO, OBTENIDO POR USTED DE RENTALFAQZ.COM CREA
               CUALQUIER GARANTÍA, DECLARACIÓN Y/O GARANTÍA NO EXPRESAMENTE ESTABLECIDA EN
               ESTE ACUERDO. A LOS EFECTOS DE ESTA LIMITACIÓN DE RESPONSABILIDAD, “TERCEROS
               LICENCIANTE” NO LO INCLUYE A USTED.
             </p>

             <h3>Terminación del servicio</h3>
             <p class='size-1'>
               Usted comprende y acepta que, a exclusivo criterio de Rentalfaqz.com, y sin previo aviso,
               su acceso a este Sitio puede cancelarse o suspenderse, y RentalFaqz.com puede ejercer
               cualquier otro recurso disponible y eliminar cualquier Contenido, si cree que su uso del Sitio
               y/o cualquier Contenido o Información de referencia que proporcione (a) viola (i) estos Términos de
               Servicio, (ii) los derechos de RentalFaqz.com, sus afiliados, un Licenciante u otro Usuario, o (iii)
               cualquier ley o regulación, o son objetables o inapropiados o (b) constituyen
               actividad fraudulenta o ilícita de cualquier naturaleza. Usted acepta que los daños monetarios pueden no
               proporcionar un remedio suficiente a RentalFaqz.com por violaciones de estos Términos de servicio, y
               Usted acepta medidas cautelares u otras medidas equitativas por tales violaciones sin la
               requisito de que RentalFaqz.com pague una fianza. RentalFaqz.com no está obligado a proporcionar
               cualquier reembolso a usted si su uso finaliza como resultado de la determinación de RentalFaqz.com, en
               a su exclusivo criterio, que ha violado estos Términos de servicio.
             </p>

             <h3>Renuncia</h3>
             <p class='size-1'>
               Usted comprende y acepta que cualquier individuo o entidad que viole cualquier término de estos
               Se impide la aplicación de los Términos de servicio, se compromete a no hacerla cumplir y se compromete a no hacerlo.
               demandar para hacer cumplir, cualquier disposición similar o relacionada en cualquier contrato, incluidos los términos y
               condiciones, términos de uso y términos de servicio, afirmados por dicha persona, entidad o cualquier
               afiliado del mismo, como vinculante para RentalFaqz.com o cualquiera de sus afiliados.
             </p>

             <h3>Varios</h3>
             <p class='size-1'>
               Estos Términos de servicio pueden cambiarse en cualquier momento y se le notificará de tal
               cambios mediante una publicación actualizada de los nuevos Términos de servicio en esta página del Sitio. Su
               el uso continuado del Sitio después de la publicación de los Términos de servicio modificados constituirá
               su aceptación de quedar obligado por dichos cambios. RentalFaqz.com puede modificar, suspender,
               descontinuar o restringir el uso de cualquier parte del Sitio sin previo aviso ni responsabilidad. Tú
               por la presente acepta que cualquier causa de acción que pueda tener con respecto al Sitio debe ser
               presentado exclusivamente en los tribunales supremos ubicados en New Providence Bahamas, dentro de un (1)
               año después de que surja la causa de la acción o prescriba la causa. Por la presente, usted da su consentimiento para
               jurisdicción bajo los tribunales supremos de New Providence Bahamas, y renuncia a cualquier
               objeción basada en la no conveniencia del foro. Como condición para usar este Sitio, usted acepta que
               todas las causas de acción que surjan o estén relacionadas con este Sitio se resolverán individualmente,
               sin recurrir a ninguna forma de demanda colectiva. Si por alguna razón un tribunal de jurisdicción competente
               considera que alguna disposición de estos Términos de servicio, o parte de los mismos, es inaplicable, que
               disposición de estos Términos de servicio se aplicará en la medida máxima permitida para que
               como para afectar la intención de las partes, y el resto de estos Términos de servicio
               continuar en pleno vigor y efecto. El incumplimiento por parte de RentalFaqz.com de hacer cumplir cualquier disposición de este
               Los Términos de servicio no se considerarán una renuncia a la aplicación futura de ese o cualquier otro
               disposición de estos Términos de Servicio. Estos Términos de servicio y cualquier otro término y
               condiciones o políticas que se aplican a Su uso del Sitio, constituyen el acuerdo completo
               entre las partes sobre el objeto del presente. Nada de lo aquí contenido será
               interpretarse como la creación de una sociedad, empresa conjunta o relación de agencia o la concesión de una
               franquicia entre las partes. Sin perjuicio de cualquier disposición en contrario en este documento, si usted y
               RentalFaqz.com ha celebrado un contrato de publicidad por escrito por separado, los términos y
               condiciones de dicho contrato prevalecerán con respecto a cualquier término que sea inconsistente con
               estos Términos de servicio. SI NO ESTÁ DE ACUERDO CON LOS TÉRMINOS ESTABLECIDOS ANTERIORMENTE O CON CUALQUIER
               CAMBIOS A ESTOS TÉRMINOS DE SERVICIO, SALGA DEL SERVICIO INMEDIATAMENTE.
               <br/><br/>
               © 2023 RentalFaqz.com, Inc., N-10841 Nueva Providencia Bahamas
               <br/><br/>
               Última actualización: 12 de mayo de 2023
             </p>`,
          privacyText: `<h3>Declaración de privacidad</h3>
             <p class='size-1'>
               Este Aviso de privacidad describe los tipos de Información personal que recopilamos y nuestras prácticas para
               usarlo, mantenerlo, compartirlo y protegerlo. También describe los derechos y opciones que puede tener
               con respecto a su información personal y cómo puede comunicarse con nosotros acerca de nuestras prácticas de privacidad.
               Nuestras prácticas de privacidad pueden variar entre los países en los que operamos para reflejar las prácticas locales.
               y los requisitos legales, y las prácticas de privacidad específicas pueden aplicarse a algunos de nuestros productos y
               servicios.
               <br/><br/>
               Al utilizar nuestros sitios web, puede optar por interactuar con funciones de terceros que operan
               independientemente de RentalFaq.com, como widgets de redes sociales y enlaces a sitios web de terceros.
               RentalFaqz.com no tiene control ni es responsable de las prácticas de privacidad de dichos terceros.
               fiestas. Este Aviso de privacidad no se aplica en la medida en que RentalFaqz.com no posee ni controla
               cualquier sitio web vinculado o función que visite o utilice. Le recomendamos que se familiarice con
               las prácticas de privacidad de estos terceros.
             </p>

             <h3>Índice</h3>
             <p class='size-1'>
               <ol class='size-1'>
                 <li class='mb-1'>Tipos de información personal</li>
                 <li class='mb-1'>Recopilación de información personal</li>
                 <li class='mb-1'>Uso de información personal</li>
                 <li class='mb-1'>Divulgación de información personal</li>
                 <li class='mb-1'>Transferencia internacional de información personal</li>
                 <li class='mb-1'>Seguridad de la información personal</li>
                 <li class='mb-1'>Retención de información personal</li>
                 <li class='mb-1'>Administrar su información personal</li>
                 <li class='mb-1'>Cambios en el aviso de política global</li>
                 <li class='mb-1'>Contáctenos</li>
               </ol>
             </p>
             
             <h3>1. Tipos de información personal</h3>
             <p class='size-1'>
               “Información personal” significa cualquier información relacionada directa o indirectamente con una persona identificada o
               individuo identificable. Los ejemplos de información personal que RentalFaqz.com puede recopilar incluyen:
               <ul class='size-1'>
                 <li class='mb-1'>
                   Información de pago (tarjeta de crédito y otra información de tarjeta de pago)
                 </li>
                 <li class='mb-1'>
                   Información de contacto personal (nombre, apodo, sexo, dirección postal, dirección de correo electrónico,
                   número de teléfono)
                 </li>
                 <li class='mb-1'>
                   Información comercial (ubicación de la oficina, información de contacto comercial, departamento, trabajo
                   título/descripción, biografía profesional)
                 </li>
                 <li class='mb-1'>
                   Credenciales de inicio de sesión (nombre de usuario, contraseña)
                 </li>
                 <li class='mb-1'>
                   Detalles de uso (actividad de inicio de sesión, términos de búsqueda, vistas, clics, descargas, envío de clientes potenciales)
                   información)
                 </li>
                 <li class='mb-1'>
                   Detalles técnicos (dirección IP, información de geolocalización, ID de cookies/sesión, autenticación
                   información, tipo/versión de navegador, sistema operativo/plataforma)
                 </li>
                 <li class='mb-1'>
                   Cualquier otra información que usted u otros clientes puedan cargar o ingresar en los Servicios
                 </li>
                 <li class='mb-1'>
                   Grabaciones de llamadas telefónicas y videoconferencias
                 </li>
               </ul>
             </p>
             <h3>2. Recopilación de Información Personal</h3>
             <p class='size-1'>
               RentalFaqz puede recopilar información personal utilizando los siguientes métodos:
               <ul class='size-1'>
                 <li class='mb-1'>
                   Directamente de usted cuando nos la proporciona (información que ingresa en formularios web,
                   chatbots, participación en llamadas telefónicas o videoconferencias, acuerdos de suscripción y
                   contratos u otro contenido que envíe a los Servicios)
                 </li>
                 <li class='mb-1'>
                   A través de su participación en encuestas, promociones y concursos
                 </li>
                 <li class='mb-1'>
                   Bases de datos y sistema interno de gestión de relaciones con los clientes de RentalFaqz
                 </li>
                 <li class='mb-1'>
                   De terceros, como proveedores de servicios de análisis y marketing por correo electrónico
                 </li>
                 <li class='mb-1'>
                   Investigar sitios web públicos donde lo permitan los Términos de uso (sitios web de empresas,
                   motores de búsqueda, redes sociales)
                 </li>
                 <li class='mb-1'>
                   Automáticamente a través de tecnologías de seguimiento (cookies, web beacons, archivos de registro), incluso sobre
                   tiempo y en sitios web de terceros u otros servicios en línea
                 </li>
               </ul>
             </p>
            
             <h3>3. Uso de información personal</h3>
             <p class='size-1'>
               Podemos utilizar su información personal para los siguientes fines:
               <ul class='size-1'>
                 <li class='mb-1'>
                   comunicándome contigo
                 </li>
                 <li class='mb-1'>
                   Proporcionarle los Servicios
                 </li>
                 <li class='mb-1'>
                   Autenticar el uso, detectar el uso fraudulento potencial y, de lo contrario, mantener la
                   seguridad de los Servicios
                 </li>
                 <li class='mb-1'>
                   Desarrollar, probar, mejorar y demostrar los Servicios
                 </li>
                 <li class='mb-1'>
                   Crear y mantener un sistema de gestión de relaciones con los clientes, directorios de miembros,
                   y listas de invitados para eventos
                 </li>
                 <li class='mb-1'>
                   Cumplir con nuestras obligaciones legales y contractuales y hacer valer nuestros derechos, incluida la facturación.
                   y procesamiento de pagos
                 </li>
                 <li class='mb-1'>
                   Anonimización y agregación de información para análisis e informes
                 </li>
                 <li class='mb-1'>
                   Publicidad, marketing y venta de los Servicios, incluida la vinculación o fusión
                   información personal con otra información personal para que podamos entender mejor
                   sus necesidades e informarle sobre nuestros Servicios y los de nuestros socios
                 </li>
                 <li class='mb-1'>
                   Uso transitorio a corto plazo, tal como se define en la Ley de Privacidad del Consumidor de California
                 </li>
                 <li class='mb-1'>
                   Formación y aseguramiento de la calidad
                 </li>
                 <li class='mb-1'>
                   Para cualquier otro propósito con su consentimiento
                 </li>
               </ul>
             </p>
             <h3>4. Divulgación de información personal</h3>
             <p class='size-1'>
               Podemos divulgar información personal:
               <ul class='size-1'>
                 <li class='mb-1'>
                   A afiliados y otras entidades dentro del grupo de empresas, socios de distribución, otros
                   clientes y proveedores de servicios
                 </li>
                 <li class='mb-1'>
                   A terceros cuando utiliza una función de terceros en nuestros Servicios
                 </li>
                 <li class='mb-1'>
                   Para hacer cumplir o aplicar nuestros Términos de uso y otros acuerdos o cumplir con cualquier orden judicial,
                   ley o proceso legal, incluidas las solicitudes gubernamentales o reglamentarias
                 </li>
                 <li class='mb-1'>
                   A un comprador u otro sucesor en caso de fusión, desinversión, reestructuración,
                   reorganización, disolución u otra venta o transferencia de algunos o todos los activos de RentalFaqz.com
                 </li>
                 <li class='mb-1'>
                   A asesores, consultores, auditores o profesionales similares para evaluar nuestros negocios, finanzas,
                   y obligaciones u operaciones legales
                 </li>
                 <li class='mb-1'>
                   Si creemos que es necesario para proteger los derechos, la propiedad o la seguridad de RentalFaqz.com o
                   otros
                 </li>
                 <li class='mb-1'>
                   Para cualquier otro propósito divulgado por nosotros cuando proporciona información personal
                 </li>
                 <li class='mb-1'>
                   Con su consentimiento, como cuando elige proporcionar su información a terceros
                   También podemos divulgar información anonimizada sin restricciones. Tenga en cuenta que si realiza alguna
                   Información personal disponible públicamente en los Servicios, cualquier persona puede ver y usar dicha información.
                 </li>
               </ul>
             </p>


             <h4><u>Servicios de listado</u></h4>
             <p class='size-1'>
               RentalFaqz puede compartir información de miembros en las siguientes áreas de los Servicios: Marketing
               Centro, Alertas por Email y Directorio Profesional. Cuando un cliente ingresa un listado de venta o arrendamiento en el
               Marketing Center, también se ingresa la información de contacto de la lista. Durante este proceso, el listado
               el remitente puede ver la información de contacto de otros clientes cuando agrega otro contacto
               al listado. Además, los clientes pueden ver la información de contacto (nombre, correo electrónico y teléfono)
               número) al ver un perfil de listado de propiedades.
               Los Servicios también pueden permitir a los usuarios compartir información de contacto entre sí a través de alertas por correo electrónico.
               Con alertas por correo electrónico, los usuarios pueden guardar sus criterios de búsqueda de propiedades para tener información sobre las propiedades.
               que coincidan con los criterios especificados que se les envió por correo electrónico. Al seleccionar este servicio, los usuarios reciben la
               opción de divulgar su información de contacto a otros usuarios que tienen listados de propiedades que coinciden
               sus criterios. Esta información de contacto luego se comparte con los usuarios que tienen listados de propiedades que
               coincida con los criterios de compra.
             </p>

             <h4><u>RentalFaqz.com</u></h4>
             <p class='size-1'>
               Si elige utilizar el Servicio de listado gratuito del sitio web para anunciar una propiedad, es importante tener en cuenta
               que cualquier información que elija incluir en su listado puede estar disponible públicamente a través del
               sitio web y otros medios en los que se publican los listados del sitio web, incluidos los sitios afiliados. Para
               por ejemplo, puede incluir un número de teléfono y/o una dirección de correo electrónico para permitir que los usuarios se comuniquen con usted
               con respecto a su listado. RentalFaqz.com no es responsable de llamadas telefónicas y correos electrónicos no solicitados que
               puede recibir de terceros, incluso después de que su listado ya no esté en el sitio web. Excepto por
               información requerida, no debe incluir ningún contacto u otra información en su listado que
               usted no quiere que otras partes vean o usen.
             </p>
            
             <h3>5. Transferencia Internacional de Información Personal</h3>
             <p class='size-1'>
               Podemos transferir su información personal a los Estados Unidos y a otros países que pueden
               no tiene las mismas leyes de privacidad que el país en el que proporcionó inicialmente la información, pero
               protegeremos su Información personal de acuerdo con este Aviso de privacidad global, o como
               de otro modo revelado a usted.
             </p>
             <h4><u>Residentes del Espacio Económico Europeo (EEE) y Residentes del Reino Unido</u></h4>
             <p class='size-1'>
               Si vive en el EEE o el Reino Unido, podemos transferir información personal a países para
               que se han emitido decisiones de adecuación, utilizar protecciones contractuales para la transferencia de Personal
               Información a terceros, como un acuerdo intraempresarial o la Comisión Europea
               Cláusulas Contractuales Tipo o su equivalente según la legislación aplicable.
             </p>

             <h4><u>Residentes de Canadá</u></h4>
             <p class='size-1'>
               Podemos utilizar proveedores de servicios ubicados fuera de Canadá, incluidos, entre otros, los Estados Unidos
               Unidos de América, para procesar Información Personal, y puede transferir su Información Personal a
               tales proveedores de servicios para este propósito. Cuando su información personal se procesa fuera de
               Canadá, estará sujeto a las leyes de los países donde se procese la información y podrá
               ser accesible a las autoridades encargadas de hacer cumplir la ley y de seguridad nacional de esos países de conformidad
               con sus leyes.
             </p>

             <h3>6. Seguridad de la información personal</h3>
             <p class='size-1'>
               RentalFaqz implementa medidas de seguridad para proteger su información personal.
               Tomamos medidas para proteger la información personal a través de procedimientos administrativos, técnicos y físicos.
               salvaguardias diseñadas para proteger contra el riesgo de destrucción accidental, ilegal o no autorizada,
               pérdida, alteración, acceso, divulgación o uso. Por ejemplo, ciframos la información de la tarjeta de pago en
               de acuerdo con el Estándar de seguridad de datos de la industria de tarjetas de pago (PCI DSS) y almacene datos confidenciales
               información como datos de geolocalización en nuestros servidores ubicados en Estados Unidos con acceso limitado
               a los empleados autorizados de RentalFaqz.
               Desafortunadamente, no podemos garantizar la seguridad de la información transmitida a través de Internet,
               y cuando le hayamos dado (o haya elegido) una contraseña, usted es responsable de
               manteniendo esta contraseña confidencial.
             </p>

             <h3>7. Retención de información personal</h3>
             <p class='size-1'>
               RentalFaqz.com conservará su información solo durante el tiempo que sea razonablemente necesario para el
               fines establecidos en este Aviso de privacidad, mientras su cuenta esté activa, o según sea necesario para proporcionar
               los Servicios a usted. Si ya no desea que usemos su información para brindarle los Servicios,
               puede cerrar su cuenta. RentalFaqz.com conservará y utilizará su información en la medida
               necesario para cumplir con nuestras obligaciones legales, resolver disputas, hacer cumplir nuestros acuerdos, y como
               descrito de otra manera en este Aviso de privacidad. Tenga en cuenta que es posible que cerrar una cuenta no haga que su
               dirección de correo electrónico, nombre de usuario o nombre de propiedad (si corresponde) disponible para su reutilización en una cuenta nueva. Nosotros también
               conservar los archivos de registro para fines de análisis interno. Estos archivos de registro se conservan durante un breve período de tiempo,
               excepto en los casos en que se utilicen para proteger la seguridad de los Servicios, para mantener
               y mejorar la funcionalidad de los Servicios, o para cumplir con obligaciones legales.
             </p>

             <h3>8. Administre su información personal</h3>
             <p class='size-1'>
               Puede administrar su información personal al hacer ciertas elecciones. Si crees que alguna
               La información personal que nos ha proporcionado está desactualizada o es inexacta, puede revisarla y
               corrija dicha información si está dentro de su cuenta en línea iniciando sesión en cualquier momento o Comuníquese con nosotros.
               También puede tener derechos adicionales que se detallan a continuación según el lugar donde viva. como seguridad
               precaución, es posible que necesitemos solicitarle información para ayudarnos a confirmar su identidad cuando
               ejercer un derecho relacionado con su Información personal.
             </p>
             <h4><u>Exclusión de marketing directo</u></h4>
             <p class='size-1'>
               Si ya no desea recibir nuestros correos electrónicos de marketing directo, puede hacer clic en "Cancelar suscripción".
               enlace en un correo electrónico en cualquier momento para dejar de recibir correos electrónicos para ese tema en particular. Dependiendo de
               Servicios, también puede administrar las preferencias de notificación en su cuenta. Tenga en cuenta que podemos
               seguir comunicándonos con usted acerca de los Términos de uso, el Aviso de privacidad global u otros avisos legales, o en
               conexión con la prestación de los Servicios que nos ha solicitado.
             </p>

             <h4><u>Exclusión de seguimiento/publicidad en línea</u></h4>
             <p class='size-1'>
               Puede deshabilitar o eliminar las cookies del navegador a través de la configuración de su navegador. Las cookies son generalmente
               fácil de desactivar o eliminar, pero el método varía entre navegadores. Para saber cómo puede administrar
               Flash cookies, visite la página de configuración del reproductor Flash de Adobe. Si deshabilita o elimina las cookies, o si está
               ejecutando software de terceros que intercepta o elimina cookies, tenga en cuenta que algunas partes de nuestro
               los sitios web pueden no funcionar correctamente.
             </p>

             <h3>9. Cambios en el Aviso de privacidad global</h3>
             <p class='size-1'>
               Este Aviso de privacidad puede actualizarse periódicamente para reflejar los cambios en nuestras prácticas de privacidad.
               Publicaremos los cambios en esta página e identificaremos la fecha en que se revisó por última vez el aviso de privacidad en la parte superior
               de la pagina Si realizamos cambios sustanciales en nuestras prácticas de privacidad, se lo notificaremos a través de un
               aviso en la página de inicio de los sitios web objeto del presente Aviso de Privacidad. Si un cambio material
               se aplican a la Información personal que ya hemos recopilado, incluidos los nuevos propósitos para los cuales el
               Se usará o divulgará información personal para la que no haya dado su consentimiento previamente,
               solicitar adicionalmente su consentimiento afirmativo.
             </p>

             <h3>10. Contáctenos</h3>
             <p class='size-1'>
               Para responder adecuadamente a sus preguntas o solicitudes de privacidad sobre nuestros servicios, envíe un correo electrónico a
               escríbanos a <a href="mailto:rentalfaqz@gmail.com">rentalfaqz@gmail.com</a>, o llámenos al: <a href="tel:+12428202234">(242) 820 -2234</a>
             </p>`,
          f_terms: "Al registrarme, acepto los",
          f_termsUse: "Condiciones de uso.",
          f_termsProperty: `Al hacer clic en Agregar propiedad/Agregar unidad arriba, acepto que proporcionaré información precisa y no discriminatoria
          y voy a cumplir con el`,
          f_termsConditions: "Términos y condiciones",
          f_termsService: "Agregue una propiedad Condiciones de servicio.",
          f_termsConjuction: "y el",

          // Misc
          prev: "Previo",
          next: "Próximo",
          vmore: "Ver más",
          f_ok: "Bueno",
          actionFailed: "Accion: Fallida",
        }
      },
      cre: {
        translation: {
          // Error
          fileBig: "Gwosè fichye a twò gwo (gwosè maksimòm pèmèt: 3 megabyte)",

          // Navbar
          login: "Koneksyon",
          signup: "Enskri",
          featured: "Mete aksan sou",
          searchAdvanced: "Rechèch avanse",
          about: "Sou nou",
          contact: "Kontakte nou",
          notifications: "Avis",
          myAccount: "Kont mwen",

          // About Page
          terms: "Tèm ak kondisyon",
          privacy: "Deklarasyon konfidansyalite",


          // Homepage
          header: "Dekouvri nouvo kòmansman",
          header2: "Nouvo rezon, nouvo avantur, nouvo souvni.",
          header3: "Jwenn anfòm pafè ou nan",
          search: "Rechèch",

          // Login Page
          f_login: "Siyen nan kont ou",
          f_createAcct: "Kreye yon kont?",
          f_resendEmail: "Revoye imèl konfimasyon",
          f_forgotPass: "Ou bliye modpas ou a?",

          //  Register New Account Page
          joinCommunity: "Antre nan kominote nou an",
          fullAccess: "Kreye yon kont pou aksè konplè",
          siteUsage: "Ki jan ou fè plan pou itilize sit sa a?",
          landLord: "Pwopriyetè",
          landLordDesc: "Lwe pwopriyete lè w poste anons sou sit sa a.",
          or: "Oswa",
          tenant: "Lokatè",
          tenantDesc: "Chèche yon pwopriyete pou viv nan, lè w aplike nan anons ki disponib sou sit sa a.",
          personInfo: "Enfòmasyon pèsonèl",
          requiredFields: "Jaden obligatwa yo make ak yon koulè fwontyè diferan",
          // Form Fields
          f_passwordReset: "Voye imel reset modpas la",
          f_sendEmail: "Voye yon imèl",
          f_passwordMatch: "Modpas la pa matche ak konfimasyon an!",
          f_fName: "Premye non",
          f_mName: "Mwayen non",
          f_lName: "Ti non",
          f_sex: "Sèks",
          f_opt_male: "Gason",
          f_opt_female: "Fi",
          f_dob: "Dat nesans",
          f_dobDesc: "Dwe gen 18 ane oswa plis",
          f_cob: "Peyi natif natal",
          f_cor: "Peyi rezidans",
          f_nib: "Nimewo BIN",
          f_govID: "Enpòte yon ID gouvènman an",
          f_photo: "Telechaje foto",
          f_contact: "Detay kontak",
          f_phone: "Nimewo telefòn",
          f_bank: "Enfòmasyon labank",
          f_bankName: "Non bank la",
          f_bankBranch: "Fouchèt",
          f_bankTransit: "Transpò",
          f_bankAccount: "Kont",
          f_family: "Relasyon fanmi",
          f_relations: "Estati relasyon",
          f_opt_single: "Sèlman",
          f_opt_married: "Marye",
          f_opt_divorced: "Divòs",
          f_opt_relationship: "Relasyon",
          f_spouse: "Èske w ap viv ak mari oswa madanm ou?",
          f_haveChildren: "Ou gen pitit?",
          f_haveBaby: "Èske nenpòt nan pitit ou yo konsidere kòm tibebe/tibebe?",
          f_income: "Enfòmasyon sou revni",
          f_occupation: "Pwofesyon",
          f_opt_agriculture: "Agrikilti, manje ak resous natirèl",
          f_opt_art: "Art, teknoloji odyo/videyo ak kominikasyon",
          f_opt_education: "Edikasyon ak fòmasyon",
          f_opt_government: "Gouvènman ak administrasyon piblik",
          f_opt_hospitality: "Ospitalite ak Touris",
          f_opt_IT: "Syans enfòmatik",
          f_opt_manufacture: "Faktori",
          f_opt_math: "Syans, Teknoloji, Jeni ak Matematik",
          f_opt_construction: "Achitekti & Konstriksyon",
          f_opt_business: "Jesyon ak administrasyon biznis",
          f_opt_finance: "Finans",
          f_opt_health: "Syans Sante",
          f_opt_human: "Sèvis pèsonèl",
          f_opt_law: "Lwa, Sekirite Piblik, Koreksyon ak Sekirite",
          f_opt_marketing: "Maketing, Komèsyal ak Sèvis",
          f_opt_transportation: "Transpò, distribisyon ak lojistik",
          f_opt_other: "Lòt",
          f_employer: "Travay nan",
          f_employmentLength: "Dire travay an mwa",
          f_jobLetter: "Telechaje lèt travay",
          f_workContact: "Kontakte moun nan travay la",
          f_annualIncome: "Revni anyèl nan",
          f_lessThan: "Mwens pase",
          f_higherThan: "ak pi wo",
          f_account: "Enfòmasyon sou kont",
          f_email: "Adrès imel",
          f_backupEmail: "Imèl sekirite",
          f_password: "Modpas",
          f_passwordConfirm: "Konfimasyon modpas",
          f_declaration: "Enfòmasyon yo bay yo kòrèk dapre sa mwen konnen.",
          f_uploadImage: "Enpòte imaj",
          f_clearImage: "Efase imaj pou telechaje",
          f_cancel: "Anile",
          f_remove: "Pou retire",
          f_submit: "Soumèt",
          f_island: "Île",
          f_settlement: "Règ",
          f_streetAddress: "adrès Ri",
          f_PoBox: "Bwat lèt",
          f_longitude: "Longitude nan Google Maps",
          f_latitude: "Latitid nan Google Maps",
          f_desc: "Deskripsyon",
          f_moveInSpecial: "Deplase-an espesyal",
          f_disclaimer: "Limit responsabilite nou",
          f_highlights: "Pwen fò",
          f_propertyPhoto: "Telechajman foto pwopriyete",
          f_propertyPhotoDesc: "Premye foto ki telechaje a itilize kòm miniature imaj pwopriyete a",
          f_propertyPhotoDesc2: "Imaj make an wouj yo chwazi pou efase",
          f_selectPhoto: "Chwazi foto kouvèti a",
          f_selectPhotoDesc: "Imaj la make an vèt se foto kouvèti chwazi a",
          f_clear: "Clair",
          f_back: "Nan la",
          propertyCategorize: "Klase pwopriyete a nan yon gwoup",
          f_unit: "Inite",
          f_propertyType: "Kalite pwopriyete",
          f_opt_apartment: "Appartement",
          f_opt_bnb: "AirBnB",
          f_opt_home: "Maison",
          f_opt_condo: "Kondominyòm",
          f_opt_townhouse: "Townhouse",
          f_sqFt: "Zòn kare nan pwopriyete a",
          f_bedrooms: "kantite chanm",
          f_bathrooms: "Kantite twalèt",
          f_payment: "Enfòmasyon sou peman",
          f_fee: "Depans",
          f_deposit: "Depo espere",
          f_depositDesc: "Peman total yo espere nan men lokatè a pou premye mwa a ak depans pou demenaje.",
          f_frequency: "frekans peman",
          f_opt_once: "Yon fwa",
          f_opt_daily: "Chak jou",
          f_opt_weekly: "Chak semèn",
          f_opt_monthly: "Chak mwa",
          f_amenities: "Ekipman pwopriyete",

          // Confirm Signup Page
          welcome: "Byenvini",
          signupThanks: ", e mèsi paske w enskri!",
          checkEmail: "Tanpri tcheke imèl ou yo",
          accountLink: "pou lyen deklanchman kont",
          checkSpam: "PS: Si ou pa wè imel aktivasyon an, tanpri tcheke dosye tenten ou oswa spam!",

          // Property Groups
          propertyGroup: "Enfòmasyon sou gwoup pwopriyete yo",
          propertyGroupDesc: "Sèvi ak gwoup pwopriyete pou konekte inite lokasyon yo si yo nan menm konplèks apatman an",
          propertyGroupSelect: "Chwazi gwoup pwopriyete yo modifye",
          propertyGroupEdit: "Edit gwoup pwopriyete",
          propertyGroupCreate: "Kreye yon nouvo gwoup pwopriyete",
          propertyGroupName: "non gwoup la",
          propertyGroupRemove: "Efase gwoup la",
          propertyGroupUpdate: "Mete ajou gwoup pwopriyete",
          propertyGroupNew: "Nouvo gwoup pwopriyete",
          propertyGroupShow: "Gade enfòmasyon gwoup la",

          // Add Property
          addProperty: "Ajoute yon nouvo pwopriyete",
          propertyCreate: "Kreye yon nouvo pwopriyete",
          propertyInfo: "Enfòmasyon sou pwopriyete",
          propertySingle: "Single inite",
          propertyMultiple: "Plizyè inite",
          propertyUnitDesc: "Chwazi swa yon lis inite endividyèl oswa yon lis konplèks apatman",
          f_addProperty: "Ajoute pwopriyete",
          f_addUnit: "Ajoute inite a",

          // Edit Property
          tenantInfo: "Enfòmasyon sou lokatè",
          appInquiry: "Demann aplikan yo",
          appInquiryDesc: "Itilizatè yo ki te mande enfòmasyon sou pwopriyete a",
          propertyTenant: "Lokatè nan pwopriyete a",
          propertyTenantDesc: "Itilizatè yo ap lwe pwopriyete a kounye a",
          f_disabled: "Èske pwopriyete sa a aksesib pou piblik la?",
          updateProperty: "Mete ajou Pwopriyete",
          apartmentComplex: "konplèks apatman",
          f_applicants: "Kandida yo",
          f_groups: "Gwoup yo",
          f_info: "Info",

          // Property Payments
          choose_plan: "Chwazi fòmil ou a",
          choose: "Chwazi",
          basic: "Debaz",
          week: "semèn",
          month: "mwa",
          allowed_to_list: "Pèmèt nan lis pwopriyete, jiska ",
          allowed_to_list_unlimited: "Pèmèt lis yon kantite illimité de propriétés",
          premium: "Premye",
          can_accept_tenants: "Ka aksepte lokatè sou entènèt",
          can_accept_payments: "Ka aksepte peman nan men lokatè sou entènèt",

          // Property Search
          select_filter: "Chwazi filtè yo",
          clear_filter: "Klè filtè yo",
          f_sqFt_greater: "Pye kare pi gran pase",
          f_price_range: "Echèl pri",
          f_min: "Le minimum",
          f_max: "Max",
          f_sort_by: "triye pa",
          f_oldest_newest: "Soti nan pi ansyen nan dernye",
          f_most_recent: "Pi resan",

          //Property Forums
          topic_summary: "Rezime Sijè",
          issue_details: "Detay pwoblèm",
          f_submit_req: "Voye DEMANN",
          property_forum: "Fowòm byen imobilye",
          maintenance_req: "Demann antretyen",
          new_repair_req: "Nouvo demann reparasyon",
          open: "Louvri",
          closed: "Farm",
          // Selected Property Forum
          repair_req: "Demann reparasyon",
          tenant_inq: "Demann lokatè yo",
          // Forum New Comment
          title: "Tit",
          enterText: "Antre tèks ki anba a",
          f_reply: "Reponn",
          // Property Forum Display
          f_edit: "Modifier",
          f_attachedImages: "Foto tache",
          f_posted: "travay",
          f_edited: "edited",
          f_replies: "repons",

          // Favorited Properties
          favoritedProperty: "Pwopriyete pi renmen yo",

          // Property Display Thumb
          t_beds: "kabann",
          t_baths: "Benyen",
          t_propertyOwned: "Pwopriyete posede",
          t_yourHome: "Kay ou",
          t_rented: "Lwe",
          t_repairsReq: "Mande Reparasyon / Rekèt",
          studio: "estidyo",
          one_bed: "1 kabann",
          two_beds: "2 kabann",
          three_beds: "3 kabann",
          studio_one_bed: "estidyo - 1 kabann",
          studio_two_beds: "estidyo - 2 kabann",
          studio_three_beds: "estidyo - 3 kabann",
          studio_studio: "estidyo - estidyo",
          one_bed_one_bed: "1 kabann - 1 kabann",
          one_bed_two_beds: "1 kabann - 2 kabann",
          one_bed_three_beds: "1 kabann - 3 kabann",
          two_beds_two_beds: "2 kabann - 2 kabann",
          two_beds_three_beds: "2 kabann - 3 kabann",
          three_beds_three_beds: "3 kabann - 3 kabann",

          // Property  Display
          g_leaveReview: "Kite yon kòmantè",
          g_leaveReviewDesc: "Kite yon kòmantè ki detaye sejou ou nan rezidans sa a.",
          needToBeResident: "Ou ka sèlman kite yon revizyon apre ou te yon rezidan nan pwopriyete a nan kesyon an.",
          g_otherUnits: "Lòt apatman konplèks inite yo",
          g_noRelatedUnits: "Pa gen inite ki asosye ki disponib.",
          g_bedroom: "chanm",
          g_apply: "Aplike",
          g_this: "Sa a",
          unitFeatures: "Karakteristik inite yo",
          g_ownerOptions: "Opsyon pwopriyetè a",
          g_residentOptions: "Opsyon pwopriyetè pou rezidan yo",
          g_tenantForums: "Fowòm lokatè yo",
          g_editProperty: "Chanje pwopriyete",
          g_removeProperty: "Efase pwopriyete",
          g_rentalProperty: "Pwopriyete lokasyon",
          g_waterIncluded: "Dlo enkli",
          g_internetIncluded: "entènèt enkli",
          g_electricityIncluded: "Elektrisite enkli",
          g_utilities: "Itilite yo",


          // Property Group Display
          g_verifiedRenter: "Lokatè verifye",
          needToRegister: "Ou dwe yon itilizatè ki anrejistre pou itilize karakteristik sa a!",
          needToSignIn: "Tanpri anrejistre oswa kreye yon kont.",
          gPricingUnit: "Pri ak inite yo",
          g_all: "Tout",
          g_studio: "Estidyo",
          g_inquire: "Enfòme",
          g_ownerNotify: "Pwopriyetè a pral enfòme sou detay kontak ou",
          g_squareFeet: "Pye kare",
          g_noneAvailable: "Okenn ki disponib nan moman sa a.",
          g_about: "Sou",
          g_propertyDesc: "Antre deskripsyon pwopriyete a isit la.",
          g_location: "Kote",
          g_propertyAddress: "Adrès pwopriyete",
          g_rentalDisclaimer: "Limit responsabilite nou pou lokasyon",
          g_contactOwner: "Kontakte pwopriyetè a",
          g_tourReq: "Mande yon vizit",
          g_language: "Lang",
          g_open: "Louvri",
          g_allHours: "Montre chak èdtan",
          g_residentReviews: "Opinyon rezidan yo",
          g_noRatings: "Pa gen okenn revizyon ki disponib",

          // Unit Display
          f_furnished: "Mèb",
          f_kitchen: "Manje",
          f_livingRoom: "Sal k ap viv",
          f_airConditioned: "È kondisyone",
          f_fencedIn: "Kloti",
          f_backyard: "Tribinal",
          f_gatedCommunity: "Kominote ki fèmen",
          f_petsAllowed: "bèt aksepte",
          f_inUnitWasher: "Machin pou lave ak seche rad nan inite a",
          f_washerHookups: "Koneksyon machin pou lave ak seche rad",
          f_dishwasher: "Dishwasher",
          f_wheelchairAccess: "Chèz woulant aksesib",
          f_laundryFacilities: "sal lesiv",
          f_fitnessCenter: "Sant Fòm",
          f_pool: "Pisin",
          f_elevator: "Asansè",
          f_parking: "Park machin",
          f_balcony: "Balkon",
          f_upstairs: "Sitiye anwo kay la",
          f_downstairs: "Sitiye anba",
          f_utilities: "Itilite enkli",
          f_water: "Dlo",
          f_internet: "entènèt la",
          f_electricity: "Elektrisite",
          units: "inite yo",
          availableUnit: "inite ki disponib",
          availableUnits: "inite ki disponib yo",
          propertyAvailable: "Pwopriyete ki disponib",
          propertyUnavailable: "Pwopriyete pa disponib",
          hideUnits: "Kache inite yo",
          showUnits: "Montre inite yo",
          rentalFee: "Frè lokasyon",
          baths: "Benyen",
          propertyFeatures: "Karakteristik nan etablisman an",
          sendMessage_title: "Imèl Pwopriyetè",
          sendMessage: "Voye mesaj la",


          // Account Page
          profileSettings: "Anviwònman pwofil",
          profile: "Profile",
          myHome: "Kay mwen",
          ownedProperties: "Pwopriyete posede",
          propertyTransactions: "Tranzaksyon Pwopriyete",
          manageSubs: "Jere abònman",
          logOut: "Dekonekte",
          f_preferredLang: "Lang prefere",
          f_english: "Angle",
          f_spanish: "Panyòl",
          f_creole: "Kreyòl",
          f_contactHours: "Lè kontak",
          f_sunday: "Dimanch",
          f_monday: "Lendi",
          f_tuesday: "Madi",
          f_wednesday: "Mèkredi",
          f_thursday: "Jedi",
          f_friday: "Vandredi",
          f_saturday: "Samdi",
          f_startTime: "Kòmanse tan",
          f_endTime: "Tan fini",
          f_contactUpdate: "Mete ajou detay kontak",
          f_updateBank: "Mete ajou enfòmasyon bankè yo",
          f_updateEmployment: "Mete ajou enfòmasyon travay",
          f_changePassword: "Chanje modpas",
          f_newPassword: "Nouvo modpas",
          f_newPasswordConfirm: "Konfimasyon nouvo modpas",
          f_deactivateAccount: "DEZACTIVE kont ou",
          f_closeAccount: "Fèmen kont lan",
          f_propertyListing: "Lis Pwopriyete",
          f_newListing: "Ajoute yon nouvo anons",
          f_singleListing: "Single Ajoute",
          f_complexListing: "Lis nan konplèks apatman",

          // Payment Modal
          f_cardHolder: "Non moun ki gen kat la",
          f_paymentAmount: "Montan peman",
          f_cardNumber: "Nimewo kat",
          f_cardExpiry: "Kat ekspirasyon",
          f_cardCVC: "Kat HVAC",
          f_pay: "Peye",

          // PaymentSettingDisplay
          f_propertySubs: "Abònman Pwopriyetè",
          f_recurring: "Renouvlab",
          f_invoiceNumb: "Nimewo bòdwo",
          f_activateDate: "Dat aktivasyon",
          f_expiryDate: "Dat ekspirasyon",
          f_renew: "Renouvle",
          f_initialDeposit: "Premye depo",
          f_monthlyFee: "Frè chak mwa",
          f_totalFee: "Pri total",
          f_paymentStatus: "Estati peman",
          f_datePaid: "Dat peman",
          f_nextPayDate: "Pwochen dat peman",
          f_tapToPay: "Tape pou peye!",
          recent_sub: "Suscripción reciente",
          purchase_sub: "Compra un plan de suscripción",
          my_home_rental: "Tarifas de alquiler de mi casa",

          // Tenant Modal
          f_termsTenantLeft: "Kondisyon kote lokatè a kite apatman anvan an",
          f_landlordScore: "Nòt pwopriyetè a",
          f_landlordComment: "Kòmantè soti nan ansyen pwopriyetè",
          f_tenantName: "Non lokatè a",
          no_reviews: "Pa gen okenn revizyon ankò.",
          reviews: "kòmantè",
          f_generalInfo: "Enfòmasyon jeneral",
          f_race: "Carrera",
          f_passport: "Nimewo paspò",
          f_citizenRecord: "Enfòmasyon sou Rejis Sitwayen an",
          f_criminalRecord: "Dosye kriminèl",
          f_reasonJailed: "rezon nan prizon",
          f_tenantHistory: "Istwa lokatè",
          no_history: "Pa gen istwa lokatè.",
          f_overallRating: "Mak mondyal",
          f_rateTenant: "To lokatè",
          f_giveRating_req: "Bay lokatè w ap retire yon nòt nan lis pwopriyete yo",
          f_tenantClosure: "Fèmen (Poukisa lokatè a ap kite)",
          f_movedOut: "Deplase",
          f_evicted: "Fòse soti",
          f_tenantRating: "Evalyasyon Lokatè,",
          f_comment: "Kòmantè",
          f_reviewTenant: "Egzamine lokatè a",
          f_denyTenant: "Dekline Sondaj Lokatè",
          f_acceptTenant: "Aksepte kòm lokatè",
          f_removeTenant: "Efase lokatè",

          //Send Mail Modal
          f_moveInDate: "Dat deplase",
          f_customMessage: "Mesaj pèsonalize",

          // Confirm Modal
          ratingRange: "Ranje Rating, 1 - 5 zetwal",

          // Report Modal
          reportIssue: "Pou rapòte yon pwoblèm",
          descIssue: "Dekri pwoblèm nan",

          // Terms of Use
          registerNow: "Enskri kounye a",
          contactText: `Pou byen reponn kesyon sou enfòmasyon prive w yo oswa demann sou sèvis nou yo,
             tanpri imèl nou nan
             <a href="mailto:rentalfaqz@gmail.com"> rentalfaqz@gmail.com</a>, oswa rele nou nan:
             <a href="tel:+12428202234"> (242) 820-2234</a>`,
          aboutText: `Isit la nan <b>Rentalfaqz</b>, objektif nou se vin pi konplè sou entènèt mache pou lokasyon Karayib la.
            <br/><br/>
            Se poutèt sa nou pral ajoute pidevan, ak transpò lokasyon nan sèvis nou yo nan lòd yo akomode ou pi byen, epi fè lavi ou pi fasil.
            Se konsa, planifye alavans ak liv davans nan men anpil machann nou yo, ak ti kras efò ak ak tan rezèv.
            <br/><br/>
            Pandan n ap elaji rive nan peyi Karayib ki antoure yo, n ap kreye yon rezo kliyantèl byen imobilye,
            pidevan, transpò lokasyon, pote yo dwa nan pwent dwèt ou.
            <br/><br/>
            Se konsa, si w ap chèche ogmante prezans biznis ou sou entènèt, sekirize pidevan ki bon pou gwo okazyon sa a,
            oswa ranpli pòs lokasyon ou yo; ou te rive nan bon kote!`,
          termsText: `<h3>Akò sou kondisyon sèvis</h3>
             <p class='size-1'>
               Kondisyon Sèvis sa yo aplike nan sèvis Rentalfaqz.com ofri yo. Nenpòt moun ki vizite,
               wè, aksè oswa itilize Sit la, ki gen ladan atravè yon bot oswa lòt mwayen otomatik, ("Ou" oswa
               “Itilizatè”) se kondisyon sèvis sa yo. Lè w sèvi ak sit la, Ou reprezante ak garanti ke Ou
               ou gen omwen dizwit (18) ane epi ou dakò pou w oblije respekte tout sa ki annapre yo.
               dispozisyon ki nan Kondisyon Sèvis sa yo, ki fòme yon kontra legalman obligatwa ant Ou menm ak
               Rentalfaqz.com. Si w pa dakò ak kondisyon sèvis sa yo, ou entèdi pou w gen aksè
               Sit la epi li dwe imedyatman sispann aksè sa yo.
             </p>

             <h3>Kisa ou ta dwe atann?</h3>
             <p class='size-1'>
               Rentalfaqz.com se yon platfòm plizyè aspè sou entènèt ki bay piblisite, ak sèvis rechèch
               pou founisè sèvis lokasyon chasè apatman, pwopriyetè, ak administratè pwopriyete. Sit sa a fè sa
               pa (a) bay okenn nan sèvis yo dirèkteman epi li pa yon pati nan okenn tranzaksyon ant sèvis yo
               founisè ak kliyan, (b) garanti oswa asire nenpòt sèvis oswa nenpòt tranzaksyon ant yon kliyan
               ak founisè sèvis, (c) fè tès depistaj sou kandida lokasyon, oswa kolekte peman sou
               non kliyan oswa founisè sèvis, oswa (d) aji kòm yon medyatè, pèseptè dèt, oswa rapò sou kredi
               ajans lan, epi li pa garanti okenn rezilta nan itilize sèvis la. Sit la fasilite itilizasyon
               apwovizyone foul moun nan rezo sèvis li yo ki bay yon kont lokatè kòm yon istwa
               rapò pwopriyetè aktyèl la ak ansyen, oswa manadjè pwopriyete a, ki soumèt epi pataje
               nan tout sistèm nan pou rezon tès de pwopriyetè pwopriyetè ak administratè pwopriyete.
             </p>

             <h3>Avètisman!</h3>
             <p class='size-1'>
               Nou ankouraje w anpil pou w enspekte pèsonèlman nenpòt apatman yo anonse pou lwe anvan:
               siyen nenpòt dokiman; bay enfòmasyon pèsonèl; oswa fil elektrik oswa otreman voye
               lajan pou nenpòt depo, oswa peman lwaye. Lè w itilize sit la, ou rekonèt ke lwaye yo pibliye
               ak disponiblite yo sijè a chanje selon sèl diskresyon pwopriyetè pwopriyete a oswa manadjè nan
               nenpòt ki lè epi san lòt avi.
             </p>

             <h3>Materyèl sou sit</h3>
             <p class='size-1'>
               Tout enfòmasyon ak kontni ki disponib sou sit sa a (ansanm, "Materyèl") pwoteje pa
               copyright ak lòt lwa sou pwopriyete entelektyèl. Materyèl yo fèt pou pèsonèl ak
               itilizasyon ki pa komèsyal sèlman. Ou pa gen dwa kopye, repwodui, vann, lisansye, pibliye, distribye, modifye,
               montre, fè, konbine, kreye yon travay derive soti nan, re-post oswa otreman itilize nenpòt pòsyon nan
               Materyèl yo nan nenpòt lòt fason oswa pou nenpòt lòt rezon san konsantman alekri anvan
               RentalFaqz.com. Demann konsènan itilizasyon Materyèl yo pou nenpòt rezon ki pa pèsonèl,
               itilizasyon ki pa komèsyal yo ta dwe dirije nan rentalfaqz@gmail.com, oswa N-10841 Wellington Street,
               Nassau Bahamas.
             </p>

             <h3>Sit Twazyèm Pati</h3>
             <p class='size-1'>
               Pandan vizit ou a sou sit la, ou ka mennen w sou sitwèb twazyèm pati pou ranpli sèten
               karakteristik ak fonksyonalite sit (pa egzanp, si w chwazi wè enfòmasyon kominote a oswa tcheke
               disponiblite yon sèten apatman). Ou dakò ke lè w ap vizite sit entènèt twazyèm pati (pou
               egzanp, pami lòt moun, sit entènèt ki pa genyen "RenalFaqz.com" nan URL la) Ou sijè a
               règleman sou enfòmasyon prive ak kondisyon sèvis, si genyen, nan sitwèb twazyèm pati sa a, ki ka diferan de sa yo
               nan RentalFaqz.com. Lè w chwazi lyen ki mennen nan sitwèb twazyèm pati sa yo, Ou dakò ke w ap
               kite sit la e ke RentalFaqz.com ak afilye li yo pa gen okenn responsablite oswa responsablite
               kèlkeswa sa an koneksyon avèk itilizasyon ou oswa echanj ou nan nenpòt enfòmasyon ak twazyèm pati sa yo
               sit entènèt. RentalFaqz.com ak afilye li yo pa andose, patwone oswa garanti sa yo lye
               sit entènèt epi yo pa responsab nan okenn fason pou nenpòt konsèy, kontni, enfòmasyon, pratik,
               pwodwi oswa sèvis ki gen rapò ak oswa ki disponib atravè sit entènèt lye sa yo.
             </p>
             <h3>Mak komèsyal</h3>
             <p class='size-1'>
               Ou rekonèt ke Materyèl yo gen ladan kèk mak komèsyal, mak sèvis, ak lòt endikasyon
               sous (ansanm "Mak") ki posede pa RetalFaqz.com, afilye li yo, Lisansye ak lòt moun.
               Ou dakò pou pa kopye, itilize, vyole oswa dilye Mak yo. Ou dakò anplis ke Ou pa pral chanje oswa
               retire nenpòt mak, avi copyright oswa lòt avi nan Materyèl yo oswa nenpòt kopi ladan l. La
               Non ak logo “RentalFaqz.com” se mak komèsyal RentalFaqz.com. Lòt pwodwi ak konpayi
               non sa yo ka mak komèsyal pwopriyetè respektif yo.
             </p>

             <h3>Konteni Itilizatè</h3>
             <p class='size-1'>
               Lè w soumèt anons, foto, deskripsyon, done, oswa lòt enfòmasyon ("Contenu") sou sit la nan
               koneksyon ak yon pwopriyete, ou bay RentalFaqz.com, afilye li yo, ak paran li yo ak
               konpayi ki gen rapò ak redevances-gratis, tout tan, irevokabl, non-exclusive, totalman transfere, totalman
               dwa ak lisans sou lisans pou kopye, modifye, montre, distribye, fè, kreye derive
               travay soti nan, magazen, ak otreman itilize ak eksplwate tout kontni sa yo nan nenpòt fòm, medya, lojisyèl oswa
               teknoloji nenpòt kalite ki egziste kounye a oswa devlope nan tan kap vini an. Ou plis akòde pou
               RentalFaqz.com, afilye li yo, ak paran li yo ak konpayi ki gen rapò, yon dwa ak lisans gratis
               pou itilize non ou, imaj ou ak resanblans an koneksyon avèk repwodiksyon oswa distribisyon an
               Kontni. Lè w soumèt nenpòt kontni atravè sit la, ki gen ladan, men pa limite a, atravè nenpòt plon
               fòm, ou dakò ke RentalFaqz.com, yon pwopriyetè, yon manadjè pwopriyete oswa lòt moun ki sanble oswa
               antite ka kontakte w pa imel, mesaj tèks, telefòn oswa otonumè pou nenpòt rezon. Sit la kapab
               pèmèt Ou volontèman soumèt Kontni ki gen rapò ak Ou, ki gen ladan revni total ou chak mwa oswa
               nenpòt lòt ("Enfòmasyon Konsomatè yo bay"). RentalFaqz.com kolekte enfòmasyon sa a pou
               rezon enfòmasyon sèlman epi RentalFaqz.com oswa kliyan li yo pa itilize li
               pwopriyetè, ajan lis oswa pwopriyetè ("Pwopriyete" ak/oswa "Pwopriyete") pou pran yon desizyon pou lwe yon
               pwopriyete pou ou. Lè w bay enfòmasyon Konsomatè yo bay, ou rekonèt sa
               RentalFaqz.com ka pataje enfòmasyon Konsomatè-bay sa yo ak Pwopriyete ki ofri inite pou
               lwe atravè sit la sijè a kondisyon sa yo nan sèvis. Nenpòt moun k ap resevwa Konsomatè sa yo
               Enfòmasyon yo dakò ke nenpòt Enfòmasyon Konsomatè yo bay yo ka pa egzat, pa ta dwe
               te konte sou pou nenpòt rezon epi yo pa ka itilize pou pran yon desizyon sou si yo lwe yon
               apatman bay yon moun an patikilye. Sit la ka ofri opòtinite pou Itilizatè ki gen 18 an ak
               pi gran pou soumèt Kontni ki eksprime opinyon yo epi pataje lide yo, pa egzanp nan yon
               fòma blog konsomatè ("Opinyon Kontni"). RentalFaqz.com ak afilye li yo pa andose
               presizyon oswa fyab nan nenpòt kontni Opinyon. Si w deside soumèt Kontni Opinyon, tanpri itilize
               pi bon jijman ou epi respekte lòt moun. Lè w poste oswa soumèt Opinyon
               Kontni, Ou dakò pou respekte Kondisyon Sèvis sa yo. Ou pa gendwa sèvi ak sit la pou w patisipe nan okenn
               Aktivite ki entèdi (jan sa defini pi ba a). Téléchargement materyèl ki gen dwadotè oswa lòt propriétaires
               nenpòt kalite sou sit la san pèmisyon eksprime pwopriyetè a nan materyèl sa a entèdi ak
               ka lakòz responsablite sivil ak/oswa kriminèl.
               <br/><br/>
               Si Ou se yon kliyan ki se yon Pwopriyete k ap trete tranzaksyon peman lokasyon atravè Sèvis la
               (jan sa defini pi ba a), Ou dwe bay non legal ou ak/oswa non konpayi legal ou, pèsonèl
               oswa enfòmasyon kontak biznis, ak nimewo NIB (yo ("Enfòmasyon Referans"), si yo mande yo.
               RentalFaqz.com ka kolekte epi kenbe Enfòmasyon Referans yo, ansanm ak kont ou
               enfòmasyon ak kantite peman ak kantite tranzaksyon peman Oumenm oswa Ou
               pwosesis biznis atravè Sèvis la, pou peman ou yo ka rapòte bay Entèn la
               Sèvis Revni lè lalwa egzije sa.
             </p>
             <h3>Dwa RentalFaqz.com</h3>
             <p class='size-1'>
               Etandone nati sit la ak volim mesaj ak afichaj, eksepte si yo bay sa
               nan Kondisyon Sèvis sa yo oswa lòt tèm ki aplikab, RentalFaqz.com pa kapab epi li pa kontwole
               tout Kontni ou afiche oswa transmèt pa Ou menm ak lòt founisè enfòmasyon twazyèm pati yo.
               RentalFaqz.com rezève dwa, nan sèl diskresyon li, pou kontwole, refize pibliye, retire,
               efase, deplase oswa modifye nenpòt kontni san avètisman, nenpòt ki lè pou nenpòt ki rezon. Lè w sèvi ak sit la, Ou
               ekspreseman dakò ke RentalFaqz.com (a) pa pral responsab pou okenn reklamasyon, aksyon oswa jijman
               ki soti nan oswa ki gen rapò ak nenpòt kontni ak (b) ka kontwole, refize pibliye, retire, efase,
               deplase oswa modifye nenpòt kontni san avètisman nenpòt ki lè pou nenpòt ki rezon, san responsablite ak san yo pa
               bay yon ranbousman. Ou ekspreseman dakò ke ou se sèl responsab pou nenpòt ak tout
               Kontni Ou soumèt sou sit la.
             </p>

             <h3>Konteni pa mande</h3>
             <p class='size-1'>
               RentalFaqz.com pa aksepte kontni oswa lide ki pa mande pou itilize oswa piblikasyon nan li yo
               pwogramasyon oswa nan lòt medya dijital, elektwonik oswa enprime eksepte an koneksyon avèk fowòm yo,
               chanm chat ak tablo afichaj sou sit la.
             </p>

             <h3>Reprezantasyon / Endemnité</h3>
             <p class='size-1'>
               Ou reprezante, garanti ak dakò ke (a) Ou gen tout otorite, dwa ak tout nesesè
               otorizasyon pou soumèt Kontni an sou sit la, (b) Kontni an pa fè epi li pap vyole oswa
               apwopriye nenpòt copyright, mak, sekrè komèsyal, patant oswa nenpòt lòt pwopriyete entelektyèl oswa
               lòt dwa nenpòt twazyèm pati, ki gen ladan nenpòt dwa sou vi prive oswa piblisite, (c) Kontni an, Referans
               Enfòmasyon ak itilizasyon ou nan sit la pa vyole nenpòt ki aplikab federal, eta, oswa
               lwa oswa règleman lokal ki gen ladan, men pa limite a, nenpòt lwa oswa règleman sou lojman san patipri oswa
               lisans byen imobilye ki aplikab oswa règleman Brokerage, oswa lakòz blesi nan nenpòt moun, (d) la
               Enfòmasyon sou kontni ak referans konplè, veridik, egzat epi yo pa twonpe, € a
               Enfòmasyon sou kontni ak referans yo pa te soumèt pou twonpe, twonpe oswa twonpe
               oswa chèche twonpe, twonpe oswa twonpe nenpòt lòt Itilizatè Sit la, epi (f) Ou pa pral twonpe,
               twonpe, twonpe, chèche twonpe, twonpe oswa twonpe, fè nenpòt defòmasyon oswa chèche
               fè defòmasyon bay nenpòt lòt itilizatè nan sit la. Ou rekonèt ke sa yo
               reprezantasyon yo konsène reyalite ki enpòtan pou RentalFaqz.com. Ou dakò lage, defann,
               dedomaje epi kenbe RentalFaqz.com, afilye li yo, Lisansye yo, ak ofisye respektif yo, direktè yo,
               anplwaye yo, ajan yo ak kontraktè yo inofansif nan ak kont nenpòt ak tout reklamasyon, depans, demand oswa
               depans, ki gen ladan frè avoka, ki soti nan (i) nenpòt distribisyon, piblikasyon, refize pibliye,
               retire, efase, mouvman, koreksyon oswa lòt itilizasyon Kontni oswa Enfòmasyon Referans Ou
               bay, (ii) itilizasyon sit la oswa nenpòt depandans sou Materyèl yo, (iii) vyolasyon kondisyon sa yo.
               Sèvis oswa (iv) nenpòt lavant aktyèl, potansyèl oswa sispann, kontra lwaye oswa lòt tranzaksyon ant Ou
               ak yon twazyèm pati.
             </p>
             <h3>Aktivite ki entèdi</h3>
             <p class='size-1'>
               Ou pa dwe:
               <ul class='size-1'>
                 <li class='mb-1'>
                   Soumèt kontni ki patant ofansif nan kominote a sou entènèt, tankou kontni ki
                   reflete oswa ankouraje rasis, fanatik, rayisman oswa domaj fizik nenpòt kalite kont nenpòt gwoup
                   oswa moun;
                 </li>
                 <li>
                   Angaje nan aktivite oswa soumèt Kontni ki ta ka danjere pou minè yo;
                 </li>
                 <li class='mb-1'>
                   Angaje nan aktivite oswa soumèt Kontni ki anmède oswa defann asèlman yon lòt moun;
                 </li>
                 <li class='mb-1'>
                   Patisipe nan aktivite ki enplike transmisyon "papò tenten" oswa voye pa lapòs an mas
                   oswa "Spam" bay lòt moun;
                 </li>
                 <li class='mb-1'>
                   Angaje nan aktivite, soumèt Kontni oswa Enfòmasyon Referans, oswa ankouraje enfòmasyon, sa vle di
                   fwod, fo, twonpe, kòrèk ak/oswa konstitye yon defòmasyon, oswa
                   ankouraje aktivite ilegal oswa konduit ki abizif, menasan, obsèn, vilgè,
                   difamatè, difamasyon oswa otreman objeksyon;
                 </li>
                 <li class='mb-1'>
                   Soumèt kontni ki gen paj aksè restriksyon oswa modpas sèlman, oswa paj kache oswa
                   imaj;
                 </li>
                 <li class='mb-1'>
                   Soumèt Kontni ki montre materyèl pònografik oswa seksyèlman eksplisit nenpòt kalite;
                 </li>
                 <li class='mb-1'>
                   Soumèt kontni ki bay enfòmasyon ansèyman sou aktivite ilegal tankou
                   fè oswa achte zam ilegal, vyole vi prive yon moun, oswa bay oswa kreye
                   viris òdinatè;
                 </li>
                 <li class='mb-1'>
                   Patisipe nan aktivite oswa soumèt Kontni ki mande modpas oswa ki kapab idantifye pèsonèlman
                   enfòmasyon lòt moun pou rezon ilegal;
                 </li>
                 <li class='mb-1'>
                   Angaje nan aktivite komèsyal ak / oswa lavant san konsantman alekri anvan nou an, tankou
                   konkou, konkou, echanj, piblisite ak plan piramid;
                 </li>
                 <li class='mb-1'>
                   Sèvi ak fòm plon sit la ak/oswa nimewo gratis pou fè piblisite oswa ankouraje pwodwi ak
                   sèvis bay piblisite RentalFaqz.com oswa pou mande piblisite RentalFaqz.com nan nenpòt
                   fason;
                 </li>
                 <li class='mb-1'>
                   Sèvi ak nenpòt robo, Spider oswa lòt aparèy otomatik, oswa yon pwosesis manyèl, pou jwenn aksè, kontwole oswa
                   kopye paj wèb oswa Materyèl ki nan sit la oswa pou nenpòt lòt ki pa otorize
                   objektif;
                 </li>
                 <li class='mb-1'>
                   Sèvi ak nenpòt aparèy, lojisyèl oswa woutin pou entèfere oswa eseye entèfere ak apwopriye a
                   travay sou sit la;
                 </li>
                 <li class='mb-1'>
                   Dekonpile, ranvèse enjenyè, demonte oswa eseye jwenn kòd sous la
                   pou lojisyèl an; oswa
                 </li>
                 <li class='mb-1'>
                   Pran nenpòt aksyon ki enpoze yon chaj ki pa rezonab oswa disproporsyonèlman gwo sou
                   Enfrastrikti pyès ki nan konpitè ak lojisyèl RentalFaqz.com.
                 </li>
               </ul>
             </p>

             <h3>Remèd RentalFaqz.com</h3>
             <p class='size-1'>
               Ou rekonèt ke nenpòt vyolasyon kondisyon sa yo obligatwa nan Sèvis, ki gen ladan san yo pa
               limitasyon restriksyon sou aksè konkiran yo ak seksyon "Materyèl Sit,"
               "Reprezantasyon/Endannman," ak "Aktivite entèdi," oswa nenpòt itilizasyon san otorizasyon nan
               Sit, se yon vyolasyon materyèl akò sa a ant Ou menm ak RentalFaqz.com epi li pral lakòz
               domaj ireparab ak blesi nan RentalFaqz.com, pou ki pa gen okenn remèd adekwat nan
               lalwa. Kidonk, anplis de tout lòt remèd ki disponib nan lalwa oswa nan ekite, Ou dakò ke nan
               evènman an nan vyolasyon sa yo RentalFaqz.com pral gen dwa a enjonksyon oswa lòt ekitab
               sekou, kit ou pa likide domaj ki disponib san egzijans ke
               RentalFaqz.com poste yon kosyon.
               <br/><br/>
               Vyolasyon materyèl nan kondisyon sa yo obligatwa nan Sèvis mal entegrite a, fonksyonalite, ak
               repitasyon RentalFaqz.com; retire konfyans Itilizatè yo nan ak itilizasyon sit la; ak domaj
               biznis RentalFaqz.com. An konsekans, Ou rekonèt epi dakò sa
               RentalFaqz.com fè domaj aktyèl, ki trè difisil oswa enposib
               quantify, kòm yon rezilta nan vyolasyon materyèl sa a obligatwa kondisyon sa yo nan Sèvis. Pou
               evite dout, domaj sa yo pa ta konstitye yon remèd adekwa nan lalwa oswa nan
               nenpòt fason afekte akò ou ke RentalFaqz.com ta gen dwa a enjonksyon oswa lòt
               sekou ekitab nan ka vyolasyon sa a.
             </p>

             <h3>Règleman ranbousman</h3>
             <p class='size-1'>
              Sispann sèvis yo nan premye semèn nan yon abònman achte sa pral lakòz yon ranbousman konplè.
              Apre dezyèm semèn nan, si w sispann sèvis ou pral resevwa mwatye nan lòd pou achte a.
              Tout lavant yo se final apre twazyèm semèn nan, jan ou te rekonèt ak dakò ke ou pa dwe gen dwa a yon ranbousman.
             </p>
 
             <h3>Deklarasyon Garanti</h3>
             <p class='size-1'>
               OU DAKÒ EXPRESMAN KE ITILIZE SIT LA AK DEPANSE SOU KONTENI LI AK
               ENFÒMASYON KONSOMÈ YO BAY SE SOU RISK OU. RENTALFAQZ.COM, ITS
               AFILIE AK TWAZI PATI RESPEKTIF YO PA FÈ Okenn
               REPREZANTASYON OSWA GARANTI NENPÒT KALITE KONSÈNAN SIT LA, LA
               LOGISYÈL, KONTENI A, ENFÒMASYON KONSOMÈ YO BAY, MATERYÈL YO, LA
               SÈVIS SOUMISYON PWOPRIYETE, SÈVIS AKASI APATMAN
               (KOLEKTIVMAN, "SÈVIS LA") OSWA REZILTA YO KE YO KA JWENN NAN ITILIZE
               NAN NENPÒT SA YO. SÈVIS LA BOUNI SOU YON "KÒM YO, JAN LI DISPONIB"
               BASIS, AK RENTALFAQZ.COM, AFILIE LI YO, AK TWAZYÈ PATI RESPEKTIF YO
               LICENSEURS ESPESIFIKMAN REJTE NENPÒT AK TOUT GARANTI EXPRESS OSWA IMPLICITE
               ENKLI, SAN LIMITATION, GARANTI POU APTITE POU YON PARTIKILÈ.
               OBJEKTIF, GARANTI DE MARCHANDABILITE AK GARANTI DE
               NON INFRACTION. RENTALFAQZ.COM AK AFILYE LI FE NON
               REPREZANTASYON OSWA GARANTI, EXPRESSE OSWA IMPLICITE, POU NENPÒT REYEL OSWA
               PWOSPEKTIF LOUYE NENPÒT APATMAN KÒM EGZISTANS LA, PWOPRIYETE OSWA
               KONDISYON APATMAN AN; KÒM DISPONIBlite yo pibliye, LWAYE, LWAYE
               TÈM, DEPO SEKIRITE, OSWA FRÈ APLIKASYON, SI GEN GENYEN; OSWA KÒM PREZIS LA OSWA
               KONPLEITE NENPÒT ENFÒMASYON SOU YON APATMAN KI PARET SOU
               SITE. RENTALFAQZ.COM PA GARANTI PRESIZYON OSWA KONPLETITE
               ENFÒMASYON SA YO. RENTALFAQZ.COM REZÈVE DWA A, NAN SÈL DISKRETÈ LI,
               POU KORIJE NENPÒT ERÈ OSWA OMISYON SOU SIT LA OSWA NAN KONTENIS LA. NENPÒT AK TOUT
               ENKSYON, DIFERANS OSWA DISKREPAANS KONSÈNAN YON APATMAN DWE
               ADRESE AK PWOPRIYETÈ AK/OSWA KONPAYI GESTION PWOPRIYETE AVAN
               POU LWE APATMAN AN. RENTALFAQZ.COM PA FÈ AK EXPRESMAN
               REJTE NENPÒT REPREZANTASYON, GARANTI OSWA GARANTI POU OU KONSÈNAN
               SÈVIS SOUMISYON PWOPRIYETE AK SÈVIS AKASI APATMAN,
               ENKLI, SAN LIMITATION, NENPÒT REPREZANTASYON, GARANTI OSWA
               GARANTI KE OU PRAL LWAYE APAT OU, JWENN YON Lwaye ki akseptab.
               POU APATMAN OU, RESEVWA SÈLMAN DEMANT LEGITIM OSWA SOLLICITASYON
               SOTI LWA KALIFYE, OSWA RESEVWA NENPÒT KENPÒT KONSÈNAN APAT OU
               POU LWE. POU OBJET GARANTI SA A, “TWAZI PATI”
               PA ENKLI OU.
             </p>
             <h3>Limitasyon Responsablite</h3>
             <p class='size-1'>
               RENTALFAQZ.COM, AFILYE LI YO, AK PWOPRIYETE YO RESPECTIF, ANPLWAYE,
               AJAN, KONTRAKTÈ AK TWAZI PATI KI GEN LICANSÈ YO PAP RESPONSAB
               POU NENPÒT DOGAJ OSWA PÈT KI GENYEN, SAN LIMITATION, DIRÈK, ENDRÈK,
               DOMAJ KONSEKANS, ESPESYAL, AK ENSEDAN OSWA PINITIF, KI RESULTE DE OSWA
               KI KOZE PA SÈVIS LA OSWA OTREMAN, ENKLI, SAN LIMITATION, DOmaj
               RESULTE DE NEGLIJANS. NAN OKENN KA PRAL MAKSIMI AGREGAT LA
               RESPONSABILITE RENTALFAQZ.COM, AFILIE LI YO, AK PWOPRIYETE RESPEKTIF YO,
               ANPLWAYE, AJAN, KONTRAKTÈ AK TWAZI PATI POU NENPÒT REKLAMASYON
               KI DEZITE OSWA AN KONEKSYON AK OU ITILIZE SÈVIS LA DEPÈ A.
               KANTITE TOTAL FRÈ OU AKTYÈLMAN PEYE POU RENTALFAQZ.COM. NEGASYON AN
               DE DOmaj ki tabli pi wo pase se yon eleman fondamantal nan baz la
               BAY ANT RENTALFAQZ.COM AK OU. SÈVIS LA PA TA
               BAY OU SAN LIMITAYON SA YO. PA KONSÈY OUBYEN ENFÒMASYON,
               KIT ORAL OSWA EKRI, OU JWENN NAN RENTALFAQZ.COM KREYE
               NENPÒT GARANTI, REPREZANTASYON AK/OSWA GARANTI KI PA EKSPLITE DEKRI NAN
               AKÒ SA A. POU LIMITYON RESPONSABILITE SA A, “TWAZI PATI
               LICENSOR” PA ENKLI OU.
             </p>

             <h3>Revokasyon Sèvis</h3>
             <p class='size-1'>
               Ou konprann epi dakò ke nan sèl diskresyon Rentalfaqz.com a, epi san avètisman davans,
               aksè ou a nan sit sa a ka sispann oswa sispann, ak RentalFaqz.com ka fè egzèsis
               nenpòt lòt remèd ki disponib epi retire nenpòt kontni, si li kwè ke ou sèvi ak sit la
               ak/oswa nenpòt ki kontni oswa enfòmasyon referans ou bay (a) vyole (i) kondisyon sa yo
               Sèvis, (ii) dwa RentalFaqz.com, afilye li yo, yon Lisans, oswa yon lòt Itilizatè, oswa (iii)
               nenpòt lwa oswa règleman, oswa yo otreman objeksyon oswa apwopriye oswa (b) konstitye
               aktivite fwod oswa ilegal nenpòt nati. Ou dakò ke domaj monetè pa ka
               bay RentalFaqz.com yon remèd ase pou vyolasyon Regleman Sèvis sa yo, epi
               Ou dakò ak enjonksyon oswa lòt sekou ekitab pou vyolasyon sa yo san yo pa
               egzijans pou RentalFaqz.com poste yon kosyon. RentalFaqz.com pa oblije bay
               nenpòt ranbousman ba Ou si itilizasyon ou sispann kòm rezilta detèminasyon RentalFaqz.com a, nan
               sèl diskresyon li, ke Ou te vyole Kondisyon Sèvis sa yo.
             </p>

             <h3>Anonsansyon</h3>
             <p class='size-1'>
               Ou konprann epi dakò ke nenpòt moun oswa antite ki vyole nenpòt tèm sa yo
               Kondisyon Sèvis yo anpeche yo aplike, dakò pou yo pa aplike, ak alyans pou yo pa fè
               lajistis pou fè respekte, nenpòt dispozisyon ki sanble oswa ki gen rapò nan nenpòt kontra, ki gen ladan tèm ak
               kondisyon, kondisyon itilizasyon ak kondisyon sèvis, afime pa moun sa yo, antite oswa nenpòt ki
               afilye li, kòm obligatwa pou RentalFaqz.com oswa nenpòt nan afilye li yo.
             </p>

             <h3>Divès</h3>
             <p class='size-1'>
               Kondisyon Sèvis sa yo ka chanje nenpòt ki lè epi w ap fè w konnen nenpòt sa yo
               chanjman pa yon afichaj mete ajou nan nouvo Kondisyon Sèvis yo sou paj sa a nan sit la. Ou
               kontinye sèvi ak sit la apre afiche nenpòt ki kondisyon ki amande nan sèvis yo dwe konstitye
               akò ou a dwe mare pa nenpòt chanjman sa yo. RentalFaqz.com ka modifye, sispann,
               sispann oswa mete restriksyon sou itilizasyon nenpòt pati nan sit la san avètisman oswa responsablite. Ou menm
               sa a dakò ke nenpòt kòz aksyon Ou ka genyen konsènan sit la dwe
               depoze sèlman nan tribinal siprèm ki nan New Providence Bahamas, nan yon (1)
               ane apre kòz aksyon an rive oswa kòz la entèdi. Ou konsantman pou pèsonèl
               jiridiksyon anba tribinal siprèm New Providence Bahamas, epi anile nenpòt ki
               objeksyon ki baze sou fowòm ki pa pratik. Kòm yon kondisyon pou itilize sit sa a, ou dakò sa
               tout kòz aksyon ki soti nan oswa ki konekte ak sit sa a dwe rezoud endividyèlman,
               san yo pa pran okenn fòm aksyon kolektif. Si pou nenpòt rezon yon tribinal ki gen jiridiksyon konpetan
               jwenn nenpòt dispozisyon nan Kondisyon sa yo nan Sèvis, oswa yon pati ladan l, yo pa ka aplike, sa
               dispozisyon kondisyon sa yo nan sèvis yo pral aplike nan limit maksimòm akseptab konsa
               kòm afekte entansyon pati yo, ak rès la nan kondisyon sa yo nan Sèvis pral
               kontinye nan tout fòs ak efè. RentalFaqz.com pa fè respekte nenpòt dispozisyon sa a
               Kondisyon Sèvis yo p ap konsidere kòm yon egzansyon nan aplikasyon sa a oswa nenpòt lòt nan lavni
               dispozisyon pou Kondisyon Sèvis sa yo. Kondisyon Sèvis sa yo ak nenpòt lòt tèm ak
               kondisyon oswa règleman ki aplike nan itilizasyon ou nan sit la, konstitye akò a tout antye
               ant pati yo konsènan sijè sa a. Pa gen anyen ki nan la a pral
               entèprete kòm kreye yon patenarya, yon antrepriz oswa yon relasyon ajans oswa akòde yon
               franchiz ant pati yo. Malgre nenpòt bagay ki di okontrè sa a, si Ou menm ak
               RentalFaqz.com te antre nan yon kontra separe piblisite ekri, kondisyon yo ak
               kondisyon kontra sa yo ap kontwole anrapò ak nenpòt tèm ki pa konsistan avèk yo
               Kondisyon Sèvis sa yo. SI OU PA DAKÒ AK KONDISYON YO DEKRI PI WO A OSWA NENPÒT
               CHANJMAN NAN KONDISYON SÈVIS SA YO, TANPRI SOTI SÈVIS LA IMEDYAT.
               <br/><br/>
               © 2023 RentalFaqz.com, Inc., N-10841 New Providence Bahamas
               <br/><br/>
               Dènye mizajou: 12 me 2023
             </p>`,
          privacyText: `<h3>Deklarasyon sou vi prive</h3>
             <p class='size-1'>
               Avi Konfidansyalite sa a dekri kalite Enfòmasyon Pèsonèl nou kolekte ak pratik nou pou yo
               itilize, kenbe, pataje, ak pwoteje li. Li dekri tou dwa ak chwa ou ka genyen
               konsènan Enfòmasyon Pèsonèl ou ak fason ou ka kontakte nou konsènan pratik konfidansyalite nou yo.
               Pratik konfidansyalite nou yo ka varye pami peyi kote nou opere pou reflete pratik lokal yo
               ak egzijans legal, ak pratik konfidansyalite espesifik ka aplike nan kèk nan pwodwi nou yo ak
               sèvis yo.
               <br/><br/>
               Lè w ap itilize sit entènèt nou yo, ou ka chwazi kominike avèk karakteristik twazyèm pati ki opere
               endepandamman de RentalFaq.com, tankou widgets medya sosyal ak lyen ki mennen nan sitwèb twazyèm pati.
               RentalFaqz.com pa gen okenn kontwòl sou epi li pa responsab pou pratik konfidansyalite twazyèm sa yo
               pati yo. Avi Konfidansyalite sa a pa aplike nan limit RentalFaqz.com pa posede oswa kontwole
               nenpòt sit entènèt ki lye oswa karakteristik ou vizite oswa itilize. Nou rekòmande ke ou familyarize tèt ou ak
               pratik sou vi prive twazyèm pati sa yo.
             </p>

             <h3>Table of Contents</h3>
             <p class='size-1'>
               <ol class='size-1'>
                 <li class='mb-1'>Kilte Enfòmasyon Pèsonèl</li>
                 <li class='mb-1'>Koleksyon Enfòmasyon Pèsonèl</li>
                 <li class='mb-1'>Itilizasyon Enfòmasyon Pèsonèl</li>
                 <li class='mb-1'>Divilgasyon Enfòmasyon Pèsonèl</li>
                 <li class='mb-1'>Transfè Entènasyonal Enfòmasyon Pèsonèl</li>
                 <li class='mb-1'>Sekirite Enfòmasyon Pèsonèl</li>
                 <li class='mb-1'>Retansyon Enfòmasyon Pèsonèl</li>
                 <li class='mb-1'>Jere Enfòmasyon Pèsonèl Ou</li>
                 <li class='mb-1'>Chanjman Avi Règleman Global</li>
                 <li class='mb-1'>Kontakte Nou</li>
               </ol>
             </p>
             
             <h3>1. Kalite Enfòmasyon Pèsonèl</h3>
             <p class='size-1'>
               "Enfòmasyon Pèsonèl" vle di nenpòt enfòmasyon dirèkteman oswa endirèkteman ki gen rapò ak yon idantifye oswa
               moun ki idantifye. Men kèk egzanp enfòmasyon pèsonèl RentalFaqz.com ka kolekte genyen ladan yo:
               <ul class='size-1'>
                 <li class='mb-1'>
                   Enfòmasyon sou peman (kat kredi ak lòt enfòmasyon sou kat peman)
                 </li>
                 <li class='mb-1'>
                   Enfòmasyon pèsonèl kontak (non, tinon, sèks, adrès postal, adrès imel,
                   nimewo telefòn)
                 </li>
                 <li class='mb-1'>
                   Enfòmasyon biznis (kote biwo, enfòmasyon kontak biznis, depatman, travay
                   tit/deskripsyon, biyo pwofesyonèl)
                 </li>
                 <li class='mb-1'>
                   kalifikasyon pou konekte (non itilizatè, modpas)
                 </li>
                 <li class='mb-1'>
                   Detay sou itilizasyon (aktivite konekte, tèm rechèch, opinyon, klik, telechaje, soumèt plon
                   enfòmasyon)
                 </li>
                 <li class='mb-1'>
                   Detay teknik (adrès IP, enfòmasyon jeolokalizasyon, ID bonbon/sesyon, otantifikasyon
                   enfòmasyon, kalite navigatè/vèsyon, sistèm operasyon/platfòm)
                 </li>
                 <li class='mb-1'>
                   Nenpòt lòt enfòmasyon oumenm oswa lòt kliyan ka telechaje oswa antre nan Sèvis yo
                 </li>
                 <li class='mb-1'>
                   Anrejistreman apèl nan telefòn ak videyo konferans
                 </li>
               </ul>
             </p>
             <h3>2. Koleksyon enfòmasyon pèsonèl</h3>
             <p class='size-1'>
               RentalFaqz ka kolekte enfòmasyon pèsonèl lè l sèvi avèk metòd sa yo:
               <ul class='size-1'>
                 <li class='mb-1'>
                   Dirèkteman nan men ou lè ou ba nou li (enfòmasyon ou antre nan fòm entènèt,
                   chatbots, patisipasyon nan apèl telefòn oswa konferans videyo, akò abònman, ak
                   kontra oswa lòt kontni ou soumèt bay Sèvis yo)
                 </li>
                 <li class='mb-1'>
                   Atravè patisipasyon w nan sondaj, pwomosyon, ak konkou
                 </li>
                 <li class='mb-1'>
                   RentalFaqz entèn sistèm jesyon relasyon kliyan ak baz done
                 </li>
                 <li class='mb-1'>
                   Soti nan twazyèm pati, tankou analytics ak founisè sèvis maketing imel
                 </li>
                 <li class='mb-1'>
                   Rechèch sou sit entènèt piblik kote Kondisyon Itilizasyon yo pèmèt (sit entènèt konpayi yo,
                   motè rechèch, medya sosyal)
                 </li>
                 <li class='mb-1'>
                   Otomatikman atravè teknoloji swiv (bonbon, baliz entènèt, dosye log), ki gen ladan sou
                   tan ak atravè sit entènèt twazyèm pati oswa lòt sèvis sou entènèt
                 </li>
               </ul>
             </p>
            
             <h3>3. Itilizasyon Enfòmasyon Pèsonèl</h3>
             <p class='size-1'>
               Nou ka itilize enfòmasyon pèsonèl ou pou rezon sa yo:
               <ul class='size-1'>
                 <li class='mb-1'>
                   Kominike avèk ou
                 </li>
                 <li class='mb-1'>
                   Bay ou ak Sèvis yo
                 </li>
                 <li class='mb-1'>
                   Otantifye itilizasyon, detekte potansyèl itilizasyon fwod, ak otreman kenbe la
                   sekirite nan Sèvis yo
                 </li>
                 <li class='mb-1'>
                   Devlope, teste, amelyore, ak demontre Sèvis yo
                 </li>
                 <li class='mb-1'>
                   Kreye ak kenbe yon sistèm jesyon relasyon kliyan, anyè manm,
                   ak lis envitasyon pou evènman yo
                 </li>
                 <li class='mb-1'>
                   Ranfòse obligasyon legal ak kontra nou yo epi fè respekte dwa nou yo, enkli bòdwo
                   ak pwosesis peman
                 </li>
                 <li class='mb-1'>
                   Anonymize ak rasanble enfòmasyon pou analiz ak rapò
                 </li>
                 <li class='mb-1'>
                   Piblisite, maketing, ak vann Sèvis yo, ki gen ladan lyen ansanm oswa fusion
                   Enfòmasyon pèsonèl ak lòt enfòmasyon pèsonèl pou nou ka pi byen konprann
                   bezwen ou yo epi enfòme w sou Sèvis nou yo ak sa yo ki nan patnè nou yo
                 </li>
                 <li class='mb-1'>
                   Itilizasyon pasajè a kout tèm, jan sa defini nan California Consumer Privacy Act
                 </li>
                 <li class='mb-1'>
                   Fòmasyon ak asirans kalite
                 </li>
                 <li class='mb-1'>
                   Pou nenpòt lòt rezon ak konsantman ou
                 </li>
               </ul>
             </p>
             <h3>4. Divilgasyon enfòmasyon pèsonèl</h3>
             <p class='size-1'>
               Nou ka divilge enfòmasyon pèsonèl:
               <ul class='size-1'>
                 <li class='mb-1'>
                   Pou afilye ak lòt antite nan gwoup konpayi yo, patnè distribisyon, lòt
                   kliyan, ak founisè sèvis yo
                 </li>
                 <li class='mb-1'>
                   Pou twazyèm pati lè ou itilize yon karakteristik twazyèm pati nan Sèvis nou yo
                 </li>
                 <li class='mb-1'>
                   Pou aplike oswa aplike Kondisyon Itilizasyon nou yo ak lòt akò oswa konfòme yo ak nenpòt lòd tribinal,
                   lwa, oswa pwosesis legal, ki gen ladan demann gouvènman oswa regilasyon
                 </li>
                 <li class='mb-1'>
                   Pou yon achtè oswa lòt siksesè nan ka yon fizyon, devestisman, restriktirasyon,
                   reòganizasyon, yap divòse, oswa lòt vann oswa transfè kèk oswa tout byen RentalFaqz.com
                 </li>
                 <li class='mb-1'>
                   Pou konseye, konsiltan, oditè, oswa pwofesyonèl menm jan an pou evalye biznis nou an, finansye,
                   ak obligasyon legal oswa operasyon
                 </li>
                 <li class='mb-1'>
                   Si nou kwè li nesesè pou pwoteje dwa, pwopriyete, oswa sekirite RentalFaqz.com oswa
                   lòt moun
                 </li>
                 <li class='mb-1'>
                   Pou nenpòt lòt rezon nou divilge lè ou bay Enfòmasyon Pèsonèl
                 </li>
                 <li class='mb-1'>
                   Avèk konsantman ou, tankou lè ou chwazi bay enfòmasyon ou bay twazyèm pati
                   Nou ka divilge enfòmasyon ki pa idantifye tou san restriksyon. Remake byen ke si ou fè nenpòt
                   Enfòmasyon pèsonèl ki disponib piblikman sou Sèvis yo, nenpòt moun ka wè epi itilize enfòmasyon sa yo.
                 </li>
               </ul>
             </p>


             <h4><u>Lis Sèvis</u></h4>
             <p class='size-1'>
               RentalFaqz ka pataje enfòmasyon manm nan domèn sa yo sou Sèvis yo: Maketing
               Sant, Alèt Imèl ak Anyè Pwofesyonèl. Lè yon kliyan antre nan yon lis vann oswa lokasyon nan la
               Maketing Center, lis enfòmasyon kontak tou antre. Pandan pwosesis sa a, lis la
               moun ki soumèt kapab wè enfòmasyon kontak lòt kliyan lè li ajoute yon lòt kontak
               nan lis la. Anplis de sa, kliyan yo ka wè enfòmasyon kontak (non, imèl, ak telefòn
               nimewo) lè w ap gade yon pwofil lis pwopriyete.
               Sèvis yo ka pèmèt itilizatè yo pataje enfòmasyon kontak youn ak lòt atravè alèt imel.
               Avèk alèt imel, itilizatè yo ka sove kritè rechèch pwopriyete yo pou gen enfòmasyon sou pwopriyete yo
               ki matche ak kritè espesifye yo voye ba yo imèl. Lè w ap chwazi sèvis sa a, yo bay itilizatè yo
               opsyon pou yo divilge enfòmasyon kontak yo bay lòt itilizatè ki gen lis pwopriyete ki matche ak
               kritè yo. Enfòmasyon kontak sa a Lè sa a, pataje ak itilizatè yo ki gen lis pwopriyete ki
               matche ak kritè achte yo.
             </p>

             <h4><u>RentalFaqz.com</u></h4>
             <p class='size-1'>
               Si w chwazi pou sèvi ak sèvis lis gratis sitwèb la pou fè piblisite yon pwopriyete, li enpòtan sonje
               ke nenpòt enfòmasyon ou chwazi pou mete nan lis ou a ka disponib piblikman atravè
               sit entènèt ak lòt medya kote lis sit entènèt yo pibliye, ki gen ladan sit afilye yo. Pou
               egzanp, ou ka mete yon nimewo telefòn ak/oswa yon adrès imel pou pèmèt itilizatè yo kontakte ou
               konsènan lis ou a. RentalFaqz.com pa responsab pou apèl telefòn ak imèl ki pa mande yo
               ou ka resevwa nan men twazyèm pati, menm apre lis ou a pa sou sit entènèt la ankò. Eksepte pou
               enfòmasyon ki nesesè yo, ou pa ta dwe mete okenn kontak oswa lòt enfòmasyon nan lis ou a
               ou pa vle lòt pati yo wè oswa itilize.
             </p>
            
             <h3>5. Transfè Entènasyonal Enfòmasyon Pèsonèl</h3>
             <p class='size-1'>
               Nou ka transfere Enfòmasyon Pèsonèl ou Ozetazini, ak nan lòt peyi ki kapab
               pa gen menm lwa sou vi prive ak peyi kote ou te bay enfòmasyon an okòmansman, men
               nou pral pwoteje Enfòmasyon Pèsonèl ou an akò ak Avi sou Konfidansyalite Global sa a, oswa kòm
               otreman divilge ou.
             </p>
             <h4><u>Rezidan Zòn Ekonomik Ewopeyen an (EEA) ak rezidan Wayòm Ini</u></h4>
             <p class='size-1'>
               Si w ap viv nan EEA oswa Wayòm Ini, nou ka transfere enfòmasyon pèsonèl nan peyi pou
               ki desizyon konpetans yo te bay, sèvi ak pwoteksyon kontra pou transfè Pèsonèl la
               Enfòmasyon bay twazyèm pati, tankou yon akò andedan konpayi oswa Komisyon Ewopeyen an
               Klaz Kontra Standard oswa ekivalan yo dapre lalwa ki aplikab yo.
             </p>

             <h4><u>Rezidan Kanadyen</u></h4>
             <p class='size-1'>
               Nou gendwa itilize founisè sèvis ki andeyò Kanada, tankou, san limitasyon, nan Etazini
               Etazini, pou trete Enfòmasyon Pèsonèl, epi yo ka transfere Enfòmasyon Pèsonèl ou a
               founisè sèvis sa yo pou objektif sa a. Lè yo trete enfòmasyon pèsonèl ou deyò
               Kanada, li pral sijè a lwa yo nan peyi yo kote enfòmasyon yo trete epi yo ka
               dwe aksesib a lapolis ak otorite sekirite nasyonal peyi sa yo an akò
               ak lwa yo.
             </p>

             <h3>6. Sekirite Enfòmasyon Pèsonèl</h3>
             <p class='size-1'>
               RentalFaqz aplike sekirite sekirite pou pwoteje enfòmasyon pèsonèl ou.
               Nou pran mezi pou sekirize Enfòmasyon Pèsonèl atravè administratif, teknik ak fizik
               pwoteksyon ki fèt pou pwoteje kont risk destriksyon aksidan, ilegal oswa san otorizasyon,
               pèt, chanjman, aksè, divilgasyon oswa itilizasyon. Pou egzanp, nou ankripte enfòmasyon sou kat peman an
               an akò ak Kat Peman endistri Done Sekirite Standard (PCI DSS) ak magazen sansib
               enfòmasyon tankou done jeolokalizasyon sou sèvè nou yo ki sitiye nan Etazini ak aksè limite
               bay anplwaye RentalFaqz otorize yo.
               Malerezman, nou pa ka garanti sekirite enfòmasyon ki transmèt atravè Entènèt la,
               epi kote nou te ba ou (oswa kote ou te chwazi) yon modpas, se ou ki responsab
               kenbe modpas sa a konfidansyèl.
             </p>

             <h3>7. Konsèvasyon enfòmasyon pèsonèl</h3>
             <p class='size-1'>
               RentalFaqz.com ap kenbe enfòmasyon ou sèlman pou toutotan li rezonab nesesè pou
               objektif ki tabli nan Avi Konfidansyalite sa a, pou osi lontan ke kont ou a aktif, oswa jan sa nesesè pou bay
               Sèvis yo ba ou. Si ou pa vle nou sèvi ak enfòmasyon ou yo ankò pou ba ou Sèvis yo,
               ou ka fèmen kont ou. RentalFaqz.com pral kenbe epi sèvi ak enfòmasyon ou yo nan limit la
               nesesè pou respekte obligasyon legal nou yo, rezoud diskisyon, aplike akò nou yo, ak kòm
               otreman ki dekri nan Avi Konfidansyalite sa a. Tanpri sonje ke fèmen yon kont ka pa fè ou
               adrès imel, non itilizatè, oswa non pwopriyete (si genyen) ki disponib pou itilize ankò sou yon nouvo kont. Nou menm tou
               kenbe dosye log pou rezon analiz entèn yo. Fichye log sa yo konsève pou yon peryòd tan kout,
               eksepte nan ka kote yo itilize yo pou pwoteje sekirite ak sekirite Sèvis yo, pou kenbe
               ak amelyore fonksyonalite Sèvis yo, oswa konfòme yo ak obligasyon legal yo.
             </p>

             <h3>8. Jere enfòmasyon pèsonèl ou</h3>
             <p class='size-1'>
               Ou ka jere Enfòmasyon Pèsonèl ou lè w fè sèten chwa. Si ou kwè ke nenpòt
               Enfòmasyon Pèsonèl ou te bay nou yo demode oswa yo pa kòrèk, ou ka revize ak
               korije enfòmasyon sa yo si li nan kont sou entènèt ou a lè w konekte nenpòt ki lè oswa kontakte nou.
               Ou ka gen dwa adisyonèl tou ki detaye pi ba a selon kote w ap viv la. Kòm yon sekirite
               prekosyon, nou ka bezwen mande w enfòmasyon pou ede nou konfime idantite w lè
               egzèse yon dwa ki gen rapò ak enfòmasyon pèsonèl ou.
             </p>
             <h4><u>Dezaktivman pou maketing dirèk</u></h4>
             <p class='size-1'>
               Si ou pa vle resevwa imèl maketing dirèk nan men nou ankò, ou ka klike sou "Dezabònman".
               lyen nan yon imèl nenpòt ki lè pou sispann resevwa imèl pou sijè sa a an patikilye. Tou depan de la
               Sèvis, ou ka kapab tou jere preferans notifikasyon nan kont ou. Remake byen ke nou ka
               toujou kontakte w konsènan Kondisyon Itilizasyon yo, Avi sou Konfidansyalite Global, oswa lòt avi legal, oswa nan
               koneksyon ak bay ou Sèvis ou te mande nan men nou.
             </p>

             <h4><u>Swiv/Dezaktivman Piblisite sou Entènèt</u></h4>
             <p class='size-1'>
               Ou ka enfim oswa efase cookies navigatè atravè paramèt navigatè w la. Bonbon yo jeneralman
               fasil pou enfim oswa efase, men metòd la varye ant navigatè. Pou aprann kijan ou ka jere
               Flash cookies, vizite paj Adobe Flash Player paramèt. Si ou enfim oswa efase bonbon, oswa si ou se
               kouri lojisyèl twazyèm pati ki entèsepte oswa efase bonbon, tanpri sonje ke kèk pati nan nou an
               sit entènèt yo ka pa travay byen.
             </p>

             <h3>9. Chanjman nan Avi Konfidansyalite Global</h3>
             <p class='size-1'>
               Avi Konfidansyalite sa a ka mete ajou detanzantan pou reflete chanjman nan pratik konfidansyalite nou yo.
               N ap poste chanjman nan paj sa a epi n ap idantifye dat dènye avi sou enfòmasyon prive yo te revize anlè an
               nan paj la. Si nou fè chanjman materyèl nan pratik konfidansyalite nou yo, n ap fè w konnen atravè yon
               avi sou paj dakèy sitwèb yo ki kouvri nan Avi Konfidansyalite sa a. Si yon chanjman materyèl ap
               aplike nan Enfòmasyon Pèsonèl nou te deja kolekte, ki gen ladan nenpòt nouvo rezon pou yo
               Enfòmasyon pèsonèl yo pral itilize oswa divilge ke ou pa te deja konsanti, nou pral
               Anplis de sa, chèche konsantman afimatif ou.
             </p>

             <h3>10. Kontakte nou</h3>
             <p class='size-1'>
               Pou byen reponn kesyon sou enfòmasyon prive w yo oswa demann sou sèvis nou yo, tanpri voye yon imèl
               nou nan <a href="mailto:rentalfaqz@gmail.com">rentalfaqz@gmail.com</a>, oswa rele nou nan: <a href="tel:+12428202234">(242) 820 -2234</a>
             </p>`,
          f_terms: "Lè m enskri, mwen aksepte",
          f_termsUse: "Tèm itilizasyon.",
          f_termsProperty: `Lè w klike sou Add Property/Add Unit pi wo a, mwen dakò pou m bay enfòmasyon ki egzat epi ki pa diskriminatwa.
          epi mwen pral respekte`,
          f_termsConditions: "Tèm ak kondisyon",
          f_termsService: "Ajoute yon pwopriyete Kondisyon Itilizasyon.",
          f_termsConjuction: "ak la",

          // Misc
          prev: "Previous",
          next: "Swivan",
          vmore: "Gade plis",
          f_ok: "Tout dwa",
          actionFailed: "Aksyon: echwe",
        }
      },
      fr: {
        translation: {
          // Error
          fileBig: "Taille de fichier trop grande (taille maximale autorisée: 3 mégaoctets)",

          // Navbar
          login: "Connexion",
          signup: "S'inscrire",
          featured: "Mis en exergue",
          searchAdvanced: "Recherche Avancée",
          about: "À propos de nous",
          contact: "Contactez-nous",
          notifications: "Avis",
          myAccount: "Mon compte",

          // About Page
          terms: "Termes et conditions",
          privacy: "Déclaration de confidentialité",


          // Homepage
          header: "Découvrez de nouveaux départs",
          header2: "De nouvelles raisons, de nouvelles aventures, de nouveaux souvenirs.",
          header3: "Trouvez votre ajustement parfait dans ",
          search: "Recherche",

          // Login Page
          f_login: "Connectez-vous à votre compte",
          f_createAcct: "Créer un compte?",
          f_resendEmail: "Renvoyer l'e-mail de confirmation",
          f_forgotPass: "Mot de passe oublié?",

          //  Register New Account Page
          joinCommunity: "Rejoignez notre communauté",
          fullAccess: "Créez un compte pour un accès complet",
          siteUsage: "Comment comptez-vous utiliser ce site ?",
          landLord: "Propriétaire",
          landLordDesc: "Louez des propriétés en publiant des annonces sur ce site.",
          or: "Ou",
          tenant: "Locataire",
          tenantDesc: "Recherchez une propriété pour y vivre, en postulant aux annonces disponibles sur ce site.",
          personInfo: "Informations personnelles",
          requiredFields: "Les champs obligatoires sont mis en évidence avec une couleur de bordure distincte",
          // Form Fields
          f_passwordReset: "Envoyer un e-mail de réinitialisation du mot de passe",
          f_sendEmail: "Envoyer un e-mail",
          f_passwordMatch: "Le mot de passe ne correspond pas à la confirmation!",
          f_fName: "Prénom",
          f_mName: "Deuxième nom",
          f_lName: "Nom de famille",
          f_sex: "Sexe",
          f_opt_male: "Mâle",
          f_opt_female: "Femme",
          f_dob: "Date de naissance",
          f_dobDesc: "Doit être âgé de 18 ans ou plus",
          f_cob: "Pays de naissance",
          f_cor: "Pays de résidence",
          f_nib: "Numéro NIB",
          f_govID: "Importation d'une pièce d'identité gouvernementale",
          f_photo: "Téléchargement de photos",
          f_contact: "Coordonnées",
          f_phone: "Coordonnées téléphoniques",
          f_bank: "Information bancaire",
          f_bankName: "Nom de la banque",
          f_bankBranch: "Bifurquer",
          f_bankTransit: "Transit",
          f_bankAccount: "Compte",
          f_family: "Relations familiales",
          f_relations: "Statut de la relation",
          f_opt_single: "Seul",
          f_opt_married: "Marié",
          f_opt_divorced: "Divorcé",
          f_opt_relationship: "Relation",
          f_spouse: "Vivez-vous avec votre conjoint ?",
          f_haveChildren: "Avez-vous des enfants?",
          f_haveBaby: "Certains de vos enfants sont-ils considérés comme des nourrissons/bébés?",
          f_income: "Informations sur le revenu",
          f_occupation: "Profession",
          f_opt_agriculture: "Agriculture, alimentation et ressources naturelles",
          f_opt_art: "Arts, technologie audio/vidéo et communications",
          f_opt_education: "Éducation et formation",
          f_opt_government: "Gouvernement et administration publique",
          f_opt_hospitality: "Hôtellerie & Tourisme",
          f_opt_IT: "Informatique",
          f_opt_manufacture: "Fabrication",
          f_opt_math: "Sciences, technologie, ingénierie et mathématiques",
          f_opt_construction: "Architecture & Construction",
          f_opt_business: "Gestion et administration des affaires",
          f_opt_finance: "Finance",
          f_opt_health: "Sciences de la santé",
          f_opt_human: "Services à la personne",
          f_opt_law: "Droit, sécurité publique, services correctionnels et sécurité",
          f_opt_marketing: "Marketing, ventes et services",
          f_opt_transportation: "Transport, distribution et logistique",
          f_opt_other: "Autre",
          f_employer: "Employé à",
          f_employmentLength: "Durée de l'emploi en mois",
          f_jobLetter: "Téléchargement de la lettre d'emploi",
          f_workContact: "Personne-ressource au travail",
          f_annualIncome: "Revenu annuel en",
          f_lessThan: "Moins que",
          f_higherThan: "et plus haut",
          f_account: "Information sur le compte",
          f_email: "Adresse e-mail",
          f_backupEmail: "E-mail de secours",
          f_password: "Mot de passe",
          f_passwordConfirm: "Confirmation mot de passe",
          f_declaration: "Les informations fournies sont correctes à ma connaissance",
          f_uploadImage: "Importer des images",
          f_clearImage: "Effacer les images à télécharger",
          f_cancel: "Annuler",
          f_remove: "Retirer",
          f_submit: "Soumettre",
          f_island: "Île",
          f_settlement: "Règlement",
          f_streetAddress: "Adresse de la rue",
          f_PoBox: "Boîte postale",
          f_longitude: "Longitude de Google Maps",
          f_latitude: "Latitude de Google Maps",
          f_desc: "Description",
          f_moveInSpecial: "Spécial emménagement",
          f_disclaimer: "Clause de non-responsabilité",
          f_highlights: "Points forts",
          f_propertyPhoto: "Téléchargements de photos de propriété",
          f_propertyPhotoDesc: "La première photo téléchargée est utilisée comme vignette d'image de propriété",
          f_propertyPhotoDesc2: "Les images surlignées en rouge sont sélectionnées pour être supprimées",
          f_selectPhoto: "Sélectionnez la photo de couverture",
          f_selectPhotoDesc: "L'image surlignée en vert est la photo de couverture sélectionnée",
          f_clear: "Clair",
          f_back: "Dos",
          propertyCategorize: "Catégoriser la propriété dans un groupe",
          f_unit: "Unité",
          f_propertyType: "Type de propriété",
          f_opt_apartment: "Appartement",
          f_opt_bnb: "AirBnB",
          f_opt_home: "Maison",
          f_opt_condo: "Copropriété",
          f_opt_townhouse: "Maison de ville",
          f_sqFt: "Superficie carrée de la propriété",
          f_bedrooms: "Nombre de chambres",
          f_bathrooms: "Nombre de salles de bain",
          f_payment: "Informations de paiement",
          f_fee: "Frais",
          f_deposit: "Dépôt attendu",
          f_depositDesc: "Paiement total attendu du locataire pour le premier mois et frais d'emménagement.",
          f_frequency: "Fréquence de paiement",
          f_opt_once: "Une fois",
          f_opt_daily: "Quotidien",
          f_opt_weekly: "Hebdomadaire",
          f_opt_monthly: "Mensuel",
          f_amenities: "Commodités de la propriété",

          // Confirm Signup Page
          welcome: "Bienvenu",
          signupThanks: ", et merci pour votre inscription!",
          checkEmail: "Merci de consulter vos emails",
          accountLink: "pour le lien d'activation du compte",
          checkSpam: "PS: Si vous ne voyez pas l'e-mail d'activation, veuillez vérifier vos dossiers de courrier indésirable ou de spam!",

          // Property Groups
          propertyGroup: "Informations sur les groupes de propriétés",
          propertyGroupDesc: "Utilisez des groupes de propriétés pour lier des unités locatives si elles se trouvent dans le même complexe d'appartements",
          propertyGroupSelect: "Sélectionnez le groupe de propriétés à modifier",
          propertyGroupEdit: "Modifier le groupe de propriétés",
          propertyGroupCreate: "Créer un nouveau groupe de propriétés",
          propertyGroupName: "Nom de groupe",
          propertyGroupRemove: "Supprimer le groupe",
          propertyGroupUpdate: "Mettre à jour le groupe de propriétés",
          propertyGroupNew: "Nouveau groupe de propriétés",
          propertyGroupShow: "Afficher les informations sur le groupe",

          // Add Property
          addProperty: "Ajouter une nouvelle propriété",
          propertyCreate: "Créer une nouvelle propriété",
          propertyInfo: "Informations sur la propriété",
          propertySingle: "Unité unique",
          propertyMultiple: "Unités multiples",
          propertyUnitDesc: "Choisissez soit une liste d'unités individuelles, soit une liste de complexes d'appartements",
          f_addProperty: "Ajouter une propriété",
          f_addUnit: "Ajouter une unité",

          // Edit Property
          tenantInfo: "Informations sur le locataire",
          appInquiry: "Demandes des candidats",
          appInquiryDesc: "Utilisateurs qui se sont renseignés sur la propriété",
          propertyTenant: "Locataires de la propriété",
          propertyTenantDesc: "Utilisateurs actuellement locataires du bien",
          f_disabled: "Cette propriété est-elle accessible au public?",
          updateProperty: "Mettre à jour la propriété",
          apartmentComplex: "Complexe d'appartements",
          f_applicants: "Candidats",
          f_groups: "Groupes",
          f_info: "Info",

          // Property Payments
          choose_plan: "Choisissez votre formule",
          choose: "Choisir",
          basic: "Basique",
          week: "semaine",
          month: "mois",
          allowed_to_list: "Autorisé à répertorier les propriétés, jusqu'à ",
          allowed_to_list_unlimited: "Autorisé à répertorier un nombre illimité de propriétés",
          premium: "Prime",
          can_accept_tenants: "Peut accepter des locataires en ligne",
          can_accept_payments: "Peut accepter les paiements des locataires en ligne",

          // Property Search
          select_filter: "Sélectionnez les filtres",
          clear_filter: "Effacer les filtres",
          f_sqFt_greater: "Pied carré supérieur à",
          f_price_range: "Échelle des prix",
          f_min: "Le minimum",
          f_max: "Max",
          f_sort_by: "Trier par",
          f_oldest_newest: "Du plus ancien au plus récent",
          f_most_recent: "Le plus récent",

          //Property Forums
          topic_summary: "Résumé du sujet",
          issue_details: "Détails du problème",
          f_submit_req: "Envoyer la demande",
          property_forum: "Forums immobiliers",
          maintenance_req: "Demandes d'entretien",
          new_repair_req: "Nouvelle demande de réparation",
          open: "Ouvrir",
          closed: "Fermé",
          // Selected Property Forum
          repair_req: "Demandes de réparation",
          tenant_inq: "Demandes des locataires",
          // Forum New Comment
          title: "Titre",
          enterText: "Entrez le texte ci-dessous",
          f_reply: "Répondre",
          // Property Forum Display
          f_edit: "Modifier",
          f_attachedImages: "Images jointes",
          f_posted: "posté",
          f_edited: "édité",
          f_replies: "réponses",

          // Favorited Properties
          favoritedProperty: "Propriétés favorites",

          // Property Display Thumb
          t_beds: "lit(s)",
          t_baths: "Bain(s)",
          t_propertyOwned: "Propriété possédée",
          t_yourHome: "Ta maison",
          t_rented: "Loué",
          t_repairsReq: "Demander des réparations / Requêtes",
          studio: "studio",
          one_bed: "1 lit",
          two_beds: "2 lits",
          three_beds: "3 lits",
          studio_one_bed: "studio - 1 lit",
          studio_two_beds: "studio - 2 lits",
          studio_three_beds: "studio - 3 lits",
          studio_studio: "studio - studio",
          one_bed_one_bed: "1 lit - 1 lit",
          one_bed_two_beds: "1 lit - 2 lits",
          one_bed_three_beds: "1 lit - 3 lits",
          two_beds_two_beds: "2 lits - 2 lits",
          two_beds_three_beds: "2 lits - 3 lits",
          three_beds_three_beds: "3 lits - 3 lits",

          // Property  Display
          g_leaveReview: "Laisser un commentaire",
          g_leaveReviewDesc: "Laissez un commentaire détaillant votre séjour dans cette résidence.",
          needToBeResident: "Vous ne pouvez laisser un avis qu'après avoir été résident du bien en question.",
          g_otherUnits: "Autres unités de complexe d'appartements",
          g_noRelatedUnits: "Aucune unité associée n'est disponible.",
          g_bedroom: "chambre à coucher",
          g_apply: "Appliquer",
          g_this: "ce",
          unitFeatures: "Caractéristiques de l'unité",
          g_ownerOptions: "Options de propriété du propriétaire",
          g_residentOptions: "Options de propriété pour les résidents",
          g_tenantForums: "Forums des locataires",
          g_editProperty: "Modifier la propriété",
          g_removeProperty: "Supprimer la propriété",
          g_rentalProperty: "Propriété locative",
          g_waterIncluded: "Eau incluse",
          g_internetIncluded: "Internet inclus",
          g_electricityIncluded: "Électricité incluse",
          g_utilities: "Utilitaires",


          // Property Group Display
          g_verifiedRenter: "Locataire vérifié",
          needToRegister: "Vous devez être un utilisateur enregistré pour utiliser cette fonctionnalité!",
          needToSignIn: "Veuillez vous enregistrer ou créer un compte.",
          gPricingUnit: "Prix et unités",
          g_all: "Tout",
          g_studio: "Studio",
          g_inquire: "Renseigner",
          g_ownerNotify: "Le propriétaire sera informé de vos coordonnées",
          g_squareFeet: "Pieds carrés",
          g_noneAvailable: "Aucun n'est disponible pour le moment.",
          g_about: "À propos",
          g_propertyDesc: "Entrez la description de la propriété ici.",
          g_location: "Emplacement",
          g_propertyAddress: "Adresse de la propriété",
          g_rentalDisclaimer: "Clause de non-responsabilité de location",
          g_contactOwner: "Contacter le propriétaire",
          g_tourReq: "Demander une visite",
          g_language: "Langue",
          g_open: "Ouvrir",
          g_allHours: "Afficher toutes les heures",
          g_residentReviews: "Avis des résidents",
          g_noRatings: "Aucune évaluation disponible",

          // Unit Display
          f_furnished: "Meublé",
          f_kitchen: "Cuisine",
          f_livingRoom: "Salon",
          f_airConditioned: "Climatisé",
          f_fencedIn: "Clôturé",
          f_backyard: "Cour",
          f_gatedCommunity: "Communauté fermée",
          f_petsAllowed: "Animaux acceptés",
          f_inUnitWasher: "Laveuse et sécheuse dans l'unité",
          f_washerHookups: "Branchements laveuse et sécheuse",
          f_dishwasher: "Lave-vaisselle",
          f_wheelchairAccess: "Accessible aux fauteuils roulants",
          f_laundryFacilities: "Buanderie",
          f_fitnessCenter: "Centre Fitness",
          f_pool: "Piscine",
          f_elevator: "Ascenseur",
          f_parking: "Parking",
          f_balcony: "Balcon",
          f_upstairs: "Situé à l'étage",
          f_downstairs: "Situé en bas",
          f_utilities: "Utilitaires inclus",
          f_water: "Eau",
          f_internet: "l'Internet",
          f_electricity: "Électricité",
          units: "unités",
          availableUnit: "unité disponible",
          availableUnits: "unités disponibles",
          propertyAvailable: "Propriété disponible",
          propertyUnavailable: "Propriété indisponible",
          hideUnits: "Masquer les unités",
          showUnits: "Afficher les unités",
          rentalFee: "Frais de location",
          baths: "Bains",
          propertyFeatures: "Caractéristiques de l'établissement",
          sendMessage_title: "Envoyer un e-mail au propriétaire",
          sendMessage: "Envoyer le message",


          // Account Page
          profileSettings: "Paramètres de profil",
          profile: "Profil",
          myHome: "Ma maison",
          ownedProperties: "Propriétés possédées",
          propertyTransactions: "Transactions immobilières",
          manageSubs: "Gérer les abonnements",
          logOut: "Se déconnecter",
          f_preferredLang: "Langue préférée",
          f_english: "Anglais",
          f_spanish: "Espagnol",
          f_creole: "Français",
          f_contactHours: "Heures de contact",
          f_sunday: "Dimanches",
          f_monday: "Lundis",
          f_tuesday: "Mardis",
          f_wednesday: "Mercredi",
          f_thursday: "Jeudis",
          f_friday: "Vendredis",
          f_saturday: "Samedis",
          f_startTime: "Heure de début",
          f_endTime: "Heure de fin",
          f_contactUpdate: "Mettre à jour les coordonnées",
          f_updateBank: "Mettre à jour les informations bancaires",
          f_updateEmployment: "Mettre à jour les informations sur l'emploi",
          f_changePassword: "Changer le mot de passe",
          f_newPassword: "Nouveau mot de passe",
          f_newPasswordConfirm: "Confirmation du nouveau mot de passe",
          f_deactivateAccount: "Désactiver votre compte",
          f_closeAccount: "Fermer le compte",
          f_propertyListing: "Listes de propriétés",
          f_newListing: "Ajouter une nouvelle annonce",
          f_singleListing: "Annonces uniques",
          f_complexListing: "Listes des complexes d'appartements",

          // Payment Modal
          f_cardHolder: "Nom du titulaire de la carte",
          f_paymentAmount: "Montant du paiement",
          f_cardNumber: "Numéro de carte",
          f_cardExpiry: "Expiration de la carte",
          f_cardCVC: "CVC de la carte",
          f_pay: "Payer",

          // PaymentSettingDisplay
          f_propertySubs: "Abonnement Propriétaire",
          f_recurring: "Récurrent",
          f_invoiceNumb: "Numéro de facture",
          f_activateDate: "Date d'activation",
          f_expiryDate: "Date d'expiration",
          f_renew: "Renouveler",
          f_initialDeposit: "Dépôt initial",
          f_monthlyFee: "Redevance mensuelle",
          f_totalFee: "Coût total",
          f_paymentStatus: "Statut de paiement",
          f_datePaid: "Date de paiement",
          f_nextPayDate: "Prochaine date de paiement",
          f_tapToPay: "Appuyez pour payer!",
          recent_sub: "Abonnement récent",
          purchase_sub: "Acheter un plan d'abonnement",
          my_home_rental: "Frais de location de ma maison",

          // Tenant Modal
          f_termsTenantLeft: "Conditions dans lesquelles le locataire a quitté l'appartement précédent",
          f_landlordScore: "Note du propriétaire",
          f_landlordComment: "Commentaire du propriétaire précédent",
          f_tenantName: "Nom du locataire",
          no_reviews: "Pas encore d'avis.",
          reviews: "commentaires",
          f_generalInfo: "Informations générales",
          f_race: "Carrera",
          f_passport: "Numéro de passeport",
          f_citizenRecord: "Informations sur le registre des citoyens",
          f_criminalRecord: "Dossier criminel",
          f_reasonJailed: "Raison emprisonnée",
          f_tenantHistory: "Historique du locataire",
          no_history: "Aucun historique de locataire.",
          f_overallRating: "Note globale",
          f_rateTenant: "Tarif Locataire",
          f_giveRating_req: "Attribuez une note au locataire que vous supprimez de la liste des propriétés",
          f_tenantClosure: "Fermeture (Pourquoi le locataire part)",
          f_movedOut: "Déménagé",
          f_evicted: "Expulsé",
          f_tenantRating: "Évaluation du locataire,",
          f_comment: "Commentaire",
          f_reviewTenant: "Examiner le locataire",
          f_denyTenant: "Refuser l'enquête du locataire",
          f_acceptTenant: "Accepter comme locataire",
          f_removeTenant: "Supprimer le locataire",

          //Send Mail Modal
          f_moveInDate: "Date d'emménagement",
          f_customMessage: "Message personnalisé",

          // Confirm Modal
          ratingRange: "Plage de notation, 1 - 5 étoiles",

          // Report Modal
          reportIssue: "Signaler un problème",
          descIssue: "Décrivez le problème",

          // Terms of Use
          registerNow: "S'inscrire maintenant",
          contactText: `Afin de répondre correctement à vos questions ou demandes de confidentialité concernant nos services,
             s'il vous plaît écrivez-nous à
             <a href="mailto:rentalfaqz@gmail.com">locationfaqz@gmail.com</a>, ou appelez-nous au :
             <a href="tél:+12428202234"> (242) 820-2234</a>`,
          aboutText: `Chez <b>Rentalfaqz</b>, notre objectif est de devenir le marché de location en ligne le plus complet des Caraïbes.
            <br/><br/>
            Nous allons donc ajouter des locations de salles et de transports à nos services afin de mieux vous satisfaire et de vous faciliter la vie.
            Alors planifiez à l'avance et réservez à l'avance auprès de nos nombreux fournisseurs, avec peu d'effort et du temps à perdre.
            <br/><br/>
            Au fur et à mesure que nous étendons notre portée dans les pays des Caraïbes environnants, nous créerons un vaste 
            réseau de clientèle de locations de biens immobiliers, de salles et de transports, les mettant à portée de main.
            <br/><br/>
            Que vous cherchiez à accroître la présence de votre entreprise en ligne, à trouver le lieu idéal pour cette 
            grande occasion ou à combler vos postes vacants de location, vous êtes au bon endroit !`,
          termsText: `<h3>Conditions d'utilisation du service</h3>
             <p class='size-1'>
               Ces conditions d'utilisation s'appliquent aux services offerts par Rentalfaqz.com. Toute personne qui visite,
               visualise, accède ou utilise le Site, y compris par le biais d'un bot ou d'autres moyens automatisés, ("Vous" ou
               « Utilisateur ») est lié par les présentes Conditions d'utilisation. En utilisant le Site, Vous déclarez et garantissez que Vous
               êtes âgé d'au moins dix-huit (18) ans et vous acceptez par la présente d'être lié par toutes les conditions suivantes
               dispositions des présentes conditions d'utilisation, qui forment un contrat juridiquement contraignant entre vous et
               Rentalfaqz.com. Si vous n'acceptez pas ces conditions d'utilisation, il vous est interdit d'accéder à
               le Site et doit immédiatement interrompre cet accès.
             </p>

             <h3>À quoi devez-vous vous attendre ?</h3>
             <p class='size-1'>
               Rentalfaqz.com est une plate-forme en ligne à multiples facettes qui fournit des services de publicité et de recherche
               pour les prestataires de services de location, les chasseurs d'appartements, les propriétaires et les gestionnaires immobiliers. Ce site fait
               ne pas (a) fournir l'un des services directement et n'est pas partie à une transaction entre le service
               fournisseur et client, (b) garantir ou assurer tout service ou toute transaction entre un client
               et fournisseur de services, (c) effectuer une vérification des antécédents des candidats à la location ou percevoir un paiement sur
               au nom d'un client ou d'un fournisseur de services, ou (d) agir en tant que médiateur, agent de recouvrement ou agent d'évaluation du crédit
               agence, et ne garantit aucun résultat de l'utilisation du service. Le Site facilite l'utilisation de
               crowd sourcing au sein de son réseau de services qui fournit un historique des locataires
               rapport du propriétaire actuel et précédent, ou du gestionnaire immobilier, qui est soumis et partagé
               dans tout le système à des fins de sélection des futurs propriétaires et gestionnaires immobiliers.
             </p>

             <h3>Attention !</h3>
             <p class='size-1'>
               Nous vous encourageons fortement à inspecter personnellement tout appartement annoncé à louer avant :
               signer tout document ; fournir des informations personnelles ; ou câblage ou autre envoi
               de l'argent pour tout dépôt ou paiement de loyer. En utilisant le Site, Vous reconnaissez que les loyers publiés
               et les disponibilités sont sujettes à changement à la seule discrétion du propriétaire ou du gestionnaire à
               à tout moment et sans préavis.
             </p>

             <h3>Matériel du site</h3>
             <p class='size-1'>
               Toutes les informations et tous les contenus disponibles sur ce Site (collectivement, les « Matériels ») sont protégés par
               droits d'auteur et autres lois sur la propriété intellectuelle. Les Matériels sont destinés à un usage personnel et
               Usage non commercial seulement. Vous ne pouvez pas copier, reproduire, vendre, concéder sous licence, publier, distribuer, modifier,
               afficher, exécuter, combiner, créer une œuvre dérivée, republier ou autrement utiliser toute partie de
               les Matériels de toute autre manière ou à toute autre fin sans le consentement écrit préalable de
               RentalFaqz.com. Les demandes concernant l'utilisation du Matériel à des fins autres que personnelles,
               l'utilisation non commerciale doit être adressée à Rentalfaqz@gmail.com, ou N-10841 Wellington Street ,
               Nassau Bahamas.
             </p>

             <h3>Sites tiers</h3>
             <p class='size-1'>
               Au cours de votre visite sur le site, vous pouvez être redirigé vers des sites Web tiers pour remplir certaines
               caractéristiques et fonctionnalités du site (par exemple, si vous choisissez d'afficher les informations de la communauté ou de vérifier
               disponibilité d'un certain appartement). Vous acceptez que lorsque vous visitez des sites Web tiers (par
               exemple, entre autres, les sites Web ne contenant pas "RenalFaqz.com" dans l'URL) Vous êtes soumis à la
               la politique de confidentialité et les conditions d'utilisation, le cas échéant, de ce site Web tiers, qui peuvent différer de celles
               de RentalFaqz.com. En sélectionnant des liens vers ces sites Web tiers, vous acceptez d'être
               quitter le site et que RentalFaqz.com et ses affiliés n'ont aucune responsabilité ou obligation
               que ce soit en relation avec votre utilisation ou votre échange d'informations avec ces tiers
               sites Internet. RentalFaqz.com et ses affiliés n'approuvent, ne parrainent ni ne garantissent ces liens
               sites Web et ne sont en aucun cas responsables des conseils, contenus, informations, pratiques,
               produits ou services liés à ou mis à disposition via ces sites Web liés.
             </p>
             <h3>Marques</h3>
             <p class='size-1'>
               Vous reconnaissez que le matériel comprend certaines marques de commerce, marques de service et autres indices
               de source (ensemble "Marques") qui appartiennent à RetalFaqz.com, ses sociétés affiliées, concédants de licence et autres.
               Vous acceptez de ne pas copier, utiliser, enfreindre ou diluer les Marques. Vous vous engagez en outre à ne pas modifier ou
               supprimer toute marque, avis de droit d'auteur ou autre avis du matériel ou de toute copie de celui-ci. Le
               Le nom et le logo « RentalFaqz.com » sont des marques déposées de RentalFaqz.com. Autre produit et entreprise
               les noms ci-inclus peuvent être des marques déposées de leurs propriétaires respectifs.
             </p>

             <h3>Contenu utilisateur</h3>
             <p class='size-1'>
               En soumettant des publicités, des photos, des descriptions, des données ou d'autres informations ("Contenu") sur le Site en
               connexion avec une propriété, vous accordez par la présente à RentalFaqz.com, ses sociétés affiliées et sa société mère et
               sociétés liées un droit libre, perpétuel, irrévocable, non exclusif, entièrement transférable, entièrement
               droit sous-licenciable et licence pour copier, modifier, afficher, distribuer, exécuter, créer des dérivés
               fonctionne à partir de, stocke et utilise et exploite autrement tout ce contenu sous quelque forme, support, logiciel ou
               toute technologie existante ou développée à l'avenir. Vous accordez en outre à
               RentalFaqz.com, ses sociétés affiliées et ses sociétés mères et liées, un droit et une licence libres de droits
               d'utiliser votre nom, image et ressemblance dans le cadre de la reproduction ou de la distribution du
               Contenu. En soumettant tout contenu via le site, y compris, mais sans s'y limiter, via tout prospect
               formulaire, vous acceptez que RentalFaqz.com, un propriétaire, un gestionnaire immobilier ou une autre personne similaire ou
               l'entité peut vous contacter par e-mail, SMS, téléphone ou composeur automatique à quelque fin que ce soit. Le Site peut
               vous permettre de soumettre volontairement du contenu vous concernant, y compris votre revenu mensuel total ou
               toute autre (« Information fournie par le consommateur »). RentalFaqz.com recueille ces informations pour
               à des fins d'information uniquement et il n'est pas utilisé par RentalFaqz.com ou ses clients qui sont
               propriétaires, agents inscripteurs ou propriétaires (« Propriétés » et/ou « Propriété ») pour prendre la décision de louer un
               propriété à vous. En fournissant des informations fournies par le consommateur, vous reconnaissez que
               RentalFaqz.com peut partager ces informations fournies par les consommateurs avec des propriétés offrant des unités pour
               louer via le site sous réserve des présentes conditions d'utilisation. Tout destinataire de ces informations fournies par le consommateur
               L'information convient que toute information fournie par le consommateur peut ne pas être exacte, ne devrait pas être
               invoqué pour quelque raison que ce soit et ne peut être utilisé pour prendre une décision quant à la location d'un
               appartement à une personne en particulier. Le Site peut offrir des opportunités aux Utilisateurs âgés de 18 ans et
               plus âgés pour soumettre des contenus qui expriment leurs opinions et partagent leurs idées, par exemple dans un
               format de blog grand public ("Contenu d'opinion"). RentalFaqz.com et ses affiliés n'approuvent pas le
               l'exactitude ou la fiabilité de tout contenu d'opinion. Si vous décidez de soumettre un contenu d'opinion, veuillez utiliser
               votre meilleur jugement et soyez respectueux des autres individus. En publiant ou en soumettant un avis
               Contenu, vous vous engagez à respecter ces conditions d'utilisation. Vous ne pouvez pas utiliser le Site pour vous engager dans
               Activités interdites (telles que définies ci-dessous). Téléchargement de matériel protégé par le droit d'auteur ou autre matériel exclusif de
               tout type sur le site sans l'autorisation expresse du propriétaire de ce matériel est interdit et
               peut entraîner une responsabilité civile et/ou pénale.
               <br/><br/>
               Si vous êtes un client qui est une propriété traitant des transactions de paiement de location via le service
               (tel que défini ci-dessous), Vous devez fournir Votre nom légal et/ou le nom légal de votre entité commerciale,
               ou coordonnées professionnelles, et numéro(s) NIB ("Informations de référence"), si demandé.
               RentalFaqz.com peut collecter et conserver les informations de référence, ainsi que votre compte
               informations et le montant des paiements et le nombre d'opérations de paiement Vous ou Votre
               processus commerciaux via le service, afin que vos paiements puissent être signalés au service interne
               Service des impôts lorsque la loi l'exige.
             </p>
             <h3>Droits de RentalFaqz.com</h3>
             <p class='size-1'>
               Compte tenu de la nature du Site et du volume de messages et de publications, sauf disposition contraire
               dans ces conditions d'utilisation ou d'autres conditions applicables, RentalFaqz.com ne peut pas et ne surveille pas
               tout le contenu publié ou transmis par vous et d'autres fournisseurs d'informations tiers.
               RentalFaqz.com se réserve le droit, à sa seule discrétion, de surveiller, de refuser de publier, de supprimer,
               supprimer, déplacer ou modifier tout contenu sans préavis, à tout moment et pour quelque raison que ce soit. En utilisant le Site, Vous
               acceptez expressément que RentalFaqz.com (a) ne sera pas responsable des réclamations, actions ou jugements
               découlant de ou lié à tout Contenu et (b) peut surveiller, refuser de publier, retirer, supprimer,
               déplacer ou modifier tout contenu sans préavis à tout moment pour quelque raison que ce soit, sans responsabilité et sans
               offrant un remboursement. Vous convenez en outre expressément que vous êtes seul responsable de tout et de tous
               Contenu que vous soumettez au site.
             </p>

             <h3>Contenu non sollicité</h3>
             <p class='size-1'>
               RentalFaqz.com n'accepte pas le contenu ou les idées non sollicités pour l'utilisation ou la publication dans son
               programmation ou dans d'autres médias numériques, électroniques ou imprimés, sauf en relation avec les Forums,
               salons de discussion et babillards électroniques sur le site.
             </p>

             <h3>Représentations / Indemnité</h3>
             <p class='size-1'>
               Par la présente, vous déclarez, garantissez et acceptez que (a) vous disposez de tous les pouvoirs, droits et
               autorisations de soumettre le Contenu au Site, (b) le Contenu n'enfreint pas et n'enfreindra pas ou
               détourner un droit d'auteur, une marque, un secret commercial, un brevet ou toute autre propriété intellectuelle ou
               autre droit d'un tiers, y compris tout droit à la vie privée ou à la publicité, (c) le contenu, la référence
               Les informations et votre utilisation du site ne violent pas et ne violeront aucune loi fédérale, étatique ou
               loi ou réglementation locale, y compris, mais sans s'y limiter, toute loi ou réglementation en matière de logement équitable ou
               les réglementations applicables en matière de licences immobilières ou de courtage, ou causer des blessures à quiconque, (d) le
               Le contenu et les informations de référence sont complets, véridiques, exacts et non trompeurs, € le
               Le contenu et les informations de référence n'ont pas été soumis dans le but d'induire en erreur, de tromper ou de frauder
               ou chercher à induire en erreur, tromper ou frauder tout autre Utilisateur du Site, et (f) Vous n'induirez pas en erreur,
               tromper, frauder, chercher à induire en erreur, tromper ou frauder, faire de fausses déclarations ou chercher à
               faire de fausses déclarations à tout autre utilisateur du site. Vous reconnaissez que ces
               les représentations concernent des faits importants pour RentalFaqz.com. Vous vous engagez à libérer, défendre,
               indemniser et tenir RentalFaqz.com, ses sociétés affiliées, concédants de licence et leurs dirigeants, administrateurs,
               employés, agents et sous-traitants indemnes de et contre toutes réclamations, coûts, demandes ou
               les dépenses, y compris les honoraires d'avocat, résultant (i) de toute diffusion, publication, refus de publication,
               le retrait, la suppression, le déplacement, la modification ou toute autre utilisation du contenu ou des informations de référence que vous
               fournir, (ii) votre utilisation du site ou toute confiance dans les matériaux, (iii) votre violation de ces conditions d'utilisation
               Service ou (iv) toute vente, location ou autre transaction réelle, potentielle ou résiliée entre vous
               et un tiers.
             </p>
             <h3>Activités interdites</h3>
             <p class='size-1'>
               Vous ne devez pas :
               <ul class='size-1'>
                 <li class='mb-1'>
                   Soumettre un contenu qui est manifestement offensant pour la communauté en ligne, tel qu'un contenu qui
                   reflète ou promeut le racisme, le sectarisme, la haine ou les atteintes physiques de quelque nature que ce soit à l'encontre de tout groupe
                   ou individuel ;
                 </li>
                 <li>
                   S'engager dans une activité ou soumettre du Contenu qui pourrait être préjudiciable aux mineurs ;
                 </li>
                 <li class='mb-1'>
                   S'engager dans une activité ou soumettre un Contenu qui harcèle ou prône le harcèlement d'une autre personne ;
                 </li>
                 <li class='mb-1'>
                   S'engager dans une activité qui implique la transmission de « courrier indésirable » ou de publipostage de masse non sollicité
                   ou "spam" à d'autres ;
                 </li>
                 <li class='mb-1'>
                   S'engager dans une activité, soumettre du contenu ou des informations de référence, ou promouvoir des informations, qui sont
                   frauduleux, faux, trompeur, inexact et/ou constitue une fausse déclaration, ou
                   encourage des activités illégales ou des comportements abusifs, menaçants, obscènes, vulgaires,
                   diffamatoire, calomnieux ou autrement répréhensible ;
                 </li>
                 <li class='mb-1'>
                   Soumettre un contenu qui contient des pages d'accès restreintes ou avec mot de passe uniquement, ou des pages cachées ou
                   images;
                 </li>
                 <li class='mb-1'>
                   Soumettre un contenu qui affiche du matériel pornographique ou sexuellement explicite de toute nature ;
                 </li>
                 <li class='mb-1'>
                   Soumettre un contenu qui fournit des informations pédagogiques sur des activités illégales telles que
                   fabriquer ou acheter des armes illégales, violer la vie privée de quelqu'un ou fournir ou créer
                   virus informatiques;
                 </li>
                 <li class='mb-1'>
                   S'engager dans des activités ou soumettre du contenu qui sollicite des mots de passe ou des informations personnellement identifiables
                   des informations provenant d'autrui à des fins illégales ;
                 </li>
                 <li class='mb-1'>
                   S'engager dans des activités commerciales et/ou des ventes sans notre consentement écrit préalable, telles que
                   concours, tirages au sort, troc, publicité et systèmes pyramidaux ;
                 </li>
                 <li class='mb-1'>
                   Utilisez les formulaires de prospection du site et/ou les numéros sans frais pour annoncer ou promouvoir des produits et
                   services aux annonceurs de RentalFaqz.com ou pour solliciter les annonceurs de RentalFaqz.com
                   manière;
                 </li>
                 <li class='mb-1'>
                   Utilisez n'importe quel robot, araignée ou autre dispositif automatique, ou un processus manuel, pour accéder, surveiller ou
                   copier des pages Web ou les éléments contenus dans le site ou pour tout autre
                   but;
                 </li>
                 <li class='mb-1'>
                   Utiliser tout appareil, logiciel ou routine pour interférer ou tenter d'interférer avec le bon
                   fonctionnement du Site ;
                 </li>
                 <li class='mb-1'>
                   Décompiler, désosser, désassembler ou tenter d'une autre manière d'obtenir le code source
                   pour le Logiciel ; ou
                 </li>
                 <li class='mb-1'>
                   Prendre toute mesure qui impose une charge déraisonnable ou disproportionnée sur
                   Infrastructure matérielle et logicielle de RentalFaqz.com.
                 </li>
               </ul>
             </p>

             <h3>Remèdes de RentalFaqz.com</h3>
             <p class='size-1'>
               Vous reconnaissez que toute violation de ces conditions d'utilisation contraignantes, y compris sans
               limitation les restrictions d'accès des concurrents et les sections "Matériel du site",
               « Déclarations/Indemnité » et « Activités interdites » ou toute utilisation non autorisée du
               Site, est une violation substantielle de cet accord entre vous et RentalFaqz.com et entraînera
               dommages et préjudices irréparables à RentalFaqz.com, pour lesquels il n'existe aucun recours adéquat à
               loi. Ainsi, en plus de tous les autres recours disponibles en droit ou en équité, Vous convenez qu'en
               le cas d'une telle violation RentalFaqz.com aura droit à une injonction ou autre équitable
               réparation, que des dommages-intérêts liquidés soient disponibles ou non sans l'exigence que
               RentalFaqz.com publie une caution.
               <br/><br/>
               Une violation substantielle de ces conditions d'utilisation contraignantes nuit à l'intégrité, à la fonctionnalité et
               la réputation de RentalFaqz.com ; porte atteinte à la confiance et à l'utilisation du Site par les Utilisateurs ; et dommages
               l'entreprise de RentalFaqz.com. En conséquence, Vous reconnaissez et acceptez que
               RentalFaqz.com subit des dommages réels, qui sont extrêmement difficiles ou impossibles à
               quantifier, à la suite d'une violation substantielle de ces présentes conditions d'utilisation contraignantes. Pour le
               Pour éviter tout doute, de tels dommages ne constitueraient pas un recours adéquat en droit ou en
               affecter de quelque manière que ce soit votre accord selon lequel RentalFaqz.com aurait droit à une injonction ou autre
               réparation équitable en cas de manquement.
             </p>

             <h3>Politique de remboursement</h3>
             <p class='size-1'>
              La résiliation des services dans la première semaine suivant l’achat d’un abonnement entraînera un remboursement complet.
              Après la deuxième semaine, si vous mettez fin aux services, vous recevrez la moitié du bon de commande.
              Toutes les ventes sont définitives après la troisième semaine, car vous avez reconnu et accepté que vous n'aurez pas droit à un remboursement.
             </p>
 
             <h3>Exclusion de garantie</h3>
             <p class='size-1'>
               VOUS ACCEPTEZ EXPRESSÉMENT QUE L'UTILISATION DU SITE ET LA CONFIANCE À SON CONTENU ET
               LES INFORMATIONS FOURNIES PAR LE CONSOMMATEUR SONT À VOS PROPRES RISQUES. LOCATIONFAQZ.COM, ITS
               LES AFFILIÉS ET LEURS CONCÉDANTS TIERS RESPECTIFS NE FONT AUCUN
               REPRÉSENTATIONS OU GARANTIES DE QUELQUE NATURE QUE CE SOIT CONCERNANT LE SITE, LE
               LE LOGICIEL, LE CONTENU, LES INFORMATIONS FOURNIES PAR LE CONSOMMATEUR, LES DOCUMENTS, LE
               SERVICE DE SOUMISSION DE PROPRIÉTÉ, LE SERVICE D'APPROVISIONNEMENT D'APPARTEMENTS
               (COLLECTIVEMENT, LE « SERVICE ») OU LES RÉSULTATS QUI PEUVENT ÊTRE OBTENUS EN UTILISANT
               DE TOUT CE QUI PRÉCÈDE. LE SERVICE EST FOURNI "TEL QUEL, SELON LA DISPONIBILITÉ"
               BASIS, ET RENTALFAQZ.COM, SES AFFILIÉS ET LEURS TIERS RESPECTIFS
               LES CONCÉDANTS DÉCLINENT SPÉCIFIQUEMENT TOUTE GARANTIE EXPRESSE OU IMPLICITE
               Y COMPRIS, SANS S'Y LIMITER, LES GARANTIES D'ADÉQUATION À UN PARTICULIER
               OBJET, GARANTIES DE QUALITÉ MARCHANDE ET GARANTIES DE
               ABSENCE DE CONTREFAÇON. RENTALFAQZ.COM ET SES AFFILIÉS NE FONT AUCUN
               REPRÉSENTATIONS OU GARANTIES, EXPRESSES OU IMPLICITES, À TOUTE RÉELLE OU
               LOCATAIRE POTENTIEL DE TOUT APPARTEMENT QUANT À L'EXISTENCE, LA PROPRIÉTÉ OU
               CONDITION DE L'APPARTEMENT; QUANT AUX DISPONIBILITÉS ANNONCÉES, LOYER, BAIL
               CONDITIONS, DÉPÔT DE GARANTIE OU FRAIS DE DEMANDE, LE CAS ÉCHÉANT ; OU QUANT A L'EXACTITUDE OU
               EXHAUSTIVITÉ DE TOUTE INFORMATION SUR UN APPARTEMENT FIGURANT SUR LE
               PLACER. RENTALFAQZ.COM NE GARANTIT PAS L'EXACTITUDE OU L'EXHAUSTIVITÉ DE
               CES INFORMATIONS. RENTALFAQZ.COM SE RÉSERVE LE DROIT, À SA SEULE DISCRÉTION,
               POUR CORRIGER TOUTE ERREUR OU OMISSION SUR LE SITE OU DANS LE CONTENU. TOUT ET TOUS
               LES PRÉOCCUPATIONS, LES DIFFÉRENCES OU LES DIFFÉRENCES CONCERNANT UN APPARTEMENT DOIVENT ÊTRE
               ADRESSÉ AVEC LE PROPRIÉTAIRE ET/OU LA SOCIÉTÉ DE GESTION DE LA PROPRIÉTÉ AVANT
               A LA LOCATION DE L'APPARTEMENT. RENTALFAQZ.COM NE FAIT PAS ET EXPRESSÉMENT
               DÉCLINE TOUTE DÉCLARATION, GARANTIE OU GARANTIE ENVERS VOUS CONCERNANT
               LE SERVICE DE SOUMISSION DE PROPRIÉTÉ ET LE SERVICE D'ACQUISITION D'APPARTEMENTS,
               Y COMPRIS, SANS S'Y LIMITER, TOUTES REPRÉSENTATIONS, GARANTIES OU
               GARANTIES QUE VOUS LOUEZ VOTRE APPARTEMENT, OBTENEZ UN LOYER ACCEPTABLE
               POUR VOTRE APPARTEMENT, RECEVEZ UNIQUEMENT LES DEMANDES OU SOLLICITATIONS LÉGITIMES
               DE LOCATAIRES QUALIFIÉS, OU RECEVEZ TOUTE DEMANDE CONCERNANT VOTRE APPARTEMENT
               A LOUER. AUX FINS DE CETTE EXCLUSION DE GARANTIE, "CONCÉDANT DE LICENCE TIERS"
               NE VOUS COMPREND PAS.
             </p>
             <h3>Limitation de responsabilité</h3>
             <p class='size-1'>
               RENTALFAQZ.COM, SES AFFILIÉS ET LEURS PROPRIÉTAIRES RESPECTIFS, EMPLOYÉS,
               LES AGENTS, CONTRACTANTS ET CONCÉDANTS TIERS NE SERONT EN AUCUN CAS RESPONSABLES
               POUR TOUT DOMMAGE OU PERTE, Y COMPRIS, SANS LIMITATION, DIRECT, INDIRECT,
               DOMMAGES CONSÉCUTIFS, SPÉCIAUX, ACCESSOIRES OU PUNITIFS, RÉSULTANT DE OU
               CAUSÉS PAR LE SERVICE OU AUTREMENT, Y COMPRIS, SANS LIMITATION, LES DOMMAGES
               RÉSULTANT D'UNE NÉGLIGENCE. EN AUCUN CAS LE MAXIMUM GLOBAL
               RESPONSABILITÉ D'RENTALFAQZ.COM, DE SES AFFILIÉS ET DE LEURS PROPRIÉTAIRES RESPECTIFS,
               EMPLOYÉS, AGENTS, SOUS-TRAITANTS ET CONCÉDANTS DE LICENCE TIERS POUR TOUTE RÉCLAMATION
               DÉCOULANT DE OU EN RELATION AVEC VOTRE UTILISATION DU SERVICE DÉPASSE LE
               MONTANT TOTAL DES FRAIS EFFECTIVEMENT PAYÉS PAR VOUS À RENTALFAQZ.COM. LA NÉGATION
               DES DOMMAGES ÉNONCÉS CI-DESSUS EST UN ÉLÉMENT FONDAMENTAL DE LA BASE DE LA
               NÉGOCIEZ ENTRE RENTALFAQZ.COM ET VOUS. LE SERVICE NE SERAIT PAS
               FOURNI SANS CES LIMITATIONS. AUCUN CONSEIL NI RENSEIGNEMENT,
               QU'IL SOIT ORAL OU ÉCRIT, OBTENU PAR VOUS DE RENTALFAQZ.COM CRÉE
               TOUTE GARANTIE, REPRÉSENTATION ET/OU GARANTIE NON EXPRESSÉMENT INDIQUÉE DANS
               CET ACCORD. AUX FINS DE CETTE LIMITATION DE RESPONSABILITÉ, "TIERCE PARTIE
               CONCÉDANT » NE VOUS INCLUT PAS.
             </p>

             <h3>Résiliation du service</h3>
             <p class='size-1'>
               Vous comprenez et acceptez qu'à la seule discrétion de Rentalfaqz.com, et sans préavis,
               votre accès à ce site peut être résilié ou suspendu, et RentalFaqz.com peut exercer
               tout autre recours disponible et supprimer tout Contenu, s'il estime que votre utilisation du Site
               et/ou tout contenu ou information de référence que vous fournissez (a) enfreint (i) les présentes conditions d'utilisation
               Service, (ii) les droits de RentalFaqz.com, ses sociétés affiliées, un Concédant ou un autre Utilisateur, ou (iii)
               toute loi ou réglementation, ou sont autrement répréhensibles ou inappropriés ou (b) constituent
               activité frauduleuse ou illégale de quelque nature que ce soit. Vous convenez que les dommages pécuniaires ne peuvent pas
               fournir un recours suffisant à RentalFaqz.com en cas de violation des présentes conditions d'utilisation, et
               Vous consentez à une injonction ou à une autre réparation équitable pour de telles violations sans
               exigence que RentalFaqz.com dépose une caution. RentalFaqz.com n'est pas tenu de fournir
               tout remboursement si votre utilisation est résiliée à la suite de la décision de RentalFaqz.com, en
               sa seule discrétion, que vous avez violé ces conditions d'utilisation.
             </p>

             <h3>Renonciation</h3>
             <p class='size-1'>
               Vous comprenez et acceptez que toute personne ou entité qui enfreint l'une des conditions de ces
               Les conditions d'utilisation sont exclues de l'application, s'engagent à ne pas appliquer et s'engagent à ne pas
               poursuivre en justice pour faire appliquer, toute disposition similaire ou connexe dans tout contrat, y compris les termes et
               conditions, conditions d'utilisation et conditions de service, revendiquées par cette personne, cette entité ou tout autre
               affilié de celui-ci, comme contraignant pour RentalFaqz.com ou l'un de ses affiliés.
             </p>
             
             <h3>Divers</h3>
             <p class='size-1'>
               Ces conditions d'utilisation peuvent être modifiées à tout moment et vous serez informé de ces
               changements par une publication mise à jour des nouvelles conditions d'utilisation sur cette page du site. Ton
               l'utilisation continue du site après la publication de toute modification des conditions d'utilisation constituera
               votre accord d'être lié par de tels changements. RentalFaqz.com peut modifier, suspendre,
               interrompre ou restreindre l'utilisation de toute partie du site sans préavis ni responsabilité. Toi
               convenez par la présente que toute cause d'action que vous pourriez avoir à l'égard du site doit être
               déposée exclusivement auprès des cours suprêmes situées à New Providence Bahamas, dans un délai d'un (1)
               an après la survenance de la cause d'action ou la prescription de la cause. Vous consentez par la présente à des informations personnelles
               juridiction sous les cours suprêmes de New Providence Bahamas, et renonce à tout
               objection basée sur le forum non convenance. Comme condition d'utilisation de ce site, vous acceptez que
               toutes les causes d'action découlant de ou liées à ce site doivent être résolues individuellement,
               sans recourir à aucune forme de recours collectif. Si, pour quelque raison que ce soit, un tribunal compétent
               conclut que toute disposition des présentes conditions d'utilisation, ou une partie de celles-ci, est inapplicable, que
               la fourniture de ces conditions d'utilisation sera appliquée dans la mesure maximale permise afin
               comme affectant l'intention des parties, et le reste des présentes conditions d'utilisation sera
               continuer en pleine force et effet. Le non-respect par RentalFaqz.com de l'application de toute disposition de ce
               Les conditions d'utilisation ne seront pas considérées comme une renonciation à l'application future de celle-ci ou de toute autre
               disposition des présentes conditions d'utilisation. Ces conditions d'utilisation et toutes autres conditions et
               conditions ou politiques s'appliquant à votre utilisation du site, constituent l'intégralité de l'accord
               entre les parties concernant l'objet des présentes. Rien de ce qui est contenu ici ne doit être
               interprété comme la création d'une relation de partenariat, de coentreprise ou d'agence ou l'octroi d'un
               franchise entre les parties. Nonobstant toute disposition contraire aux présentes, si vous et
               RentalFaqz.com a conclu un contrat publicitaire écrit séparé, les termes et
               les conditions d'un tel contrat prévaudront en ce qui concerne toute clause incompatible avec
               ces conditions d'utilisation. SI VOUS N'ACCEPTEZ PAS LES CONDITIONS ÉNONCÉES CI-DESSUS OU TOUTE
               MODIFICATIONS DE CES CONDITIONS D'UTILISATION, VEUILLEZ QUITTER LE SERVICE IMMÉDIATEMENT.
               <br/><br/>
               © 2023 RentalFaqz.com, Inc., N-10841 New Providence Bahamas
               <br/><br/>
               Dernière mise à jour : 12 mai 2023
             </p>`,
          privacyText: `<h3>Déclaration de confidentialité</h3>
             <p class='size-1'>
               Cet avis de confidentialité décrit les types d'informations personnelles que nous collectons et nos pratiques pour
               l'utiliser, l'entretenir, le partager et le protéger. Il décrit également les droits et les choix que vous pouvez avoir
               en ce qui concerne vos informations personnelles et comment vous pouvez nous contacter au sujet de nos pratiques de confidentialité.
               Nos pratiques de confidentialité peuvent varier selon les pays dans lesquels nous opérons pour refléter les pratiques locales
               et les exigences légales, et des pratiques de confidentialité spécifiques peuvent s'appliquer à certains de nos produits et
               prestations de service.
               <br/><br/>
               Lorsque vous utilisez nos sites Web, vous pouvez choisir d'interagir avec des fonctionnalités de tiers qui exploitent
               indépendamment de RentalFaq.com, tels que les widgets de médias sociaux et les liens vers des sites Web tiers.
               RentalFaqz.com n'a aucun contrôle sur et n'est pas responsable des pratiques de confidentialité de ces tiers
               des soirées. Cet avis de confidentialité ne s'applique pas dans la mesure où RentalFaqz.com ne possède ni ne contrôle
               tous les sites Web ou fonctionnalités liés que vous visitez ou utilisez. Nous vous recommandons de vous familiariser avec
               les pratiques de confidentialité de ces tiers.
             </p>

             <h3>Table des matières</h3>
             <p class='size-1'>
               <ol class='size-1'>
                 <li class='mb-1'>Types d'informations personnelles</li>
                 <li class='mb-1'>Collecte d'informations personnelles</li>
                 <li class='mb-1'>Utilisation des informations personnelles</li>
                 <li class='mb-1'>Divulgation d'informations personnelles</li>
                 <li class='mb-1'>Transfert international d'informations personnelles</li>
                 <li class='mb-1'>Sécurité des informations personnelles</li>
                 <li class='mb-1'>Conservation des informations personnelles</li>
                 <li class='mb-1'>Gérer vos informations personnelles</li>
                 <li class='mb-1'>Modifications de l'avis de politique globale</li>
                 <li class='mb-1'>Contactez-nous</li>
               </ol>
             </p>
             
             <h3>1. Types d'informations personnelles</h3>
             <p class='size-1'>
               « Informations personnelles » désigne toute information directement ou indirectement liée à une personne identifiée ou
               individu identifiable. Exemples d'informations personnelles que RentalFaqz.com peut collecter :
               <ul class='size-1'>
                 <li class='mb-1'>
                   Informations de paiement (carte de crédit et autres informations de carte de paiement)
                 </li>
                 <li class='mb-1'>
                   Coordonnées personnelles (nom, surnom, sexe, adresse postale, adresse e-mail,
                   numéro de téléphone)
                 </li>
                 <li class='mb-1'>
                   Informations sur l'entreprise (emplacement du bureau, coordonnées de l'entreprise, service, emploi
                   titre/description, biographie professionnelle)
                 </li>
                 <li class='mb-1'>
                   Identifiants de connexion (nom d'utilisateur, mot de passe)
                 </li>
                 <li class='mb-1'>
                   Détails d'utilisation (activité de connexion, termes de recherche, vues, clics, téléchargements, soumission de prospects
                   information)
                 </li>
                 <li class='mb-1'>
                   Détails techniques (adresse IP, informations de géolocalisation, identifiants de cookie/session, authentification
                   informations, type/version de navigateur, système d'exploitation/plate-forme)
                 </li>
                 <li class='mb-1'>
                   Toute autre information que vous ou d'autres clients pouvez télécharger ou entrer dans les Services
                 </li>
                 <li class='mb-1'>
                   Enregistrements d'appels téléphoniques et de vidéoconférences
                 </li>
               </ul>
             </p>
             <h3>2. Collecte d'informations personnelles</h3>
             <p class='size-1'>
               RentalFaqz peut collecter des informations personnelles en utilisant les méthodes suivantes :
               <ul class='size-1'>
                 <li class='mb-1'>
                   Directement auprès de vous lorsque vous nous les fournissez (informations que vous entrez dans les formulaires Web,
                   chatbots, participation à des appels téléphoniques ou à des vidéoconférences, accords d'abonnement, et
                   contrats ou tout autre contenu que vous soumettez aux Services)
                 </li>
                 <li class='mb-1'>
                   Par votre participation à des sondages, des promotions et des concours
                 </li>
                 <li class='mb-1'>
                   Système de gestion de la relation client interne et bases de données RentalFaqz
                 </li>
                 <li class='mb-1'>
                   De tiers, tels que des fournisseurs de services d'analyse et de marketing par e-mail
                 </li>
                 <li class='mb-1'>
                   Recherche de sites Web publics lorsque les conditions d'utilisation l'autorisent (sites Web d'entreprise,
                   moteurs de recherche, réseaux sociaux)
                 </li>
                 <li class='mb-1'>
                   Automatiquement grâce à des technologies de suivi (cookies, balises Web, fichiers journaux), y compris plus de
                   temps et sur des sites Web tiers ou d'autres services en ligne
                 </li>
               </ul>
             </p>
            
             <h3>3. Utilisation des informations personnelles</h3>
             <p class='size-1'>
               Nous pouvons utiliser vos informations personnelles aux fins suivantes :
               <ul class='size-1'>
                 <li class='mb-1'>
                   Communiquer avec vous
                 </li>
                 <li class='mb-1'>
                   Vous fournir les Services
                 </li>
                 <li class='mb-1'>
                   Authentification de l'utilisation, détection d'une utilisation frauduleuse potentielle et maintien de la
                   sécurité des Services
                 </li>
                 <li class='mb-1'>
                   Développer, tester, améliorer et démontrer les Services
                 </li>
                 <li class='mb-1'>
                   Créer et maintenir un système de gestion de la relation client, des répertoires de membres,
                   et listes d'invitations pour les événements
                 </li>
                 <li class='mb-1'>
                   Exécuter nos obligations légales et contractuelles et faire valoir nos droits, y compris la facturation
                   et traitement des paiements
                 </li>
                 <li class='mb-1'>
                   Anonymisation et agrégation d'informations pour l'analyse et la création de rapports
                 </li>
                 <li class='mb-1'>
                   Publicité, marketing et vente des Services, y compris les liens ou la fusion
                   Informations personnelles avec d'autres informations personnelles afin que nous puissions mieux comprendre
                   vos besoins et vous informer sur nos Services et ceux de nos partenaires
                 </li>
                 <li class='mb-1'>
                   Utilisation transitoire à court terme, telle que définie dans le California Consumer Privacy Act
                 </li>
                 <li class='mb-1'>
                   Formation et assurance qualité
                 </li>
                 <li class='mb-1'>
                   Pour toute autre fin avec votre consentement
                 </li>
               </ul>
             </p>
             <h3>4. Divulgation d'informations personnelles</h3>
             <p class='size-1'>
               Nous pouvons divulguer des informations personnelles :
               <ul class='size-1'>
                 <li class='mb-1'>
                   Aux sociétés affiliées et autres entités au sein du groupe de sociétés, partenaires de distribution, autres
                   clients et prestataires de services
                 </li>
                 <li class='mb-1'>
                   À des tiers lorsque vous utilisez une fonctionnalité tierce dans nos Services
                 </li>
                 <li class='mb-1'>
                   Pour faire respecter ou appliquer nos conditions d'utilisation et d'autres accords ou se conformer à toute ordonnance d'un tribunal,
                   la loi ou la procédure judiciaire, y compris les demandes gouvernementales ou réglementaires
                 </li>
                 <li class='mb-1'>
                   A un acquéreur ou autre successeur en cas de fusion, cession, restructuration,
                   réorganisation, dissolution ou autre vente ou transfert de tout ou partie des actifs de RentalFaqz.com
                 </li>
                 <li class='mb-1'>
                   Aux conseillers, consultants, auditeurs ou professionnels similaires pour évaluer nos activités commerciales, financières,
                   et obligations légales ou opérations
                 </li>
                 <li class='mb-1'>
                   Si nous pensons qu'il est nécessaire de protéger les droits, la propriété ou la sécurité de RentalFaqz.com ou
                   autres
                 </li>
                 <li class='mb-1'>
                   Pour toute autre fin que nous divulguons lorsque vous fournissez des informations personnelles
                 </li>
                 <li class='mb-1'>
                   Avec votre consentement, par exemple lorsque vous choisissez de fournir vos informations à des tiers
                   Nous pouvons également divulguer des informations anonymisées sans restriction. Notez que si vous faites des
                   Informations personnelles accessibles au public sur les Services, toute personne peut voir et utiliser ces informations.
                 </li>
               </ul>
             </p>


             <h4><u>Services de référencement</u></h4>
             <p class='size-1'>
               RentalFaqz peut partager des informations sur les membres dans les domaines suivants des Services : Marketing
               Center, alertes e-mail et annuaire professionnel. Lorsqu'un client saisit une annonce de vente ou de location dans le
               Marketing Center, les informations de contact de la liste sont également saisies. Au cours de ce processus, la liste
               l'émetteur peut voir les informations de contact d'autres clients lorsqu'il ajoute un autre contact
               à la liste. En outre, les clients peuvent afficher les informations de contact (nom, e-mail et numéro de téléphone
               numéro) lors de la consultation d'un profil d'annonce de propriété.
               Les Services peuvent également permettre aux utilisateurs de partager des informations de contact entre eux via des alertes par e-mail.
               Avec les alertes par e-mail, les utilisateurs peuvent enregistrer leurs critères de recherche de propriétés pour avoir des informations sur les propriétés
               qui correspondent aux critères spécifiés qui leur sont envoyés par e-mail. Lors de la sélection de ce service, les utilisateurs reçoivent la
               possibilité de divulguer leurs coordonnées à d'autres utilisateurs qui ont des listes de propriétés qui correspondent
               leurs critères. Ces informations de contact sont ensuite partagées avec les utilisateurs qui ont des listes de propriétés qui
               correspondre aux critères d'achat.
             </p>

             <h4><u>LocationFaqz.com</u></h4>
             <p class='size-1'>
               Si vous choisissez d'utiliser le service d'inscription gratuite du site Web pour annoncer une propriété, il est important de noter
               que toute information que vous choisissez d'inclure dans votre annonce peut être rendue publique via le
               site Web et autres médias dans lesquels les listes de sites Web sont publiées, y compris les sites affiliés. Pour
               par exemple, vous pouvez inclure un numéro de téléphone et/ou une adresse e-mail pour permettre aux utilisateurs de vous contacter
               concernant votre annonce. RentalFaqz.com n'est pas responsable des appels téléphoniques et des courriels non sollicités qui
               que vous pouvez recevoir de tiers, même après que votre annonce ne soit plus sur le site Web. À l'exception de
               informations requises, vous ne devez pas inclure de contact ou d'autres informations dans votre annonce qui
               vous ne voulez pas que d'autres parties voient ou utilisent.
             </p>
            
             <h3>5. Transfert international d'informations personnelles</h3>
             <p class='size-1'>
               Nous pouvons transférer vos informations personnelles aux États-Unis et dans d'autres pays qui peuvent
               ne pas avoir les mêmes lois sur la confidentialité que le pays dans lequel vous avez initialement fourni les informations, mais
               nous protégerons vos informations personnelles conformément à cet avis de confidentialité global, ou comme
               autrement divulgués.
             </p>
             <h4><u>Résidents de l'Espace économique européen (EEE) et résidents du Royaume-Uni</u></h4>
             <p class='size-1'>
               Si vous vivez dans l'EEE ou au Royaume-Uni, nous pouvons transférer des informations personnelles vers des pays pour
               lesquelles des décisions d'adéquation ont été émises, utiliser des protections contractuelles pour le transfert de
               Informations à des tiers, telles qu'un accord intra-entreprise ou la Commission européenne
               Clauses contractuelles types ou leur équivalent en vertu de la loi applicable.
             </p>

             <h4><u>Résidents canadiens</u></h4>
             <p class='size-1'>
               Nous pouvons utiliser des fournisseurs de services situés à l'extérieur du Canada, y compris, sans s'y limiter, aux États-Unis
               États d'Amérique, pour traiter les informations personnelles, et peut transférer vos informations personnelles à
               ces prestataires de services à cette fin. Lorsque vos informations personnelles sont traitées en dehors de
               Canada, elles seront assujetties aux lois des pays où les renseignements sont traités et peuvent
               être accessible aux forces de l'ordre et aux autorités de sécurité nationale de ces pays conformément
               avec leurs lois.
             </p>

             <h3>6. Sécurité des informations personnelles</h3>
             <p class='size-1'>
               RentalFaqz met en œuvre des mesures de sécurité pour protéger vos informations personnelles.
               Nous prenons des mesures pour sécuriser les informations personnelles par des moyens administratifs, techniques et physiques.
               des garanties destinées à protéger contre le risque de destruction accidentelle, illicite ou non autorisée,
               perte, altération, accès, divulgation ou utilisation. Par exemple, nous chiffrons les informations de carte de paiement dans
               conformément à la norme de sécurité des données de l'industrie des cartes de paiement (PCI DSS) et sensibles au stockage
               des informations telles que les données de géolocalisation sur nos serveurs situés aux États-Unis avec un accès limité
               aux employés autorisés de RentalFaqz.
               Malheureusement, nous ne pouvons pas garantir la sécurité des informations transmises via Internet,
               et si nous vous avons donné (ou si vous avez choisi) un mot de passe, vous êtes responsable de
               garder ce mot de passe confidentiel.
             </p>

             <h3>7. Conservation des informations personnelles</h3>
             <p class='size-1'>
               RentalFaqz.com ne conservera vos informations que le temps raisonnablement nécessaire pour la
               fins énoncées dans le présent avis de confidentialité, aussi longtemps que votre compte est actif, ou au besoin pour fournir
               les Services pour vous. Si vous ne souhaitez plus que nous utilisions vos informations pour vous fournir les Services,
               vous pouvez fermer votre compte. RentalFaqz.com conservera et utilisera vos informations dans la mesure où
               nécessaires pour nous conformer à nos obligations légales, résoudre les litiges, faire respecter nos accords et, le cas échéant,
               autrement décrit dans le présent avis de confidentialité. Veuillez noter que la fermeture d'un compte peut ne pas rendre votre
               adresse e-mail, nom d'utilisateur ou nom de propriété (le cas échéant) pouvant être réutilisés sur un nouveau compte. Nous avons aussi
               conserver les fichiers journaux à des fins d'analyse interne. Ces fichiers journaux sont conservés pendant une courte période,
               sauf dans les cas où ils sont utilisés pour protéger la sûreté et la sécurité des Services, pour maintenir
               et améliorer la fonctionnalité des Services, ou pour se conformer aux obligations légales.
             </p>

             <h3>8. Gérer vos informations personnelles</h3>
             <p class='size-1'>
               Vous pouvez gérer vos informations personnelles en faisant certains choix. Si vous croyez que tout
               Les informations personnelles que vous nous avez fournies sont obsolètes ou inexactes, vous pouvez consulter et
               corriger ces informations si elles se trouvent dans votre compte en ligne en vous connectant à tout moment ou en nous contactant.
               Vous pouvez également avoir des droits supplémentaires détaillés ci-dessous en fonction de votre lieu de résidence. En guise de sécurité
               précaution, nous pouvons avoir besoin de vous demander des informations pour nous aider à confirmer votre identité lorsque
               exercer un droit relatif à vos Informations Personnelles.
             </p>
             <h4><u>Désactivation du marketing direct</u></h4>
             <p class='size-1'>
               Si vous ne souhaitez plus recevoir d'e-mails de marketing direct de notre part, vous pouvez cliquer sur "Se désinscrire"
               lien dans un e-mail à tout moment pour cesser de recevoir des e-mails pour ce sujet particulier. En fonction de la
               Services, vous pouvez également gérer les préférences de notification dans votre compte. Notez que nous pouvons
               toujours vous contacter au sujet des conditions d'utilisation, de l'avis de confidentialité global ou d'autres avis juridiques, ou dans
               dans le cadre de la fourniture des Services que vous nous avez demandés.
             </p>

             <h4><u>Désactivation du suivi/de la publicité en ligne</u></h4>
             <p class='size-1'>
               Vous pouvez désactiver ou supprimer les cookies du navigateur via les paramètres de votre navigateur. Les cookies sont généralement
               facile à désactiver ou à supprimer, mais la méthode varie selon les navigateurs. Pour savoir comment gérer
               Cookies Flash, visitez la page des paramètres du lecteur Flash d'Adobe. Si vous désactivez ou supprimez les cookies, ou si vous êtes
               exécutant un logiciel tiers qui intercepte ou supprime les cookies, veuillez noter que certaines parties de notre
               les sites Web peuvent ne pas fonctionner correctement.
             </p>

             <h3>9. Modifications de l'Avis de confidentialité global</h3>
             <p class='size-1'>
               Cet avis de confidentialité peut être mis à jour périodiquement pour refléter les changements dans nos pratiques de confidentialité.
               Nous publierons les modifications sur cette page et identifierons la date à laquelle l'avis de confidentialité a été révisé pour la dernière fois en haut
               de la page. Si nous apportons des modifications importantes à nos pratiques de confidentialité, nous vous en informerons par le biais d'un
               avis sur la page d'accueil des sites Web couverts par le présent avis de confidentialité. Si un changement important devait
               s'appliquent aux renseignements personnels que nous avons déjà recueillis, y compris toute nouvelle fin pour laquelle le
               Des renseignements personnels seront utilisés ou divulgués auxquels vous n'avez pas préalablement consenti, nous
               solliciter en outre votre consentement affirmatif.
             </p>

             <h3>10. Contactez-nous</h3>
             <p class='size-1'>
               Afin de répondre correctement à vos questions ou demandes de confidentialité concernant nos services, veuillez envoyer un e-mail à
               contactez-nous à <a href="mailto:rentalfaqz@gmail.com">rentalfaqz@gmail.com</a>, ou appelez-nous au : <a href="tel:+12428202234">(242) 820 -2234</a>
             </p>`,
          f_terms: "En m'inscrivant, j'accepte les",
          f_termsUse: "Conditions d'utilisation.",
          f_termsProperty: `En cliquant sur Ajouter une propriété/Ajouter une unité ci-dessus, j'accepte de fournir des informations exactes et non discriminatoires
          et je me conformerai aux`,
          f_termsConditions: "Termes et conditions",
          f_termsService: "Ajouter une propriété Conditions d'utilisation.",
          f_termsConjuction: "et le",

          // Misc
          prev: "Précédent",
          next: "Suivant",
          vmore: "Voir plus",
          f_ok: "D'accord",
          actionFailed: "Action: échoué",
        }
      },
    }
  });

export default i18n;