import React from 'react';
import propTypes from 'prop-types';
import '../../assets/css/checkBox.css';

const Checkbox = ({name, desc, checkValue, setCheckValue, handleCheckbox, showName = true}) => (
  <button type='button' className='mt-05 mr-1 not-button checkboxDiv' onClick={e => handleCheckbox(checkValue, setCheckValue)}>
    {showName && <span className='form-sub-text'>{desc || name}</span>}
    <span className='ml-1 checkbox text-center' checkedvalue={ JSON.stringify(Boolean(checkValue))}>{checkValue ? 'yes' : 'no'}</span>
  </button>
);

Checkbox.propTypes = {
  name: propTypes.string.isRequired,
  desc: propTypes.string,
  checkValue: propTypes.bool.isRequired,
  setCheckValue: propTypes.func.isRequired,
  handleCheckbox: propTypes.func.isRequired,
};

export default Checkbox;
