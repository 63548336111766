/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { numberWithCommas } from '../../misc/misc';
import { useTranslation } from 'react-i18next';
import ExpandIcon from '../../../assets/images/expand.svg';
import MinimizeIcon from '../../../assets/images/minimize.svg';
// import '../../../assets/css/property.css';

const UnitDisplay = ({ user, property, renderInquiry, handleImageModal, propertyTag, unitGroup = false }) => {
  const { t } = useTranslation();

  // eslint-disable-next-line camelcase
  const [isOwner, setIsOwner] = useState(false);
  const [isResident, setIsResident] = useState(false);
  const [showFeatures, setShowFeatures] = useState(false)
  const [showUnit, setShowUnit] = useState(true)
  const [showMore, setShowMore] = useState(false);

  const availableUnits = property?.records?.filter(p => p.resident_id === null)
  const unitsLength = availableUnits?.length;

  useEffect(() => {
    if(!unitGroup)setShowUnit(false);
  }, [unitGroup]);

  const displayFeatures = () => {
    const features = [];
    if(property.furnished) {features.push(t('f_furnished'));}
    if(property.kitchen) {features.push(t('f_kitchen'));}
    if(property.living_room) {features.push(t('f_livingRoom'));}
    if(property.air_conditioned) {features.push(t('f_airConditioned'));}
    if(property.fenced_in) {features.push(t('f_fencedIn'));}
    if(property.back_yard) {features.push(t('f_backyard'));}
    if(property.gated_community) {features.push(t('f_gatedCommunity'));}
    if(property.pets_allowed) {features.push(t('f_petsAllowed'));}
    if(property.in_unit_washer_and_dryer) {features.push(t('f_inUnitWasher'));}
    if(property.washer_and_dryer_hookups) {features.push(t('f_washerHookups'));}
    if(property.dishwasher) {features.push(t('f_dishwasher'));}
    if(property.wheelchair_accessible) {features.push(t('f_wheelchairAccess'));}
    if(property.laundry_facilities) {features.push(t('f_laundryFacilities'));}
    if(property.fitness_center) {features.push(t('f_fitnessCenter'));}
    if(property.pool) {features.push(t('f_pool'));}
    if(property.elevator) {features.push(t('f_elevator'));}
    if(property.parking) {features.push(t('f_parking'));}
    if(property.balcony) {features.push(t('f_balcony'));}
    if(property.upstairs) {features.push(t('f_upstairs'));}
    if(property.downstairs) {features.push(t('f_downstairs'));}
    if(property.water || property.internet || property.electricity){
      features.push(t('f_utilities'));
    }
    if(property.water) {features.push(t('f_water'));}
    if(property.internet) {features.push(t('f_internet'));}
    if(property.electricity) {features.push(t('f_electricity'));}

    return features;
  };

  const populateFeatures = (features) => features.map((f, index) => (
    f === (t('f_utilities') || 'Utilities included') ? <span key={`Feats${index}`} style={{marginLeft: '-36px', fontWeight: '600'}}>{f}</span> : <li key={`Feats${index}`}>{f}</li>
  ));

  // const populateProperties = () => availableUnits.map((propData) => (
    // <UnitDisplay
    //   key={propData.id}
    //   property={propData}
    //   renderInquiry={renderInquiry}
    //   handleImageModal={handleImageModal}
    //   unitGroup={false}
    // />
  // ));
  const populateProperties = () => {

    const unitArr = [];
    const showAmount = showMore ? availableUnits.length : (availableUnits.length > 3 ? 3 : availableUnits.length);
    for (let i = 0; i < showAmount; i++) {
      unitArr.push(
        <UnitDisplay
          key={availableUnits[i].id}
          property={availableUnits[i]}
          renderInquiry={renderInquiry}
          handleImageModal={handleImageModal}
          unitGroup={false}
        />
      )
    }

    return unitArr;
  };

  const propertyType = (property, style = 1) => {
    if(style === 1) {
      if(property.studio) { return 'studio' };
      if(property.bedrooms === 1) { return 'one bedroom' };
      if(property.bedrooms === 2) { return 'two bedroom' };
      if(property.bedrooms === 3) { return 'three bedroom' };
    } else if(style === 2) {
      if(property.studio) { return 'studio' };
      if(property.bedrooms === 1) { return '1 bed' };
      if(property.bedrooms === 2) { return '2 beds' };
      if(property.bedrooms === 3) { return '3 beds' };
    }
  };

  const renderAvailability = () => {
    // const isAvailable = property.resident_id === null ? t('propertyAvailable') : t('propertyUnavailable');
    // return (<span className="available-btn mr-1">{isAvailable}</span>)
    return (
      <a href={`/properties/${property.id}`} className="available-btn text-camel mr-1">{t('g_apply')}</a>
    )
  };

  const renderGroup = () => (
    <div>
    <div className='related-listings mb-2'>
      <div className='pos-relative'>
        <div className='flex-row'>
          <div className='mr-3'>
            <div className="text-camel">{propertyTag} {t('units')}</div>
            <div className="text-bold">{numberWithCommas(property?.sq_ft)} sq ft</div>
          </div>
          <button type='button' onClick={() => setShowUnit(!showUnit)} className='available-btn mt-1'>
            <span className='mt-05 text-link'>{showUnit ? t('hideUnits'): t('showUnits')}</span>
          </button>
        </div>
        <div className='text-camel text-bold'>{unitsLength > 1 ? `${unitsLength} ${t('availableUnits')}` : `${unitsLength} ${t('availableUnit')}`} </div>
      </div>
    </div>
    {showUnit && populateProperties()}
    {(showUnit && !showMore && availableUnits.length > 3) && <button className='available-btn' onClick={() => setShowMore(true)}>View More</button>}
    </div>
  );

  const renderUnit = () => {
    const minimizedRender = (
      <div className='flex-row pos-relative'>
        <div className='flex-col mr-3'>
          <span>{t('f_unit')}</span>
          <a href={`/properties/${property.id}`} className="text-camel text-bold mr-1">{property?.property_unit || `${t('f_unit')} #`}</a>
        </div>
        <div className='flex-col mr-3 text-camel'>
          <span>{t('rentalFee')}</span>
          <span className="text-camel">${numberWithCommas(property.fee)}</span>
        </div>
        <div className='flex-col mr-3 text-camel hide-sm'>
          <span>{t('baths')}</span>
          <span className="">{property.bathrooms}</span>
        </div>
        <div className='flex-col text-camel'>
          <span>Sq ft</span>
          <span className="">{numberWithCommas(property?.sq_ft)}</span>
        </div>
      </div>
    );

    const fullRender = (
      <div className='flex-col pos-relative pb-1'>
        <a style={{maxWidth: '300px'}} href={`/properties/${property.id}`} className='text-camel text-bold'>{propertyType(property)} - {t('f_unit')}</a>
        <span className="text-camel text-bold">${numberWithCommas(property.fee)} {property?.payment_frequency} {t('f_fee')}</span>
        <span className="">{propertyType(property, 2)}, {property.bathrooms} {t('baths')}, {numberWithCommas(property?.sq_ft)} sq ft</span>
        <a title="Send email message to property owner" href={`mailto:${property?.email}`}>{t('f_sendEmail')}</a>
        <div className='unit-images'>
          <button type="button" onClick={() => handleImageModal(property.images[property.cover_photo_index || 0]?.url)}>
            <img alt="Property unit" src={property.images[property.cover_photo_index || 0]?.url} />
          </button>
        </div>
        <button type='button' onClick={() => setShowFeatures(!showFeatures)} className='btn text-left' style={{maxWidth: '280px'}}>
          <span className='mt-05 text-link'>{t('propertyFeatures')}</span>
          {!showFeatures && <img src={ExpandIcon} className='p-1' style={{width: '32px', filter: 'invert(57%) sepia(48%) saturate(7499%) hue-rotate(166deg) brightness(101%) contrast(102%)'}} alt='Minimize' />}
          {showFeatures && <img src={MinimizeIcon} className='p-1' style={{width: '32px', filter: 'invert(57%) sepia(48%) saturate(7499%) hue-rotate(166deg) brightness(101%) contrast(102%)'}} alt='Expand' />}
        </button>
        {showFeatures && (<ol className="features-list mt-0 pl-2">
          {populateFeatures(displayFeatures())}
        </ol>)}
        <div className='flex-row self-flex-start mt-1'>
          {renderAvailability()}
          <div className="contact-btn">
            <a title={t('sendMessage_title')} href={`mailto:${property?.owner?.email}`} style={{color: 'white'}}>{t('sendMessage')}</a>
          </div>
          {/* {renderInquiry()} */}
        </div>
      </div>
    );

    return (
      <div className='related-listings mb-3'>
        {!showUnit && <button type='button' className='maximize-btn maximize-btn-sm' onClick={() => setShowUnit(!showUnit)}><img src={ExpandIcon} className='p-1' style={{width: '32px', filter: 'invert(57%) sepia(48%) saturate(7499%) hue-rotate(166deg) brightness(101%) contrast(102%)', top: '0'}} alt='Expand' /></button>}
        {showUnit && <button type='button' className='maximize-btn maximize-btn-lg' onClick={() => setShowUnit(!showUnit)}><img src={MinimizeIcon} className='p-1' style={{width: '32px', filter: 'invert(57%) sepia(48%) saturate(7499%) hue-rotate(166deg) brightness(101%) contrast(102%)', top: '0'}} alt='Minimize' /></button>}
        {!showUnit && minimizedRender}
        {showUnit && fullRender}
      </div>
    );
  };

  return unitGroup ? renderGroup() : renderUnit()
};

UnitDisplay.propTypes = {
  user: propTypes.instanceOf(Object),
  property: propTypes.instanceOf(Object).isRequired,
  renderInquiry: propTypes.func.isRequired,
  handleImageModal: propTypes.func.isRequired,
  unitGroup: propTypes.bool
};

export default UnitDisplay;
