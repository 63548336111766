import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Star from '../../assets/images/star2_full.svg';
import StarEmpty from '../../assets/images/star2.svg';

const ConfirmModal = ({ options, handleConfirmModal }) => {
  const { t } = useTranslation();

  const { header, message, textbox, rating, handleFunction } = options;
  const [comment, setComment] = useState('');
  const [itemRating, setItemRating] = useState(1);

  // Returns JSX relative to whether the messages are given in array or string format
  const showMessage = () => {
    return (<div className="message">{message}</div>);
  };

  const genrateRatings = () => (
      <div className='flex-col mt-1 mb-05'>
        {<div className='text-center mb-1'>
          <img onClick={() => setItemRating(1)} className='rating-star filter-star' src={Star} alt='star rating'/>
          {itemRating <= 1 ? <img onClick={() => setItemRating(2)} className='rating-star filter-star' src={StarEmpty} alt='star rating'/> :
            <img onClick={() => setItemRating(2)} className='rating-star filter-star' src={Star} alt='star rating'/>}
          {itemRating <= 2 ? <img onClick={() => setItemRating(3)} className='rating-star filter-star' src={StarEmpty} alt='star rating'/> :
            <img onClick={() => setItemRating(3)} className='rating-star filter-star' src={Star} alt='star rating'/>}
          {itemRating <= 3 ? <img onClick={() => setItemRating(4)} className='rating-star filter-star' src={StarEmpty} alt='star rating'/> :
            <img onClick={() => setItemRating(4)} className='rating-star filter-star' src={Star} alt='star rating'/>}
          {itemRating <= 4 ? <img onClick={() => setItemRating(5)} className='rating-star filter-star' src={StarEmpty} alt='star rating'/> :
            <img onClick={() => setItemRating(5)} className='rating-star filter-star' src={Star} alt='star rating'/>}
          </div>}
        <input type="range" id="score" name="score" min="1" max="5" value={itemRating} onChange={e => setItemRating(e.target.value)}></input>
        <span className=''>{t('ratingRange')}</span>
      </div>
    );
  const textForm = () => (
      <form className='confirm-modal-form'>
        <textarea
          rows="8"
          cols="42"
          value={comment}
          onChange={e => setComment(e.target.value)}
        />
        {rating && genrateRatings()}
      </form>
  );

  const handleSubmit = () => {
    // For functions requesting both text and a rating on submit
    if(textbox && rating) return handleFunction(comment, itemRating);

    // For functions requesting text on submit
    if(textbox) return handleFunction(comment);

    // For Confirm Functions
    if(!textbox) return handleFunction(true);
  }

  return (
    <div className="modal">
      <div className="modal-content" style={{minHeight: 'auto'}}>
        <div className="container-md">
          {header && <h3>{header}</h3>}
          <div className="p-1">
            {showMessage()}
          </div>
          {textbox && textForm()}
        </div>
        <div className='text-center'>
          <button type="button" className="confirm-modal-btn mr-1" onClick={() => handleConfirmModal(null)}>Cancel</button>
          <button type="button" className="confirm-modal-btn ml-1" onClick={() => handleSubmit()}>{ textbox ? 'Submit' : 'Confirm'}</button>
        </div>
      </div>
    </div>
  );
};

ConfirmModal.propTypes = {
  options: propTypes.instanceOf(Object).isRequired,
  handleConfirmModal: propTypes.func.isRequired,
};

export default ConfirmModal;
