import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ConfirmPage = ({ user }) => {
  const { t } = useTranslation();

  return (
    <div id="ConfirmPage" className="bg-main pt-1">
      {console.log(user)}
      <div className="container-md text-center">
        <div className="section">
          <h1 className="section">
            {t('welcome')}
            <span className="text-camel text-email">{` ${user.first_name}`}</span>
            {t('signupThanks')}
          </h1>
          <h3 className="section">
            {t('checkEmail')}
            <span className="text-email">{`(${user.email})`}</span>
            <br />
            {t('accountLink')}
          </h3>
          <span className="text-email">{t('checkSpam')}</span>
        </div>
      </div>
    </div>
  )
};

ConfirmPage.propTypes = {
  user: propTypes.instanceOf(Object).isRequired,
};

export default ConfirmPage;
