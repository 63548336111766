import React, { useState, useEffect } from "react";

const CountdownTimer = ({ handleTimerFinish, setMinutes }) => {
  const [eventDate, setEventDate] = useState("");
  const [countdownStarted, setCountdownStarted] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0);

  // Start Timer on Load
  useEffect(() => {
    setCountdownStarted(true)
  }, [])

  // Set Event Date on load
  useEffect(() => {
    var countDownDate = new Date();
    const minutesInMillis = (minutes) => (minutes * 60 * 1000); // Set Date/Time to ___ minutes ahead of current Date/Time
    countDownDate.setTime(countDownDate.getTime() + minutesInMillis(setMinutes));

    setEventDate(countDownDate)
  }, [setMinutes])

  useEffect(() => {
    if (countdownStarted && eventDate) {
      const countdownInterval = setInterval(() => {
        const currentTime = new Date().getTime();
        const eventTime = new Date(eventDate).getTime();
        let remainingTime = eventTime - currentTime;

        if (remainingTime <= 0) {
          remainingTime = 0;
          clearInterval(countdownInterval);
          handleTimerFinish();
        }

        setTimeRemaining(remainingTime);
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [countdownStarted, eventDate, timeRemaining, handleTimerFinish]);

  const formatTime = (time, opts = { days: false, hours: false, minutes: true, seconds: true }) => {
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / (1000 * 60)) % 60);
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const days = Math.floor(time / (1000 * 60 * 60 * 24));

    return (
      <div className="countdown-display d-flex flex-row">
        { opts.days && (
          <div className="border border-4 rounded px-2 px-2 border-dark-subtle">
            <span className="fs-3 fw-bold">{days.toString().padStart(2, "0")}</span>
            <sup> days</sup>
          </div>
        )}

        { opts.hours && (
          <div className="border border-4 rounded px-2 px-2 border-dark-subtle">
            <span className="fs-3 fw-bold">{hours.toString().padStart(2, "0")}</span>
            <sup> hr</sup>
          </div>
        )}

        { opts.minutes && (
          <div className="border border-4 rounded px-2 px-2 border-dark-subtle">
            <span className="fs-3 fw-bold">{minutes.toString().padStart(2, "0")}</span>
            <sup> min</sup>
          </div>
        )}

        <div className="px-2 py-2">:</div>

        { opts.seconds && (
          <div className="border border-4 rounded px-2 px-2 border-dark-subtle">
            <span className="fs-3 fw-bold">{seconds.toString().padStart(2, "0")}</span>
            <sup className=""> sec</sup>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="countdown-timer-container mb-3">
      {countdownStarted && (
        <>
          {formatTime(timeRemaining)}
        </>
      )}
    </div>
  );
};

export default CountdownTimer;
