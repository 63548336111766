/* eslint-disable no-unused-vars */

import React, { useEffect, useRef } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ImageGallery from '../../functional/imageGallery';
import Star from '../../../assets/images/Star.svg';
import { newComment } from '../../misc/apiRequests';

const ForumNewComment = ({
  isReply = false, editComment = false, mainComment, comment, commentTitle, setCommentTitle,
  commentBody, setCommentBody, commentImages, setCommentImages, handleSubmitNew, handleSubmitEdit, handleCheckFileSize,
  handleNewCommentMode, handleEditCommentMode, handleImageRemoval, handleImageModal
}) => {
  const { t } = useTranslation();

  const uploadsInputRef = useRef(null);

  const submitFunction = e => {
    // console.log('\nSubmit FUNCTION', comment)

    if(editComment) { return handleSubmitEdit(e, comment, isReply); }
    if(!editComment) { return handleSubmitNew(e); }
  }

  const handleClearImages = () => {
    const uploadsInputElem = uploadsInputRef.current;

    uploadsInputElem.value = '';
    setCommentImages([]);
  }

  const populateImages = () => commentImages?.map((img, index) => (
    <button type="button" name={img.id} className='img-upload-btn-2 mt-1 mb-05' key={index + 'img'} onClick={() => handleImageModal(img.url)}>
      <img alt="complaint" src={img.url} />
    </button>
  ));

  // const populateImages = () => comment.images.map(img => (
  //   <ImageGallery key={img.id} imgInfo={img} handleImageModal={handleImageModal} handleImageRemoval={handleImageRemoval} />
  // ));

  useEffect(() => {
    if(editComment) { 
      setCommentBody(comment.body)
    } else { setCommentBody(''); }
  }, [comment, editComment, setCommentBody]);

  useEffect(() => {
    if(editComment && !isReply) { 
      setCommentTitle(comment.title)
    }
  }, [comment, editComment, setCommentTitle, isReply]);

  useEffect(() => {
    if(editComment) { 
      setCommentImages(comment.images)
    } else { setCommentImages([]); }
  }, [comment, editComment, setCommentImages]);

  return (
    <form className='confirm-modal-form account-form' onSubmit={submitFunction} encType="multipart/form-data">
      {/* {(mainComment === null && comment === null) && ( */}
      {(editComment && !isReply) && (
        <React.Fragment>
          <h3 className='mb-05'>{t('title')}</h3>
          <input
            type="text"
            className='form-control'
            value={commentTitle}
            onChange={e => setCommentTitle(e.target.value)}
            minLength="3"
            required
          />
        </React.Fragment>
      )}
      <h3 className='mb-1'>{t('enterText')}</h3>
      <textarea
        className='size-2 form-control'
        rows="8"
        cols="42"
        value={commentBody}
        onChange={e => setCommentBody(e.target.value)}
      />
      <div className='flex-col mt-2'>
        <h3 className='mb-1'>{t('f_photo')}</h3>
        <input
          type="file"
          multiple
          ref={uploadsInputRef}
          className='form-control'
          alt='Property photo uploads'
          id='photo_uploads'
          name="photo_uploads"
          accept="image/png, image/jpeg, image/jpg"
          // value={photoUpload}
          onChange={e => handleCheckFileSize(e)}
          style={{
            width: 'auto',
            height: 'auto',
            opacity: '100',
            overflow: 'hidden',
            position: 'relative',
            zIndex: 'auto'
          }}
        />
        {editComment && <div className='flex-row'>{commentImages && populateImages()}</div>}
        <button className='mt-1 btn btn-secondary' style={{padding: '16px 8px'}} type='button' onClick={() => handleClearImages()}>{t('f_clearImage')}</button>
      </div>
    </form>
  );
};

ForumNewComment.propTypes = {
  mainComment: propTypes.instanceOf(Object),
  comment: propTypes.instanceOf(Object),
  handleSubmitNew: propTypes.func.isRequired,
  handleSubmitEdit: propTypes.func.isRequired,
  handleCheckFileSize: propTypes.func.isRequired,
  handleImageRemoval: propTypes.func.isRequired,
  commentTitle: propTypes.string,
  setCommentTitle: propTypes.func,
  commentBody: propTypes.string,
  setCommentBody: propTypes.func.isRequired,
  setCommentImages: propTypes.func.isRequired
};

export default ForumNewComment;
