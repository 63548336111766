import axios from 'axios';

// const URL = 'https://arn-qrentals.com/';
const URL = 'https://api-rentalfaqz.com/';
const GoogleMapsApiKey = "AIzaSyDrkYswOt0GG2_W-MtKCJ8kR3oEHC2QMh8";

// Since all errors are returned from the backend in a string we split the string
// into an array to break them down into individual errors
const organizeErrors = errors => {
  // console.log(errors);
  const errorMsg = !Array.isArray(errors) ? errors.split(':') : errors[0];
  let errorList = errors;
  if (Array.isArray(errorMsg)) {
    if (errorMsg.length > 1) errorMsg.shift();
    errorList = errorMsg.join().trim().split(',');
  }

  return errorList;
};

// Handles the error catching of an API request
const errorCatch = error => {
  // console.log(error);
  if (!error.response) { return { errors: `${error}`, success: false }; }
  const errorMsg = error?.response.data.errors || [`${error?.response.statusText}`];

  return { errors: organizeErrors(errorMsg), success: false };
};

// API Configuration
const fetchConfiguration = async () => axios.get(`${URL}api/v1/api_configs`)
.then(response => {
  const { record } = response.data;

  return {
    record, success: true,
  };
})
.catch(error => errorCatch(error));

// User Logout
const userDeactivateAccount = async () => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  sessionStorage.clear();
  return axios.patch(`${URL}api/v1/profiles/deactivate`, null, { headers: { Authorization: login?.token } })
    .then(response => ({ message: response.data.message, success: true }))
    .catch(error => errorCatch(error));
};

// User Login
const userLogin = async user => {
  sessionStorage.clear();
  return axios.post(`${URL}api/v1/users/login`, { user })
    .then(response => {
      const retrievedUser = response.data.user;
      sessionStorage.setItem('user', JSON.stringify({ ...retrievedUser }));

      return { user: retrievedUser, success: true };
    })
    .catch(error => errorCatch(error));
};

// User Logout
const userLogout = async () => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  sessionStorage.clear();
  return axios.patch(`${URL}api/v1/users/logout`, null, { headers: { Authorization: login?.token } })
    .then(() => ({ success: true }))
    .catch(error => errorCatch(error));
};

// Is User Still Logged In?
const userLoggedIn = async () => {
  if (sessionStorage.getItem('user')) {
    const user = JSON.parse(sessionStorage.getItem('user'));
    return axios.get(`${URL}api/v1/users/logged_in`, { headers: { Authorization: user.token } })
      .then(response => {
        const retrievedUser = response.data.user;

        return { user: retrievedUser, success: true };
      })
      .catch(error => errorCatch(error));
  }
  return { user: { logged_in: false }, success: true };
};

// User Register
const userRegister = async (user) => {
  sessionStorage.clear();
  return axios.post(`${URL}api/v1/users/sign_up`, user,
    { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(response => {
      const { message } = response.data;

      return { message, success: true };
    })
    .catch(error => errorCatch(error));
};

// User Confirmation Email Resend
const userResendConfirmation = async (user) => {
  return axios.patch(`${URL}api/v1/users/resend_confirmation`, { user: user })
    .then(response => {
      const { message } = response.data;

      return { message, success: true };
    })
    .catch(error => errorCatch(error));
};

// Update a user's profile image
const userPhotoUpdate = async (photo_upload) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.patch(`${URL}api/v1/profiles/change_photo`, photo_upload,
    { headers: { 'Content-Type': 'multipart/form-data', Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Update a user's government id card upload
const userGovIDUploadUpdate = async (gov_id_upload) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.patch(`${URL}api/v1/profiles/upload_gov_id`, gov_id_upload,
    { headers: { 'Content-Type': 'multipart/form-data', Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Update a user's job letter upload
const userJobLetterUpdate = async (job_letter_upload) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.patch(`${URL}api/v1/profiles/upload_job_letter`, job_letter_upload,
    { headers: { 'Content-Type': 'multipart/form-data', Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};


// Update a user's profile image
const userProfileUpdate = async (profile) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.patch(`${URL}api/v1/profiles`, profile,
    { headers: { 'Content-Type': 'application/json', Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Fetch user by ID
const fetchUser = async id => axios.get(`${URL}users/${id}`)
  .then(response => {
    const retrievedUser = response.data.user;

    return { user: retrievedUser, success: true };
  })
  .catch(error => errorCatch(error));

// Fetch latest users
const fetchLatestUsers = async () => axios.get(`${URL}users`)
  .then(response => {
    const retrievedUsers = response.data.users;

    return { users: retrievedUsers, success: true };
  })
  .catch(error => errorCatch(error));

// User Password Change when old password is known
const changePassword = async (user) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.patch(`${URL}api/v1/users/change_password`, user,
    { headers: { Authorization: login?.token } })
    .then(response => {
      const { user } = response.data;

      return { user, success: true };
    })
    .catch(error => errorCatch(error));
};

// User Forgot Password
const forgotPassword = async email => axios.patch(`${URL}api/v1/users/forgot_password`, { email })
.then(response => {
  const { message } = response.data;

  return { message, success: true };
})
.catch(error => errorCatch(error));

// User Change Password with Token
// eslint-disable-next-line camelcase
const changePasswordWithToken = async (password_reset_token, user) => axios.patch(`${URL}api/v1/users/change_password_with_token`, { password_reset_token, user })
.then(response => {
  const { message } = response.data;

  return { message, success: true };
})
.catch(error => errorCatch(error));

// Fetch all Properties relevant to role
// eslint-disable-next-line camelcase
const fetchProperties = async (per_page = 10, page = 1) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.get(`${URL}api/v1/properties?per_page=${per_page}&page=${page}`,
    { headers: { Authorization: login?.token } })
    .then(response => {
      const {
        // eslint-disable-next-line camelcase
        records, records_params,
      } = response.data;

      return {
        records, records_params, success: true,
      };
    })
  .catch(error => errorCatch(error));
};

const fetchPropertiesByIsland = async (per_page = 10, page = 1, island) => {
  return axios.get(`${URL}api/v1/properties/by_island?island=${island}&per_page=${per_page}&page=${page}`)
    .then(response => {
      const {
        // eslint-disable-next-line camelcase
        records, records_params,
      } = response.data;

      return {
        records, records_params, success: true,
      };
    })
  .catch(error => errorCatch(error));
};

// Fetch all Properties no authorization
// eslint-disable-next-line camelcase
const fetchAllProperties = async (per_page = 10, page = 1) => {
  return axios.get(`${URL}api/v1/properties/base?per_page=${per_page}&page=${page}`)
    .then(response => {
      const {
        // eslint-disable-next-line camelcase
        records, records_params,
      } = response.data;

      return {
        records, records_params, success: true,
      };
    })
  .catch(error => errorCatch(error));
};

// Fetch featured Properties no authorization
// eslint-disable-next-line camelcase
const fetchFeaturedProperties = async (property_type = 'rental', per_page = 5, page = 1) => {
  return axios.get(`${URL}api/v1/properties/featured?property_type=${property_type}&per_page=${per_page}&page=${page}`)
    .then(response => {
      const {
        // eslint-disable-next-line camelcase
        records, records_params,
      } = response.data;

      return {
        records, records_params, success: true,
      };
    })
  .catch(error => errorCatch(error));
};

// Fetch all Properties relevant to role
// eslint-disable-next-line camelcase
const fetchRentedProperties = async (per_page = 5, page = 1) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.get(`${URL}api/v1/properties/rented?per_page=${per_page}&page=${page}`,
    { headers: { Authorization: login?.token } })
    .then(response => {
      const {
        // eslint-disable-next-line camelcase
        records, records_params,
      } = response.data;

      return {
        records, records_params, success: true,
      };
    })
  .catch(error => errorCatch(error));
};

// Fetch Specific Properties
const fetchProperty = async (id) => axios.get(`${URL}api/v1/properties/${id}`)
.then(response => {
  const {
    // eslint-disable-next-line camelcase
    record, advertisements, property_history,
  } = response.data;

  return {
    record, advertisements, property_history, success: true,
  };
})
.catch(error => errorCatch(error));

// Fetch all Properties relevant to role
// eslint-disable-next-line camelcase
const fetchFilteredProperties = async (per_page = 5, page = 1, filter) => {
  return axios.post(`${URL}api/v1/properties/filter_by`, filter)
    .then(response => {
      const {
        // eslint-disable-next-line camelcase
        records, records_params,
      } = response.data;

      return {
        records, records_params, success: true,
      };
    })
  .catch(error => errorCatch(error));
};

// Fetch all of a selected Property's Appointments
const fetchAppointments = async (id, date) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.get(`${URL}api/v1/properties/${id}/appointments?date=${date}`,
    { headers: { Authorization: login?.token } })
    .then(response => {
      const {
        // eslint-disable-next-line camelcase
        records, records_params,
      } = response.data;

      return {
        records, records_params, success: true,
      };
    })
  .catch(error => errorCatch(error));
};

// Create a New Property record
const newAppointment = async (id, appointment) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.post(`${URL}api/v1/properties/${id}/appointments/create`, appointment,
    { headers: { 'Content-Type': 'multipart/form-data', Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Create a New Property record
const removeAppointment = async (id, app_id) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.delete(`${URL}api/v1/properties/${id}/appointments/${app_id}/remove`,
    { headers: { 'Content-Type': 'multipart/form-data', Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Create a New Property record
const newProperty = async (property) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.post(`${URL}api/v1/properties`, property,
    { headers: { 'Content-Type': 'multipart/form-data', Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Update an existing Property record
const updateProperty = async (property, propertyID) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.patch(`${URL}api/v1/properties/${propertyID}`, property,
    { headers: { 'Content-Type': 'multipart/form-data', Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Update an existing Property record
const removeProperty = async (propertyID) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.delete(`${URL}api/v1/properties/${propertyID}`,
    { headers: { 'Content-Type': 'multipart/form-data', Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Inquire about a Property record
const inquireProperty = async (propertyID, comment = '') => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.post(`${URL}api/v1/properties/${propertyID}/inquire`, comment,
    { headers: { 'Content-Type': 'multipart/form-data', Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Fetch all users that inquired about a Specific Property
const fetchTenantInquiries = async (per_page = 10, page = 1, id) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.get(`${URL}api/v1/properties/${id}/tenant_inquiries?per_page=${per_page}&page=${page}`,
  { headers: { Authorization: login?.token } })
  .then(response => {
    const {
      // eslint-disable-next-line camelcase
      records, records_params
    } = response.data;

    return {
      records, records_params, success: true
    };
  })
  .catch(error => errorCatch(error));
};

// Fetch all residents of a Specific Property
const fetchPropertyResidents = async (id) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.get(`${URL}api/v1/properties/${id}/residents`,
  { headers: { Authorization: login?.token } })
  .then(response => {
    const {
      // eslint-disable-next-line camelcase
      records,
    } = response.data;

    return {
      records, success: true,
    };
  })
  .catch(error => errorCatch(error));
};

// Update an existing Property record with a new tenant
const addTenant = async (tenantID, propertyID) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.patch(`${URL}api/v1/properties/${propertyID}/add_tenant/${tenantID}`, null,
    { headers: { 'Content-Type': 'multipart/form-data', Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Update an existing Property record with a new tenant
const denyTenant = async (tenantID, propertyID) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.patch(`${URL}api/v1/properties/${propertyID}/deny_tenant/${tenantID}`, null,
    { headers: { 'Content-Type': 'application/json', Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Update an existing Property record by removing a tenant/resident
const removeTenant = async (tenant, propertyID) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.patch(`${URL}api/v1/properties/${propertyID}/remove_tenant/${tenant.tenant.id}`, tenant,
    { headers: { 'Content-Type': 'application/json', Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Rate a property after being removed as a tenant
const rateProperty = async (propertyID, property) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.patch(`${URL}api/v1/properties/${propertyID}/rate_property`, property,
    { headers: { 'Content-Type': 'application/json', Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Fetch featured Properties no authorization
// eslint-disable-next-line camelcase
const fetchPropertyComments = async (per_page = 10, page = 1, comment_type, comment_active = true, property_id) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.get(`${URL}api/v1/comments/properties/${property_id}?per_page=${per_page}&page=${page}&comment_type=${comment_type}&comment_active=${comment_active}`,
    { headers: { Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};


// Fetch Specific Properties
const fetchComment = async (per_page = 10, page = 1, comment_id) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.get(`${URL}api/v1/comments/${comment_id}?per_page=${per_page}&page=${page}`,
    { headers: { Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Create a New Property record
const newComment = async (comment, property_id) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.post(`${URL}api/v1/comments/properties/${property_id}`, comment,
    { headers: { 'Content-Type': 'multipart/form-data', Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Update an existing Property record
const updateComment = async (comment_id, comment) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.patch(`${URL}api/v1/comments/${comment_id}`, comment,
    { headers: { 'Content-Type': 'multipart/form-data', Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Update an existing Property record
const closeComment = async (comment_id) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.patch(`${URL}api/v1/comments/${comment_id}/close`, null,
    { headers: { 'Content-Type': 'application/json', Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Fetch user's recieved notifications
const fetchNotifications = async (per_page = 10, page = 1) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.get(`${URL}api/v1/notifications?per_page=${per_page}&page=${page}`,
    { headers: { Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Update viewed field on Notification
const viewNotification = async (notificationID) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.patch(`${URL}api/v1/notifications/${notificationID}/view`, null,
    { headers: { 'Content-Type': 'application/json', Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Fetch all resident transactions
// eslint-disable-next-line camelcase
const fetchResidentTransactions = async (per_page = 5, page = 1) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.get(`${URL}api/v1/properties/resident/transactions?per_page=${per_page}&page=${page}`,
    { headers: { Authorization: login?.token } })
    .then(response => {
      const {
        // eslint-disable-next-line camelcase
        records, records_params,
      } = response.data;

      return {
        records, records_params, success: true,
      };
    })
  .catch(error => errorCatch(error));
};

// Fetch all property transactions
// eslint-disable-next-line camelcase
const fetchPropertyTransactions = async (propertyID, per_page = 20, page = 1) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.get(`${URL}api/v1/properties/${propertyID}/transactions?per_page=${per_page}&page=${page}`,
    { headers: { Authorization: login?.token } })
    .then(response => {
      const {
        // eslint-disable-next-line camelcase
        records, records_params,
      } = response.data;

      return {
        records, records_params, success: true,
      };
    })
  .catch(error => errorCatch(error));
};

// Fetch all property groups relevant to user
// eslint-disable-next-line camelcase
const fetchPropertyGroups = async (per_page = 10, page = 1) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.get(`${URL}api/v1/property_groups?per_page=${per_page}&page=${page}`,
    { headers: { Authorization: login?.token } })
    .then(response => {
      const {
        // eslint-disable-next-line camelcase
        records, records_params,
      } = response.data;

      return {
        records, records_params, success: true,
      };
    })
  .catch(error => errorCatch(error));
};

const fetchPropertyGroupsByIsland = async (per_page = 10, page = 1, island) => {
  return axios.get(`${URL}api/v1/property_groups/by_island?island=${island}&per_page=${per_page}&page=${page}`)
    .then(response => {
      const {
        // eslint-disable-next-line camelcase
        records, records_params,
      } = response.data;

      return {
        records, records_params, success: true,
      };
    })
  .catch(error => errorCatch(error));
};

// Fetch property group by id
// eslint-disable-next-line camelcase
const fetchPropertyGroup = async (groupID) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.get(`${URL}api/v1/property_groups/${groupID}`,
    { headers: { Authorization: login?.token } })
    .then(response => {
      const {
        // eslint-disable-next-line camelcase
        record
      } = response.data;

      return {
        record, success: true,
      };
    })
  .catch(error => errorCatch(error));
};

// Create a New Property Group record
const newPropertyGroup = async (property_group) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.post(`${URL}api/v1/property_groups`, property_group,
    { headers: { 'Content-Type': 'application/json', Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Update an existing Property Group record
const updatePropertyGroup = async (groupID, property_group) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.patch(`${URL}api/v1/property_groups/${groupID}`, property_group,
    { headers: { 'Content-Type': 'application/json', Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Update an existing Property Group record
const destroyPropertyGroup = async (groupID) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.delete(`${URL}api/v1/property_groups/${groupID}`,
    { headers: { 'Content-Type': 'application/json', Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Fetch all favorites relevant to user
const fetchFavorites = async (per_page = 10, page = 1) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.get(`${URL}api/v1/favorites?per_page=${per_page}&page=${page}`,
    { headers: { Authorization: login?.token } })
    .then(response => {
      const {
        // eslint-disable-next-line camelcase
        records, records_params,
      } = response.data;

      return {
        records, records_params, success: true,
      };
    })
  .catch(error => errorCatch(error));
};

// Check if property group is favored by relevant user
const favoredPropertyGroup = async (groupID) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.get(`${URL}api/v1/property_groups/${groupID}/favorited`,
    { headers: { Authorization: login?.token } })
    .then(response => {
      const {
        // eslint-disable-next-line camelcase
        message, status
      } = response.data;

      return {
        message, status, success: true,
      };
    })
  .catch(error => errorCatch(error));
};

// Check if property is favored by relevant user
const favoredProperty = async (propertyID) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.get(`${URL}api/v1/properties/${propertyID}/favorited`,
    { headers: { Authorization: login?.token } })
    .then(response => {
      const {
        // eslint-disable-next-line camelcase
        message, status
      } = response.data;

      return {
        message, status, success: true,
      };
    })
  .catch(error => errorCatch(error));
};

// Favorite an existing Property record
const favoriteProperty = async (propertyID) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.patch(`${URL}api/v1/properties/${propertyID}/favorite`, null,
    { headers: { 'Content-Type': 'application/json', Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Favorite an existing Property Group record
const favoritePropertyGroup = async (groupID) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.patch(`${URL}api/v1/property_groups/${groupID}/favorite`, null,
    { headers: { 'Content-Type': 'application/json', Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Fetch current Subscription
// eslint-disable-next-line camelcase
const fetchCurrentSubscription = async () => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.get(`${URL}api/v1/transactions/subscriptions`,
    { headers: { Authorization: login?.token } })
    .then(response => {
      const {
        // eslint-disable-next-line camelcase
        subscription
      } = response.data;

      return {
        subscription, success: true,
      };
    })
  .catch(error => errorCatch(error));
};

// Create a New Subscription record
const newSubscription = async (subscription) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.post(`${URL}api/v1/transactions/subscriptions`, subscription,
    { headers: { 'Content-Type': 'application/json', Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Create a New Payment Request
const paymentRequest = async (subscription) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.post(`${URL}api/v1/transactions/payment_gateway_request`, subscription,
    { headers: { 'Content-Type': 'application/json', Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Complete Payment Gateway Purchase
const paymentPurchase = async (spi_token) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.post(`${URL}api/v1/transactions/payment_gateway_payment`, { spi_token: spi_token },
    { headers: { 'Content-Type': 'application/json', Authorization: login?.token } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Send a Tour Request
const sendTourRequest = async (tour) => {
  return axios.post(`${URL}api/v1/properties/request_tour`, tour,
    { headers: { 'Content-Type': 'application/json' } })
    .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Create a complaint
const reportComplaint = async (complaint) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.post(`${URL}api/v1/report_complaint`, complaint,
  { headers: { 'Content-Type': 'multipart/form-data', Authorization: login?.token } })
  .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Send invite Token to tenant
const inviteTenant = async (email) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.patch(`${URL}api/v1/properties/invite_tenant_token`, email,
  { headers: { 'Content-Type': 'multipart/form-data', Authorization: login?.token } })
  .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

// Add tenant via invite token
const addTenantByInviteToken = async (invitation_token) => {
  let login;
  if (sessionStorage.getItem('user')) login = JSON.parse(sessionStorage.getItem('user'));
  return axios.patch(`${URL}api/v1/properties/add_tenant_by_token`, invitation_token,
  { headers: { 'Content-Type': 'multipart/form-data', Authorization: login?.token } })
  .then(response => {
      const data = response.data;

      return { ...data, success: true };
    })
    .catch(error => errorCatch(error));
};

export {
  URL, GoogleMapsApiKey,
  paymentPurchase, paymentRequest, fetchConfiguration,
  fetchCurrentSubscription, newSubscription,
  userDeactivateAccount, userProfileUpdate,
  userLogin, userLogout, userLoggedIn, userRegister, userResendConfirmation,
  fetchUser, fetchLatestUsers,
  userPhotoUpdate, userGovIDUploadUpdate, userJobLetterUpdate,
  forgotPassword, changePasswordWithToken, changePassword,
  fetchProperties, fetchProperty, fetchRentedProperties,
  fetchPropertiesByIsland,
  fetchAllProperties, fetchFeaturedProperties, fetchFilteredProperties,
  newProperty, updateProperty, removeProperty, inquireProperty, rateProperty,
  fetchTenantInquiries, fetchPropertyResidents, denyTenant, addTenant, removeTenant,
  fetchPropertyComments, fetchComment, newComment, updateComment, closeComment,
  fetchNotifications, viewNotification,
  fetchResidentTransactions, fetchPropertyTransactions,
  fetchPropertyGroups, fetchPropertyGroup, newPropertyGroup,
  fetchPropertyGroupsByIsland,
  updatePropertyGroup, destroyPropertyGroup,
  fetchFavorites, favoredProperty, favoredPropertyGroup,
  favoriteProperty, favoritePropertyGroup,
  sendTourRequest, reportComplaint, inviteTenant, addTenantByInviteToken,
  fetchAppointments, newAppointment, removeAppointment,
};
