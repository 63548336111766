/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
// import translate from "translate";
import Mail from '../../assets/images/mail_fill.svg';
import Phone from '../../assets/images/phone_fill.svg';

import '../../assets/css/miscPages.css';

// translate.engine = "google";

const ContactUs = ({currentLang}) => {
  const { t } = useTranslation();

  // const [contactText, setContactText] = useState(t('contactText'));

  const contactRef = useRef(null);

  // useEffect(() => {
  //   const contactDiv = contactRef.current;
  //   setContactText(t('contactText'));
  //   if (contactDiv) contactDiv.innerHTML = contactText;
  // }, [contactRef, contactText, currentLang, t]);

  return (
    <div className='screen-height'>
      <div className='contactUsBG'>
        <span role="img" aria-label="https://unsplash.com/photos/SBOHLtENzEY?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"/>  
        <div className='container-md'>
          <div className='contactUsForeground mx-auto'>
            <h3 className='text-center border-bottom'>{t('contact')}</h3>
            <div ref={contactRef} className='size-1'>
              {/* {contactText} */}
              <div className='flex-row mt-2'>
                <img src={Phone} alt='phone' className='mr-1' style={{width: '1.65em'}}/>
                <a href="tel:2428202234">(242) 820-2234</a>
              </div>
              <div className='flex-row mt-1'>
                <img src={Mail} alt='Mail' className='mr-1' style={{width: '1.65em'}}/>
                <a href="mailto:rentalfaqz@gmail.com">rentalfaqz@gmail.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ContactUs;
