/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { userLogin, userResendConfirmation } from '../../misc/apiRequests';
import '../../../assets/css/users.css';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
// import userData from '../../misc/jsonData/loginData';

const Login = ({ handleModal, handleMessagesModal, handleLoader, handleLogin }) => {
  const { t } = useTranslation();

  const [credential, setCredential] = useState('');
  const [password, setPassword] = useState('');
  const [errorsCaught, setErrorsCaught] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    const user = { email: credential, backup_email: credential, password };

    handleLoader(true);
    userLogin(user)
       .then(response => {
         if (response.success) {
          handleLogin(response.user);
          Swal.fire({
            position: 'top-end',
            toast: true,
            timerProgressBar: true,
            icon: 'success',
            title: 'Successfully logged in',
            showConfirmButton: false,
            timer: 2000
          })
        }
         if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
          setErrorsCaught(response.errors);
        }
         handleLoader(false);
       });
  };

  const handleResendConfirm = e => {
    e.preventDefault();
    const user = { email: credential, backup_email: credential };

    handleLoader(true);
    userResendConfirmation(user)
       .then(response => {
         if (response.success) { 
          // handleMessagesModal(response.message);
          setErrorsCaught('');
          Swal.fire({
            position: 'top-end',
            toast: true,
            timerProgressBar: true,
            icon: 'success',
            title: response.message,
            showConfirmButton: false,
            timer: 2000
          })
         }
         if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
         }
         handleLoader(false);
       });
  };

  // FAKE DATA LOGIN
  // const handleSubmit = e => {
  //   e.preventDefault();
  //   const user = { email: credential, backup_email: credential, password };
  //   const errors = [];

  //   handleLoader(true);
  //   if (userData.password !== user.password || user.email !== (userData.email || userData.backup_email)) errors.push('Incorrect credentials')
  //   if (errors.length <= 0) handleLogin(userData);
  //   if (errors.length >= 1) handleModal(errors);
  //   handleLoader(false);
  // };

  return (
    <div id="LoginPage" className="bg-main">
      <div className='loginBG'>
        <span role="img" aria-label="https://unsplash.com/photos/fFx7m5_07Ro?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"/>  
        <div className="container" style={{height: '90vh'}}>
          <form className="login-form" style={{marginTop: '15%'}} onSubmit={handleSubmit}>
            <div className='text-center mb-1'>
              <h3>{t('f_login')}</h3>
              <span>{t('or')} <Link to="/sign_up">{t('f_createAcct')}</Link></span>
            </div>
            <span className='form-sub-text'>{t('f_email')}</span>
            <input
              type="email"
              className='form-control'
              value={credential}
              onChange={e => setCredential(e.target.value)}
              minLength="3"
              required
            />
            <span className='form-sub-text'>{t('f_password')}</span>
            <input
              type="password"
              className='form-control'
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
            />
            <button type="submit" className='btn'>{t('login')}</button>
            { errorsCaught[0] === 'Account not activated, check email to activate account.' && (
              <button type='button' className='mt-1 btn' style={{backgroundColor: 'teal', borderColor: 'teal'}} onClick={e => handleResendConfirm(e)}>{t('f_resendEmail')}</button>
            )}
            <Link to="/forgot_password" className='size-14 mt-1'>{t('f_forgotPass')}</Link>
          </form>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  handleModal: propTypes.func.isRequired,
  handleMessagesModal: propTypes.func.isRequired,
  handleLoader: propTypes.func.isRequired,
  handleLogin: propTypes.func.isRequired,
};

export default Login;
