function resetAnim(elem, anim) {
  elem.classList.remove(anim);
  /* eslint-disable no-void */
  void elem.offsetWidth;
  /* eslint-enable no-void */
  elem.classList.add(anim);
}

const windowWidth = () => window.innerWidth || document.documentElement.clientWidth
|| document.body.clientWidth;

// Phone number format
const isNumericInput = (event) => {
	const key = event.keyCode;
	return ((key >= 48 && key <= 57) || // Allow number line
		(key >= 96 && key <= 105) // Allow number pad
	);
};

const isModifierKey = (event) => {
	const key = event.keyCode;
	return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
		(key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
		(key > 36 && key < 41) || // Allow left, up, right, down
		(
			// Allow Ctrl/Command + A,C,V,X,Z
			(event.ctrlKey === true || event.metaKey === true) &&
			(key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
		)
};

const enforceFormat = (event) => {
	// Input must be of a valid number format or a modifier key, and not longer than ten digits
	if(!isNumericInput(event) && !isModifierKey(event)){
		event.preventDefault();
	}
};

const formatToPhone = (event) => {
	if(isModifierKey(event)) {return;}

	// I am lazy and don't like to type things more than once
	const target = event.target;
	const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
	const zip = input.substring(0,3);
	const middle = input.substring(3,6);
	const last = input.substring(6,10);

	if(input.length > 6){target.value = `(${zip}) ${middle} - ${last}`;}
	else if(input.length > 3){target.value = `(${zip}) ${middle}`;}
	else if(input.length > 0){target.value = `(${zip}`;}
};

function numberWithCommas(x) {
	return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const timeFormat = (time) => {
	const hours = time.slice(0,2);
	const intHours = parseInt(hours);
	const skybreak = parseInt(hours) < 12 ? ' AM' : ' PM';

	let hourStr = hours[0] === '0' ? hours[1] : hours;
	if(hours === '00'){ hourStr = '12'; }
	if(intHours >= 13){ hourStr = String(intHours - 12); }

	return hours ? `${hourStr}:${time.slice(3)}${skybreak}` : 'N/A'
};

const officeHours = (jsonString, todayOnly = true) => {
	
	const officeHours = jsonString ? JSON.parse(jsonString) : null;
	if(!officeHours){return}

	const dayNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

	if(todayOnly){
		const today = new Date();
		const day = today.getDay();
		const currentDayName = dayNames[day];

	 return `${timeFormat(officeHours[currentDayName]?.start_time)} - ${timeFormat(officeHours[currentDayName]?.end_time)} Today`;
	}

	const sameDays = [];
	for (let c = 0; c < Object.keys(officeHours).length; c++) {
		let dayChosen = officeHours[dayNames[c]];
		let sameDaysTogether = [];
		for (let i = 0; i < Object.keys(officeHours).length; i++) {
			const dayStart = officeHours[dayNames[i]].start_time;
			const dayEnd = officeHours[dayNames[i]].end_time;
			if(dayStart === dayChosen.start_time && dayEnd === dayChosen.end_time){
				sameDaysTogether.push(dayNames[i]);
			}
		}
		sameDays.push(sameDaysTogether);
	}

	let sameDaysFiltered = [];
	sameDaysFiltered = sameDays;
	let i = 0;
	while (i < sameDaysFiltered.length) {
		let stringArray = sameDays[i-1]?.join('');
		if(stringArray === sameDays[i].join('')){
			sameDaysFiltered.splice(i,1);
			i -= 1;
		}
		i ++;
	}

	const elemHours = () => sameDaysFiltered.map((d, index) => {
		let elem = null;
		const startTime = timeFormat(officeHours[d[0]].start_time);
		const endTime = timeFormat(officeHours[d[0]].end_time);
		const timeResult = startTime !=='N/A' ? `${startTime} - ${endTime}` : 'Closed';

		if(d.length <= 1){
			elem = (
				<div key={index} className="mb-3">
					<span className='text-camel'>
						<b>{d[0]}</b>
						<br/>
						{timeResult}
					</span>
				</div>)
		} else {
			elem = (
				<div key={index} className="mb-3">
					<span className='text-camel'>
						<b>{d[0].substring(0,3)} - {d[d.length - 1].substring(0,3)}</b>
						<br/>
						{timeResult}
					</span>
				</div>)
		}

		return elem;
	});

	return (
		<div className='flex-col'>
			{elemHours()}
		</div>
	)
};

export { resetAnim, windowWidth, enforceFormat,
				 formatToPhone, numberWithCommas, timeFormat,
				 officeHours };
