import React, { useEffect } from "react"
import { useLocation } from 'react-router-dom';
import ErrorX from '../../../../assets/images/payments/xmark-solid.svg'
import CheckMark from '../../../../assets/images/payments/check-solid.svg'
import { paymentPurchase } from "../../../misc/apiRequests";

const PaymentCompletion = ({ user, setRedirect, handleMessagesModal, handleLoader }) => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();

  const success = query.get('success') === 'false' ? false : true
  const error = query.get('error');
  const SpiToken = query.get('spi_token');

  // const [] = useState();

  useEffect(() => {
    // Handle Subscription plan payment
    const handlePaymentCompletion = () => {
      handleLoader(true);
      paymentPurchase(SpiToken)
        .then(response => {
          if (response.success) {
            handleMessagesModal('Payment was successful', response.message, 'success');
            // console.log(response);
          }
          if (!response.success) {
            handleMessagesModal('Submission Issue', Array.isArray(response.errors) ? response.errors.join("\n") : response.errors, 'error');
          }
          // setShowPaymentAuth(false)
          handleLoader(false);
        });
    }

    if (success && SpiToken) { handlePaymentCompletion(); }
  }, [SpiToken, success, handleLoader, handleMessagesModal])

  const unsuccessfulRender = () => (
    <div className="container-md">
      <div className="text-center">
        <div className="mb-4">
          <button className="btn btn-danger rounded-circle" style={{width: '110px', height: '110px'}}>
            <img src={ErrorX} className="w-50" style={{ filter: 'invert(1)' }} alt="error"/>
          </button>
        </div>
        <p className="text-danger fw-bold fs-2">Your payment could not be processed</p>
        <p className="fs-4">Your Bank declined your payment saying <span className="fw-bold">“{error}”</span>. Unfortunately, we cannot solve this problem from our side. You need to contact your Bank and explain the problem to them.</p>
      </div>
      {/* <div className="d-flex flex-row justify-content-center">
        <button className="btn btn-primary fs-4 mt-4" onClick={() => setRedirect('/account?page=payment')}>Okay, I Understand</button>
      </div> */}
    </div>
  )

  const successfulRender = () => (
    <div className="container-md">
      <div className="text-center">
        <div className="mb-4">
          <button className="btn btn-success rounded-circle" style={{width: '110px', height: '110px'}}>
            <img src={CheckMark} className="w-50" style={{ filter: 'invert(1)' }} alt="success"/>
          </button>
        </div>
        <p className="text-success fw-bold fs-2">Your payment was processed successfully</p>
        <p className="fs-4">Thank you for your purchase.</p>
      </div>
      {/* <div className="d-flex flex-row justify-content-center">
        <button className="btn btn-primary fs-4 mt-4" onClick={() => setRedirect('/account?page=payment')}>Okay, I Understand</button>
      </div> */}
    </div>
  )

  return success ? successfulRender() : unsuccessfulRender()
}

export default PaymentCompletion