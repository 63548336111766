import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../../assets/css/property.css';
// import remove from '../../assets/images/exit.svg';

const ImageGallery = ({ imgInfo, handleImageModal, handleImageRemoval }) => {
  const { t } = useTranslation();

  const { id, url } = imgInfo;
  const [removed, setRemoved] = useState(false);

  const handleRemoved = e => {
    setRemoved(!removed);

    handleImageRemoval(e, id);
  };


  return (
    <div className='text-center mb-1'>
      <button type="button" className={`img-upload-btn btn mt-1 mr-05 mb-05 ${removed && 'danger-btn'}`} key={id} onClick={() => handleImageModal(url)}>
        <img alt="Property" src={url} />
      </button>
      { removed && <button type='button' className='danger-btn btn' onClick={(e) => handleRemoved(e)}>{t('f_cancel')}</button>}
      { !removed && <button type='button' className='danger-btn btn' onClick={(e) => handleRemoved(e)}>{t('f_remove')}</button>}
      {/* { !removed && <button type='button' className='danger-btn' onClick={(e) => handleRemoved(e)}><img src={remove}/></button>} */}
    </div>
  );
};

ImageGallery.propTypes = {
  imgInfo: propTypes.instanceOf(Object).isRequired,
  handleImageModal: propTypes.func,
  handleImageRemoval: propTypes.func,
};

export default ImageGallery;
