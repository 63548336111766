import React, { useState } from "react";
import { paymentRequest } from "../../../misc/apiRequests";
import countries from "../../../misc/jsonData/countries";
import visaIMG from "../../../../assets/images/payments/visa-secure.jpg"
import MasterCardIMG from "../../../../assets/images/payments/mastercard-idcheck.jpg"
import AmexIMG from "../../../../assets/images/payments/amex-safekey.jpg"
import { useTranslation } from "react-i18next";
import PhoneInput from 'react-phone-number-input';

const CheckoutPage = ({ handleLoader, handleMessagesModal, subscriptionPlanSelected,
                        setShowPaymentForm, setPaymentData,
                        setShowPaymentAuth }) => {
  const { t } = useTranslation();

  const [cardFormData, setCardFormData] = useState({
    Amount: subscriptionPlanSelected.subscription.payment_amount,
    CardholderName: "John Doe",
    CardPan: "4012010000020005", // "4111111111111111111",
    CardExpiration: "2612",
    CardCvv: "123",
    RecurringInitial: true,
    // TestCase: "V2-06-YA"
  })
  const [cardMonth, setCardMonth] = useState('');
  const [cardYear, setCardYear] = useState('');

  const [billingFormData, setBillingFormData] = useState({
    FirstName: "John",
    LastName: "Doe",
    // Line1: "",
    // Line2: "",
    // City: "",
    // State: "",
    // PostalCode: "",
    CountryCode: "",
    EmailAddress: "",
    PhoneNumber: ""
  })

  const populateCountries = () => {
    return countries.map((x, index) => (
      <option key={index} value={x.number}>{x.name}</option>
    ))
  }

  const handleInput = (ev, form = 'card', inputType = 'text') => {
    // ev.preventDefault();

    if (form === 'card') {
      if (inputType === 'text') setCardFormData({ ...cardFormData, [ev.target.name]: ev.target.value });
      else if(inputType === 'checkbox') setCardFormData({ ...cardFormData, [ev.target.name]: ev.target.checked });
    }
    if (form === 'bill') {
      if (inputType === 'text') setBillingFormData({ ...billingFormData, [ev.target.name]: ev.target.value })
      else if(inputType === 'phone') setBillingFormData({ ...billingFormData, PhoneNumber: ev });
    }
  }

  const handleFormSubmit = (ev) => {
    ev.preventDefault();

    const data = { ...subscriptionPlanSelected,
                   source: { ...cardFormData, CardExpiration: `${cardYear}${cardMonth}` },
                   billingAddress: { ...billingFormData }
                  };

    // return console.log(data);

    handleLoader(true);
    paymentRequest(data)
      .then(response => {
        if (response.success) {
          if(response.record?.Errors?.length >=1) {
            handleMessagesModal('Submission Issue', 'Please ensure all information is correct.', 'error');
          } else {
            setPaymentData(response.record);
            // console.log(response.record);
            setShowPaymentForm(false);
            setShowPaymentAuth(true);
          }
        }
        if (!response.success) {
          handleMessagesModal('Submission Issue', Array.isArray(response.errors) ? response.errors.join("\n") : response.errors, 'error');
        }
        handleLoader(false);
      });
  }

  const checkMonthValue = e => {
    const value = e.target.value.toString();
    // adds 0 to month user input like 9 -> 09
    if (value.length === 1 && value > 1) {
      e.target.value = "0" + value;
    }
    // bounds
    if (value === "00") {
      e.target.value = "01";
    } else if (value > 12) {
      e.target.value = "12";
    }
  
    setCardMonth(e.target.value);
  };


  return (
    <div className="container mx-auto max-w-2xl drop-shadow-2xl">
      <form onSubmit={handleFormSubmit} className="" id="FrmCheckout">
          <div className="px-4 py-2 bg-white sm:p-6">
            <div className="flex items-center justify-between mb-5">
              <div className="flex-1 min-w-0">
                <h3 className="text-2xl leading-8 font-extrabold text-gray-900">
                  Checkout
                </h3>
                <p className="mt-1 text-sm leading-5 text-gray-500">
                  Please fill out the form below to complete your purchase.
                </p>
              </div>
            </div>
            <div className="mb-4 d-flex flex-row align-items-center justify-content-center overflow-hidden">
              <div className="w-25" style={{maxWidth: '120px'}}><img src={visaIMG} className="img-fluid" alt="visa secure"/></div>
              <div className="w-25 mx-2" style={{maxWidth: '120px'}}><img src={MasterCardIMG} className="img-fluid" alt="mastercard id check"/></div>
              <div className="w-25" style={{maxWidth: '120px'}}><img src={AmexIMG} className="img-fluid" alt="amex safe key"/></div>
            </div>
            <div className="mt-5">
              <div className="row g-3">
                  <div className="col-md-8" id="CardholderField">
                    <label htmlFor="CardholderName" className="required form-label">Name on card</label>
                    <input
                      type="text"
                      required
                      maxLength="45"
                      name="CardholderName"
                      id="CardholderName"
                      autoComplete="CardholderName"
                      className="form-control border border-warning"
                      value={cardFormData?.CardholderName}
                      onChange={(ev) => handleInput(ev, 'card')}
                    />
                  </div>
                  <div className="col-md-4" id="CardPanField">
                    <label htmlFor="CardPan" className="required form-label">Card Number</label>
                    <input
                      type="text"
                      required
                      maxLength="19"
                      placeholder="XXXX-XXXX-XXXX-XXXX"
                      name="CardPan"
                      id="CardPan"
                      autoComplete="CardPan"
                      className="form-control"
                      value={cardFormData?.CardPan}
                      onChange={(ev) => handleInput(ev, 'card')}
                    />
                  </div>
                  <div className="col-md-4" id="CardExpirationField">
                    <label htmlFor="CardExpiration" className="required form-label">{t('f_cardExpiry')} (MM/YY)</label>
                    {/* <input
                      type="text"
                      required
                      maxLength="4"
                      placeholder="YY / MM"
                      name="CardExpiration"
                      id="CardExpiration"
                      autoComplete="CardExpiration"
                      className="form-control border border-warning"
                      value={cardFormData?.CardExpiration}
                      onChange={(ev) => handleInput(ev, 'card')}
                    /> */}
                    <div className="d-flex w-50">
                      <input
                        type="text"
                        name="month"
                        placeholder="MM"
                        className="form-control border border-warning"
                        maxLength="2"
                        size="2"
                        pattern="[0-9]*"
                        inputMode="numerical"
                        data-pattern-validate
                        onChange={e => checkMonthValue(e)}
                        required
                      />
                      <span className="fs-3 fw-bold">{" / "}</span>
                      <input
                        type="text"
                        name="year"
                        placeholder="YY"
                        className="form-control border border-warning"
                        maxLength="2"
                        size="2"
                        pattern="[0-9]*"
                        inputMode="numerical"
                        data-pattern-validate
                        onChange={e => setCardYear(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4" id="CardCvvField">
                    <label htmlFor="CardCvv" className="required form-label">Security Code (CVV2)</label>
                    <input
                      type="text"
                      required
                      maxLength="4"
                      placeholder="XXX"
                      name="CardCvv"
                      id="CardCvv"
                      autoComplete="CardCvv"
                      className="form-control border border-warning"
                      value={cardFormData?.CardCvv}
                      onChange={(ev) => handleInput(ev, 'card')}
                    />
                  </div>
                  <div className="col-md-4" id="AmountField">
                    <label htmlFor="Amount" className="form-label">Amount to Pay ($ BSD)</label>
                    <input
                      type="number"
                      name="Amount"
                      id="Amount"
                      step={0.01}
                      value={cardFormData.Amount}
                      disabled
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-12">
                    <h4 className="text-lg font-medium text-gray-900">Billing Details</h4>
                  </div>
                  <div className="col-md-6" id="BillingFirstNameField">
                    <label htmlFor="FirstName" className="required form-label">First name</label>
                    <input
                      type="text"
                      name="FirstName"
                      id="FirstName"
                      autoComplete="FirstName"
                      className="form-control border border-warning"
                      value={billingFormData?.FirstName}
                      onChange={(ev) => handleInput(ev, 'bill')}
                      required
                    />
                  </div>
                  <div className="col-md-6" id="BillingLastNameField">
                    <label htmlFor="LastName" className="required form-label">Last name</label>
                    <input
                      type="text"
                      name="LastName"
                      id="LastName"
                      autoComplete="LastName"
                      className="form-control border border-warning"
                      value={billingFormData?.LastName}
                      onChange={(ev) => handleInput(ev, 'bill')}
                      required
                    />
                  </div>
                  <div className="col-md-6" id="BillingEmailField">
                    <label htmlFor="EmailAddress" className="required form-label">Email address</label>
                    <input
                      type="text"
                      name="EmailAddress"
                      id="EmailAddress"
                      required
                      autoComplete="email"
                      className="form-control border border-warning"
                      value={billingFormData?.EmailAddress}
                      onChange={(ev) => handleInput(ev, 'bill')}
                    />
                  </div>
                  <div className="col-md-6" id="BillingPhoneField">
                    {/* <label htmlFor="PhoneNumber" className="form-label">Phone (Must include country code)</label>
                    <input
                      type="text"
                      name="PhoneNumber"
                      id="PhoneNumber"
                      autoComplete="phone"
                      className="form-control"
                      value={billingFormData?.PhoneNumber}
                      onChange={(ev) => handleInput(ev, 'bill')}
                    /> */}
                    <label htmlFor="PhoneNumber" className="form-label">{t('f_phone')}</label>
                    <PhoneInput
                      placeholder="Enter home/mobile phone number"
                      className="form-control"
                      name="PhoneNumber"
                      id="PhoneNumber"
                      autoComplete="phone"
                      value={billingFormData?.PhoneNumber}
                      onChange={(ev) => handleInput(ev, 'bill', 'phone')}
                      // defaultCountry={"BS"}
                      limitMaxLength={true}
                      rules={{ required: true }}
                    />
                  </div>
                  <div className="col-md-6" id="BillingCountryField">
                    <label htmlFor="CountryCode" className="form-label">Country</label>
                    <select
                      id="CountryCode"
                      name="CountryCode"
                      autoComplete="country-name"
                      className="form-select"
                      value={billingFormData?.CountryCode}
                      onChange={(ev) => handleInput(ev, 'bill')}
                    >
                      <option value={''}></option>
                      {populateCountries()}
                    </select>
                  </div>
                  {/* <div className="col-md-3" id="BillingStateField">
                    <label htmlFor="State" className="form-label">State</label>
                    <select id="State" name="State" autoComplete="state-name" className="form-select" onChange={(ev) => handleInput(ev, 'bill')}>
                        <option disabled>
                        </option>
                    </select>
                  </div>
                  <div className="col-md-3" id="BillingCityField">
                    <label htmlFor="City" className="form-label">City</label>
                    <select id="City" name="City" autoComplete="city-name" className="form-select" onChange={(ev) => handleInput(ev, 'bill')}>
                        <option disabled>
                        </option>
                    </select>
                  </div>
                  <div className="col-md-3" id="BillingPostalCodeField">
                    <label htmlFor="PostalCode" className="form-label">ZIP / Postal code</label>
                    <input
                      type="text"
                      maxLength="10"
                      name="PostalCode"
                      id="PostalCode"
                      autoComplete="postal-code"
                      className="form-control"
                      value={billingFormData?.PostalCode}
                      onChange={(ev) => handleInput(ev, 'bill')}
                    />
                  </div>
                  <div className="col-md-12" id="BillingAddressLine1Field">
                    <label htmlFor="Line1" className="form-label">Address Line 1</label>
                    <input
                      type="text"
                      maxLength="30"
                      name="Line1"
                      id="Line1"
                      autoComplete="street-address"
                      className="form-control"
                      value={billingFormData?.Line1}
                      onChange={(ev) => handleInput(ev, 'bill')}
                    />
                  </div>
                  <div className="col-md-12" id="BillingAddressLine2Field">
                    <label htmlFor="Line2" className="form-label">Address Line 2</label>
                    <input
                      type="text"
                      maxLength="30"
                      name="Line2"
                      id="Line2"
                      autoComplete="street-address"
                      className="form-control"
                      value={billingFormData?.Line2}
                      onChange={(ev) => handleInput(ev, 'bill')}
                    />
                  </div> */}
                  <div className="form-check">
                    <input 
                      className="form-check-input"
                      type="checkbox"
                      name="RecurringInitial"
                      value={cardFormData?.RecurringInitial}
                      onChange={(ev) => handleInput(ev, 'card', 'checkbox')}
                      id="RecurringInitial"
                      checked={cardFormData?.RecurringInitial}
                    />
                    <label className="form-check-label fs-6" htmlFor="RecurringInitial">
                      Subscribe your card for {subscriptionPlanSelected?.subscription?.subscription_length === 'week' ? 'weekly' : 'monthly'} recurring payments to renew your subscription automatically
                    </label>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row mt-5">
                <div className="mr-auto ">
                  <button 
                    id="BtnSubmit"
                    name="BtnSubmit"
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setShowPaymentForm(false)}
                  >
                    {t('f_cancel') || 'Cancel Payment'}
                  </button>
                </div>
                <div className="ml-auto ">
                  <button 
                    id="BtnSubmit"
                    name="BtnSubmit"
                    type="submit"
                    className="btn btn-primary"
                  >
                    Confirm Payment
                  </button>
                </div>
              </div>
          </div>
      </form>
    </div>
  )
}

export default CheckoutPage;
