/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';
import PropertyDisplayThumb from '../../presentational/properties/propertyDisplayThumb';
import PropertyAccountThumb from '../../presentational/properties/propertyAccountThumb';
import PropertyPayments from '../properties/payment/propertyPayments';
import ReportModal from './reportModal';
import PhoneInput from 'react-phone-number-input';
import PageIndex from '../../presentational/pageIndex';
import { useTranslation } from 'react-i18next';
import { changePassword, userPhotoUpdate, userGovIDUploadUpdate,
         fetchProperties, fetchRentedProperties,
         fetchPropertyGroups, 
         userProfileUpdate,
         userJobLetterUpdate, URL as BackendURL} from '../../misc/apiRequests';
import { timeFormat } from '../../misc/misc';
import addNewIcon from '../../../assets/images/add.svg';
import '../../../assets/css/account.css';
import Swal from 'sweetalert2';
import PropertyTransactions from '../properties/payment/propertyTransactions';
// import { propertiesData } from '../../misc/jsonData/propertyListingData'
import ProfileBlank from '../../../assets/images/account/profile_blank.png';
import { useSearchParams } from 'react-router-dom';

const Account = ({ handlePropertyRequests, handlePropertyRemove, handleMessagesModal,
                   handleModal, isLoading, handleLoader, handleLogout, handlePropertySelect,
                   handleConfirmModal, setRedirect, user, propertyType = null }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');

  const [accountPage, setAccountPage] = useState(page || 'profile');
  const [govIDUpload, setGovIDUpload] = useState(null);
  const [photoUpload, setPhotoUpload] = useState(null);
  const [jobLetterUpload, setJobLetterUpload] = useState(null);
  const [properties, setProperties] = useState([]);
  const [listingPage, setListingPage] = useState(1);
  const [listingMaxPages, setListingMaxPages] = useState(1);
  const [listingPerPage, setListingPerPage] = useState(10);
  const [groups, setGroups] = useState([]);
  const [groupsPage, setGroupsPage] = useState(1);
  const [groupsMaxPages, setGroupsMaxPages] = useState(1);
  const [groupsPerPage, setGroupsPerPage] = useState(10);
  const showPropertyType = propertyType ? false : true

  const [occupation, setOccupation] = useState(user?.occupation || '');
  const [employer, setEmployer] = useState(user?.employer || '');
  const [employmentLength, setEmploymentLength] = useState(user?.employment_length || '');
  const [workPhone, setWorkPhone] = useState(user?.work_phone_numb || '');
  const [annualIncome, setAnnualIncome] = useState(user?.bank_name || 'less than 10,000');

  // const [bankName, setBankName] = useState(user?.bank_name || '');
  // const [bankBranch, setBankBranch] = useState(user?.bank_branch || '');
  // const [bankAcctNumb, setBankAcctNumb] = useState(user?.bank_acct_numb || '');
  // const [bankTransit, setBankTransit] = useState(user?.bank_transit_numb || '');

  const jsonOfficeHours = user?.office_hours ? JSON.parse(user?.office_hours) : {};
  const [phoneNumb, setPhoneNumb] = useState(user?.phone_numb || '');
  const [mobileNumb, setMobileNumb] = useState(user?.mobile_numb || '');
  const [preferredLang, setPreferredLang] = useState(user?.preferred_language || '');

  const [sundayStart, setSundayStart] = useState(jsonOfficeHours?.sunday?.start_time || '');
  const [sundayEnd, setSundayEnd] = useState(jsonOfficeHours?.sunday?.end_time || '');

  const [mondayStart, setMondayStart] = useState(jsonOfficeHours?.monday?.start_time || '');
  const [mondayEnd, setMondayEnd] = useState(jsonOfficeHours?.monday?.end_time || '');

  const [tuesdayStart, setTuesdayStart] = useState(jsonOfficeHours?.tuesday?.start_time || '');
  const [tuesdayEnd, setTuesdayEnd] = useState(jsonOfficeHours?.tuesday?.end_time || '');

  const [wednesdayStart, setWednesdayStart] = useState(jsonOfficeHours?.wednesday?.start_time || '');
  const [wednesdayEnd, setWednesdayEnd] = useState(jsonOfficeHours?.wednesday?.end_time || '');

  const [thursdayStart, setThursdayStart] = useState(jsonOfficeHours?.thursday?.start_time || '');
  const [thursdayEnd, setThursdayEnd] = useState(jsonOfficeHours?.thursday?.end_time || '');

  const [fridayStart, setFridayStart] = useState(jsonOfficeHours?.friday?.start_time || '');
  const [fridayEnd, setFridayEnd] = useState(jsonOfficeHours?.friday?.end_time || '');

  const [saturdayStart, setSaturdayStart] = useState(jsonOfficeHours?.saturday?.start_time || '');
  const [saturdayEnd, setSaturdayEnd] = useState(jsonOfficeHours?.saturday?.end_time || '');

  const [showReportModal, setShowReportModal] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const [showListings, setShowListings] = useState(false);
  const [showGroupListings, setShowGroupListings] = useState(false);

  // console.log(jsonOfficeHours);
  // console.log(sundayStart);

  // Populate all single property listings
  const populateProperties = () => properties.map(propertyData => (
    <PropertyDisplayThumb
      key={propertyData.id}
      property={propertyData}
      user={user}
      style={{minHeight: '280px', minWidth: '240px', maxHeight: '320px', maxWidth: '240px'}}
      setRedirect={setRedirect}
      handlePropertyRequests={handlePropertyRequests}
      handlePropertyRemove={handlePropertyRemove}
      handlePropertySelect={handlePropertySelect}
      showPropertyType={false}
      isPropertyGroup={false}
    />
  ));

  // Populate all group property listings
  const populateGroups = () => {
    // console.log(groups);
    const allGroupsDiv = [];
    let groupDiv;
    for (let i = 0; i < groups.length; i++) {
      const listings = groups[i]?.properties.map(propertyData => (
        <PropertyAccountThumb
          key={propertyData.id}
          property={propertyData}
          user={user}
          setRedirect={setRedirect}
          handlePropertyRequests={handlePropertyRequests}
          handlePropertyRemove={handlePropertyRemove}
          handlePropertySelect={handlePropertySelect}
          showPropertyType={false}
          isPropertyGroup={false}
          showUnitNumber={true}
        />
      ));

      groupDiv = (<div key={groups[i].id}>
        <section style={{padding: '12px', border: '2px solid #ebe9e9', borderRadius: '8px'}} className='mb-3'>
        <div className='flex-row'>
          <h3 className=''>{groups[i].group_name}</h3>
          <button
            style={{backgroundColor: 'var(--navButtonGold)'}}
            className='btn text-white ml-1'
            onClick={() => setRedirect(`properties/edit/0?group_id=${groups[i].id}&group_name=${groups[i].group_name}`)}
          >
            Edit
          </button>
        </div>
        <div className="pb-1 mb-2">
          <span className="text-camel">{groups[i].settlement}, {}
          {groups[i].island}</span>
          <div className="text-camel">
            {groups[i].street_address}, {}
          <span className="text-caps">{groups[i].po_box}</span>
          </div>
        </div>
        <ul style={{justifyContent: 'center'}}>
          {listings}
        </ul>
        </section>
      </div>);

      allGroupsDiv.push(groupDiv);
    }

    return allGroupsDiv;
  };
  
  const handleNewProperty = e => {
    e.preventDefault();
    setRedirect('/properties/new')
  };

  // const handleIndexChange = (e, nextPage = false) => {
  //   e.preventDefault();
  //   if(!nextPage && ((listingPage - 1) !== 0)) setListingPage(listingPage - 1);
  //   if(nextPage && ((listingPage + 1) <= listingMaxPages)) setListingPage(listingPage + 1);
  // };

  const handlePageChange = (page = 'profile') => {
    setSearchParams({ page: page });
    setAccountPage(page);
  };

  // Ensures that the profile image uploaded doesn't go too far over 1 megabyte
  const handleCheckFileSize = (e, fileType = 'photo') => {
    const elem = e.target;
    if (elem.files[0].size > 2048576) {
      elem.value = '';
      handleMessagesModal('Image Error', 'File size too big (max size allowed: 2 megabyte)', 'error')
    } else { 
      if(fileType === 'photo') { return setPhotoUpload(elem.files[0]); }
      if(fileType === 'govID') { return setGovIDUpload(elem.files[0]); }
      if(fileType === 'jobLetter') { return setJobLetterUpload(elem.files[0]); }
    }
  };
    
  const handlePhotoUpload = e => {
    e.preventDefault();

    const photo = new FormData();

    photo.append('photo_upload', photoUpload);

    handleLoader(true);
    userPhotoUpdate(photo)
      .then(response => {
        if (response.success) { 
          handleMessagesModal('Successful', response.message, 'success');
          user.profile_photo = response.photo; setPhotoUpload(null);
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });

    return null;
  };

  const handleGovIDUpload = e => {
    e.preventDefault();

    const photo = new FormData();

    photo.append('gov_id_upload', govIDUpload);

    handleLoader(true);
    userGovIDUploadUpdate(photo)
      .then(response => {
        if (response.success) { 
          handleMessagesModal('Successful', response.message, 'success');
          user.gov_id_upload = response.photo; setGovIDUpload(null);
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });

    return null;
  };

  const handleJobLetterUpload = e => {
    e.preventDefault();

    const photo = new FormData();

    photo.append('job_letter_upload', jobLetterUpload);

    handleLoader(true);
    userJobLetterUpdate(photo)
      .then(response => {
        if (response.success) { 
          handleMessagesModal('Successful', response.message, 'success');
          user.job_letter_upload = response.photo; setJobLetterUpload(null);
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });

    return null;
  };

  const handlePasswordChange = async e => {
    e.preventDefault();
  
    Swal.fire({
      title: "Change Password",
      html: `
        <form class="d-flex flex-column align-items-center">
          <div class="">
            <span>Current Password</span>
            <input id="swal-input1" class="form-control" required />
          </div>

          <div class="mt-2 mb-2">
            <span>New Password</span>
            <input id="swal-input2" class="form-control" required/>
          </div>

          <div class="">
            <span>New Password Confirmation</span>
            <input id="swal-input3" class="form-control" required />
          </div>
        </form>
      `,
      showCancelButton: true,
      confirmButtonText: "Submit",
      focusConfirm: false,
      preConfirm: () => {
        const oldPass = document.getElementById("swal-input1").value.trim()
        const newPass = document.getElementById("swal-input2").value.trim()
        const newPassConfirm = document.getElementById("swal-input3").value.trim()

        if (!oldPass) {
          return Swal.showValidationMessage(`Please enter Current Password`)
        }

        if (!newPass) {
          return Swal.showValidationMessage(`Please enter New Password`)
        }

        if (newPass !== newPassConfirm) {
          return Swal.showValidationMessage(`Please ensure New Password and Confirmation are the same`)
        }

        const passwordHash = {
          email: user.email,
          old_password: oldPass,
          password : newPass,
          password_confirmation: newPassConfirm,
        };

        handleLoader(true);
        return changePassword({user: passwordHash})
          .then(response => {
            handleLoader(false);
            return response
          });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((response) => {
      console.log(response);
      if (response.isDismissed) return
      if (response?.value?.success) handleMessagesModal('Password Changed', response?.value?.message, 'success');
      if (!response?.value?.success) {
        // handleModal(response.errors);
        Swal.fire({
          position: 'middle',
          icon: 'error',
          title: Array.isArray(response.value.errors) ? response.value.errors.join("\n") : response.value.errors,
          showConfirmButton: false,
          timer: 3000
        })
      }
    });
  
    // if (password !== passwordConfirm) {
    //   return handleModal([t('f_passwordMatch')]);
    // }
    // if (newPassword !== newPasswordConfirm) {
    //   return handleModal([t('f_passwordMatch')]);
    // }

    return null;
  };
  
  const handleEmploymentUpdate = e => {
    e.preventDefault();

    const profile = {
      profile: {
        employer: employer,
        employment_length: employmentLength,
        work_phone_numb : workPhone,
        occupation: occupation,
      }
    };

    handleLoader(true);
    userProfileUpdate(profile)
      .then(response => {
        if (response.success) handleMessagesModal('Successful', response.message, 'success');
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });

    return null;
  };

  // const handleBankingUpdate = e => {
  //   e.preventDefault();

  //   const profile = {
  //     profile: {
  //       bank_name: bankName,
  //       bank_acct_numb: bankAcctNumb,
  //       bank_branch : bankBranch,
  //       bank_transit_numb: bankTransit,
  //     }
  //   };

  //   handleLoader(true);
  //   userProfileUpdate(profile)
  //     .then(response => {
  //       if (response.success) handleMessagesModal(response.message);
  //       if (!response.success) {
  //         // handleModal(response.errors);
  //         Swal.fire({
  //           position: 'middle',
  //           icon: 'error',
  //           title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
  //           showConfirmButton: false,
  //           timer: 2000
  //         })
  //       }
  //       handleLoader(false);
  //     });

  //   return null;
  // };

  const handleContactUpdate = e => {
    e.preventDefault();

    const newHours = {
      sunday: { start_time: sundayStart, end_time: sundayEnd },
      monday: { start_time: mondayStart, end_time: mondayEnd },
      tuesday: { start_time: tuesdayStart, end_time: tuesdayEnd },
      wednesday: { start_time: wednesdayStart, end_time: wednesdayEnd },
      thursday: { start_time: thursdayStart, end_time: thursdayEnd },
      friday: { start_time: fridayStart, end_time: fridayEnd },
      saturday: { start_time: saturdayStart, end_time: saturdayEnd },
    };
    const profile = {
      profile: {
        preferred_language: preferredLang,
        office_hours: JSON.stringify(newHours),
        phone_numb: phoneNumb,
        mobile_numb: mobileNumb
      }
    };

    handleLoader(true);
    userProfileUpdate(profile)
      .then(response => {
        if (response.success) handleMessagesModal('Successful', response.message, 'success');
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });

    return null;
  };

  const generatePage = (page) => {
    const profile = (
      <div className='acct-menu-container'>
        <h1 className='text-center-sm'>{t('profileSettings')}</h1>

        <h4 className='mt-1 text-center-sm'>{t('f_photo')}</h4>
        <form className="d-flex flex-row g-3 align-items-center justify-content-center justify-content-sm-start mt-1 mb-2" onSubmit={handlePhotoUpload} encType="multipart/form-data">
          <div className="me-md-3 me-sm-0">
            <img className='profile-bubble mb-05' alt='Profile upload' src={photoUpload ? URL.createObjectURL(photoUpload) : (user.profile_photo || ProfileBlank)}></img>
          </div>

          <div className="">
            <div className="input-group">
              <input
                id='photo_upload'
                className='form-control'
                aria-describedby="inputGroupFileAddon04"
                aria-label="Upload"
                type="file"
                alt='Profile photo upload'
                name="photo_upload"
                accept="image/png, image/jpeg, image/jpg"
                onChange={e => handleCheckFileSize(e, 'photo')}
              />
              {photoUpload ? (
                <button type="submit" id='inputGroupFileAddon04' style={{backgroundColor: 'var(--navButtonGold)'}} className='btn text-white'>{t('f_submit')}</button>) 
                : 
                (<button type="submit" id='inputGroupFileAddon04' style={{backgroundColor: 'var(--navButtonGold)'}} className='btn text-white' disabled>{t('f_submit')}</button>)}
            </div>
          </div>
        </form>

        {user?.roles?.includes('citizen') && (<h4 className='text-center-sm'>{t('f_jobLetter')}</h4>)}
        {user?.roles?.includes('citizen') && (<form className="flex-row account-form text-center-sm" onSubmit={handleJobLetterUpload} encType="multipart/form-data">
          <img className='nib-img mb-05' alt='Profile job letter upload' src={jobLetterUpload ? URL.createObjectURL(jobLetterUpload) : user.job_letter_upload}></img>
          <div>
            <input
              id='job_letter_upload'
              type="file"
              alt='Profile job letter upload'
              name="job_letter_upload"
              accept="image/png, image/jpeg, image/jpg"
              onChange={e => handleCheckFileSize(e, 'jobLetter')}
            />
            <label htmlFor="job_letter_upload">Upload Job Letter</label>
            {jobLetterUpload ? (<button type="submit">{t('f_submit')}</button>) : (<button type="submit" disabled>{t('f_submit')}</button>)}
          </div>
        </form>)}

        <div className='border-bottom text-center-sm pb-3'>
          <h4 className='mb-2'>{t('f_govID')}</h4>
          <img className='mb-1 nib-img' alt='Government ID upload' src={govIDUpload ? URL.createObjectURL(govIDUpload) : user.gov_id_upload}></img>

          {/* <h4>Government ID Upload</h4>
          <form className="flex-row account-form" onSubmit={handleGovIDUpload} encType="multipart/form-data">
            <img className='nib-img' alt='Government ID upload' src={govIDUpload ? URL.createObjectURL(govIDUpload) : user.gov_id_upload}></img>
            <input
              id='gov_id_upload'
              type="file"
              name="gov_id_upload"
              alt='Government ID upload'
              accept="image/png, image/jpeg, image/jpg"
              onChange={e => handleCheckFileSize(e, 'govID')}
            />
            <label htmlFor="gov_id_upload">Upload Government ID</label>

            {govIDUpload ? (<button type="submit">Submit Upload</button>) : (<button type="submit" disabled>Submit ID</button>)}
          </form> */}
        </div>

        <div type='button' className=' btn btn-secondary text-white flex-row mt-4 p-2' onClick={() => setShowAccount(!showAccount)}>
          <h3 className='text-white'>{t('f_account')}</h3>
          <span className='size-4 ml-1 bare-btn'><i className="fas fa-bars text-dark"></i></span>
        </div>
        {showAccount && (
          <div className='mt-3'>
          <h3 className="pb-1">{t('f_contact')}</h3>
          <form className='row g-3 mb-2' onSubmit={handleContactUpdate}>
            <div className="col-md-4">
              <label htmlFor="home_phone" className="form-label size-1">{t('f_phone')} #1</label>
              <PhoneInput
                placeholder="Enter home/mobile phone number"
                className='form-control border-warning-subtle'
                value={phoneNumb}
                onChange={setPhoneNumb}
                // defaultCountry={"BS"}
                limitMaxLength={true}
                rules={{ required: true }}
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="mobile_phone" className="form-label size-1">{t('f_phone')} #2</label>
              <PhoneInput
                placeholder="Enter mobile phone number"
                className='form-control'
                value={mobileNumb}
                onChange={setMobileNumb}
                // defaultCountry={"BS"}
                limitMaxLength={true}
              />
            </div>
           </form>
          {user?.roles.includes('renter') && (
            <form className='row g-3 mb-3' onSubmit={handleContactUpdate}>
              <div className='col-md-6'>
                <label htmlFor="preferred_lang" className='size-1'>{t('f_preferredLang')}</label>
                  <select required name="preferred_lang" id="preferred_lang" value={preferredLang} className='form-select border-warning-subtle' onChange={e => setPreferredLang(e.target.value)}>
                    <option value="english">{t('f_english')}</option>
                    <option value="spanish">{t('f_spanish')}</option>
                    <option value="creole">{t('f_creole')}</option>
                  </select>
              </div>

              <h3 className='mt-4'>{t('f_contactHours')}</h3>

              {/* Sunday */}
              <div className='col-md-12'>
                <span className='fs-5 text-bold'>{t('f_sunday')}</span>
                <div>
                <div className='d-inline-flex flex-row fs-5 bg-body-secondary p-1 rounded'>
                  <div className=''>{timeFormat(sundayStart)}</div>
                  <div className='mx-3'>{" - "}</div>
                  <div className=''>{timeFormat(sundayEnd)}</div>
                </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className="input-group">
                  <span className="input-group-text" id="sunday-addon1">{t('f_startTime')}:</span>
                  <input type="time" name="sunday_start" className="form-control" value={sundayStart} aria-label="sunday-start" aria-describedby="sunday-start-addon1" onChange={e => setSundayStart(e.target.value)} />
                </div>
              </div>
              <div className='col-md-3'>
                <div className="input-group">
                  <span className="input-group-text" id="sunday-addon1">{t('f_endTime')}:</span>
                  <input type="time" name="sunday_end" className="form-control" value={sundayEnd} aria-label="sunday-end" aria-describedby="sunday-start-addon1" onChange={e => setSundayEnd(e.target.value)} />
                </div>
              </div>

              {/* Monday */}
              <div className='col-md-12'>
                <span className='fs-5 text-bold'>{t('f_monday')}</span>
                <div>
                <div className='d-inline-flex flex-row fs-5 bg-body-secondary p-1 rounded'>
                  <div className=''>{timeFormat(mondayStart)}</div>
                  <div className='mx-3'>{" - "}</div>
                  <div className=''>{timeFormat(mondayEnd)}</div>
                </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className="input-group">
                  <span className="input-group-text" id="monday-addon1">{t('f_startTime')}:</span>
                  <input type="time" name="monday_start" className="form-control" value={mondayStart} aria-label="monday-start" aria-describedby="monday-start-addon1" onChange={e => setMondayStart(e.target.value)} />
                </div>
              </div>
              <div className='col-md-3'>
                <div className="input-group">
                  <span className="input-group-text" id="monday-addon1">{t('f_endTime')}:</span>
                  <input type="time" name="monday_end" className="form-control" value={mondayEnd} aria-label="monday-end" aria-describedby="monday-start-addon1" onChange={e => setMondayEnd(e.target.value)} />
                </div>
              </div>

              {/* Tuesday */}
              <div className='col-md-12'>
                <span className='fs-5 text-bold'>{t('f_tuesday')}</span>
                <div>
                <div className='d-inline-flex flex-row fs-5 bg-body-secondary p-1 rounded'>
                  <div className=''>{timeFormat(tuesdayStart)}</div>
                  <div className='mx-3'>{" - "}</div>
                  <div className=''>{timeFormat(tuesdayEnd)}</div>
                </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className="input-group">
                  <span className="input-group-text" id="tuesday-addon1">{t('f_startTime')}:</span>
                  <input type="time" name="tuesday_start" className="form-control" value={tuesdayStart} aria-label="tuesday-start" aria-describedby="tuesday-start-addon1" onChange={e => setTuesdayStart(e.target.value)} />
                </div>
              </div>
              <div className='col-md-3'>
                <div className="input-group">
                  <span className="input-group-text" id="tuesday-addon1">{t('f_endTime')}:</span>
                  <input type="time" name="tuesday_end" className="form-control" value={tuesdayEnd} aria-label="tuesday-end" aria-describedby="tuesday-start-addon1" onChange={e => setThursdayEnd(e.target.value)} />
                </div>
              </div>

              {/* Wednesday */}
              <div className='col-md-12'>
                <span className='fs-5 text-bold'>{t('f_wednesday')}</span>
                <div>
                <div className='d-inline-flex flex-row fs-5 bg-body-secondary p-1 rounded'>
                  <div className=''>{timeFormat(wednesdayStart)}</div>
                  <div className='mx-3'>{" - "}</div>
                  <div className=''>{timeFormat(wednesdayEnd)}</div>
                </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className="input-group">
                  <span className="input-group-text" id="wednesday-addon1">{t('f_startTime')}:</span>
                  <input type="time" name="wednesday_start" className="form-control" value={wednesdayStart} aria-label="wednesday-start" aria-describedby="wednesday-start-addon1" onChange={e => setWednesdayStart(e.target.value)} />
                </div>
              </div>
              <div className='col-md-3'>
                <div className="input-group">
                  <span className="input-group-text" id="wednesday-addon1">{t('f_endTime')}:</span>
                  <input type="time" name="wednesday_end" className="form-control" value={wednesdayEnd} aria-label="wednesday-end" aria-describedby="wednesday-start-addon1" onChange={e => setWednesdayEnd(e.target.value)} />
                </div>
              </div>

              {/* Thursday */}
              <div className='col-md-12'>
                <span className='fs-5 text-bold'>{t('f_thursday')}</span>
                <div>
                <div className='d-inline-flex flex-row fs-5 bg-body-secondary p-1 rounded'>
                  <div className=''>{timeFormat(thursdayStart)}</div>
                  <div className='mx-3'>{" - "}</div>
                  <div className=''>{timeFormat(thursdayEnd)}</div>
                </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className="input-group">
                  <span className="input-group-text" id="thursday-addon1">{t('f_startTime')}:</span>
                  <input type="time" name="thursday_start" className="form-control" value={thursdayStart} aria-label="thursday-start" aria-describedby="thursday-start-addon1" onChange={e => setThursdayStart(e.target.value)} />
                </div>
              </div>
              <div className='col-md-3'>
                <div className="input-group">
                  <span className="input-group-text" id="thursday-addon1">{t('f_endTime')}:</span>
                  <input type="time" name="thursday_end" className="form-control" value={thursdayEnd} aria-label="thursday-end" aria-describedby="thursday-start-addon1" onChange={e => setThursdayEnd(e.target.value)} />
                </div>
              </div>

              {/* Friday */}
              <div className='col-md-12'>
                <span className='fs-5 text-bold'>{t('f_friday')}</span>
                <div>
                <div className='d-inline-flex flex-row fs-5 bg-body-secondary p-1 rounded'>
                  <div className=''>{timeFormat(fridayStart)}</div>
                  <div className='mx-3'>{" - "}</div>
                  <div className=''>{timeFormat(fridayEnd)}</div>
                </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className="input-group">
                  <span className="input-group-text" id="friday-addon1">{t('f_startTime')}:</span>
                  <input type="time" name="friday_start" className="form-control" value={fridayStart} aria-label="friday-start" aria-describedby="friday-start-addon1" onChange={e => setFridayStart(e.target.value)} />
                </div>
              </div>
              <div className='col-md-3'>
                <div className="input-group">
                  <span className="input-group-text" id="friday-addon1">{t('f_endTime')}:</span>
                  <input type="time" name="friday_end" className="form-control" value={fridayEnd} aria-label="friday-end" aria-describedby="friday-start-addon1" onChange={e => setFridayEnd(e.target.value)} />
                </div>
              </div>

              {/* Saturday */}
              <div className='col-md-12'>
                <span className='fs-5 text-bold'>{t('f_saturday')}</span>
                <div>
                <div className='d-inline-flex flex-row fs-5 bg-body-secondary p-1 rounded'>
                  <div className=''>{timeFormat(saturdayStart)}</div>
                  <div className='mx-3'>{" - "}</div>
                  <div className=''>{timeFormat(saturdayEnd)}</div>
                </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className="input-group">
                  <span className="input-group-text" id="saturday-addon1">{t('f_startTime')}:</span>
                  <input type="time" name="saturday_start" className="form-control" value={saturdayStart} aria-label="saturday-start" aria-describedby="saturday-start-addon1" onChange={e => setSaturdayStart(e.target.value)} />
                </div>
              </div>
              <div className='col-md-3'>
                <div className="input-group">
                  <span className="input-group-text" id="saturday-addon1">{t('f_endTime')}:</span>
                  <input type="time" name="saturday_end" className="form-control" value={saturdayEnd} aria-label="saturday-end" aria-describedby="saturday-start-addon1" onChange={e => setSaturdayEnd(e.target.value)} />
                </div>
              </div>

              <div className='d-grid gap-2 d-md-flex mt-3'>
                <button style={{backgroundColor: 'var(--navButtonGold)'}} className='btn text-white' type="submit">{t('f_contactUpdate')}</button>
              </div>
            </form>
          )}
          {/* {user?.roles.includes('renter') && (
            <form className="account-form flex-col" onSubmit={handleBankingUpdate}>
              <h4 className='mb-1'>{t('f_bank')}</h4>
                <span className='form-sub-text'>{t('f_bankName')}</span>
                <select name="bank_name" id="bank_name" value={bankName} onChange={e => setBankName(e.target.value)}>
                  <option value=""></option>
                  <option value="scotiabank">Scotiabank</option>
                  <option value="cibc">CIBC First Caribbean</option>
                  <option value="bob">Bank of the Bahamas</option>
                  <option value="fidelity">Fidelity Bank</option>
                  <option value="commonwealth">Commonwealth Bank</option>
                  <option value="rbc">Royal Bank of Canada</option>
                </select>
                <span className='form-sub-text'>{t('f_bankBranch')}</span>
                <input
                  type="text"
                  value={bankBranch}
                  onChange={e => setBankBranch(e.target.value)}
                 />
                <span className='form-sub-text'>{t('f_bankTransit')} #</span>
                <input
                  type="number"
                  value={bankTransit}
                  onChange={e => setBankTransit(e.target.value)}
                 />
                <span className='form-sub-text'>{t('f_bankAccount')} #</span>
                <input
                  type="number"
                  value={bankAcctNumb}
                  onChange={e => setBankAcctNumb(e.target.value)}
                 />
                <button type="submit">{t('f_updateBank')}</button>
            </form>
          )} */}

          <h3 className='mt-1'>{t('f_income')}</h3>
          <form className="row g-3 mb-5" onSubmit={handleEmploymentUpdate}>
            <div className='col-md-12'>
              <label className='fs-6'>{t('f_occupation')}</label>
              <select name="occupation" id="occupation" className='form-select' value={occupation} onChange={e => setOccupation(e.target.value)}>
                <option value=""></option>
                <option className='size-4' value="Agriculture, Food &amp; Natural Resources">{t('f_opt_agriculture')}</option>
                <option className='size-4' value="Arts, Audio/Video Technology &amp; Communications">{t('f_opt_art')}</option>
                <option className='size-4' value="Education &amp; training">{t('f_opt_education')}</option>
                <option className='size-4' value="Government &amp; Public Administration">{t('f_opt_government')}</option>
                <option className='size-4' value="Hospitality &amp; Tourism">{t('f_opt_hospitality')}</option>
                <option className='size-4' value="Information technology">{t('f_opt_IT')}</option>
                <option className='size-4' value="Manufacturing">{t('f_opt_manufacture')}</option>
                <option className='size-4' value="Science, technology, Engineering &amp; Mathematics">{t('f_opt_math')}</option>
                <option className='size-4' value="Architecture &amp; Construction">{t('f_opt_construction')}</option>
                <option className='size-4' value="Business Management &amp; Administration">{t('f_opt_business')}</option>
                <option className='size-4' value="Finance">{t('f_opt_finance')}</option>
                <option className='size-4' value="Health Science">{t('f_opt_health')}</option>
                <option className='size-4' value="Human Services">{t('f_opt_human')}</option>
                <option className='size-4' value="Law, Public Safety, Corrections &amp; Security">{t('f_opt_law')}</option>
                <option className='size-4' value="Marketing, Sales &amp; Service">{t('f_opt_marketing')}</option>
                <option className='size-4' value="Transportation, Distribution &amp; Logistics">{t('f_opt_transportation')}</option>
                <option className='size-4' value="Other">{t('f_opt_other')}</option>
              </select>
            </div>

            <div className='d-flex flex-row'>
              <div className=''>
                <label htmlFor='employer' className='fs-6'>{t('f_employer')}</label>
                <input
                  type="text"
                  id='employer'
                  className='form-control border-warning-subtle'
                  value={employer}
                  onChange={e => setEmployer(e.target.value)}
                  minLength="3"
                  required
                />
              </div>

              <div className='ms-md-3 me-md-3 me-0 ms-0'>
                <label htmlFor='employment_length' className='fs-6'>{t('f_employmentLength')}</label>
                <input
                  type="number"
                  id='employment_length'
                  className='form-control border-warning-subtle'
                  value={employmentLength}
                  onChange={e => setEmploymentLength(e.target.value)}
                  required
                />
              </div>

              <div className=''>
                <label htmlFor='phone' className='fs-6'>{t('f_workContact')}</label>
                <PhoneInput
                  className='form-control'
                  placeholder="Enter work phone number"
                  value={workPhone}
                  onChange={setWorkPhone}
                  defaultCountry={"BS"}
                  limitMaxLength={true}
                />
              </div>
            </div>

            <div className='d-grid gap-2 d-md-flex mt-3'>
              <button style={{backgroundColor: 'var(--navButtonGold)'}} className='btn text-white' type="submit">{t('f_updateEmployment')}</button>
            </div>
          </form>

          <form className="row g-3 mt-4 border bg-body-secondary p-2" onSubmit={handlePasswordChange}>
            <span className='fw-bold fs-4'>Do you wish to change your password?</span>
            <div className='d-grid gap-2 d-md-flex mt-3'>
              <button className='btn rounded p-3 text-white fs-5 btn-danger' type="submit">{t('f_changePassword')}</button>
            </div>
          </form>
          {/* <div className='flex-col'>
            <span>{t('f_deactivateAccount')}</span>
            <button type='button' className='nav-btn p-1'>{t('f_closeAccount')}</button>
          </div> */}
        </div>
        )}
      </div>);

    const listings = (
      <div className='acct-menu-container'>
        <div className='property-thumb'>
          <h1>{t('f_propertyListing')}</h1>
            {accountPage === 'listings' && (
              <button key='addNew' onClick={e => handleNewProperty(e)} className='property-thumb rental-box mt-3' style={{minHeight: '280px', minWidth: '240px', maxHeight: '280px', maxWidth: '240px'}}>
                <div className='half-vert-img' style={{"backgroundColor": "lightgrey", "display": "flex", "justifyContent": "center"}}>
                  <img src={addNewIcon} alt='property listing' style={{width: "auto", height: "85%"}}></img>
                </div>
                <div className='half-vert p-1'>
                  <h3 className='text-camel mt-1'>{t('f_newListing')}</h3>
                </div>
              </button>)}
        </div>
        <div className='mb-3 border-bottom property-thumb'>
          <div type='button' className=' btn btn-secondary flex-row mt-4 mb-4 p-2' onClick={() => setShowListings(!showListings)}>
            <h3 className='text-white'>{user?.roles?.includes('renter') ? t('f_singleListing') : t('myHome')}</h3>
            <span className='size-4 ml-1 bare-btn'><i className="fas fa-bars text-dark"></i></span>
          </div>
          {showListings && (
            <div>
              <ul style={{justifyContent: 'normal'}}>
                {properties.length > 0 ? populateProperties() : 'No listings detailed.'}
              </ul>
              {listingMaxPages > 1 && (
                <div className='text-center mb-1'>
                  <PageIndex setPage={setListingPage} setPerPage={setListingPerPage} page={listingPage} perPage={listingPerPage} maxPages={listingMaxPages} />
                </div>
              )}
            </div>
          )}
        </div>
        {user?.roles?.includes('renter') && (
          <div className='property-thumb-acc'>
            <div type='button' className=' btn btn-secondary flex-row mb-2 p-2' onClick={() => setShowGroupListings(!showGroupListings)}>
              <h3 className='text-white'>{t('f_complexListing')}</h3>
              <span className='size-4 ml-1 bare-btn'><i className="fas fa-bars text-dark"></i></span>
            </div>
            {showGroupListings && (
              <div>
                {groups.length > 0 ? populateGroups() : 'No listings detailed.'}
                {groupsMaxPages > 1 && (
                  <div className='text-center'>
                    <PageIndex setPage={setGroupsPage} setPerPage={setGroupsPerPage} page={groupsPage} perPage={groupsPerPage} maxPages={groupsMaxPages} />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>);

    const payment = <PropertyPayments user={user} handleMessagesModal={handleMessagesModal} handleLoader={handleLoader} handleModal={handleModal} isLoading={isLoading} />;

    const transactions = <PropertyTransactions user={user} handleLoader={handleLoader} handleModal={handleModal} />;
  
    // if(page === 'messages') { return messages; }
    if(page === 'profile') { return profile; }
    if(page === 'listings') { return listings; }
    if(page === 'rented') { return listings; }
    if(page === 'payment') { return payment; }
    if(page === 'transactions') { return transactions; }
  };

  useEffect(() => {
  //   if(!user.logged_in) { setRedirect("/"); }
  if (user) {
    setOccupation(user?.occupation || '');
    setEmployer(user?.employer || '');
    setEmploymentLength(user?.employment_length || '');
    setWorkPhone(user?.work_phone_numb || '');
    setAnnualIncome(user?.bank_name || 'less than 10,000');
  
    // setBankName(user?.bank_name || '');
    // setBankBranch(user?.bank_branch || '');
    // setBankAcctNumb(user?.bank_acct_numb || '');
    // setBankTransit(user?.bank_transit_numb || '');
  
    const jsonOfficeHours = user?.office_hours ? JSON.parse(user?.office_hours) : {};
    setPhoneNumb(user?.phone_numb || '');
    setMobileNumb(user?.mobile_numb || '');
    setPreferredLang(user?.preferred_language || '');
  
    setSundayStart(jsonOfficeHours?.sunday?.start_time || '');
    setSundayEnd(jsonOfficeHours?.sunday?.end_time || '');
  
    setMondayStart(jsonOfficeHours?.monday?.start_time || '');
    setMondayEnd(jsonOfficeHours?.monday?.end_time || '');
  
    setTuesdayStart(jsonOfficeHours?.tuesday?.start_time || '');
    setTuesdayEnd(jsonOfficeHours?.tuesday?.end_time || '');
  
    setWednesdayStart(jsonOfficeHours?.wednesday?.start_time || '');
    setWednesdayEnd(jsonOfficeHours?.wednesday?.end_time || '');
  
    setThursdayStart(jsonOfficeHours?.thursday?.start_time || '');
    setThursdayEnd(jsonOfficeHours?.thursday?.end_time || '');
  
    setFridayStart(jsonOfficeHours?.friday?.start_time || '');
    setFridayEnd(jsonOfficeHours?.friday?.end_time || '');
  
    setSaturdayStart(jsonOfficeHours?.saturday?.start_time || '');
    setSaturdayEnd(jsonOfficeHours?.saturday?.end_time || '');
  }
  // console.log(user);
  }, [user]);

  // Grab all properties posted by current user
  useEffect(() => {
    if(accountPage === 'listings') {
      handleLoader(true);
      const record = { per_page: listingPerPage, page: listingPage, };
      fetchProperties(record.per_page, record.page)
        .then(response => {
          if (response.success) {
            setProperties(response.records);
            setListingMaxPages(response.records_params.max_page || 1);
          }
          if (!response.success) {
            // handleModal(response.errors);
            Swal.fire({
              position: 'top-end',
              toast: true,
              timerProgressBar: true,
              icon: 'error',
              title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
              showConfirmButton: false,
              timer: 2000
            })
          }
          handleLoader(false);
        });
      handleLoader(true);

      fetchPropertyGroups(groupsPerPage, groupsPage)
        .then(response => {
          if (response.success) {
            setGroups(response.records);
            setGroupsMaxPages(response.records_params.max_page || 1);
          }
          if (!response.success) {
            // handleModal(response.errors);
            Swal.fire({
              position: 'top-end',
              toast: true,
              timerProgressBar: true,
              icon: 'error',
              title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
              showConfirmButton: false,
              timer: 2000
            })
          }
          handleLoader(false);
        });
    }
    if(accountPage === 'rented') {
      handleLoader(true);
      const record = { per_page: listingPerPage, page: listingPage, };
      fetchRentedProperties(record.per_page, record.page)
        .then(response => {
          if (response.success) {
            setProperties(response.records);
            setListingMaxPages(response.records_params.max_page || 1);
          }
          if (!response.success) {
            // handleModal(response.errors);
            Swal.fire({
              position: 'top-end',
              toast: true,
              timerProgressBar: true,
              icon: 'error',
              title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
              showConfirmButton: false,
              timer: 2000
            })
          }
          handleLoader(false);
        });
    }
  }, [accountPage, listingPage, listingPerPage, handleLoader, handleModal, groupsPerPage, groupsPage]);

    // Grab all FAKE DATA for properties posted by current user
    // useEffect(() => {
    //   if(accountPage === 'listings') {
    //     handleLoader(true);
    //     const userProperties = propertiesData.filter(x => x.owner_id === user.id)
        
    //     setProperties(userProperties);
    //     setListingMaxPages(1);

    //     handleLoader(false);
    //   }
    //   if(accountPage === 'rented') {
    //     handleLoader(true);
    //     const userProperties = propertiesData.filter(x => x.resident_id === user.id)
        
    //     setProperties(userProperties);
    //     setListingMaxPages(1);

    //     handleLoader(false);
    //   }
    // }, [accountPage, handleLoader, listingPage, listingPerPage, user.id]);


  return (
    <div id="AccountPage">
      {showReportModal && <ReportModal
                            handleModal={handleModal}
                            handleLoader={handleLoader}
                            handleMessagesModal={handleMessagesModal}
                            setShowReportModal={setShowReportModal}
                          />}
      <div className="container">
        <div className='acct-container'>
          <div className='acct-nav-container' style={{position: 'relative'}}>
            <div style={{position: 'sticky', top: '20'}}>
              <h1>{t('f_bankAccount')}</h1>
              <div className='flex-col align-items-start'>
                {/* <button onClick={e => handlePageChange('messages')} className='acct-btn'>Messages</button> */}
                <button onClick={e => handlePageChange('profile')} className={`${accountPage === 'profile' && 'selected-acct-btn'} acct-btn`}>{t('profile')}</button>
                {user?.roles?.includes('citizen') && <button onClick={e => handlePageChange('rented')} className={`${accountPage === 'rented' && 'selected-acct-btn'} acct-btn`}>{t('myHome')}</button>}
                {user?.roles?.includes('renter') && <button onClick={e => handlePageChange('listings')} className={`${accountPage === 'listings' && 'selected-acct-btn'} acct-btn`}>{t('ownedProperties')}</button>}
                {user?.roles?.includes('renter') && <button onClick={e => handlePageChange('payment')} className={`${accountPage === 'payment' && 'selected-acct-btn'} acct-btn`}>{t('manageSubs')}</button>}
                {user?.roles?.includes('admin') && <a href={`${BackendURL}/admin`} className={`${accountPage === 'admin' && 'selected-acct-btn'} acct-btn text-bold`}>Administration</a>}
                {/* {user?.roles?.includes('renter') && <button onClick={e => handlePageChange('transactions')} className={`${accountPage === 'transactions' && 'selected-acct-btn'} acct-btn`}>{t('propertyTransactions')}</button>} */}
                <button onClick={e => setShowReportModal(true)} className='acct-btn'>{t('reportIssue')}</button>
                <button onClick={e => handleLogout()} className='acct-btn'>{t('logOut')}</button>
              </div>
            </div>
          </div>
          {generatePage(accountPage)}
        </div>
      </div>
    </div>
  );
};

Account.propTypes = {
  handlePropertyRemove: propTypes.func.isRequired,
  handleModal: propTypes.func.isRequired,
  handleLoader: propTypes.func.isRequired,
  handleLogout: propTypes.func.isRequired,
  setRedirect: propTypes.func.isRequired,
  user: propTypes.instanceOf(Object).isRequired,
};

export default Account;
