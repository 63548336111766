/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useRef, useMemo } from 'react';
import propTypes from 'prop-types';
import { newProperty, fetchPropertyGroups } from '../../misc/apiRequests';
import Checkbox from '../../presentational/checkbox';
import PropertyGroup from './propertyGroup';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import HouseImg from '../../../assets/images/house.svg';
import ApartmentImg from '../../../assets/images/apartment.svg';
import { islands } from '../../misc/jsonData/islandData';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import OpenMapLeaf from '../../misc/openStreetMap';

const NewProperty = ({ user, setRedirect, handleMessagesModal, handleImageModal, handleModal, handleLoader }) => {
  const { t } = useTranslation();

  const [images, setImages] = useState([]);
  const [propertyType, setPropertyType] = useState('apartment');
  const [unitNumb, setUnitNumb] = useState('');
  const [settlement, setSettlement] = useState('');
  const [moveInSpecial, setMoveInSpecial] = useState('');
  const [electricity, setElectricity] = useState(false);
  const [water, setWater] = useState(false);
  const [internet, setInternet] = useState(false);
  const [highlights, setHighlights] = useState([]);
  const [disclaimer, setDisclaimer] = useState('');
  const [coverPhotoIndex, setCoverPhotoIndex] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [poBox, setPoBox] = useState('');
  const [phoneNumb, setPhoneNumb] = useState('');
  const [phoneNumb2, setPhoneNumb2] = useState('');
  const [email, setEmail] = useState('');
  const [desc, setDesc] = useState('');
  const [fee, setFee] = useState('');
  const [advancedPaymentAmount, setAdvancedPaymentAmount] = useState();
  const [depositExpected, setDepositExpected] = useState('');
  const [paymentFrequency, setPaymentFrequency] = useState('monthly');
  const [squareFt, setSquareFt] = useState('');
  const [bedrooms, setBedrooms] = useState(1);
  const [bathrooms, setBathrooms] = useState(1);
  const [rooms, setRooms] = useState(0);
  const [island, setIsland] = useState('');
  const [city, setCity] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [furnished, setFurnished] = useState(false);
  const [studio, setStudio] = useState(false);
  const [garage, setGarage] = useState(false);
  const [kitchen, setKitchen] = useState(false);
  const [livingRoom, setLivingRoom] = useState(false);
  const [airConditioned, setAirConditioned] = useState(false);
  const [fencedIn, setFencedIn] = useState(false);
  const [backyard, setBackyard] = useState(false);
  const [gatedCommunity, setGatedCommunity] = useState(false);
  const [petsAllowed, setPetsAllowed] = useState(false);
  const [inUnitWasherAndDryer, setInUnitWasherAndDryer] = useState(false);
  const [washerAndDryerHookups, setWasherAndDryerHookups] = useState(false);
  const [dishwasher, setDishwasher] = useState(false);
  const [wheelchairAccessible, setWheelchairAccessible] = useState(false);
  const [laundryFacilities, setLaundryFacilities] = useState(false);
  const [fitnessCenter, setFitnessCenter] = useState(false);
  const [pool, setPool] = useState(false);
  const [elevator, setElevator] = useState(false);
  const [parking, setParking] = useState(false);
  const [balcony, setBalcony] = useState(false);
  const [upstairs, setUpstairs] = useState(false);
  const [downstairs, setDownstairs] = useState(false);

  const [groupID, setGroupID] = useState('');

  const [unitType, setUnitType] = useState('single');
  const [propertyGroups, setPropertyGroups] = useState([]);
  const [groupPerPage, setGroupPerPage] = useState(10);
  const [groupPage, setGroupPage] = useState(1);

  const uploadsInputRef = useRef(null);
  const singleRef = useRef(null);
  const multipleRef = useRef(null);

  // Populate Islands and Settlements
  const populateIslands = () => islands.map((data, index) => (
    <option className='size-1' key={index} value={data.name}>{`${data.name}, Bahamas`}</option>
  ));
  const populateSettlements = () => islands.filter(x => x.name === island)[0]?.settlements.map((data, index) => (
    <option className='size-1' key={index + data} value={data}>{data}</option>
  ));

  const populateGroups = () => propertyGroups.map(data => (
    <option key={data.id} value={data.id}>{data.group_name}</option>
  ));

  const populatePaymentFrequencies = () => {
    let optionsList = [];

    if (propertyType === 'air bnb') {
      optionsList = [
        <option key="dailybnb" value="daily">{t('f_opt_daily')}</option>,
      ];
    } else if (propertyType === 'apartment') {
      optionsList = [
        <option key="weeklyap"  value="weekly">{t('f_opt_weekly')}</option>,
        <option key="monthlyap"  value="monthly">{t('f_opt_monthly')}</option>
      ];
    } else {
      optionsList = [
        <option key="once"  value="once">{t('f_opt_once')}</option>,
        <option key="daily"  value="daily">{t('f_opt_daily')}</option>,
        <option key="weekly"  value="weekly">{t('f_opt_weekly')}</option>,
        <option key="monthly"  value="monthly">{t('f_opt_monthly')}</option>
      ];
    }

    return optionsList;
  };
  
  // Ensures that the images uploaded doesn't go too far over 1 megabyte
  const handleCheckFileSize = e => {
    const elem = e.target;
    let overlimit = false;
    const errorList = [];
    Array.from(elem.files).forEach((x, index) => {
      if (elem.files[index].size > 3048576) {
        // console.log(elem.files[index]);
        errorList.push(`${x.name} - ${t('fileBig')}`);
        overlimit = true;
      } else { 
        const imagesList = images;
        imagesList.push(elem.files[index]);
        setImages(imagesList);
      }
    })

    if (overlimit) {
      elem.value = '';
      setImages([]);
      handleMessagesModal('Image error', errorList, 'error');
    }
  };

  const handleCheckbox = (state, setState) => {
    // e.preventDefault();
    setState(!state)
  }

  const handleUnitSelect = (e, unit) => {
    const singleElem = singleRef.current;
    const multipleElem = multipleRef.current;
    e.preventDefault();

    singleElem.classList.remove('unit-block-selected')
    multipleElem.classList.remove('unit-block-selected')

    if(unit === 'single') {
      singleElem.classList.toggle('unit-block-selected');
      setGroupID(null);
    } else if(unit === 'multiple') {
      multipleElem.classList.toggle('unit-block-selected');
      setPropertyType('apartment');
    }
    setUnitType(unit);
  }
  
  const handleNewSubmit = e => {
    e.preventDefault();

    const property = new FormData();

    property.append('property[property_type]', propertyType);
    property.append('property[property_unit]', unitNumb.trim());
    property.append('property[settlement]', settlement);
    property.append('property[move_in_special]', moveInSpecial);
    property.append('property[disclaimer]', disclaimer);
    property.append('property[street_address]', streetAddress.trim());
    property.append('property[po_box]', poBox);
    property.append('property[phone_numb]', phoneNumb);

    property.append('property[phone_numb_2]', phoneNumb2);
    property.append('property[email]', email);
    property.append('property[desc]', desc.trim());
    property.append('property[fee]', fee);
    property.append('property[advanced_payment_amount]', advancedPaymentAmount);
    property.append('property[deposit_expected]', depositExpected);
    property.append('property[payment_frequency]', paymentFrequency);
    property.append('property[sq_ft]', squareFt);
    property.append('property[bedrooms]', bedrooms);
    property.append('property[bathrooms]', bathrooms);
    property.append('property[rooms]', rooms);
    property.append('property[island]', island.trim());
    property.append('property[city]', city.trim());
    property.append('property[latitude]', latitude);
    property.append('property[longitude]', longitude);
    images.forEach((x) => property.append("property[images][]", x))
    highlights.forEach((x) => property.append("property[highlights][]", x))
    // property.append('property[images]', images);
    property.append('property[furnished]', JSON.stringify(Boolean(furnished)));
    property.append('property[studio]', JSON.stringify(Boolean(studio)));
    property.append('property[garage]', JSON.stringify(Boolean(garage)));
    property.append('property[kitchen]', JSON.stringify(Boolean(kitchen)));
    property.append('property[living_room]', JSON.stringify(Boolean(livingRoom)));
    property.append('property[air_conditioned]', JSON.stringify(Boolean(airConditioned)));
    property.append('property[fenced_in]', JSON.stringify(Boolean(fencedIn)));
    property.append('property[back_yard]', JSON.stringify(Boolean(backyard)));
    property.append('property[gated_community]', JSON.stringify(Boolean(gatedCommunity)));
    property.append('property[pets_allowed]', JSON.stringify(Boolean(petsAllowed)));
    property.append('property[in_unit_washer_and_dryer]', JSON.stringify(Boolean(inUnitWasherAndDryer)));
    property.append('property[washer_and_dryer_hookups]', JSON.stringify(Boolean(washerAndDryerHookups)));
    property.append('property[dishwasher]', JSON.stringify(Boolean(dishwasher)));
    property.append('property[wheelchair_accessible]', JSON.stringify(Boolean(wheelchairAccessible)));
    property.append('property[laundry_facilities]', JSON.stringify(Boolean(laundryFacilities)));
    property.append('property[fitness_center]', JSON.stringify(Boolean(fitnessCenter)));
    property.append('property[pool]', JSON.stringify(Boolean(pool)));
    property.append('property[elevator]', JSON.stringify(Boolean(elevator)));
    property.append('property[parking]', JSON.stringify(Boolean(parking)));
    property.append('property[balcony]', JSON.stringify(Boolean(balcony)));
    property.append('property[upstairs]', JSON.stringify(Boolean(upstairs)));
    property.append('property[downstairs]', JSON.stringify(Boolean(downstairs)));
    property.append('property[electricity]', JSON.stringify(Boolean(electricity)));
    property.append('property[water]', JSON.stringify(Boolean(water)));
    property.append('property[internet]', JSON.stringify(Boolean(internet)));
    property.append('property[disabled]', true);

    if(groupID !== null) property.append('property[property_group_id]', groupID);

    handleLoader(true);
    newProperty(property)
      .then(response => {
        if (response.success) {
          const wording = groupID ? 'units' : 'properties';
          const addTip = `<br/><br/><i>* Other ${wording} may also be added, simply change any needed fields then hit the "<b>Add Property</b>" button again.</i>`;
          const addResponse = groupID && `Unit # "<b>${unitNumb}</b>" was successfully added to "<b>${propertyGroups.filter(g => g.id === parseInt(groupID))[0].group_name}</b>" Apartment Complex`;
          if (groupID) {
            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Successful Addition',
              html: 
                `${addResponse}` +
                `${addTip}`,
              showDenyButton: true,
              confirmButtonText: "Add more units",
              denyButtonText: "I'm finished adding units"
            }).then(res => {
              if (res.isDenied) {
                setRedirect('/account?page=listings');
              }
            })
            // handleMessagesModal([addResponse, addTip]);
          }
          else {
            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Successful Addition',
              html: 
                `${response.message}` +
                `${addTip}`,
            })
            // handleMessagesModal([response.message, addTip]); 
          }
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });

    return null;
  };

  const handleClearImages = () => {
    const uploadsInputElem = uploadsInputRef.current;

    uploadsInputElem.value = '';
    setImages([]);
  };

  // Grab property groups
  useEffect(() => {
    handleLoader(true);
    fetchPropertyGroups(groupPerPage, groupPage)
      .then(response => {
        if (response.success) {
          setPropertyGroups(response.records);
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'top-end',
            toast: true,
            timerProgressBar: true,
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });
  }, [handleLoader, handleModal, groupPerPage, groupPage]);

  useEffect(() => {
    // if(!user.logged_in) { setRedirect("/"); }
  }, [user]);

  return (
    <div id="LoginPage" className="pt-1">
      <div className="container">
        <div className='register-flex'>
          <div className='login-img'>
            <div className='overlay-bg-dark' />
            <div className='register-text'>
              <h3>{t('addProperty')}</h3>
              <span>{t('propertyCreate')}</span>
            </div>
          </div>
          <div className='ml-1-lg overflow-scroll pb-1 register-form'>
            <div className="login-form" style={{marginTop: '12px'}}>
              <h3 className="pb-1">{t('propertyInfo')}</h3>
              <span className='form-sub-text pb-1 text-grey'>* {t('requiredFields')}</span>

              <div className='unit-block'>
                <button ref={singleRef} type='button' className='unit-block-selected btn' onClick={e => handleUnitSelect(e, 'single')}><img src={HouseImg} alt='house'/>{t('propertySingle')}</button>
                <button ref={multipleRef} type='button' className='btn' onClick={e => handleUnitSelect(e, 'multiple')}><img src={ApartmentImg} alt='apartmentcomplex'/>{t('propertyMultiple')}</button>
              </div>
              <span className='form-sub-text pb-1 text-grey'>* {t('propertyUnitDesc')}</span>
            </div>
            {/* For Multiple Apartment Complex Listings */}
            {unitType === 'multiple' && 
              <PropertyGroup
                newGroup={true}
                propertyGroups={propertyGroups}
                setPropertyGroups={setPropertyGroups}
                handleMessagesModal={handleMessagesModal}
                handleModal={handleModal}
                handleLoader={handleLoader}
                handleImageModal={handleImageModal} />
            }
            <div>
            <form className="login-form" onSubmit={handleNewSubmit} encType="multipart/form-data" style={{backgroundColor: 'rgb(255 250 239)', borderColor: '#fffdfd', marginTop: '12px'}}>
              {(unitType === 'multiple' && propertyGroups.length > 0) && (
                <div className='flex-col'>
                  <h5 className=''>{t('propertyCategorize')}</h5>
                  <select name="property_group" id="property_group" className='form-select' value={groupID} onChange={e => setGroupID(e.target.value)}>
                    <option value=""></option>
                    {populateGroups()}
                  </select>
                  <span className='form-sub-text'>{t('f_propertyType')}</span>
                  <select required name="property_type" id="property_type" className='form-select' value={propertyType} disabled>
                    <option value="apartment">{t('f_opt_apartment')}</option>
                  </select>
                  <span className='form-sub-text'>{t('f_unit')} #</span>
                  <input
                    type="text"
                    className='form-control'
                    value={unitNumb}
                    onChange={e => setUnitNumb(e.target.value)}
                    minLength="2"
                    required
                  />
                </div>
              )}

              {/* For Single Listings */}
              {unitType === 'single' && (
                <div className='flex-col'>
                  <span className='form-sub-text'>{t('f_propertyType')}</span>
                  <select required name="property_type" id="property_type" className='form-select' value={propertyType} onChange={e => setPropertyType(e.target.value)}>
                    <option value="apartment">{t('f_opt_apartment')}</option>
                    <option value="air bnb">{t('f_opt_bnb')}</option>
                    <option value="home">{t('f_opt_home')}</option>
                    <option value="condo">{t('f_opt_condo')}</option>
                    <option value="town house">{t('f_opt_townhouse')}</option>
                  </select>
                  <span className='form-sub-text'>{t('f_island')}</span>
                  <select required name="island" id="by_island" className='form-select' value={island} onChange={e => setIsland(e.target.value)}>
                    <option value={null}></option>
                    {populateIslands()}
                  </select>
                  <span className='form-sub-text'>{t('f_settlement')}</span>
                  <select required name="settlement" id="by_settlement" className='form-select' value={settlement} onChange={e => setSettlement(e.target.value)}>
                    <option value={null}></option>
                    {populateSettlements()}
                  </select>
                  <span className='form-sub-text'>{t('f_streetAddress')}</span>
                  <input
                    type="text"
                    className='form-control'
                    value={streetAddress}
                    onChange={e => setStreetAddress(e.target.value)}
                    minLength="3"
                    required
                  />
                  <span className='form-sub-text'>{t('f_PoBox')}</span>
                  <input
                    type="text"
                    className='form-control'
                    value={poBox}
                    onChange={e => setPoBox(e.target.value)}
                    minLength="3"
                    required
                  />
                  <OpenMapLeaf formLatitude={latitude} formLongitude={longitude} setFormLatitude={setLatitude} setFormLongitude={setLongitude} showMap={false}/>
                  {/* <span className='form-sub-text mt-1 mb-05'>
                    <a target='_blank' rel='noreferrer' href='https://support.google.com/maps/answer/18539?hl=en&co=GENIE.Platform%3DDesktop#:~:text=of%20a%20place-,On%20your%20computer%2C%20open%20Google%20Maps.,decimal%20format%20at%20the%20top.'>Googlemaps co-ordinates Help?</a>
                  </span> */}
                  {/* <span className='form-sub-text'>* Separate the values by the comma (,) the first field</span> */}
                  <span className='form-sub-text'>{t('f_latitude')}</span>
                  <input
                    type="text"
                    className='form-control'
                    value={latitude}
                    onChange={e => setLatitude(e.target.value)}
                    disabled
                  />
                  <span className='form-sub-text'>{t('f_longitude')}</span>
                  <input
                    type="text"
                    className='form-control'
                    value={longitude}
                    onChange={e => setLongitude(e.target.value)}
                    disabled
                  />
                </div>
              )}
              {(unitType === 'single' || (unitType === 'multiple' && propertyGroups.length > 0)) && (
                <div className='flex-col'>
                  <span className='form-sub-text'>{t('f_sqFt')}</span>
                  <input
                    type="number"
                    className='form-control'
                    value={squareFt}
                    onChange={e => setSquareFt(e.target.value)}
                    required
                  />
                  <span className='form-sub-text'>{t('f_bedrooms')}</span>
                  <div className="input-group mb-2">
                    <input
                      className='form-control mb-0'
                      type="number"
                      value={bedrooms}
                      onChange={e => setBedrooms(e.target.value)}
                      min='1'
                      max='10'
                      aria-label="Property's number of bedrooms"
                      required
                    />
                    <button type='button' className='btn mr-05' onClick={e => setBedrooms(parseInt(bedrooms) > 1 ? parseInt(bedrooms) - 1 : 10)}>-</button>
                    <button type='button' className='btn' onClick={e => setBedrooms(parseInt(bedrooms) < 10 ? parseInt(bedrooms) + 1 : 1)}>+</button>
                  </div>

                  <span className='form-sub-text'>{t('f_bathrooms')}</span>
                  <div className="input-group mb-3">
                    <input
                      className='form-control mb-0'
                      type="number"
                      value={bathrooms}
                      onChange={e => setBedrooms(e.target.value)}
                      min='1'
                      max='10'
                      aria-label="Property's number of bathrooms"
                      required
                    />
                    <button type='button' className='btn mr-05' onClick={e => setBathrooms(parseInt(bathrooms) > 1 ? parseInt(bathrooms) - 1 : 10)}>-</button>
                    <button type='button' className='btn' onClick={e => setBathrooms(parseInt(bathrooms) < 10 ? parseInt(bathrooms) + 1 : 1)}>+</button>
                  </div>
                  {/* <span className='form-sub-text'>Total number of Rooms</span>
                  <input
                    type="number"
                    value={rooms}
                    onChange={e => setRooms(e.target.value)}
                    required
                  />               */}
                  <span className='form-sub-text'>{t('f_desc')}</span>
                  <textarea
                    className='form-control'
                    value={desc}
                    onChange={e => setDesc(e.target.value)}
                  />
                  <span className='form-sub-text'>{t('f_moveInSpecial')}</span>
                  <textarea
                    className='form-control'
                    value={moveInSpecial}
                    onChange={e => setMoveInSpecial(e.target.value)}
                  />
                  <span className='form-sub-text'>{t('f_disclaimer')}</span>
                  <textarea
                    className='form-control'
                    value={disclaimer}
                    onChange={e => setDisclaimer(e.target.value)}
                  />
                  {/* <span className='form-sub-text'>{t('f_highlights')}</span>
                  <input
                    type="text"
                    value={highlights}
                    onChange={e => setHighlights(e.target.value)}
                  /> */}
                  <span className='form-sub-text'>{t('f_propertyPhoto')}</span>
                  <div>
                    {/* <label for="formFileMultiple" className="form-label">Multiple files input example</label> */}
                    <input
                      name="photo_uploads"
                      ref={uploadsInputRef}
                      alt='Property photo uploads'
                      className="form-control"
                      type="file"
                      id="formFileMultiple"
                      accept="image/png, image/jpeg, image/jpg"
                      multiple
                      onChange={e => handleCheckFileSize(e)}
                    />
                  </div>
                  <button type='button' className='btn btn-secondary' onClick={() => handleClearImages()} >{t('f_clearImage')}</button>

                  {/* <h3 className="pb-1 pt-1">Contact Information</h3>
                  <span className='form-sub-text'>Leave any related contact information field blank if you want to use the contact info attached to your profile.</span>
                  <span className='form-sub-text pt-1'>Phone Contact</span>
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={phoneNumb}
                    onChange={setPhoneNumb}
                    defaultCountry={"BS"}
                    limitMaxLength={true}
                  />
                  <span className='form-sub-text'>Another Phone Contact</span>
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={phoneNumb2}
                    onChange={setPhoneNumb2}
                    defaultCountry={"BS"}
                    limitMaxLength={true}
                  />
                  <span className='form-sub-text'>Email Linked to Property</span>
                  <input
                    type="text"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    minLength="3"
                  /> */}


                  <h3 className="pb-1 pt-1 mt-3">{t('f_payment')}</h3>
                  <span className='form-sub-text'>{t('f_fee')}</span>
                  <input
                    type="number"
                    className='form-control'
                    value={fee}
                    onChange={e => setFee(e.target.value)}
                    required
                  />
                  {/* <span className='form-sub-text'>Advanced Payment Fee</span>
                  <input
                    type="number"
                    value={advancedPaymentAmount}
                    onChange={e => setAdvancedPaymentAmount(e.target.value)}
                  /> */}
                  <span className='form-sub-text'>{t('f_deposit')}</span>
                  <span className='form-sub-text'>{t('f_depositDesc')}</span>
                  <input
                    type="number"
                    className='form-control'
                    value={depositExpected}
                    onChange={e => setDepositExpected(e.target.value)}
                  />
                  <span className='form-sub-text'>{t('f_frequency')}</span>
                  <select required name="property_payment_frequency" id="property_payment_frequency" className='form-select' value={paymentFrequency} onChange={e => setPaymentFrequency(e.target.value)}>
                    {populatePaymentFrequencies()}
                  </select>

                  <h3 className="pb-1 pt-1">{t('f_amenities')}</h3>
                  <div className='flex-row mb-3'>
                    <Checkbox name={'furnished'} desc={''} checkValue={furnished} setCheckValue={setFurnished} handleCheckbox={handleCheckbox} />
                    <Checkbox name={'studio'} desc={''} checkValue={studio} setCheckValue={setStudio} handleCheckbox={handleCheckbox} />
                    <Checkbox name={'garage'} desc={''} checkValue={garage} setCheckValue={setGarage} handleCheckbox={handleCheckbox} />
                    <Checkbox name={'kitchen'} desc={''} checkValue={kitchen} setCheckValue={setKitchen} handleCheckbox={handleCheckbox} />
                    <Checkbox name={'living room'} desc={''} checkValue={livingRoom} setCheckValue={setLivingRoom} handleCheckbox={handleCheckbox} />
                    <Checkbox name={'air conditioned'} desc={''} checkValue={airConditioned} setCheckValue={setAirConditioned} handleCheckbox={handleCheckbox} />
                    <Checkbox name={'fenced in'} desc={''} checkValue={fencedIn} setCheckValue={setFencedIn} handleCheckbox={handleCheckbox} />
                    <Checkbox name={'backyard'} desc={''} checkValue={backyard} setCheckValue={setBackyard} handleCheckbox={handleCheckbox} />
                    <Checkbox name={'gated community'} desc={''} checkValue={gatedCommunity} setCheckValue={setGatedCommunity} handleCheckbox={handleCheckbox} />
                    <Checkbox name={'pets allowed'} desc={''} checkValue={petsAllowed} setCheckValue={setPetsAllowed} handleCheckbox={handleCheckbox} />
                    <Checkbox name={'in unit washer and dryer'} desc={''} checkValue={inUnitWasherAndDryer} setCheckValue={setInUnitWasherAndDryer} handleCheckbox={handleCheckbox} />
                    <Checkbox name={'washer and dryer hookups'} desc={''} checkValue={washerAndDryerHookups} setCheckValue={setWasherAndDryerHookups} handleCheckbox={handleCheckbox} />
                    <Checkbox name={'dishwasher'} desc={''} checkValue={dishwasher} setCheckValue={setDishwasher} handleCheckbox={handleCheckbox} />
                    <Checkbox name={'wheelchair accessible'} desc={''} checkValue={wheelchairAccessible} setCheckValue={setWheelchairAccessible} handleCheckbox={handleCheckbox} />
                    <Checkbox name={'laundry facilities'} desc={''} checkValue={laundryFacilities} setCheckValue={setLaundryFacilities} handleCheckbox={handleCheckbox} />
                    <Checkbox name={'fitness center'} desc={''} checkValue={fitnessCenter} setCheckValue={setFitnessCenter} handleCheckbox={handleCheckbox} />
                    <Checkbox name={'pool'} desc={''} checkValue={pool} setCheckValue={setPool} handleCheckbox={handleCheckbox} />
                    <Checkbox name={'elevator'} desc={''} checkValue={elevator} setCheckValue={setElevator} handleCheckbox={handleCheckbox} />
                    <Checkbox name={'parking'} desc={''} checkValue={parking} setCheckValue={setParking} handleCheckbox={handleCheckbox} />
                    <Checkbox name={'balcony'} desc={''} checkValue={balcony} setCheckValue={setBalcony} handleCheckbox={handleCheckbox} />
                    <Checkbox name={'upstairs'} desc={''} checkValue={upstairs} setCheckValue={setUpstairs} handleCheckbox={handleCheckbox} />
                    <Checkbox name={'downstairs'} desc={''} checkValue={downstairs} setCheckValue={setDownstairs} handleCheckbox={handleCheckbox} />
                    <Checkbox name={'internet'} desc={''} checkValue={internet} setCheckValue={setInternet} handleCheckbox={handleCheckbox} />
                    <Checkbox name={'water'} desc={''} checkValue={water} setCheckValue={setWater} handleCheckbox={handleCheckbox} />
                    <Checkbox name={'electricity'} desc={''} checkValue={electricity} setCheckValue={setElectricity} handleCheckbox={handleCheckbox} />
                  </div>                                                                                                                                                                                                
                  <button type="submit" className='btn'>{ unitType === 'single' ? t('f_addProperty') : t('f_addUnit')}</button>
                  <p className='size-05 mt-2'>
                    {t('f_termsProperty')} RentalFaqz.com <a href='/about?legal_terms=terms_and_conditions' target="_blank">{t('f_termsConditions') + ' '}</a>
                    {t('f_termsConjuction')} <a href='/about?legal_terms=privacy_statement' target="_blank">{t('f_termsService')}</a>
                  </p>
              </div>
              )}
            </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
};

NewProperty.propTypes = {
  user: propTypes.instanceOf(Object).isRequired,
  setRedirect: propTypes.func.isRequired,
  handleMessagesModal: propTypes.func.isRequired,
  handleModal: propTypes.func.isRequired,
  handleLoader: propTypes.func.isRequired,
};

export default NewProperty;
