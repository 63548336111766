/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useCallback } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { fetchFilteredProperties } from '../../misc/apiRequests';
import PageIndex from '../../presentational/pageIndex';
import PropertyDisplayThumb from '../../presentational/properties/propertyDisplayThumb';
import Checkbox from '../../presentational/checkbox';
import { islands } from '../../misc/jsonData/islandData';
import Swal from 'sweetalert2';
// import { propertiesData as propertyListingData } from '../../misc/jsonData/propertyListingData';

const SearchForProperty = ({ user, handleLoader, handleModal, handlePropertySelect, handlePropertyRequests, handlePropertyRemove }) => {
  const { t } = useTranslation();

  const [properties, setProperties] = useState([]);
  const [pageNumb, setPageNumb] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [propertyType, setPropertyType] = useState('');
  const [address, setAddress] = useState('');
  const [island, setIsland] = useState('');
  const [city, setCity] = useState('');
  const [settlement, setSettlement] = useState('');
  const [squareFt, setSquareFt] = useState('');
  const [bedrooms, setBedrooms] = useState('');
  const [bathrooms, setBathrooms] = useState('');
  const [rooms, setRooms] = useState('');
  const [furnished, setFurnished] = useState(false);
  const [studio, setStudio] = useState(false);
  const [garage, setGarage] = useState(false);
  const [kitchen, setKitchen] = useState(false);
  const [livingRoom, setLivingRoom] = useState(false);
  const [airConditioned, setAirConditioned] = useState(false);
  const [fencedIn, setFencedIn] = useState(false);
  const [backyard, setBackyard] = useState(false);
  const [gatedCommunity, setGatedCommunity] = useState(false);
  const [petsAllowed, setPetsAllowed] = useState(false);
  const [inUnitWasherAndDryer, setInUnitWasherAndDryer] = useState(false);
  const [washerAndDryerHookups, setWasherAndDryerHookups] = useState(false);
  const [dishwasher, setDishwasher] = useState(false);
  const [wheelchairAccessible, setWheelchairAccessible] = useState(false);
  const [laundryFacilities, setLaundryFacilities] = useState(false);
  const [fitnessCenter, setFitnessCenter] = useState(false);
  const [pool, setPool] = useState(false);
  const [elevator, setElevator] = useState(false);
  const [parking, setParking] = useState(false);
  const [balcony, setBalcony] = useState(false);
  const [downstairs, setDownstairs] = useState(false);
  const [upstairs, setUpstairs] = useState(false);
  const [paymentFrequency, setPaymentFrequency] = useState('');
  const [priceRangeMin, setPriceRangeMin] = useState('');
  const [priceRangeMax, setPriceRangeMax] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [verified, setVerified] = useState(false);

  const [searchActive, setSearchActive] = useState(false);

  const populateProperties = () => properties.map(propertyData => (
    <PropertyDisplayThumb
      key={propertyData.id}
      property={propertyData}
      user={user}
      handlePropertyRequests={handlePropertyRequests}
      handlePropertyRemove={handlePropertyRemove}
      handlePropertySelect={handlePropertySelect}
      isPropertyGroup={false}
      showPropertyType={true}
    />
  ));

  // Populate Islands and Settlements
  const populateIslands = () => islands.map((data, index) => (
    <option className='' key={index} value={data.name}>{`${data.name}, Bahamas`}</option>
  ));
  const populateSettlements = () => islands.filter(x => x.name === island)[0]?.settlements.map((data, index) => (
    <option className='' key={index + data} value={data}>{data}</option>
  ));

  // const handleIndexChange = (e, nextPage = false) => {
  //   e.preventDefault();
  //   if(!nextPage && ((pageNumb - 1) !== 0)) setPageNumb(pageNumb - 1);
  //   if(nextPage && ((pageNumb + 1) <= maxPages)) setPageNumb(pageNumb + 1);
  // };

  const handleCheckbox = (state, setState) => {
    // e.preventDefault();
    setState(!state)
  }

  const handleClearFilters = e => {
    e.preventDefault();

    setPropertyType('');
    setAddress('');
    setIsland('');
    setCity('');
    setSettlement('');
    setSquareFt('');
    setBedrooms('');
    setBathrooms('');
    setRooms('');
    setFurnished(false);
    setStudio(false);
    setGarage(false);
    setKitchen(false);
    setLivingRoom(false);
    setAirConditioned(false);
    setFencedIn(false);
    setBackyard(false);
    setGatedCommunity(false);
    setPetsAllowed(false);
    setInUnitWasherAndDryer(false);
    setWasherAndDryerHookups(false);
    setDishwasher(false);
    setWheelchairAccessible(false);
    setLaundryFacilities(false);
    setFitnessCenter(false);
    setPool(false);
    setElevator(false);
    setParking(false);
    setBalcony(false);
    setDownstairs(false);
    setUpstairs(false);
    setVerified(false);
    setPaymentFrequency('');
    setPriceRangeMin('');
    setPriceRangeMax('');
    setSortBy('');
  };

  const generateFilters = useCallback((filter) => {
    if(propertyType !== '') filter['property_type'] = propertyType;
    if(address !== '') filter['address'] = address;
    if(island !== '') filter['island'] = island;
    if(city !== '') filter['city'] = city;
    if(settlement !== '') filter['settlement'] = settlement;
    if(squareFt !== '') filter['sq_ft'] = squareFt;
    if(bedrooms !== '') filter['bedrooms'] = bedrooms;
    if(bathrooms !== '') filter['bathrooms'] = bathrooms;
    if(rooms !== '') filter['rooms'] = rooms;
    if(paymentFrequency !== '') filter['payment_frequency'] = paymentFrequency;
    if(priceRangeMin !== '') filter['price_range_min'] = priceRangeMin;
    if(priceRangeMax !== '') filter['price_range_max'] = priceRangeMax;
    if(sortBy !== '') filter['sort_by'] = sortBy;

    if(studio !== false) filter['studio'] = studio;
    if(garage !== false) filter['garage'] = garage;
    if(kitchen !== false) filter['kitchen'] = kitchen;
    if(livingRoom !== false) filter['living_room'] = livingRoom;
    if(airConditioned !== false) filter['air_conditioned'] = airConditioned;
    if(fencedIn !== false) filter['fenced_in'] = fencedIn;
    if(backyard !== false) filter['back_yard'] = backyard;
    if(gatedCommunity !== false) filter['gated_community'] = gatedCommunity;
    if(petsAllowed !== false) filter['pets_allowed'] = petsAllowed;
    if(parking !== false) filter['parking'] = parking;
    if(inUnitWasherAndDryer !== false) filter['in_unit_washer_and_dryer'] = inUnitWasherAndDryer;
    if(washerAndDryerHookups !== false) filter['washer_and_dryer_hookups'] = washerAndDryerHookups;
    if(dishwasher !== false) filter['dishwasher'] = dishwasher;
    if(wheelchairAccessible !== false) filter['wheelchair_accessible'] = wheelchairAccessible;
    if(laundryFacilities !== false) filter['laundry_facilities'] = laundryFacilities;
    if(fitnessCenter !== false) filter['fitness_center'] = fitnessCenter;
    if(pool !== false) filter['pool'] = pool;
    if(elevator !== false) filter['elevator'] = elevator;
    if(furnished !== false) filter['furnished'] = furnished;
    if(balcony !== false) filter['balcony'] = balcony;
    if(downstairs !== false) filter['downstairs'] = downstairs;
    if(upstairs !== false) filter['upstairs'] = upstairs;
    if(verified !== false) filter['verified'] = verified;
  }, [address, airConditioned, backyard, bathrooms, bedrooms, city, dishwasher, elevator,
      fencedIn, fitnessCenter, furnished, garage, gatedCommunity, inUnitWasherAndDryer,
      island, kitchen, laundryFacilities, livingRoom, parking, paymentFrequency, petsAllowed,
      pool, priceRangeMax, priceRangeMin, propertyType, rooms, settlement, sortBy, squareFt,
      studio, verified, washerAndDryerHookups, wheelchairAccessible, balcony, downstairs, upstairs])

  const handleSelectFilter = e => {
    e.preventDefault();
    setSearchActive(true);

    // const filter = {
    //   per_page: perPage, page: pageNumb
    // }

    // generateFilters(filter);

    // handleLoader(true);
    // fetchFilteredProperties(perPage, pageNumb, filter)
    //   .then(response => {
    //     if (response.success) {
    //       setProperties(response.records);
    //       setMaxPages(response.records_params.max_page || 1);
    //     }
    //     if (!response.success) {
    //       // handleModal(response.errors);
    //       Swal.fire({
    //         position: 'middle',
    //         icon: 'error',
    //         title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
    //         showConfirmButton: false,
    //         timer: 2000
    //       })
    //     }
    //     handleLoader(false);
    //   });

    return null;
  };

  // Grab property groups
  useEffect(() => {
    if(!searchActive) return;

    const filter = {
      per_page: perPage, page: pageNumb
    }

    generateFilters(filter);

    handleLoader(true);
    fetchFilteredProperties(perPage, pageNumb, filter)
      .then(response => {
        if (response.success) {
          setProperties(response.records);
          setMaxPages(response.records_params.max_page || 1);
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
        setSearchActive(false);
      });
  }, [handleLoader, handleModal, perPage, pageNumb, generateFilters, searchActive]);

    // Fetch FAKE DATA Property by ID
    // useEffect(() => {
    //   const filteredProperties = propertyListingData.filter(x => x.property_type === propertyType)
    //   if (filteredProperties.length) {
    //     setProperties(filteredProperties);
    //   }
    //   else {
    //     setProperties([]);
    //     handleModal([`No properties found with given filters`]);
    //   }
    // }, [handleModal, propertyType]);

  return (
    <div id='PropertySearch' className='container'>
      <div className='mt-2 mb-5'>
        <h3 className='mb-3'>{t('select_filter')}</h3>
        <form className='property-search-box' style={{backgroundColor: 'whitesmoke'}} onSubmit={handleSelectFilter}>
          <div className='flex-row'>
            <div className='flex-col mr-2'>
              <span className='text-grey'>{t('f_propertyType')}</span>
              <select name="property_type" id="property_type" className='form-select' value={propertyType} onChange={e => setPropertyType(e.target.value)}>
                <option value=""></option>
                <option value="apartment">{t('f_opt_apartment')}</option>
                <option value="air bnb">{t('f_opt_bnb')}</option>
                <option value="home">{t('f_opt_home')}</option>
                <option value="condo">{t('f_opt_condo')}</option>
                <option value="town house">{t('f_opt_townhouse')}</option>
              </select>
            </div>
            <div className='flex-col mr-2'>
              <span className='text-grey'>{t('f_island')}</span>
              <select name="island" className='form-select' id="by_island" value={island} onChange={e => setIsland(e.target.value)}>
                <option value={null}></option>
                {populateIslands()}
              </select>
            </div>
            <div className='flex-col mr-2'>
              <span className='text-grey'>{t('f_settlement')}</span>
              <select name="settlement" className='form-select' id="by_settlement" value={settlement} onChange={e => setSettlement(e.target.value)}>
                <option value={null}></option>
                {populateSettlements()}
              </select>
            </div>
            {/* <div className='flex-col mr-2'>
              <span className='text-grey'>Street Address</span>
                <input
                  type="text"
                  value={address}
                  onChange={e => setAddress(e.target.value)}
                  minLength="3"
                />
            </div> */}
            <div className='flex-col mr-2'>
              <span className='text-grey'>{t('f_sqFt_greater')}</span>
                <input
                  type="number"
                  className='form-control'
                  value={squareFt}
                  onChange={e => setSquareFt(e.target.value)}
                />
            </div>
            <div className='flex-col mr-2'>
              <span className='text-grey'>{t('f_bathrooms')}</span>
                <input
                  type="number"
                  className='form-control'
                  value={bathrooms}
                  onChange={e => setBathrooms(e.target.value)}
                />
            </div>
            <div className='flex-col mr-2'>
              <span className='text-grey'>{t('f_bedrooms')}</span>
                <input
                  type="number"
                  className='form-control'
                  value={bedrooms}
                  onChange={e => setBedrooms(e.target.value)}
                />
            </div>
            {/* <div className='flex-col mr-2'>
              <span className='text-grey'>No. of Rooms</span>
                <input
                  type="number"
                  value={rooms}
                  onChange={e => setRooms(e.target.value)}
                />
            </div> */}
            {/* <div className='flex-col mr-2'>
              <span className='text-grey'>{t('f_frequency')}</span>
              <select name="payment_frequency" id="payment_frequency" value={paymentFrequency} onChange={e => setPaymentFrequency(e.target.value)}>
                <option value=""></option>
                <option value="daily">{t('f_opt_daily')}</option>
                <option value="weekly">{t('f_opt_weekly')}</option>
                <option value="monthly">{t('f_opt_monthly')}</option>
              </select>
            </div> */}
          </div>
          <div className='mt-4 mb-1'>
            <h3>{t('f_price_range')}</h3>
            <div className='flex-row mr-2'>
              <div className='flex-col mr-1'>
                <span className='text-grey'>{t('f_min')}</span>
                  <input
                    type="number"
                    className='form-control'
                    value={priceRangeMin}
                    onChange={e => setPriceRangeMin(e.target.value)}
                  />
              </div>
              <div className='flex-col'>
                <span className='text-grey'>{t('f_max')}</span>
                  <input
                    type="number"
                    className='form-control'
                    value={priceRangeMax}
                    onChange={e => setPriceRangeMax(e.target.value)}
                    required={priceRangeMin}
                  />
              </div>
            </div>
          </div>
          <h3 className="pb-1 mt-4">{t('f_amenities')}</h3>
          <div className='flex-row mb-1'>
            <Checkbox name={'furnished'} desc={''} checkValue={furnished} setCheckValue={setFurnished} handleCheckbox={handleCheckbox} />
            <Checkbox name={'studio'} desc={''} checkValue={studio} setCheckValue={setStudio} handleCheckbox={handleCheckbox} />
            <Checkbox name={'garage'} desc={''} checkValue={garage} setCheckValue={setGarage} handleCheckbox={handleCheckbox} />
            <Checkbox name={'kitchen'} desc={''} checkValue={kitchen} setCheckValue={setKitchen} handleCheckbox={handleCheckbox} />
            <Checkbox name={'living room'} desc={''} checkValue={livingRoom} setCheckValue={setLivingRoom} handleCheckbox={handleCheckbox} />
            <Checkbox name={'air conditioned'} desc={''} checkValue={airConditioned} setCheckValue={setAirConditioned} handleCheckbox={handleCheckbox} />
            <Checkbox name={'fenced in'} desc={''} checkValue={fencedIn} setCheckValue={setFencedIn} handleCheckbox={handleCheckbox} />
            <Checkbox name={'backyard'} desc={''} checkValue={backyard} setCheckValue={setBackyard} handleCheckbox={handleCheckbox} />
            <Checkbox name={'gated community'} desc={''} checkValue={gatedCommunity} setCheckValue={setGatedCommunity} handleCheckbox={handleCheckbox} />
            <Checkbox name={'pets allowed'} desc={''} checkValue={petsAllowed} setCheckValue={setPetsAllowed} handleCheckbox={handleCheckbox} />
            <Checkbox name={'in unit washer and dryer'} desc={''} checkValue={inUnitWasherAndDryer} setCheckValue={setInUnitWasherAndDryer} handleCheckbox={handleCheckbox} />
            <Checkbox name={'washer and dryer hookups'} desc={''} checkValue={washerAndDryerHookups} setCheckValue={setWasherAndDryerHookups} handleCheckbox={handleCheckbox} />
            <Checkbox name={'dishwasher'} desc={''} checkValue={dishwasher} setCheckValue={setDishwasher} handleCheckbox={handleCheckbox} />
            <Checkbox name={'wheelchair accessible'} desc={''} checkValue={wheelchairAccessible} setCheckValue={setWheelchairAccessible} handleCheckbox={handleCheckbox} />
            <Checkbox name={'laundry facilities'} desc={''} checkValue={laundryFacilities} setCheckValue={setLaundryFacilities} handleCheckbox={handleCheckbox} />
            <Checkbox name={'fitness center'} desc={''} checkValue={fitnessCenter} setCheckValue={setFitnessCenter} handleCheckbox={handleCheckbox} />
            <Checkbox name={'pool'} desc={''} checkValue={pool} setCheckValue={setPool} handleCheckbox={handleCheckbox} />
            <Checkbox name={'elevator'} desc={''} checkValue={elevator} setCheckValue={setElevator} handleCheckbox={handleCheckbox} />
            <Checkbox name={'parking'} desc={''} checkValue={parking} setCheckValue={setParking} handleCheckbox={handleCheckbox} />
            <Checkbox name={'balcony'} desc={''} checkValue={balcony} setCheckValue={setBalcony} handleCheckbox={handleCheckbox} />
            <Checkbox name={'upstairs'} desc={''} checkValue={upstairs} setCheckValue={setUpstairs} handleCheckbox={handleCheckbox} />
            <Checkbox name={'downstairs'} desc={''} checkValue={downstairs} setCheckValue={setDownstairs} handleCheckbox={handleCheckbox} />
          </div>
          <div className='mb-4'>
            <Checkbox name={'verified'} desc={''} checkValue={verified} setCheckValue={setVerified} handleCheckbox={handleCheckbox} />
          </div>
          {/* <div className='flex-row mb-2'>
            <div className='flex-col'>
              <span className='text-grey'>{t('f_sort_by')}</span>
              <select required name="sort_by" id="sort_by" value={sortBy} onChange={e => setSortBy(e.target.value)}>
                <option value="ASC">{t('f_oldest_newest')}</option>
                <option value="DESC">{t('f_most_recent')}</option>
              </select>
            </div>
          </div> */}
          <div className='mt-4'>
            <button type='button' onClick={(e) => handleClearFilters(e)} className='btn btn-dark button-searchx mr-1'>{t('clear_filter')}</button>
            <button className='button-searchx btn btn-dark'>{t('search')}</button>
          </div>
        </form>
      </div>
      <div className='property-thumb'>
        <ul>
          {populateProperties()}
        </ul>
        {maxPages > 1 && (
          <div className='mx-auto text-center mb-1'>
            <PageIndex setPage={setPageNumb} setPerPage={setPerPage} page={pageNumb} perPage={perPage} maxPages={maxPages} funcCallback={setSearchActive} />
          </div>
        )}
      </div>
    </div>
  );
};

SearchForProperty.propTypes = {
  handleLoader: propTypes.func.isRequired,
  handleModal: propTypes.func.isRequired,
};

export default SearchForProperty;