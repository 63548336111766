/* eslint-disable no-unused-vars */

import React, {useRef, useState} from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ForumNewComment from '../../presentational/properties/forumNewComment';
import '../../../assets/css/propertyForms.css';
import EditIcon from '../../../assets/images/edit.svg';
import ReplyIcon from '../../../assets/images/reply.svg';

const PropertyForumReplyDisplay = ({
  user,
  mainComment, comment, populateImages,
  commentBody, setCommentBody, commentImages,
  setCommentImages, setRemoveImages,
  handleSubmitEdit, handleSubmitNew, handleCheckFileSize,
  handleImageModal, handleImageRemoval
}) => {
  const { t } = useTranslation();

  const [newCommentMode, setNewCommentMode] = useState(false);
  const [editCommentMode, setEditCommentMode] = useState(false);

  // Created At 
  const created_at_seconds_index = comment?.created_at.indexOf('.');
  const created_at_no_seconds = comment?.created_at.substring(0, created_at_seconds_index);
  const dateEnd = comment?.created_at.indexOf('T');
  const shortDate = comment?.created_at.substring(0, dateEnd);

  // Updated At
  const updated_at_seconds_index = comment?.updated_at.indexOf('.');
  const updated_at_no_seconds = comment?.updated_at.substring(0, updated_at_seconds_index);
  const dateEndEdited = comment?.updated_at.indexOf('T');
  const shortDateEdited = comment?.updated_at.substring(0, dateEndEdited);
  
  const handleNewCommentMode = (mode = true) => {
    setNewCommentMode(!newCommentMode);
    setCommentImages([]);
    setRemoveImages([]);
  };

  const handleEditCommentMode = (mode = true) => {
    setEditCommentMode(!editCommentMode);
    setCommentImages([]);
    setRemoveImages([]);
  };

  return (
    <div>
      {(newCommentMode || editCommentMode) && (<div className="modal">
        <div className="modal-content" style={{minHeight: 'auto', maxHeight: 'none'}}>
          <div className='container'>
            {/* Call the new comment component */}
            {
              newCommentMode && (
                <ForumNewComment
                  isReply={true}
                  editComment={false}
                  mainComment={mainComment}
                  comment={comment}
                  commentBody={commentBody}
                  setCommentBody={setCommentBody}
                  commentImages={commentImages}
                  setCommentImages={setCommentImages}
                  handleSubmitNew={handleSubmitNew}
                  handleSubmitEdit={handleSubmitEdit}
                  handleCheckFileSize={handleCheckFileSize}
                  handleNewCommentMode={handleNewCommentMode}
                  handleEditCommentMode={handleEditCommentMode}
                  handleImageModal={handleImageModal}
                  handleImageRemoval={handleImageRemoval}
                />
              )
            }
            {/* Call the new comment component with edit comment flag */}
            {
              editCommentMode && (
                <ForumNewComment
                  isReply={true}
                  editComment={true}
                  mainComment={mainComment}
                  comment={comment}
                  commentBody={commentBody}
                  setCommentBody={setCommentBody}
                  setCommentTitle={() => {}}
                  commentImages={commentImages}
                  setCommentImages={setCommentImages}
                  handleSubmitNew={handleSubmitNew}
                  handleSubmitEdit={handleSubmitEdit}
                  handleCheckFileSize={handleCheckFileSize}
                  handleNewCommentMode={handleNewCommentMode}
                  handleEditCommentMode={handleEditCommentMode}
                  handleImageModal={handleImageModal}
                  handleImageRemoval={handleImageRemoval}
                />
              )
            }
          </div>
          {newCommentMode && (
            <div className='text-center mt-1 mb-1'>
              <button type="button" className="btn btn-danger confirm-modal-btnx mr-1" onClick={() => handleNewCommentMode()}>{t('f_cancel')}</button>
              <button type="button" className="btn btn-primary confirm-modal-btnx ml-1" onClick={(e) => handleSubmitNew(e)}>{t('f_submit')}</button>
            </div>
          )}

          {editCommentMode && (
            <div className='text-center mt-1 mb-1'>
              <button type="button" className="btn btn-danger confirm-modal-btnx mr-1" onClick={() => handleEditCommentMode()}>{t('f_cancel')}</button>
              <button type="button" className="btn btn-primary confirm-modal-btnx ml-1" onClick={(e) => handleSubmitEdit(e, comment, true)}>{t('f_submit')} {t('f_edit')}</button>
            </div>
          )}
        </div>
      </div>
      )}
      <div className='container'>
        <div className='forum-thread'>
          <div className='flex-row align-items-start'>
            <img alt='User Avatar' className='mr-05' src={comment?.user?.photo} />
            <div className='forum-thread-body'>
              <div className='flex-col'>
                <div className='flex-row'>
                  <h5>{comment?.user.profile.first_name} {comment?.user.profile.last_name}</h5>
                  {(!editCommentMode && comment?.user_id === user?.id) && (
                    <button type='button' disabled={!mainComment?.active} onClick={() => handleEditCommentMode()} className="reply-btn ml-1 d-flex align-items-center">{t('f_edit')}
                      <img src={EditIcon} style={{width: '22px', height: 'auto', filter: 'invert(100%) sepia(0%) saturate(7496%) hue-rotate(175deg) brightness(113%) contrast(93%)'}} alt='edit'></img>
                    </button>
                    )}
                </div>
                <span className='size-01'>{shortDate} {created_at_no_seconds !== updated_at_no_seconds && `(${t('f_edited')})`}</span>
              </div>
              <div>
                {comment?.images?.length > 0 && <div className='text-bold mt-1'>{t('f_attachedImages')}...</div>}
                <div className='flex-row mb-4'>
                  {comment && populateImages(comment)}
                </div>
              </div>
              {!editCommentMode && <p className='size-4'>{comment?.body}</p>}

              {newCommentMode === false &&  (
                <button type='button' disabled={!mainComment?.active} onClick={() => handleNewCommentMode()} className="reply-btn d-flex align-items-center">{t('f_reply')}
                  <img src={ReplyIcon} style={{width: '22px', height: 'auto', filter: 'invert(100%) sepia(0%) saturate(7496%) hue-rotate(175deg) brightness(113%) contrast(93%)'}} alt='edit'></img>
                </button>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PropertyForumReplyDisplay.propTypes = {
  user: propTypes.instanceOf(Object).isRequired,
  mainComment: propTypes.instanceOf(Object),
  comment: propTypes.instanceOf(Object).isRequired,
  populateImages: propTypes.func.isRequired,
  handleSubmitNew: propTypes.func.isRequired,
  handleSubmitEdit: propTypes.func.isRequired,
  handleCheckFileSize: propTypes.func.isRequired,
  handleImageModal: propTypes.func.isRequired,
  handleImageRemoval: propTypes.func.isRequired
};

export default PropertyForumReplyDisplay;
