/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import propTypes, { object } from 'prop-types';
import { Link } from 'react-router-dom';
import { addTenantByInviteToken, viewNotification } from '../../misc/apiRequests';
import { useTranslation } from 'react-i18next';
import Favorite from '../../../assets/images/favorite.svg';
import notificationsBtn from '../../../assets/images/notification.svg';
import accountBtn from '../../../assets/images/settings.svg';
import '../../../assets/css/notifications.css';
import PageIndex from '../../presentational/pageIndex';
import Swal from 'sweetalert2';
import ProfileBlank from '../../../assets/images/account/profile_blank.png';

const Notifications = ({
  user, notifications,
  perPage, page, maxPages,
  setPage, setPerPage,
  handleMessagesModal,
  newNotice,
  handleLoader, setRedirect,
  navbar = false, noticeOnly = false
}) => {
  const { t } = useTranslation();

  const [noticeID, setNoticeID] = useState();
  const [showNotifications, setShowNotifications] = useState(false);

  // const handleIndexChange = (e, nextPage = false, more = false) => {
  //   e.preventDefault();
  //   if(more) {return setPerPage(perPage + 10)}
  //   if(!nextPage && ((page - 1) !== 0)) setPage(page - 1);
  //   if(nextPage && ((page + 1) <= maxPages)) setPage(page + 1);
  // };

  const handleTokenInvite = async (notice) => {
    const noticeMessage = JSON.parse(notice.info)?.message;
    const invitationToken = JSON.parse(notice.info)?.invitation_token;

    Swal.fire({
      title: "Invite Tenant by Email",
      html: `
        <form class="d-flex flex-column align-items-center">
          <div class="mt-2 mb-2 w-75">
            <div>${noticeMessage}</div>
            <div>Would you like to accept the tenant invite?</div>
          </div>
        </form>
      `,
      showCancelButton: true,
      confirmButtonText: "Submit",
      focusConfirm: false,
      preConfirm: () => {
        const inviteHash = {
          invitation_token: invitationToken,
        };

        handleLoader(true);
        return addTenantByInviteToken(inviteHash)
          .then(response => {
            handleLoader(false);
            return response
          });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((response) => {
      console.log(response);
      if (response.isDismissed) return
      if (response?.value?.success) handleMessagesModal('Invite Accepted', response?.value?.message, 'success');
      if (!response?.value?.success) {
        // handleModal(response.errors);
        Swal.fire({
          position: 'middle',
          icon: 'error',
          title: Array.isArray(response.value.errors) ? response.value.errors.join("\n") : response.value.errors,
          showConfirmButton: false,
          timer: 3000
        })
      }
    });
  }

  const handleView = (e, notice) => {
    e.preventDefault();

    if (notice.viewed) {
      if (['tenant_deny', 'tenant_add'].includes(notice.notice_type)) setRedirect(`/properties/${notice.property_id}`);
      if (notice.notice_type === 'tenant_remove') setRedirect(`/properties/${notice.property_id}?review=true`);
      if (notice.notice_type === 'inquiry') setRedirect(`/properties/edit/${notice.property_id}`);
      if (notice.notice_type === 'repair') setRedirect(`/properties/forms/${notice.property_id}/comment/${notice.comment_id}`);
      if (notice.notice_type === 'tenant_invite') handleTokenInvite(notice);
      setShowNotifications(false);
    } else {
    handleLoader(true);
    viewNotification(notice.id)
       .then(response => {
         if (response.success) {
          // %w[advertisement inquiry note repair tenant_add tenant_remove tenant_deny rating]
          if (['tenant_deny', 'tenant_add'].includes(notice.notice_type)) setRedirect(`/properties/${notice.property_id}`);
          if (notice.notice_type === 'tenant_remove') setRedirect(`/properties/${notice.property_id}?review=true`);
          if (notice.notice_type === 'inquiry') setRedirect(`/properties/edit/${notice.property_id}`);
          if (notice.notice_type === 'repair') setRedirect(`/properties/forms/${notice.property_id}/comment/${notice.comment_id}`);
          if (notice.notice_type === 'tenant_invite') handleTokenInvite(notice);
          setShowNotifications(false);
        }
         if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
         handleLoader(false);
       });
    }
  };

  const handleNotifications = e => {
    e.preventDefault();

    setShowNotifications(!showNotifications);
  };

  const populateNotifications = (viewed = false) => notifications.filter(n => n.viewed === viewed).map(notice => {
    const dateEnd = notice?.created_at.indexOf('T');
    const shortDate = notice?.created_at.substring(0, dateEnd);

    const isJson = (str) => {
      try {
        JSON.parse(str);
      } catch (e) {
        return str;
      }
      return JSON.parse(str)?.message
    }
    return (
      <button type='button' key={notice.id} className='bare-btn notification container rounded' onClick={e => handleView(e, notice)}>
        <span className='pb-1 fs-6' style={{color: viewed ? '#cdc7c7' : 'black'}}>{isJson(notice.info)}</span>
        <span className='notice-date'>{shortDate}</span>
        {!notice.viewed && <div className='notice-alert'>x</div>}
      </button>
    )
  });

  const notificationModal = () => (
    <div className='notification-modal rounded p-3'>
      <h3 className='ml-05 mb-1'>{t('notifications')}</h3>
      {/* <h3 className='mt-1 ml-05 border-bottom'>Unread</h3> */}
      <div className='flex-col flex-nowrap align-items-start'>
        {populateNotifications(false)}
      </div>
      {/* <h3 className='mt-1 ml-05 border-bottom text-grey'>Old</h3> */}
      <div className='flex-col flex-nowrap align-items-start'>
        {populateNotifications(true)}
      </div>
      <div className='p-1 mt-2'>
        <PageIndex setPage={setPage} setPerPage={setPerPage} page={page} perPage={perPage} maxPages={maxPages} viewMore={false} />
      </div>
    </div>
  );


  const navFullSetup = (
    <div id="Notifications" className="">
      {showNotifications && <button
          id='navBackDrop'
          onClick={e => handleNotifications(e)}
        />}
      <Link to="/favorites" className="nav-acct-btn">
        <img alt='favorite' src={Favorite} style={{width: '40px'}}/>
      </Link>
      <button type='button' className='nav-notify-btn' onClick={e => handleNotifications(e)}>
        <img className='small-img' src={notificationsBtn} alt='Notifications icon' />
        {newNotice && <div className='notice-alert'>x</div>}
      </button>
      <Link id='account' to="/account" className="nav-acct-btn">
        {user?.id ? <img className='small-img-bubble' alt='Profile upload' src={user.profile_photo || ProfileBlank}></img> :
          <img className='small-img' src={accountBtn} alt='Account settings icon' />
        }
      </Link>
      {showNotifications && notificationModal()}
    </div>
  );

  const navNoticeOnly = noticeOnly ? (
    <div id="Notifications" className="">
      {showNotifications && <button
          id='navBackDrop'
          onClick={e => handleNotifications(e)}
        />}
      <button type='button' className='nav-notify-btn' onClick={e => handleNotifications(e)}>
        <img className='small-img small-bell' src={notificationsBtn} alt='Notifications icon' />
        {newNotice && <div className='notice-alert'>x</div>}
      </button>
      {showNotifications && notificationModal()}
    </div>
  ) : navFullSetup;

  return navbar ? (
    <div id="Notifications" className="">
      {showNotifications && <button
          id='navBackDrop'
          onClick={e => handleNotifications(e)}
        />}
      <div className='d-flex flex-row'>
        <Link id='account' to="/account" className="">
          {user?.id ? (<div className='flex-row'>{t('myAccount')} <img className='ml-05 small-img-bubble' alt='Profile upload' src={user.profile_photo ? user.profile_photo : ProfileBlank}></img></div>) :
            (<div className='flex-row'>{t('myAccount')} <img className='ml-05 small-img' src={accountBtn} alt='Account settings icon' /></div>)
          }
        </Link>
        <button type='button' className='nav-notify-btn d-sm-none' onClick={e => handleNotifications(e)}>
          <img className='small-img small-bell' src={notificationsBtn} alt='Notifications icon' />
          {newNotice && <div className='notice-alert'>x</div>}
        </button>
      </div>

      {showNotifications && notificationModal()}
    </div>
  )
  : navNoticeOnly
};

Notifications.propTypes = {
  setPage: propTypes.func.isRequired,
  setPerPage: propTypes.func.isRequired,
  perPage: propTypes.number.isRequired,
  page: propTypes.number.isRequired,
  maxPages: propTypes.number.isRequired,
  newNotice: propTypes.bool.isRequired,
  handleLoader: propTypes.func.isRequired,
  setRedirect: propTypes.func.isRequired,
  user: propTypes.instanceOf(Object),
  notifications: propTypes.instanceOf(Array),
};

export default Notifications;
