/* eslint-disable no-unused-vars */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import propTypes from 'prop-types';
import { newAppointment, fetchAppointments } from '../../misc/apiRequests';
import PaymentModal from '../../presentational/properties/payments/paymentModal';
import Swal from 'sweetalert2';
import Exit from '../../../assets/images/exit.svg';
import ArrowLeft from '../../../assets/images/arrow_left.svg';
import ArrowRight from '../../../assets/images/arrow_right.svg';
import '../../../assets/css/appointment.css';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { windowWidth } from '../../misc/misc';

const AppointmentTable = ({ property, setShowCalendarModal, handleLoader }) => {
  // eslint-disable-next-line camelcase
  const { t } = useTranslation();

  const today = useMemo(() => new Date(), []);
  const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
  const { id } = property;
  const [showModal, setShowModal] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [searchDate, setSearchDate] = useState();
  const [step, setStep] = useState(1);
  const [month, setMonth] = useState(today?.getMonth());
  // const [slots, setSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateEnd, setSelectedDateEnd] = useState(null);
  // const [htmlDesc, setHtmlDesc] = useState();

  // How far into the future appointments can be made;
  const monthLimit = 3;
  const cleaningDays = 1; // Amount of days after a rental is finished being rented for cooldown to clean

  const apptTableRef = useRef(null);

  useEffect(() => {
    if(searchDate) {
      const searchDateArray = searchDate.split('-');
      const newSearchDate = new Date(parseInt(searchDateArray[0]), parseInt(searchDateArray[1]) - 2, parseInt(searchDateArray[2])).toISOString().split('T')[0];
      fetchAppointments(id, newSearchDate)
      .then(response => {
        if (response.success) {
          setAppointments(response.records);
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'top-end',
            toast: true,
            timerProgressBar: true,
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });
    }
  }, [handleLoader, id, searchDate]);

  const handleMakeAppointment = (cancel = false) => {
    if (cancel) return setShowCalendarModal(false);

    handleLoader(true);
    const appointment = {
                          rental_date: selectedDate,
                          rental_date_until: selectedDateEnd
                        };
    newAppointment(id, appointment)
      .then(response => {
        if (response.success) {
          Swal.fire({
            position: 'top-end',
            toast: true,
            timerProgressBar: true,
            icon: 'success',
            title: response.message,
            showConfirmButton: false,
            timer: 3000
          })
          setShowCalendarModal(false);
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            // showConfirmButton: false,
            // timer: 2000
          })
        }
        handleLoader(false);
      });
  };

  const handleMonth = (next = true) => {
    if(next && (month - today?.getMonth() >= monthLimit)) return;
    if(!next && month === today?.getMonth()) return;
    if (!next) setMonth(month - 1);
    if (next) setMonth(month + 1);
  };

  const handleStepReset = () => {
    setStep(1);
    setSelectedDate(null);
    setSelectedDateEnd(null);
  };

  const slot = useCallback((day, active = true) => {
    let isDisabled = false;
    if(month === today.getMonth()) {
      isDisabled = (day + 1) <= today.getDate();
    } else if(month > today.getMonth()){
      isDisabled = false;
    } else if(month < today.getMonth()){
      isDisabled = true;
    }

    if(!active) isDisabled = true;
    const yearLabel = today.getFullYear();
    const monthLabel = (month + 1) >= 10 ? month + 1 : `0${month + 1}`
    const dayLabel = (day + 1) >= 10 ? day + 1 : `0${day + 1}`
    const dataLabel = `${yearLabel}-${monthLabel}-${dayLabel}`;

    // Compare with fetched appointments
    appointments.forEach(x => {
      const endDateArray = x.rental_date_until.split('-');
      // Disable a set number of days (cleaningDays) after rental_date_until
      const cleanerCheck = new Date(parseInt(endDateArray[0]), parseInt(endDateArray[1]) - 1, parseInt(endDateArray[2]) + cleaningDays).toISOString().split('T')[0];
      if ((dataLabel >= x.rental_date && dataLabel <= cleanerCheck)) {
        // console.log(dataLabel, x);
        isDisabled = true;
      }
    })
 
    return isDisabled ? 
      `<td><button disabled class='calendar-slot'>
        ${day + 1}
      </button></td>` : 
      `<td><button class='calendar-slot' data-value=${dataLabel}>
        ${day + 1}
      </button></td>`
  }, [appointments, month, today]);

  // Populate Calendar slots, run once on load
  useEffect(() => {
    if (step === 2) return

    let htmlDesc = null;

    const handleSlot = (e, day) => {
      e.preventDefault();
      // console.log('handleSlot');
  
      const selectDate = new Date(today.getFullYear(), month, parseInt(day)).toISOString().split('T')[0];
      // console.log(e.target);
      // console.log(selectDate);
      if (!selectedDate) {
        // e.target.classList.toggle("selected-slot");
        setSelectedDate(selectDate);
      } else if (selectedDate) {
        if (selectDate < selectedDate) return setSelectedDate(null);
        // e.target.classList.toggle("selected-slot");
        setSelectedDateEnd(selectDate);
      }
    };

    const populateCalendar = async () => {
      // const daysJsx = [];
      // console.log('populateCalendar');
      const prevDaysInMonth = new Date(today.getFullYear(), month, 0).getDate();
      const daysInMonth = new Date(today.getFullYear(), month+1, 0).getDate();
      const dayStart = new Date(today.getFullYear(), month, 1).getDay();
      const dayEnd = new Date(today.getFullYear(), month+1, 0).getDay();
      let p = dayStart;
      // console.log(today, 'prevDaysInMonth: ', prevDaysInMonth, 'daysInMonth: ', daysInMonth, 'dayStart: ', dayStart)
      let htmlStore = ``;
      let isFirst = true;
      for(let d = 0; d < daysInMonth + dayStart; d++) {
        if (d % 7 === 0) {
          isFirst ? htmlStore += '<tr>' : htmlStore += '</tr><tr>';
          isFirst = false;
        }
        // Start week off with leftover days from previous month
        for(p; p > 0; p--) { htmlStore += slot(prevDaysInMonth - p, false); d += 1; }
  
        // Current days within the month
        htmlStore += slot(d - dayStart);
      }
      // Finish week off with next month dates
      for(let d = 0; d < 6 - dayEnd; d++) {
        htmlStore += slot(d, false);
      }
  
      // console.log(htmlStore);
      // console.log(windowWidth());
      const shorten = windowWidth() < 900;
      const theader = `
        <tr class='calendar-days'>
          <th>${shorten ? t('f_sunday').substring(0,3) : t('f_sunday')}</th>
          <th>${shorten ? t('f_monday').substring(0,3) : t('f_monday')}</th>
          <th>${shorten ? t('f_tuesday').substring(0,3) : t('f_tuesday')}</th>
          <th>${shorten ? t('f_wednesday').substring(0,3) : t('f_wednesday')}</th>
          <th>${shorten ? t('f_thursday').substring(0,3) : t('f_thursday')}</th>
          <th>${shorten ? t('f_friday').substring(0,3) : t('f_friday')}</th>
          <th>${shorten ? t('f_saturday').substring(0,3) : t('f_saturday')}</th>
        </tr>`
  
      htmlDesc = theader + htmlStore;
      // return theader + htmlStore
    };

    setSearchDate(new Date(today.getFullYear(), month, 1).toISOString().split('T')[0]);

    let allCalendorSlots;
    populateCalendar().then(() => {
      // console.log('SetupSlots');
      const bodyDiv = apptTableRef.current;
      if (bodyDiv) bodyDiv.innerHTML = htmlDesc;
      // Add click function to calendar slot buttons
      allCalendorSlots = document.getElementById("Calendar").querySelectorAll('.calendar-slot')
      // const slotsArray = [...allCalendorSlots].map(x => x);
      // setSlots(slotsArray);
      allCalendorSlots.forEach( c => {
        if(c.dataset.value === selectedDate) c.classList.toggle("selected-slot");
        if(c.dataset.value === selectedDateEnd) c.classList.toggle("selected-slot");
        if(parseInt(c.innerText) < parseInt(selectedDateEnd?.split('-')[2]) && parseInt(c.innerText) > parseInt(selectedDate?.split('-')[2])) c.classList.toggle("selected-slot");
        c.addEventListener("click", function(e) { handleSlot(e, c.innerText); });
        // console.log(c.innerText);
      })
    });

    return () => allCalendorSlots.forEach( c => {
      c.addEventListener("click", function(e) { handleSlot(e, c.innerText); });
    })
  }, [month, selectedDate, selectedDateEnd, slot, t, today, step])


  // Handle Confirmation modal
  useEffect(() => {
    const result = async () => {
      const daysStayed = (Math.ceil((new Date(selectedDateEnd) - new Date(selectedDate))/ (1000 * 3600 * 24)) + 1);
      const val = await Swal.fire({
        title: 'Confirm Dates',
        icon: 'question',
        // toast: true,
        html: `<div>Are you sure you want to schedule these dates?</div>
          <div>From: <b>${selectedDate}</b></div>
          <div>To: <b>${selectedDateEnd}</b></div>
          <div><b>${daysStayed}</b> day(s) stay</div>
        `,
        showConfirmButton: true,
        showDenyButton: false,
        showCancelButton: true,
      })
      return val.isConfirmed;
    }
    if (selectedDateEnd) {
      result().then(res => {
        if(res) { setStep(2); }
        else {
          // Reset Selected Date values when step 1 is set
          setStep(1);
          setSelectedDate(null);
          setSelectedDateEnd(null);
        }
      })
    }
  }, [selectedDate, selectedDateEnd])

  return (
    <div className="modal">
      <button type="button" className="modal-bg" onClick={() => setShowCalendarModal(false)}/>
      <div className="modal-content" style={{minHeight: 'auto', maxWidth: '1000px', maxHeight: 'none'}}>
        <button type="button" className="modal-exit" onClick={() => setShowCalendarModal(false)}><img src={Exit} alt='exit icon' /></button>
        <div className="container-md">
          <div className='text-center mt-1 mb-2'>
            {/* <h3 className='mb-1'>Steps</h3> */}
            <div className='flex-row justify-flex-center'>
              <button className={`calendar-step ${step === 1 && 'selected'}`} onClick={() => handleStepReset()}><h3>Choose Date</h3></button>
              <button className={`calendar-step ml-1 ${step === 2 && 'selected'}`}><h3>Confirmation</h3></button>
            </div>
          </div>
          {/* Calendar Modal */}
          {step === 1 && (
            <div>
              <div className='flex-row justify-flex-center mb-3'>
                <button className='page-btn' onClick={() => handleMonth(false)}><img alt='previous month' src={ArrowLeft}/></button>
                <h3 className='ml-1 mr-1 text-camel'>{months[month]}</h3>
                <button className='page-btn' onClick={() => handleMonth(true)}><img alt='next month' src={ArrowRight}/></button>
              </div>
              <table id='Calendar' className='mx-auto mb-1' ref={apptTableRef}>
              </table>
            </div>
          )}
          {/* Payment Modal */}
          {/* {step === 2 && (
            <PaymentModal
              info={property}
              days={(Math.ceil((new Date(selectedDateEnd) - new Date(selectedDate))/ (1000 * 3600 * 24)) + 1)}
              handleLoader={handleLoader}
              handlePaymentModal={handleMakeAppointment}
              isModal={false}
            />
          )} */}
          {/* Comfirmation Modal */}
          {step === 2 && (
            <div>
              <h3>Confirmation</h3>
              <p>An email notification will be sent to the property owner, and once they confirm your booking you will then also receive a notification.</p>
              <div className='flex-row justify-flex-end mt-1'>
                <button className='page-btn selected text-white mr-2' onClick={() => handleMakeAppointment()}>Confirm</button>
                <button className='page-btn' onClick={() => setShowCalendarModal(false)}>Cancel</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

AppointmentTable.propTypes = {
  property: propTypes.instanceOf(Object).isRequired,
  handleLoader: propTypes.func.isRequired,
  setShowCalendarModal: propTypes.func.isRequired,
};

export default AppointmentTable;
