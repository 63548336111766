/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import '../../assets/css/account.css';
import { useTranslation } from 'react-i18next';

const PageIndex = ({ setPage, setPerPage, perPage, page, maxPages, viewMore = true, funcCallback = null }) => {
  const { t } = useTranslation();

  const handleIndexChange = (e, nextPage = false, more = false) => {
    e.preventDefault();
    if(funcCallback) funcCallback(true);
    if(more) {return setPerPage(perPage + 10)}
    if(!nextPage && ((page - 1) !== 0)) setPage(page - 1);
    if(nextPage && ((page + 1) <= maxPages)) setPage(page + 1);
  };

  return viewMore ? <button onClick={(e) => handleIndexChange(e, false, true)} className='mt-1 button-search'>{t('vmore')}</button> : (<div className='page-index m-1 d-flex align-items-center justify-content-center'>
          <button className='btn btn-secondary' onClick={(e) => handleIndexChange(e)}>{t('prev')}</button>
          <span className='p-1'>{page}/{maxPages || 1}</span>
          <button className='btn btn-secondary' onClick={(e) => handleIndexChange(e, true)}>{t('next')}</button>
        </div>
      )
};

PageIndex.propTypes = {
  // handleIndexChange: propTypes.func.isRequired,
  setPage: propTypes.func.isRequired,
  setPerPage: propTypes.func.isRequired,
  page: propTypes.number.isRequired,
  perPage: propTypes.number.isRequired,
  maxPages: propTypes.number.isRequired,
};

export default PageIndex;
