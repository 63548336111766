/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchConfiguration } from '../misc/apiRequests';
import PropertyListing from '../functional/properties/propertyListing';
// import Star from '../../assets/images/Star.svg';
import { islands } from '../misc/jsonData/islandData';
// import SlideShowSection from '../functional/home/sliderSection';
import propTypes from 'prop-types';
import Swal from 'sweetalert2';

import Ad1 from '../../assets/images/ads/ad1.jpeg';
import Ad2 from '../../assets/images/ads/ad2.jpeg';
// import Ad3 from '../../assets/images/ads/ad3.jpeg';

const ads = [
  (<div className='ad'>
    <h3 className='text-center mb-05'>Why become a member?</h3>
    {/* <span className='text-grey text-bold'>Ad</span> */}
    <img src={Ad1} alt='advertisement depiction'/>
    <p>
      <span className='a text-bold mb-05'>Because your investment is our business!</span><br></br>
      <span className='b'>You’ve worked hard to get to where you are, and we want to help you stay there.</span>
      <span className='mt-05'>Our property owners network alliance allows you to screen applicants before their approval, protecting your investment and your peace of mind.</span>
    </p>
  </div>),
  (<div className='ad'>
    <h3 className='text-center mb-05'>Why become a member?</h3>
    {/* <span className='text-grey text-bold'>Ad</span> */}
    <img src={Ad2} alt='advertisement depiction'/>
    <p>
      <span className='a'><b>We understand just how stressful it can be to relocate</b>, and to locate an apartment within a timely fashion, and a price range within budget.</span>
      <span className='mt-05'>There are thousands of apartments out there that are being overlooked everyday that may be your perfect fit, so we brought them all under one roof to make your life easy.</span>
      <span className='mt-05 text-italic' style={{textIndent: '6px'}}>So stop looking so hard… And don’t worry, they’ll find you!</span>
    </p>
  </div>),
  // (<div className='ad'>
  //   <h3 className='text-center mb-05'>Why become a member?</h3>
  //   {/* <span className='text-grey text-bold'>Ad</span> */}
  //   <img src={Ad3} alt='advertisement depiction'/>
  //   <p>
  //     <span className='a text-bold'>Because we take your business and safety serious!</span><br></br>
  //     <span className='b mt-05'>With <b>Rentalfaqz.com</b>, you can now rest easy knowing that your monthly rent has been deposited directly into your bank account;
  //       saving you valuable time, and preventing any risk that may come along with in person transactions.</span>
  //   </p>
  // </div>)
];

const Home = ({user, handlePropertyRequests, handlePropertyRemove, handlePropertySelect, setRedirect, handleLoader, handleModal}) => {
  const { t } = useTranslation();

  // const [rentals, setRentals] = useState(0);
  // const [homes, setHomes] = useState(0);
  // const [airbnb, setAirbnb] = useState(0);
  const [island, setIsland] = useState('new providence');
  const [bgImage, setBgImage] = useState('');
  const [selectedAd, setSelectedAd] = useState(ads[0]);
  const mainPhrase = t('header');
  const slogan = t('header2');

  const determineAd = () => {
    const max = 2; // Number of ads possible
    const randomNum = Math.floor(Math.random() * max);

    setSelectedAd(ads[randomNum]);
  };

  const handleSubmit = e => {
    e.preventDefault();
    // setRedirect('/search')
    return null;
  };

  const populateIslands = () => islands.map((data, index) => (
    <option className='size-1 search-select-opt' key={index} value={data.name}>{`${data.name}, Bahamas`}</option>
  ));

  // Grab API Configuration
  useEffect(() => {
    determineAd(); // Set Random Member Advertisement
    handleLoader(true);
    fetchConfiguration()
      .then(response => {
        if (response.success) {
          setIsland(response.record.default_island);
          setBgImage(response.record.home_page_image);
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'top-end',
            toast: true,
            timerProgressBar: true,
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });
  }, [handleLoader, handleModal]);

  // Grab all featured Rentals
  // useEffect(() => {
  //   handleLoader(true);
  //   fetchFeaturedProperties('rental', 1, 1)
  //     .then(response => {
  //       if (response.success) {
  //         setRentals(response.records_params.no_of_records);
  //       }
  //       if (!response.success) handleModal(response.errors);
  //       handleLoader(false);
  //     });
  // }, [handleLoader, handleModal]);

  // // Grab all featured Homes
  // useEffect(() => {
  //   handleLoader(true);
  //   fetchFeaturedProperties('home', 1, 1)
  //     .then(response => {
  //       if (response.success) {
  //         setHomes(response.records_params.no_of_records);
  //       }
  //       if (!response.success) handleModal(response.errors);
  //       handleLoader(false);
  //     });
  // }, [handleLoader, handleModal]);

  // // Grab all featured AirBnB
  // useEffect(() => {
  //   handleLoader(true);
  //   fetchFeaturedProperties('air bnb', 1, 1)
  //     .then(response => {
  //       if (response.success) {
  //         setAirbnb(response.records_params.no_of_records);
  //       }
  //       if (!response.success) handleModal(response.errors);
  //       handleLoader(false);
  //     });
  // }, [handleLoader, handleModal]);

  return (
    <div>
      {/* <section id="Slider">
        <SlideShowSection />
      </section> */}
      <section id='Main-1'>
        <div className='container-img nav-shadow-sm'>
          {/* <div className='mt-2 hide-sm'></div> */}
          <div className='parent-box'>

            {/* Mobile Screens */}
            <div className='overlay-bg-dark hide-xl hide-lg hide-md' />
            <div className='overlay-bg flex-column hide-xl hide-lg hide-md'>
              <div className='container-md'>
                <div className=' home-h1 hide-xl hide-lg hide-md'>
                  <h1 className='home-header text-white'>{mainPhrase}</h1>
                  <div className='text-white mb-1 slogan fs-5'>{slogan}</div>
                  <form className='nav-search-form' onSubmit={handleSubmit}>
                    <select className='nav-search-bar fs-5' required name="island" id="by_island" value={island} onChange={e => setIsland(e.target.value)}>
                      {populateIslands()}
                    </select>
                    <button type="submit" className='nav-button-search'>{t('search')}</button>
                  </form>
                </div>
              </div>
            </div>

            {/* Medium Screens  */}
            <div className='overlay-bg-dark hide-xl hide-lg hide-sm' />
            <div className='overlay-bg flex-column justify-flex-center hide-xl hide-lg hide-sm'>
              <div className='container'>
                <div className=' home-h1 hide-xl hide-lg hide-sm'>
                  <h1 className='home-header text-white mx-auto'>{mainPhrase}</h1>
                  <div className='text-white mb-5 slogan fs-3'>{slogan}</div>
                  <form className='nav-search-form' onSubmit={handleSubmit}>
                    <select className='nav-search-bar fs-4' required name="island" id="by_island" value={island} onChange={e => setIsland(e.target.value)}>
                      {populateIslands()}
                    </select>
                    <button type="submit" className='nav-button-search fs-4'>{t('search')}</button>
                  </form>
                </div>
              </div>
            </div>

            {/* Large screens */}
            <div className=' home-h1 hide-sm hide-md'>
              <h1 className='home-header text-white'>{mainPhrase}</h1>
              <div className='text-white mb-5 slogan fs-3'>{slogan}</div>
              <form className='nav-search-form' onSubmit={handleSubmit}>
                <select className='nav-search-bar fs-4' required name="island" id="by_island" value={island} onChange={e => setIsland(e.target.value)}>
                  {populateIslands()}
                </select>
                <button type="submit" className='nav-button-search fs-4'>{t('search')}</button>
              </form>
            </div>
            <div className='half-horizontal-img' style={{backgroundImage: `url(${bgImage})`}}>
              <div className='overlay-bg hide-sm hide-md' style={{backgroundColor: "#0c13143d"}}></div>
            </div>
          </div>
        </div>
        <div>
          {/* {rentals > 0 && (
            <div className='section-a'>
              <div className='container'>
                <div className='flex-row p-1 justify-flex-center'>
                  <img className='featured-star' src={Star} alt='star rating'/>
                  <h3 className='text-center sub-header'>Featured Rental Listings</h3>
                </div>
                <PropertyListing
                  user={user}
                  handlePropertyRequests={handlePropertyRequests}
                  handlePropertyRemove={handlePropertyRemove}
                  handlePropertySelect={handlePropertySelect}
                  handleLoader={handleLoader}
                  handleModal={handleModal}
                  propertyType = 'rental' />
              </div>
            </div>
          )}
          {homes > 0 && (
            <div className='section-b'>
              <div className='container'>
                <div className='flex-row p-1 justify-flex-center'>
                  <img className='featured-star' src={Star} alt='star rating'/>
                  <h3 className='text-center sub-header'>Featured Home Listings</h3>
                </div>
                <PropertyListing
                  user={user}
                  handlePropertyRequests={handlePropertyRequests}
                  handlePropertyRemove={handlePropertyRemove}
                  handlePropertySelect={handlePropertySelect}
                  handleLoader={handleLoader}
                  handleModal={handleModal}
                  propertyType = 'home' />
              </div>
            </div>
          )}
          {airbnb > 0 && (
            <div className='section-a'>
              <div className='container'>
                <div className='flex-row p-1 justify-flex-center'>
                  <img className='featured-star' src={Star} alt='star rating'/>
                  <h3 className='text-center sub-header'>Featured Air BnB Listings</h3>
                </div>
                <PropertyListing
                  user={user}
                  handlePropertyRequests={handlePropertyRequests}
                  handlePropertyRemove={handlePropertyRemove}
                  handlePropertySelect={handlePropertySelect}
                  handleLoader={handleLoader}
                  handleModal={handleModal}
                  propertyType = 'air bnb' />
              </div>
            </div>
          )} */}
          {/* All Properties */}
          <div className='section-b'>
            <div className='container'>
              <div className='px-2'>
                <h2 className='text-center sub-header'>{t('header3')} {`${island}, Bah`}</h2>
              </div>
              <PropertyListing
                user={user}
                byIsland={island}
                handlePropertyRequests={handlePropertyRequests}
                handlePropertyRemove={handlePropertyRemove}
                handlePropertySelect={handlePropertySelect}
                setRedirect={setRedirect}
                handleLoader={handleLoader}
                handleModal={handleModal}
                propertyType = 'all' />
            </div>
          {/* <div className='' style={{backgroundColor: 'rgb(239 239 226)', padding: '2em'}}>
            <div className='container text-whitex'>
              <h3 className='text-center' style={{textTransform: 'none'}}>Welcome to Rentalfaqz.com</h3>
              <p style={{fontSize: '0.8em'}}>
                Rentalfaqz.com is a search engine and a
                advertisement platform that allows apartment
                seekers to discover the new a beginning that’s the
                perfect fit for them.
                We are both proud and ecstatic that you have
                found yourself here with us today; here’s what we
                have to offer!
              </p>
            </div>
          </div> */}
            <div className='containerx mt-2'>
              {selectedAd}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

Home.propTypes = {
  user: propTypes.instanceOf(Object).isRequired,
  handlePropertyRequests: propTypes.func.isRequired,
  handlePropertyRemove: propTypes.func.isRequired,
  handlePropertySelect: propTypes.func.isRequired,
  setRedirect: propTypes.func.isRequired,
  handleLoader: propTypes.func.isRequired,
  handleModal: propTypes.func.isRequired,
};

export default Home;
